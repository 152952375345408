import * as React from 'react';
import {
  Dimensions,
  Keyboard,
  KeyboardEvent,
  LayoutAnimation,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  style?: StyleProp<ViewStyle>;
  topSpacing?: number;
}

const KeyboardSpacer: React.FC<Props> = props => {
  const {style, topSpacing} = props;
  const [keyboardSpace, setKeyboardSpace] = React.useState(0);
  const updateKeyboardSpace = React.useCallback(
    (event: KeyboardEvent) => {
      if (!event.endCoordinates) {
        return;
      }
      if (Platform.OS === 'ios') {
        LayoutAnimation.configureNext({duration: 200});
      } else {
        LayoutAnimation.configureNext(defaultAnimation);
      }
      const {height} = Dimensions.get('window');
      setKeyboardSpace(
        height - event.endCoordinates.screenY + (topSpacing || 0),
      );
    },
    [topSpacing],
  );
  const resetKeyboardSpace = React.useCallback((event: KeyboardEvent) => {
    if (Platform.OS === 'ios') {
      LayoutAnimation.configureNext({duration: 200});
    } else {
      LayoutAnimation.configureNext(defaultAnimation);
    }
    setKeyboardSpace(0);
  }, []);
  React.useEffect(() => {
    if (Platform.OS !== 'ios') {
      return;
    }
    const listeners = [
      Keyboard.addListener(
        Platform.select({
          android: 'keyboardDidShow',
          default: 'keyboardWillShow',
        }),
        updateKeyboardSpace,
      ),
      Keyboard.addListener(
        Platform.select({
          android: 'keyboardDidHide',
          default: 'keyboardWillHide',
        }),
        resetKeyboardSpace,
      ),
    ];
    return () => {
      listeners.forEach(listener => listener.remove());
    };
  }, []);
  return <View style={[styles.container, {height: keyboardSpace}, style]} />;
};

export default React.memo(KeyboardSpacer);

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const defaultAnimation = {
  create: {
    duration: 300,
    property: LayoutAnimation.Properties.opacity,
    type: LayoutAnimation.Types.easeInEaseOut,
  },
  duration: 500,
  update: {
    springDamping: 200,
    type: LayoutAnimation.Types.spring,
  },
};
