import React from 'react';

import {StyleSheet, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import {ControlledInput, Label, Field, ErrorMessage} from '@/components/form';
import {PrimaryButton} from '@/components/ui/buttons';

export type Inputs = {
  resetPasswordToken: string;
  password: string;
  passwordConfirmation: string;
};

interface Props {
  resetPasswordToken: string;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const FormEdit: React.FC<Props> = props => {
  const {resetPasswordToken, errors, onSubmit} = props;
  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    defaultValues: {
      resetPasswordToken,
      password: '',
      passwordConfirmation: '',
    },
  });
  const onPress = handleSubmit(onSubmit);
  return (
    <View style={styles.container}>
      {errors?.resetPasswordToken?.message && (
        <Field>
          <ErrorMessage>{errors.resetPasswordToken.message}</ErrorMessage>
        </Field>
      )}
      <Field>
        <Label>パスワード(8文字以上)</Label>
        <ControlledInput
          placeholder={'パスワードを入力'}
          secureTextEntry={true}
          onSubmitEditing={onPress}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'password',
          }}
          errors={errors}
        />
      </Field>
      <Field>
        <Label>確認用パスワード</Label>
        <ControlledInput
          placeholder={'パスワードを再度入力'}
          secureTextEntry={true}
          onSubmitEditing={onPress}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'passwordConfirmation',
          }}
          errors={errors}
        />
      </Field>
      <View style={styles.submitButton}>
        <PrimaryButton disabled={!isValid} onPress={onPress}>
          送信する
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(FormEdit);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 25,
  } as ViewStyle,
  submitButton: {
    alignItems: 'center',
    marginTop: 24,
  } as ViewStyle,
});
