import React from 'react';
import {
  GestureResponderEvent,
  Image,
  ImageStyle,
  PanResponderGestureState,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';
import Draggable from 'react-native-draggable';

import SubmitButtonWithEditable from '@/components/utils/SubmitButtonWithEditable';

import {getDoubleResizeImageUrl} from '@/helpers/ImageHelper';

import Actor from '@/entities/Actor';

export type Inputs = {
  faceCenterPosition: {top: number; left: number};
};

interface Props {
  actor: Actor;
  defaultValues?: Inputs;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {actor, defaultValues, errors, onSubmit} = props;
  const {
    control,
    formState: {isValid},
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues:
      defaultValues || ({faceCenterPosition: {top: 0.25, left: 0.5}} as Inputs),
  });
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(actor, {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      }),
    }),
    [actor],
  );
  const faceCenterPosition = watch('faceCenterPosition');
  const x = React.useMemo(() => -faceCenterPosition.left * AVATOR_SIZE, []);
  const y = React.useMemo(
    () => -(faceCenterPosition.top - 0.15) * AVATOR_SIZE,
    [],
  );
  const onDragRelease = React.useCallback(
    (
      event: GestureResponderEvent,
      gestureState: PanResponderGestureState,
      bounds: {left: number; top: number; right: number; bottom: number},
    ) => {
      const top = -(bounds.top / AVATOR_SIZE - 0.15);
      const left = -(bounds.left / AVATOR_SIZE);
      setValue('faceCenterPosition', {top, left});
    },
    [],
  );
  const onPress = handleSubmit(onSubmit);
  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <View style={styles.imageWrapper}>
          <Draggable x={x} y={y} onDragRelease={onDragRelease}>
            <Image source={source} style={styles.image} />
          </Draggable>
        </View>
        <Text style={styles.text}>全表情に反映されます</Text>
      </View>
      <SubmitButtonWithEditable disabled={!isValid} onPress={onPress} />
    </View>
  );
};

export default React.memo(Form);

const AVATOR_SIZE = 240;

const IMAGE_WIDTH = AVATOR_SIZE * 2;
const IMAGE_HEIGHT = IMAGE_WIDTH;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
  } as ViewStyle,
  inner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  imageWrapper: {
    width: AVATOR_SIZE,
    height: AVATOR_SIZE,
    borderRadius: AVATOR_SIZE / 2,
    overflow: 'hidden',
    borderColor: '#efefef',
    borderWidth: 1,
  } as ViewStyle,
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  } as ImageStyle,
  text: {
    color: '#666',
    fontSize: 12,
    marginTop: 16,
  } as TextStyle,
});
