import React from 'react';

import {BasicModal, BasicModalProps} from '@/components/modals';

const ApplicationModalContext = React.createContext<{
  showModal?: (modalProps: BasicModalProps) => void;
  hideModal?: () => void;
}>({});

interface Props extends React.PropsWithChildren {}

const ApplicationModalProvider: React.FC<Props> = React.memo(props => {
  const {children} = props;
  const [modalProps, setModalProps] = React.useState<BasicModalProps | null>(
    null,
  );
  const showModal = React.useCallback((modalProps: BasicModalProps) => {
    setModalProps(modalProps);
  }, []);
  const hideModal = React.useCallback(() => {
    setModalProps(null);
  }, []);
  const onRequestClose = hideModal;
  return (
    <ApplicationModalContext.Provider value={{showModal, hideModal}}>
      {children}
      {modalProps && (
        <BasicModal {...modalProps} onRequestClose={onRequestClose} />
      )}
    </ApplicationModalContext.Provider>
  );
});

export {ApplicationModalContext, ApplicationModalProvider};

export default ApplicationModalContext;
