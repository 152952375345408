import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {Control, FieldValues, FieldPath, FieldErrors} from 'react-hook-form';

import {Field, ControlledLabel, ControlledInput} from '@/components/form';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  style?: StyleProp<ViewStyle> | undefined;
  control?: Control<TFieldValues>;
  name: TName;
  errors?: FieldErrors<TFieldValues>;
}

function ControlledFaceNameOtherInputField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {style, control, name, errors} = props;
  return (
    <Field style={style}>
      <ControlledLabel
        label={'その他の表情名'}
        control={control}
        name={name}
        errors={errors}
        badgeType={'required'}
      />
      <ControlledInput
        placeholder={'表情名を入力'}
        required={true}
        controllerProps={{
          control,
          rules: {
            required: {value: true, message: '入力してください'},
            maxLength: 10,
          },
          name,
        }}
        errors={errors}
        hideErrorMessage={true}
      />
    </Field>
  );
}

export default React.memo(
  ControlledFaceNameOtherInputField,
) as typeof ControlledFaceNameOtherInputField;
