import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import dateFormat from 'dateformat';
import numeral from 'numeral';

import BalanceTransaction from '@/entities/BalanceTransaction';

interface Props {
  balanceTransaction: BalanceTransaction;
}

const BalanceTransactionListItem: React.FC<Props> = props => {
  const {balanceTransaction} = props;
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <Text style={styles.title}>{balanceTransaction.description}</Text>
        <Text style={styles.date}>
          {dateFormat(balanceTransaction.createdAt, 'yyyy.mm.dd')}
        </Text>
      </View>
      <View style={styles.right}>
        <Text
          style={[
            styles.balance,
            balanceTransaction.grossProfit < 0 ? styles.balanceMinus : null,
          ]}>
          <Text style={styles.balanceUnit}>
            {balanceTransaction.grossProfit > 0
              ? '＋'
              : balanceTransaction.grossProfit < 0
              ? '−'
              : ''}
            ￥
          </Text>
          {numeral(Math.abs(balanceTransaction.grossProfit)).format('0,0')}
        </Text>
      </View>
    </View>
  );
};

export default React.memo(BalanceTransactionListItem);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
  } as ViewStyle,
  left: {} as ViewStyle,
  right: {} as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 12,
  } as TextStyle,
  date: {
    color: '#666',
    fontSize: 10,
    marginTop: 10,
  } as TextStyle,
  balance: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  balanceMinus: {
    color: '#f23406',
  } as TextStyle,
  balanceUnit: {
    fontSize: 14,
  } as TextStyle,
});
