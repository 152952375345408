import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {Layout} from '@/components/layout';
import {LinkButton} from '@/components/ui/buttons';
import {Paragraph} from '@/components/content';

import useCurrentUser from '@/hooks/useCurrentUser';

export interface Params {}

const Page = () => {
  const linkTo = useLinkTo();
  const {currentUser} = useCurrentUser();
  const onPressRetransmission = React.useCallback(() => {
    linkTo('/users/confirmation/new');
  }, []);
  const onPressBack = React.useCallback(() => {
    linkTo('/users/sign_up');
  }, []);
  return (
    <Layout title={'仮会員登録完了'} back={true} onPressBack={onPressBack}>
      <View style={styles.container}>
        <Text style={styles.title}>メールを送信しました</Text>
        <Paragraph marginBottom={32}>
          メールをご確認いただき、認証を行ってください。
        </Paragraph>
        <Text style={styles.subTitle}>認証メールが届かない方</Text>
        <Paragraph marginVertical={16}>
          入力したメールアドレスに誤りがないかご確認ください。
        </Paragraph>
        <View style={styles.emailBox}>
          <Text style={styles.email}>{currentUser?.email}</Text>
        </View>
        <Paragraph marginVertical={16}>
          メールアドレスが間違っていた場合は
          <LinkButton color={'#383838'} bold={true} onPress={onPressBack}>
            再度登録
          </LinkButton>
          ください。
        </Paragraph>
        <Paragraph marginVertical={16}>
          メールアドレスに誤りがなくても届かない場合は、迷惑フォルダに振り分けられている可能性がございますので、必ずご確認をお願いいたします。
        </Paragraph>
        <Paragraph marginBottom={32}>
          ドメイン指定受信を利用している場合は{'\n'}
          【tapnovel.com】の設定をした上で認証メールを再送信ください。
        </Paragraph>
        <Text style={{textAlign: 'center'}}>
          <LinkButton
            color={'#383838'}
            size={'large'}
            bold={true}
            onPress={onPressRetransmission}>
            再送信はこちら
          </LinkButton>
        </Text>
      </View>
    </Layout>
  );
};

export default React.memo(Page);

const color = '#383838';

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    marginHorizontal: 24,
  } as ViewStyle,
  title: {
    color,
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 32,
    textAlign: 'center',
  } as TextStyle,
  subTitle: {
    color,
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  emailBox: {
    backgroundColor: '#efefef',
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  email: {
    color,
    fontSize: 14,
  } as TextStyle,
});
