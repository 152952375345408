import React from 'react';
import {useLinkTo} from '@react-navigation/native';

import FormEdit, {Inputs} from './components/FormEdit';

import ScreenProps from '@/navigation/ScreenProps';

import {Layout} from '@/components/layout';

import {useRawUpdateMutation} from '@/hooks/swr';
import {buildFieldErrors} from '@/helpers/FormHelper';

export interface Params {
  reset_password_token: string;
}

const Page = (props: ScreenProps<'UserPasswordEdit'>) => {
  const linkTo = useLinkTo();
  const {reset_password_token: resetPasswordToken} = props.route.params;
  const {trigger, isMutating, error} = useRawUpdateMutation(
    '/api/creator/users/password',
  );
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(res => {
      if (res?.ok) {
        linkTo('/users/sign_in');
      }
    });
  }, []);
  return (
    <Layout title={'パスワード再設定'} close={true} loading={isMutating}>
      <FormEdit
        resetPasswordToken={resetPasswordToken}
        errors={convertResponseToErrors(error)}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {apiCreatorUser: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    password: 'password',
    passwordConfirmation: 'passwordConfirmation',
    resetPasswordToken: 'resetPasswordToken',
  });
};
