import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import FacebookLoginButton from './buttons/social_login/FacebookLoginButton';
import LineLoginButton from './buttons/social_login/LineLoginButton';
import TwitterLoginButton from './buttons/social_login/TwitterLoginButton';

const SocialLoginButtons: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={styles.buttons}>
        <FacebookLoginButton />
        <LineLoginButton />
      </View>
    </View>
  );
};

export default React.memo(SocialLoginButtons);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: 16,
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 124,
  } as ViewStyle,
});
