import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {Control, FieldValues, FieldPath, FieldErrors} from 'react-hook-form';

import ResetButton from './ResetButton';

import {
  Field,
  ControlledActorImagePicker,
  ControlledLabel,
} from '@/components/form';

import useTooltipModal, {
  Props as TooltipModalProps,
} from '@/hooks/useTooltipModal';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  control?: Control<TFieldValues>;
  name: TName;
  value: File | null;
  errors?: FieldErrors<TFieldValues>;
  disabled?: boolean;
  disabledReset?: boolean;
  onPressReset: () => void;
}

function ControlledImagePickerField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {control, name, value, errors, disabled, disabledReset, onPressReset} =
    props;
  const tooltipModal = useTooltipModal();
  const onPressImageQuestion = React.useCallback(() => {
    tooltipModal.show(IMAGE_QUESTION);
  }, []);
  return (
    <Field>
      <View style={styles.withReset}>
        <ControlledLabel
          label={'仕様について'}
          control={control}
          name={name}
          errors={errors}
          hiddenEnteredLabel={true}
          onPressQuestion={onPressImageQuestion}
        />
        {value && (
          <View style={{position: 'absolute', top: 0, right: 0}}>
            <ResetButton
              disabled={disabled || disabledReset}
              onPress={onPressReset}
            />
          </View>
        )}
      </View>
      <ControlledActorImagePicker
        disabled={disabled}
        controllerProps={{
          control,
          name,
          rules: {
            required: {value: true, message: '入力してください'},
          },
        }}
      />
    </Field>
  );
}

export default React.memo(
  ControlledImagePickerField,
) as typeof ControlledImagePickerField;

const styles = StyleSheet.create({
  withReset: {
    minHeight: 32,
    paddingTop: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
});

const IMAGE_QUESTION: TooltipModalProps = {
  title: '仕様について',
  description:
    'サイズは2039 × 2894での入稿となります。\n全身表示されませんので、太ももあたりまでをご制作ください。',
};
