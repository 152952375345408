import * as React from 'react';

import CurrentUserStatusContext from '@/contexts/CurrentUserStatusContext';
import useCurrentUserAndAccessToken from './useCurrentUserAndAccessToken';
import {
  useRawCreateMutation,
  useUpdateMutation,
  useRawDestroyMutation,
} from './swr';
import {sendDefaultCustomEvent, setUser} from '@/helpers/AnalyticsHelper';

import CurrentUser from '@/entities/CurrentUser';

interface CreateParams {
  apiCreatorUser: {
    email: string;
    password: string;
    passwordConfirmation: string;
    facebookAccount?: {
      accessToken: string;
      userId: string;
      screenName: string;
    };
    twitterAccount?: {
      accessToken: string;
      accessTokenSecret: string;
      userId: string;
      screenName: string;
    };
    lineAccount?: {
      accessToken: string;
      userId: string;
      screenName: string;
    };
  };
}

interface UpdateParams {
  apiCreatorUser: {
    userName?: string;
    userProfileAttributes?: {
      description: string;
      birthday: string | null;
      image: File | null;
      homePageUrl: string | null;
      twitterAccountUrl: string | null;
    };
    email?: string;
    password?: string;
    passwordConfirmation?: string;
    currentPassword?: string;
  };
}

const useCurrentUserRegistration = () => {
  const {currentUser, setCurrentUser} = React.useContext(
    CurrentUserStatusContext,
  );
  const {update: updateToken, destroy: destroyToken} =
    useCurrentUserAndAccessToken();
  const {trigger: createTrigger, ...createResponse} = useRawCreateMutation(
    '/api/creator/temporary_users/registration',
  );
  const {trigger: updateTrigger, ...updateResponse} =
    useUpdateMutation<CurrentUser>('/api/creator/users');
  const {trigger: destroyTrigger, ...destroyResponse} =
    useRawDestroyMutation('/api/creator/users');
  const create = React.useCallback(
    (params: CreateParams) =>
      createTrigger(params)
        .then(updateToken)
        .then(currentUser => {
          if (currentUser) {
            setUser(currentUser);
            sendDefaultCustomEvent({
              resourceType: 'creator/temporary_user',
              resourceId: currentUser.id,
              resourceName: currentUser.userName,
              actionName: 'create',
            });
          }
          return currentUser;
        }),
    [],
  );
  const currentEnabled = currentUser?.enabled;
  const update = React.useCallback(
    (params: UpdateParams) =>
      updateTrigger(params).then(currentUser => {
        if (setCurrentUser && currentUser) {
          setCurrentUser(currentUser);
        }
        if (currentUser) {
          setUser(currentUser);
          sendDefaultCustomEvent({
            resourceType: 'creator/user',
            resourceId: currentUser.id,
            resourceName: currentUser.userName,
            actionName:
              !currentEnabled && currentUser.enabled ? 'create' : 'update',
          });
        }
        return currentUser || null;
      }),
    [currentEnabled],
  );
  const destroy = React.useCallback(
    () =>
      destroyTrigger({})
        .then(destroyToken)
        .then(() => {
          if (currentUser) {
            sendDefaultCustomEvent({
              resourceType: 'creator/user',
              resourceId: currentUser.id,
              actionName: 'destroy',
            });
            setUser(null);
          }
        }),
    [currentUser],
  );
  return {
    currentUser,
    create,
    createResponse,
    update,
    updateResponse,
    destroy,
    destroyResponse,
  };
};

export default useCurrentUserRegistration;
