import React from 'react';
import {useLinkTo, useFocusEffect} from '@react-navigation/native';
import {WebViewMessageEvent} from 'react-native-webview';

import NoIllustrations from './components/NoIllustrations';
import ServiceExplanation from './components/ServiceExplanation';
import ActorList from './actors/components/ActorList';
import ScrollableTabView from '@/components/ui/ScrollableTabView';
import {BottomButton} from '@/components/ui/buttons';

import {Layout} from '@/components/layout';
import TapNovelWebView from '@/components/utils/TapNovelWebView';
import BellIconWithBadgeCount from '@/components/utils/BellIconWithBadgeCount';
import {MenuIcon} from '@/components/icons';

import useCurrentUser from '@/hooks/useCurrentUser';
import useCurrentUserStatus from '@/hooks/useCurrentUserStatus';
import useRedirectionByUserStatus from '@/hooks/useRedirectionByUserStatus';
import {usePaginatedGetWithResponse} from '@/hooks/swr';

import Actor from '@/entities/Actor';

export interface Params {}

const Page = () => {
  const linkTo = useLinkTo();
  const mounted = React.useRef(false);
  const {currentUser} = useCurrentUser();
  const {currentUserStatus, get: getCurrentUserStatus} = useCurrentUserStatus();
  const {
    data: draftData,
    size: draftSize,
    setSize: setDraftSize,
    mutate: mutateDraft,
  } = usePaginatedGetWithResponse<Actor>(
    currentUser ? '/api/creator/actors' : null,
    {published: false},
    {keepPreviousData: true},
  );
  const {
    data: publishedData,
    size: publishedSize,
    setSize: setPublishedSize,
    mutate: mutatePublished,
  } = usePaginatedGetWithResponse<Actor>(
    currentUser ? '/api/creator/actors' : null,
    {published: true},
    {keepPreviousData: true},
  );
  React.useEffect(() => {
    if (currentUser) {
      mounted.current = false;
    }
  }, [currentUser]);
  const updateDraftActors = React.useCallback(() => {
    if (!mounted.current) {
      return;
    }
    mutateDraft(undefined, true);
  }, [currentUser, mutateDraft]);
  const updatePublishedActors = React.useCallback(() => {
    if (!mounted.current) {
      return;
    }
    mutatePublished(undefined, true);
  }, [currentUser, mutatePublished]);
  const draftActors = draftData
    ?.flatMap(row => row.data)
    .filter(row => row.creatorUserId === currentUser?.creatorUserId);
  const publishedActors = publishedData
    ?.flatMap(row => row.data)
    .filter(row => row.creatorUserId === currentUser?.creatorUserId);
  useFocusEffect(updateDraftActors);
  useFocusEffect(updatePublishedActors);
  useFocusEffect(
    React.useCallback(() => {
      if (currentUser) {
        getCurrentUserStatus();
      }
    }, [currentUser]),
  );
  React.useEffect(() => {
    mounted.current = true;
  }, []);
  const onMessage = React.useCallback(
    (event: WebViewMessageEvent) => {
      if (typeof event.nativeEvent.data !== 'string') {
        return;
      }
      const [action, url] = event.nativeEvent.data.split(',');
      if (action == 'dispatchAction' && url) {
        linkTo(url);
      }
    },
    [linkTo],
  );
  useRedirectionByUserStatus();
  const linkToMenu = React.useCallback(() => {
    linkTo('/menu');
  }, []);
  const linkToNotifications = React.useCallback(() => {
    linkTo('/notifications');
  }, []);
  const onEndReachedDraft = React.useCallback(
    (info: {distanceFromEnd: number}) => setDraftSize(draftSize + 1),
    [draftSize],
  );
  const onEndReachedPublished = React.useCallback(
    (info: {distanceFromEnd: number}) => setPublishedSize(publishedSize + 1),
    [publishedSize],
  );
  const onNotifyActorDelete = React.useCallback(() => {
    updateDraftActors();
    updatePublishedActors();
  }, [mutateDraft, mutatePublished]);
  const onPressNewActor = React.useCallback(() => linkTo('/actors/new'), []);
  const rightButton = React.useMemo(() => {
    return currentUser?.enabled
      ? {
          children: <MenuIcon size={16} />,
          handler: linkToMenu,
          tintColor: 'black',
        }
      : undefined;
  }, [currentUser]);
  const innerRightButton = React.useMemo(() => {
    const unconfirmedNotificationsCount =
      currentUserStatus?.unconfirmedNotificationsCount || 0;
    const notificationsCount = currentUserStatus?.notificationsCount || 0;
    const disabled = notificationsCount === 0;
    return {
      children: (
        <BellIconWithBadgeCount
          badgeCount={unconfirmedNotificationsCount}
          disabled={notificationsCount === 0}
        />
      ),
      handler: disabled ? undefined : linkToNotifications,
      tintColor: 'black',
    };
  }, [
    currentUserStatus?.unconfirmedNotificationsCount,
    currentUserStatus?.notificationsCount,
  ]);
  if (!currentUser) {
    return (
      <Layout hideNavigationBar={true} scrollable={false}>
        <TapNovelWebView path={'/creator'} onMessage={onMessage} />
      </Layout>
    );
  }
  if (!currentUserStatus) {
    return null;
  }
  if (!currentUserStatus.serviceAgreementCodes.includes('tapnovel_creator')) {
    return <ServiceExplanation />;
  }
  return (
    <Layout
      title={'イラスト一覧'}
      rightButton={rightButton}
      innerRightButton={innerRightButton}
      scrollable={false}>
      {draftActors &&
        publishedActors &&
        (draftActors.length === 0 && publishedActors.length === 0 ? (
          <NoIllustrations />
        ) : (
          <>
            <ScrollableTabView
              tabs={[
                {
                  label: '非公開',
                  element: (
                    <ActorList
                      actors={draftActors}
                      labelPrefix={'非公開の'}
                      onNotifyActorDelete={onNotifyActorDelete}
                      onEndReached={onEndReachedDraft}
                    />
                  ),
                },
                {
                  label: '公開済み',
                  element: (
                    <ActorList
                      actors={publishedActors}
                      labelPrefix={'公開済みの'}
                      onNotifyActorDelete={onNotifyActorDelete}
                      onEndReached={onEndReachedPublished}
                    />
                  ),
                },
              ]}
            />
            <BottomButton onPress={onPressNewActor}>
              新しいイラストを登録する
            </BottomButton>
          </>
        ))}
    </Layout>
  );
};

export default React.memo(Page);
