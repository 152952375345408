import React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import AnnouncementListItem from './AnnouncementListItem';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';

import Announcement from '@/entities/Announcement';

interface Props {
  announcements: Announcement[];
  refreshing?: boolean | null;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
  onRefresh?: () => void;
}

const AnnouncementList: React.FC<Props> = props => {
  const {announcements, refreshing, onEndReached, onRefresh} = props;
  const keyExtractor = React.useCallback(
    (announcement: Announcement, index: number): string => `${announcement.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (
      info: ListRenderItemInfo<Announcement>,
    ): React.ReactElement<any> | null => (
      <AnnouncementListItem announcement={info.item} />
    ),
    [],
  );
  return (
    <FlatList
      data={announcements}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
      windowSize={5}
      onEndReachedThreshold={0.1}
      refreshing={refreshing}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onRefresh={onRefresh}
    />
  );
};

export default React.memo(AnnouncementList);
