import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import numeral from 'numeral';

import ResourceRevenueAggregation from '@/entities/summary/ResourceRevenueAggregation';
import ActorInfo from './ActorInfo';

import Actor from '@/entities/Actor';

interface Props {
  resourceRevenueAggregation: ResourceRevenueAggregation;
}

const ResourceRevenueInfo: React.FC<Props> = props => {
  const {resourceRevenueAggregation} = props;
  const {resource} = resourceRevenueAggregation;
  return (
    <View style={styles.container}>
      <Text style={styles.name}>{resource.name}</Text>
      {resourceRevenueAggregation.type === 'Actor' ? (
        <ActorInfo actor={resource as Actor} />
      ) : null}
      <View style={styles.revenue}>
        <View style={styles.revenueRow}>
          <Text style={styles.revenueRowLabel}>販売数</Text>
          <Text style={styles.revenueRowValue}>
            {numeral(resourceRevenueAggregation.salesQuantity).format('0,0')}
          </Text>
        </View>
        <View style={styles.revenueRow}>
          <Text style={styles.revenueRowLabel}>売上</Text>
          <Text style={styles.revenueRowValue}>
            ￥{numeral(resourceRevenueAggregation.salesTotal).format('0,0')}
          </Text>
        </View>
        <View style={styles.revenueRowEarnings}>
          <Text style={styles.revenueRowLabel}>獲得収益</Text>
          <Text style={styles.revenueRowValue}>
            ￥{numeral(resourceRevenueAggregation.grossProfit).format('0,0')}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default React.memo(ResourceRevenueInfo);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 11,
    marginRight: 40,
    justifyContent: 'center',
  } as ViewStyle,
  name: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 6,
  } as TextStyle,
  revenue: {
    marginTop: 4,
    width: '100%',
  } as ViewStyle,
  revenueRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  revenueRowEarnings: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopColor: '#f5f5f5',
    borderTopWidth: 1,
    marginTop: 4,
    paddingTop: 4,
  } as ViewStyle,
  revenueRowLabel: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  revenueRowValue: {
    color: '#e7b600',
    fontSize: 11,
  } as TextStyle,
});
