import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import ResourceRevenueAggregation from '@/entities/summary/ResourceRevenueAggregation';
import Actor from '@/entities/Actor';

import {getDoubleResizeImageUrl} from '@/helpers/ImageHelper';

interface Props {
  actorRevenueAggregation: ResourceRevenueAggregation<Actor>;
}

const ActorRevenueAggregationInfo: React.FC<Props> = props => {
  const {actorRevenueAggregation} = props;
  const {resource} = actorRevenueAggregation;
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(resource, {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      }),
    }),
    [],
  );
  return (
    <View style={styles.container}>
      <Image source={source} style={styles.image} />
      <View style={styles.right}>
        <Text style={styles.costumeName}>{resource.defaultCostumeName}</Text>
        <View style={styles.revenue}>
          <View style={styles.revenueRows}>
            <View style={styles.revenueRow}>
              <Text style={styles.revenueRowLabel}>表情差分</Text>
              <Text style={styles.revenueRowValue}>
                {numeral(resource.faceCount).format('0,0')}
              </Text>
            </View>
            <View style={styles.revenueRow}>
              <Text style={styles.revenueRowLabel}>販売金額</Text>
              <Text style={styles.revenueRowValue}>
                ￥{numeral(resource.amount).format('0,0')}
              </Text>
            </View>
          </View>
          <View style={styles.revenueRows}>
            <View style={styles.revenueRow}>
              <Text style={styles.revenueRowLabel}>販売数</Text>
              <Text style={styles.revenueRowValue}>
                {numeral(actorRevenueAggregation.salesQuantity).format('0,0')}
              </Text>
            </View>
            <View style={styles.revenueRow}>
              <Text style={styles.revenueRowLabel}>売上</Text>
              <Text style={styles.revenueRowValue}>
                ￥{numeral(actorRevenueAggregation.salesTotal).format('0,0')}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.revenueRowEarnings}>
          <Text style={styles.revenueRowEarningsLabel}>獲得収益</Text>
          <Text style={styles.revenueRowEarningsValue}>
            ￥{numeral(actorRevenueAggregation.grossProfit).format('0,0')}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default React.memo(ActorRevenueAggregationInfo);

const IMAGE_WIDTH = 70;
const IMAGE_HEIGHT = 100;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#fff',
    paddingVertical: 16,
  } as ViewStyle,
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  } as ImageStyle,
  right: {
    marginLeft: 16,
    flex: 1,
  } as ViewStyle,
  costumeName: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 6,
  } as TextStyle,
  revenue: {
    marginTop: 4,
    flex: 1,
  } as ViewStyle,
  revenueRows: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 12,
  } as ViewStyle,
  revenueRow: {
    flex: 1,
    justifyContent: 'space-between',
  } as ViewStyle,
  revenueRowLabel: {
    color: '#383838',
    fontSize: 10,
    paddingBottom: 4,
  } as TextStyle,
  revenueRowValue: {
    color: '#e7b600',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  revenueRowEarnings: {
    borderTopColor: '#f5f5f5',
    borderTopWidth: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 10,
    marginRight: 16,
  } as ViewStyle,
  revenueRowEarningsLabel: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  revenueRowEarningsValue: {
    color: '#e7b600',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
