import React from 'react';
import {
  GestureResponderEvent,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import {RightIcon} from '../icons';

import {ItemValue, Item} from '@/components/value/types';

export interface Props<IValue extends ItemValue = ItemValue> {
  value?: IValue | IValue[];
  data?: Array<Item<IValue>>;
  placeholder?: string;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

function TextLink<IValue extends ItemValue = ItemValue>(props: Props<IValue>) {
  const {value, data, placeholder, onPress} = props;
  const valueString = convertValueToString(value, data);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      {valueString ? (
        <Text style={styles.text}>{valueString}</Text>
      ) : (
        <Text style={styles.textPlaceholder}>{placeholder || '選択する'}</Text>
      )}
      <RightIcon color={'#999999'} size={13} />
    </TouchableOpacity>
  );
}

export default React.memo(TextLink) as typeof TextLink;

const convertValueToString = (
  value?: ItemValue | ItemValue[] | null,
  data?: Item[],
): string => {
  if (!value) {
    return '';
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return '';
    } else {
      return value.map(v => convertValueToString(v, data)).join(',');
    }
  } else {
    if (data) {
      return data.find(v => v.value === value)?.label || `${value}`;
    } else {
      return `${value}`;
    }
  }
};

const container = {
  backgroundColor: 'white',
  borderBottomWidth: 1,
  borderColor: '#efefef',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 8,
  paddingVertical: 8,
  paddingRight: 8,
} as ViewStyle;

const text = {
  backgroundColor: 'white',
  fontSize: 14,
} as TextStyle;

const styles = StyleSheet.create({
  container,
  text,
  textPlaceholder: {
    ...text,
    color: '#999',
  } as TextStyle,
});
