import {modalScreenOptions} from '../ScreenOptions';
import RootStack from '../RootStack';

import UserEdit from '@/pages/user/edit';

const UnacceptedMemberScreens = (
  <RootStack.Group screenOptions={modalScreenOptions}>
    <RootStack.Screen
      name={'UserEdit'}
      component={UserEdit}
      options={{title: 'プロフィール'}}
    />
  </RootStack.Group>
);

export default UnacceptedMemberScreens;
