import React from 'react';
import {TextInputProps} from 'react-native';
import {
  Controller,
  UseControllerProps,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
  FieldErrors,
} from 'react-hook-form';

import Textarea from './Textarea';
import ErrorMessage from './ErrorMessage';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends TextInputProps {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  errors?: FieldErrors<TFieldValues>;
}

function ControlledTextarea<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {controllerProps, errors, ...textInputProps} = props;
  const {name, rules, control} = controllerProps;
  const message = errors ? errors[name]?.message?.toString() : null;
  const render = React.useCallback(
    ({
      field: {onChange, onBlur, value},
    }: {
      field: ControllerRenderProps<TFieldValues, TName>;
    }) => (
      <>
        {message && <ErrorMessage>{message}</ErrorMessage>}
        <Textarea
          isInvalid={!!message}
          {...textInputProps}
          onBlur={onBlur}
          onChangeText={onChange}
          value={value}
        />
      </>
    ),
    [message],
  );
  return (
    <Controller control={control} rules={rules} render={render} name={name} />
  );
}

export default React.memo(ControlledTextarea) as typeof ControlledTextarea;
