import React from 'react';
import {Image, StyleSheet, View, ViewStyle} from 'react-native';

import SvgUriAdapter from '@/components/utils/SvgUriAdapter';

import CurrentUser from '@/entities/CurrentUser';

import iconNoimageCreatorUri from '@/assets/svgs/icon-noimage-creator.svg';

interface Props {
  currentUser: CurrentUser;
}

const CreatorAvator: React.FC<Props> = props => {
  const {currentUser} = props;
  const imageUrl = getImageUrl(currentUser);
  return (
    <View style={styles.container}>
      {imageUrl ? (
        <Image source={{uri: imageUrl}} style={sizeStyle} />
      ) : (
        <SvgUriAdapter uri={iconNoimageCreatorUri} {...sizeStyle} />
      )}
    </View>
  );
};

export default React.memo(CreatorAvator);

const size = 60;
const width = size;
const height = size;
const borderRadius = size / 2;

const sizeStyle = {width, height};

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    width,
    height,
    borderRadius,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});

const getImageUrl = (currentUser: CurrentUser): string | null => {
  if (!currentUser.userProfile?.hasImage) {
    return null;
  }
  if (currentUser.userProfile.originalImageUrl) {
    return currentUser.userProfile.originalImageUrl;
  } else {
    return null;
  }
};
