import React from 'react';

import Select, {Props as SelectProps} from './Select';

import {DatePeriod, Item} from '@/components/value/types';

export interface Props
  extends Omit<SelectProps<DatePeriod>, 'value' | 'items' | 'onValueChange'> {
  value: DatePeriod;
  onChangeValue: (value: DatePeriod) => void;
}

const DatePeriodSelect: React.FC<Props> = props => {
  const {value, onChangeValue, ...selectProps} = props;
  return (
    <Select
      {...selectProps}
      items={items}
      value={value}
      onValueChange={onChangeValue}
    />
  );
};

export default React.memo(DatePeriodSelect);

const items: Array<Item<DatePeriod>> = [
  {label: '今日', value: 'today'},
  {label: '昨日', value: 'yesterday'},
  {label: '過去7日間', value: 'last_7days'},
  {label: '過去30日間', value: 'last_30days'},
  {label: '全期間', value: 'all'},
];
