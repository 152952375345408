import React from 'react';
import {ListRenderItemInfo, FlatList} from 'react-native';

import MenuListItem, {Props as ItemProps} from './MenuListItem';

import {
  HistoryIcon,
  ListCharactersIcon,
  NewsIcon,
  QuestionIcon,
  RevenueIcon,
  SettingIcon,
  TransferIcon,
  UploadIcon,
} from '@/components/icons/menu';

interface Props {}

const MenuList: React.FC<Props> = props => {
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ItemProps>) => <MenuListItem {...info.item} />,
    [],
  );
  return (
    <FlatList
      style={style}
      data={data}
      scrollEnabled={false}
      numColumns={numColumns}
      renderItem={renderItem}
    />
  );
};

const style = {margin: 16};
const numColumns = 3;

export default React.memo(MenuList);

const unalignedData: Array<ItemProps> = [
  {
    icon: <UploadIcon size={32} />,
    label: 'イラスト登録',
    url: '/actors/new',
  },
  {
    icon: <ListCharactersIcon size={32} />,
    label: 'イラスト一覧',
    url: '/',
  },
  {
    icon: <RevenueIcon size={32} />,
    label: '収益管理',
    url: '/revenue',
  },
  {
    icon: <TransferIcon size={32} />,
    label: '振込申請',
    url: '/transfer',
  },
  {
    icon: <HistoryIcon size={32} />,
    label: '残高履歴',
    url: '/balance',
  },
  {
    icon: <NewsIcon size={32} />,
    label: 'お知らせ',
    url: '/announcements',
  },
  {
    icon: <SettingIcon size={32} />,
    label: '設定',
    url: '/settings',
  },
  {
    icon: <QuestionIcon size={32} />,
    label: 'ヘルプ',
    url: '/helps',
  },
];

const emptyItem: ItemProps = {icon: null, label: '', url: ''};

const mod = unalignedData.length % numColumns;
const need = mod > 0 ? numColumns - mod : 0;

const data: Array<ItemProps> = [
  ...unalignedData,
  ...Array.from(Array(need)).map(() => emptyItem),
];
