import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {Control, FieldValues, FieldPath, FieldErrors} from 'react-hook-form';

import {Field, ControlledLabel, ControlledSelect} from '@/components/form';

import useTooltipModal, {
  Props as TooltipModalProps,
} from '@/hooks/useTooltipModal';

import actorFaceNameUri from '@/assets/images/tips/actor-face-name.png';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  style?: StyleProp<ViewStyle> | undefined;
  control?: Control<TFieldValues>;
  name: TName;
  errors?: FieldErrors<TFieldValues>;
}

function ControlledFaceNameSelectField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {style, control, name, errors} = props;
  const tooltipModal = useTooltipModal();
  const onPressFaceNameQuestion = React.useCallback(() => {
    tooltipModal.show(FACE_NAME_QUESTION);
  }, []);
  return (
    <Field style={style}>
      <ControlledLabel
        label={'表情名'}
        control={control}
        name={name}
        errors={errors}
        badgeType={'required'}
        onPressQuestion={onPressFaceNameQuestion}
      />
      <ControlledSelect
        includeBlank={'表情名を選択'}
        cancelText={'キャンセル'}
        confirmText={'決定'}
        items={items}
        required={true}
        controllerProps={{
          control,
          rules: {
            required: {value: true, message: '入力してください'},
          },
          name,
        }}
      />
    </Field>
  );
}

export default React.memo(
  ControlledFaceNameSelectField,
) as typeof ControlledFaceNameSelectField;

const FACE_NAME_QUESTION: TooltipModalProps = {
  title: '表情名',
  description: '該当する表情名を選びましょう。',
  imageProps: {
    source: actorFaceNameUri,
    style: {width: 279, height: 160},
  },
};

const items = [
  {label: '微笑む', value: '微笑む'},
  {label: '笑う', value: '笑う'},
  {label: '怒る', value: '怒る'},
  {label: '悲しい', value: '悲しい'},
  {label: '泣く', value: '泣く'},
  {label: '驚く', value: '驚く'},
  {label: '照れる', value: '照れる'},
  {label: '惚れる', value: '惚れる'},
  {label: '目を閉じる', value: '目を閉じる'},
  {label: '無表情', value: '無表情'},
  {label: '困る', value: '困る'},
  {label: '苦しい', value: '苦しい'},
  {label: 'その他', value: 'その他'},
];

export const AVAILABLE_FACE_NAMES = items.map(item => item.label);
