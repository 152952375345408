import React from 'react';
import {ScrollView, StyleSheet, Text, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import FormWarning from './FormWarning';
import PreviewSection from './PreviewSection';

import {
  Field,
  ControlledLabel,
  ControlledMultiSwitch,
  ControlledInput,
  ControlledSelect,
} from '@/components/form';
import {CheckButton, PrimaryButton} from '@/components/ui/buttons';
import {Item} from '@/components/value/types';
import useTooltipModal, {
  Props as TooltipModalProps,
} from '@/hooks/useTooltipModal';

import ResourcePublicationRangeType from '@/entities/enums/ResourcePublicationRangeType';

export type Inputs = {
  published: boolean;
  publicationRangeType: ResourcePublicationRangeType;
  publicationRangeValue: string | null;
};

interface Props {
  actorId: number;
  sellable: boolean;
  defaultValues?: Inputs;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {actorId, sellable, defaultValues, errors, onSubmit} = props;
  const mounted = React.useRef(false);
  const {
    control,
    formState: {isValid},
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues:
      defaultValues ||
      ({
        published: false,
        publicationRangeType: 'no_writer_users',
        publicationRangeValue: null,
      } as Inputs),
  });
  const tooltipModal = useTooltipModal();
  const published = watch('published');
  const publicationRangeType = watch('publicationRangeType');
  const displayStyle = React.useMemo(
    () => (published ? null : styles.hide),
    [!!published],
  );
  const onPress = handleSubmit(onSubmit);
  const onPressPublicationRangeQuestion = React.useCallback(() => {
    tooltipModal.show(PUBLICATION_RANGE_QUESTION);
  }, []);
  React.useEffect(() => {
    if (!mounted.current) {
      return;
    }
    const value = published
      ? sellable
        ? 'every_writer_users'
        : 'limited_creator_users'
      : 'no_writer_users';
    setValue('publicationRangeType', value);
    if (value !== 'limited_creator_users') {
      setValue('publicationRangeValue', '');
    }
  }, [published]);
  React.useEffect(() => {
    mounted.current = true;
  }, []);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);
  const toggleChecked1 = React.useCallback(
    () => setChecked1(!checked1),
    [checked1],
  );
  const toggleChecked2 = React.useCallback(
    () => setChecked2(!checked2),
    [checked2],
  );
  const toggleChecked3 = React.useCallback(
    () => setChecked3(!checked3),
    [checked3],
  );
  const toggleChecked4 = React.useCallback(
    () => setChecked4(!checked4),
    [checked4],
  );
  const checked = checked1 && checked2 && checked3 && checked4;
  const [previewed, setPreviewed] = React.useState(false);
  const onPressPreview = React.useCallback(() => {
    setPreviewed(true);
  }, []);
  return (
    <View style={styles.container}>
      <ScrollView>
        <Field style={styles.field}>
          <ControlledLabel
            label={'公開設定'}
            control={control}
            name={'published'}
            errors={errors}
            hiddenEnteredLabel={true}
          />
          <ControlledMultiSwitch
            items={PUBLISHED_ITEMS}
            controllerProps={{
              control,
              name: 'published',
            }}
            errors={errors}
          />
        </Field>
        <Field style={[styles.field, displayStyle]}>
          <ControlledLabel
            label={'公開範囲'}
            control={control}
            name={'publicationRangeType'}
            errors={errors}
            hiddenEnteredLabel={true}
            onPressQuestion={onPressPublicationRangeQuestion}
          />
          <ControlledSelect
            items={
              sellable
                ? SALE_PUBLICATION_RANGE_ITEMS
                : NOT_FOR_SALE_PUBLICATION_RANGE_ITEMS
            }
            controllerProps={{
              control,
              name: 'publicationRangeType',
            }}
            errors={errors}
          />
          {publicationRangeType === 'limited_writer_users' && (
            <ControlledInput
              placeholder={'ライターID'}
              controllerProps={{
                control,
                rules: {
                  required: {value: true, message: '入力してください'},
                  pattern: {
                    value: /^\d+(,\d+)*$/,
                    message: '不正です',
                  },
                },
                name: 'publicationRangeValue',
              }}
            />
          )}
        </Field>
        {published ? (
          <View style={styles.checkButtonList}>
            <CheckButton
              style={styles.checkButton}
              checked={checked1}
              onPress={toggleChecked1}>
              他サービスで購入したイラストではございません
            </CheckButton>
            <CheckButton
              style={styles.checkButton}
              checked={checked2}
              onPress={toggleChecked2}>
              フリー素材サービスでダウンロードしたイラストではございません
            </CheckButton>
            <CheckButton
              style={styles.checkButton}
              checked={checked3}
              onPress={toggleChecked3}>
              AIで作成したイラストではございません
            </CheckButton>
            <CheckButton
              style={styles.checkButton}
              checked={checked4}
              onPress={toggleChecked4}>
              ご自身が描き、著作権を保有しているイラストです
            </CheckButton>
          </View>
        ) : null}
        {published ? <FormWarning /> : null}
        {published ? (
          <PreviewSection
            url={`/actors/${actorId}/preview`}
            onPress={onPressPreview}
          />
        ) : null}
      </ScrollView>

      <View style={styles.submit}>
        <PrimaryButton
          disabled={published && (!isValid || !checked || !previewed)}
          onPress={onPress}>
          決定
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form);

const PUBLISHED_ITEMS = [
  {label: '公開', value: true},
  {label: '非公開', value: false},
];

const SALE_PUBLICATION_RANGE_ITEMS: Item<ResourcePublicationRangeType>[] = [
  {label: '全タップライター', value: 'every_writer_users'},
  {label: 'タップライター指定', value: 'limited_writer_users'},
  {label: '自分のみ', value: 'limited_creator_users'},
];

const NOT_FOR_SALE_PUBLICATION_RANGE_ITEMS: Item<ResourcePublicationRangeType>[] =
  [{label: '自分のみ', value: 'limited_creator_users'}];

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
  } as ViewStyle,
  field: {
    marginVertical: 8,
  } as ViewStyle,
  checkButtonList: {
    marginHorizontal: 32,
  } as ViewStyle,
  checkButton: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingHorizontal: 8,
    marginVertical: 5,
  } as ViewStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});

const PUBLICATION_RANGE_QUESTION: TooltipModalProps = {
  title: '公開範囲',
  description:
    'イラストの公開範囲を設定することができます。\n自分のみが使用する場合は「自分のみ」を、第三者に販売する場合は「全タップライター」もしくは「タップライター指定」を選択ください。\n「タップライター指定」を選択した場合は、特定のタップライターのみに販売することができます。',
};
