import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';

import {RootStackParamList} from '@/navigation/RootStackParamList';

const useDefaultNavigation = () => {
  return useNavigation<StackNavigationProp<RootStackParamList>>();
};

export default useDefaultNavigation;
