import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useForm, Controller} from 'react-hook-form';

type Inputs = {
  value: string;
};
interface Props {
  onCreate: (value: string) => void;
}

const Form: React.FC<Props> = props => {
  const {onCreate} = props;
  const {control, handleSubmit, reset} = useForm({
    defaultValues: {value: ''},
  });
  const textInputRef = React.useRef<TextInput>(null);
  const onSubmit = React.useCallback(
    ({value}: Inputs) => {
      const text = value
        .trim()
        .replace(/[\s,.*+?^=!:${}()|[\]/\\・／「」【】（）『』。、]+/g, '');
      if (text.length === 0) {
        return;
      }
      onCreate(text);
      reset();
      textInputRef.current?.focus();
    },
    [onCreate],
  );
  const onPress = handleSubmit(onSubmit);
  return (
    <View style={styles.container}>
      <Controller
        control={control}
        rules={{
          maxLength: 100,
        }}
        render={({field: {onChange, onBlur, value}}) => (
          <TextInput
            ref={textInputRef}
            style={styles.input}
            value={value}
            returnKeyType={'done'}
            placeholder={'キーワードを入力'}
            placeholderTextColor={'#aaa'}
            autoFocus={true}
            onChangeText={onChange}
            onBlur={onBlur}
            onSubmitEditing={onPress}
          />
        )}
        name={'value'}
      />
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.button}>追加</Text>
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    height: 44,
    justifyContent: 'space-between',
    borderTopColor: '#E5E5E5',
    borderTopWidth: 1,
  } as ViewStyle,
  input: {
    backgroundColor: 'white',
    color: 'black',
    flex: 1,
    fontSize: 15,
    padding: 15,
  } as TextStyle,
  button: {
    color: '#e7b600',
    fontSize: 15,
    fontWeight: 'bold',
    margin: 15,
  } as ViewStyle,
});
