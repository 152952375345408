import React from 'react';
import {useLinkTo} from '@react-navigation/native';

import FormNew, {Inputs} from './components/FormNew';

import {Layout} from '@/components/layout';

import {useRawCreateMutation} from '@/hooks/swr';
import {buildFieldErrors} from '@/helpers/FormHelper';

export interface Params {}

const Page = () => {
  const linkTo = useLinkTo();
  const {trigger, isMutating, error} = useRawCreateMutation(
    '/api/creator/users/password',
  );
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(res => {
      if (res?.ok) {
        linkTo('/users/sign_in');
      }
    });
  }, []);
  return (
    <Layout title={'パスワード再設定'} close={true} loading={isMutating}>
      <FormNew errors={convertResponseToErrors(error)} onSubmit={onSubmit} />
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {apiCreatorUser: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    email: 'error',
  });
};
