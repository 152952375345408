import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import dateFormat from 'dateformat';
import numeral from 'numeral';

import TransferRequest from '@/entities/TransferRequest';

interface Props {
  transferRequest: TransferRequest;
}

const CompleteMessage: React.FC<Props> = props => {
  const {transferRequest} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>振込申請が完了しました！</Text>
      <View style={styles.box}>
        <View style={styles.boxRow}>
          <Text style={styles.boxRowLabel}>受け取れる金額</Text>
          <Text style={styles.boxRowValue}>
            <Text style={styles.boxRowValueUnit}>￥</Text>
            {numeral(transferRequest.amountWithoutFee).format('0,0')}
          </Text>
        </View>
        <View style={styles.boxRow}>
          <Text style={styles.boxRowLabel}>振込予定日</Text>
          <Text style={styles.boxRowValue}>
            {dateFormat(transferRequest.paymentOn, 'yyyy年mm月dd日')}
          </Text>
        </View>
        <Text style={styles.note}>※振込日は多少前後する可能性がございます</Text>
      </View>
    </View>
  );
};

export default React.memo(CompleteMessage);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fafafa',
    paddingVertical: 32,
    paddingHorizontal: 16,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
  box: {
    height: 135,
    borderRadius: 3,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32,
  } as ViewStyle,
  boxRow: {
    width: '100%',
    paddingHorizontal: 24,
    marginTop: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  boxRowLabel: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  boxRowValue: {
    color: '#383838',
    fontSize: 22,
    fontWeight: 'bold',
  } as TextStyle,
  boxRowValueUnit: {
    fontSize: 18,
  } as TextStyle,
  note: {
    color: '#383838',
    fontSize: 11,
    width: '100%',
    marginTop: 4,
    paddingHorizontal: 24,
    textAlign: 'right',
  } as TextStyle,
});
