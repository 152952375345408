import React from 'react';
import {Platform, ScrollView, StyleSheet, View, ViewStyle} from 'react-native';

import Form from './Form';
import KeywordTags from './Tags';

import KeyboardSpacer from '@/components/utils/KeyboardSpacer';

interface Props {
  values: string[];
  onRemove: (value: string) => void;
  onCreate: (value: string) => void;
}

const FormWithTags: React.FC<Props> = props => {
  const {values, onRemove, onCreate} = props;
  return (
    <View style={styles.container}>
      <ScrollView bounces={false}>
        <KeywordTags values={values} onRemove={onRemove} />
      </ScrollView>
      <View style={styles.form}>
        <Form onCreate={onCreate} />
        <KeyboardSpacer />
      </View>
    </View>
  );
};

export default React.memo(FormWithTags);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  form: {
    backgroundColor: 'white',
    borderTopColor: '#fff',
    borderTopWidth: 1,
    height: 60,
    ...Platform.select({
      web: {position: 'absolute', bottom: 0, left: 0, right: 0},
      default: {},
    }),
  } as ViewStyle,
});
