import React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import BalanceTransactionListHeader from './BalanceTransactionListHeader';
import BalanceTransactionListItem from './BalanceTransactionListItem';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';

import BalanceTransaction from '@/entities/BalanceTransaction';
import Balance from '@/entities/Balance';

interface Props {
  balanceTransactions: BalanceTransaction[];
  balance: Balance;
  ListFooterComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
}

const BalanceTransactionList: React.FC<Props> = props => {
  const {balanceTransactions, balance, ListFooterComponent} = props;
  const keyExtractor = React.useCallback(
    (balanceTransaction: BalanceTransaction, index: number): string =>
      `${balanceTransaction.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (
      info: ListRenderItemInfo<BalanceTransaction>,
    ): React.ReactElement<any> | null => {
      const {item: balanceTransaction} = info;
      return (
        <BalanceTransactionListItem balanceTransaction={balanceTransaction} />
      );
    },
    [],
  );
  return (
    <FlatList
      data={balanceTransactions}
      style={style}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ListHeaderComponent={<BalanceTransactionListHeader balance={balance} />}
      ListFooterComponent={ListFooterComponent}
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(BalanceTransactionList);

const style = {
  backgroundColor: '#fafafa',
};
