import React from 'react';
import {TouchableOpacity, View} from 'react-native';

import Container from './Container';
import Link from './Link';

import useCurrentUserSession from '@/hooks/useCurrentUserSession';
import SvgUriAdapter from '@/components/utils/SvgUriAdapter';
import {RightIcon} from '@/components/icons';
import applicationLinkTo from '@/navigation/applicationLinkTo';
import logoWhiteUri from '@/assets/svgs/logo-white.svg';

interface Props {
  signedIn?: boolean;
}

const ApplicationHeader: React.FC<Props> = props => {
  const {signedIn} = props;
  const [showMenu, setShowMenu] = React.useState(false);
  const {destroy} = useCurrentUserSession();
  React.useEffect(() => {}, [showMenu, setShowMenu]);
  const onPress = React.useCallback(() => applicationLinkTo('/'), []);
  const onPressSignUp = React.useCallback(
    () => applicationLinkTo('/users/sign_up'),
    [],
  );
  const onPressSignIn = React.useCallback(
    () => applicationLinkTo('/users/sign_in'),
    [],
  );
  return (
    <Container signedIn={signedIn}>
      <TouchableOpacity onPress={onPress}>
        <SvgUriAdapter uri={logoWhiteUri} height={30} />
      </TouchableOpacity>
      <View
        style={{
          position: 'absolute',
          right: 20,
        }}>
        {signedIn ? (
          <Link onPress={destroy}>
            ログアウト
            <RightIcon color={'rgb(153, 153, 153)'} size={13} />
          </Link>
        ) : (
          <View style={{flexDirection: 'row'}}>
            <Link onPress={onPressSignUp}>会員登録</Link>
            <Link onPress={onPressSignIn}>ログイン</Link>
          </View>
        )}
      </View>
    </Container>
  );
};

export default React.memo(ApplicationHeader);
