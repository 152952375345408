import React from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';
import {Item, ItemValue} from '@/components/value/types';

interface Props<IValue extends ItemValue> {
  style?: StyleProp<ViewStyle>;
  data: Array<Item<IValue>>;
  focusIndex?: number;
  onValueChange: (itemValue: IValue, itemPosition: number) => void;
}

function Autocomplete<IValue extends ItemValue = ItemValue>(
  props: Props<IValue>,
) {
  const {style, data, onValueChange} = props;
  const ref = React.useRef<FlatList>(null);
  const [focusIndex, setFocusIndex] = React.useState(props.focusIndex);
  const listener = React.useCallback(
    (e: globalThis.KeyboardEvent) => {
      switch (e.code) {
        case 'ArrowUp': {
          const newFocusIndex =
            focusIndex === undefined || focusIndex < 1
              ? data.length - 1
              : focusIndex - 1;
          setFocusIndex(newFocusIndex);
          ref.current?.scrollToIndex({
            index: newFocusIndex,
            animated: false,
          });
          break;
        }
        case 'ArrowDown': {
          const newFocusIndex =
            focusIndex === undefined || focusIndex > data.length - 2
              ? 0
              : focusIndex + 1;
          setFocusIndex(newFocusIndex);
          ref.current?.scrollToIndex({
            index: newFocusIndex,
            animated: false,
          });
          break;
        }
        case 'Enter':
          if (focusIndex !== undefined) {
            const item = data.find((_, i) => i === focusIndex);
            if (item) {
              onValueChange(item.value, focusIndex);
            }
          }
          break;
      }
    },
    [data.length, focusIndex],
  );
  React.useEffect(() => {
    if (Platform.OS === 'web') {
      document.addEventListener('keydown', listener, true);
    }
    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('keydown', listener, true);
      }
    };
  }, [listener]);
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Item<IValue>>): React.ReactElement | null => {
      const onPress = () => {
        onValueChange(info.item.value, info.index);
      };

      return (
        <TouchableOpacity onPress={onPress}>
          <Text
            style={[
              styles.option,
              focusIndex === info.index ? {backgroundColor: '#eee'} : null,
            ]}>
            {info.item.label}
          </Text>
        </TouchableOpacity>
      );
    },
    [focusIndex],
  );
  if (data.length === 0) {
    return null;
  }
  return (
    <FlatList
      ref={ref}
      style={[styles.container, style]}
      data={data}
      ItemSeparatorComponent={ListItemNormalSeparator}
      renderItem={renderItem}
      getItemLayout={(data, index) => {
        return {length: OPTION_HEIGHT, offset: index * OPTION_HEIGHT, index};
      }}
      focusable={true}
      extraData={focusIndex}
    />
  );
}

export default React.memo(Autocomplete) as typeof Autocomplete;

const OPTION_HEIGHT = 45;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 10,
    borderColor: '#E5E5E5',
    borderWidth: 1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderRadius: 4,
    maxHeight: 220,
    shadowOffset: {width: 0, height: 1},
    shadowColor: '#999',
    shadowOpacity: 0.5,
  } as ViewStyle,
  option: {
    color: '#383838',
    padding: 12,
    height: OPTION_HEIGHT,
  } as TextStyle,
});
