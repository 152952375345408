import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import {BellOnIcon, BellOffIcon} from '@/components/icons';
import {useCreateMutation, useUpdateMutation} from '@/hooks/swr';
import UserNotificationSetting from '@/entities/UserNotificationSetting';

interface Props {
  userNotificationSetting: UserNotificationSetting;
}

const NotificationListItem: React.FC<Props> = props => {
  const [userNotificationSetting, setUserNotificationSetting] =
    React.useState<UserNotificationSetting>(props.userNotificationSetting);
  React.useEffect(() => {
    setUserNotificationSetting(props.userNotificationSetting);
  }, [props.userNotificationSetting]);
  const {trigger: createTrigger} = useCreateMutation<UserNotificationSetting>(
    '/api/creator/user_notification_settings',
  );
  const {trigger: updateTrigger} = useUpdateMutation<UserNotificationSetting>(
    `/api/creator/user_notification_settings/${userNotificationSetting.id}`,
  );
  const {
    creatorNotificationSetting: {id: creatorNotificationSettingId},
    enabled,
  } = userNotificationSetting;
  const onPress = React.useCallback(() => {
    const enabled = !userNotificationSetting.enabled;
    (userNotificationSetting.id
      ? updateTrigger({creatorNotificationSetting: {enabled}})
      : createTrigger({
          creatorNotificationSetting: {enabled, creatorNotificationSettingId},
        })
    ).then(userNotificationSetting => {
      if (userNotificationSetting) {
        setUserNotificationSetting(userNotificationSetting);
      }
    });
  }, [userNotificationSetting]);
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {userNotificationSetting.creatorNotificationSetting.name}
      </Text>
      <TouchableOpacity style={styles.bell} onPress={onPress}>
        {userNotificationSetting.enabled ? <BellOnIcon /> : <BellOffIcon />}
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(NotificationListItem);

const styles = StyleSheet.create({
  container: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-between',

    flexDirection: 'row',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
    paddingHorizontal: 16,
  } as TextStyle,
  bell: {
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
