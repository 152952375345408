import React from 'react';
import {useLinkTo, useIsFocused} from '@react-navigation/native';

import navigationRef from '@/navigation/ref';
import useCurrentUser from '@/hooks/useCurrentUser';

import CurrentUser from '@/entities/CurrentUser';

const useRedirectionByUserStatus = () => {
  const isFocused = useIsFocused();
  const linkTo = useLinkTo();
  const [isFocusedDelay, setIsFocusedDelay] = React.useState(isFocused);
  const {currentUser} = useCurrentUser();
  const redirect = React.useCallback(() => {
    if (!currentUser) {
      return;
    }
    if (currentUser.enabled) {
      return;
    }
    const url = getUrlByUserStatus(currentUser);
    if (navigationRef.current?.isReady()) {
      linkTo(url);
    } else {
      setTimeout(() => linkTo(url), 200);
    }
  }, [currentUser]);
  React.useEffect(() => {
    setIsFocusedDelay(isFocused);
  }, [isFocused]);
  React.useEffect(redirect, [redirect, isFocusedDelay]);
  return {redirect};
};

export default useRedirectionByUserStatus;

export const getUrlByUserStatus = (currentUser: CurrentUser) => {
  if (!currentUser.confirmed) {
    return '/temporary_user/registration_completion';
  } else if (!currentUser.acceptedTerms) {
    return '/temporary_user/terms_acceptance';
  } else if (!currentUser.enabled) {
    return '/user/edit';
  } else {
    return '/';
  }
};
