import React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

import {useStyle} from './useStyle';

interface Props extends React.PropsWithChildren {
  style?: ViewStyle;
}

const Container: React.FC<Props> = props => {
  const {style, children} = props;
  const styles = useStyle();
  const flattenStyle = React.useMemo(
    () => StyleSheet.flatten([styles.container, style]) as React.CSSProperties,
    [styles.container, style],
  );
  return (
    <div className={'view-div layout-content'} style={flattenStyle}>
      {children}
    </div>
  );
};

export default React.memo(Container);
