import React from 'react';
import {
  FlatList,
  LayoutChangeEvent,
  ListRenderItemInfo,
  StyleProp,
  ViewStyle,
} from 'react-native';

import CheckGridListItem from './CheckGridListItem';

import {ItemValue, Item} from '@/components/value/types';

import {scaledValue} from '@/styles';

interface Props<IValue extends ItemValue = ItemValue> {
  data: Array<Item<IValue>>;
  values: IValue[];
  maxLength?: number;
  onSelectValue: (item: IValue) => void;
}

function CheckGridList<IValue extends ItemValue = ItemValue>(
  props: Props<IValue>,
) {
  const {data, values, maxLength, onSelectValue} = props;
  const [width, setWidth] = React.useState<number | undefined>(undefined);
  const onLayout = React.useCallback((event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);
  const contentContainerStyle = React.useMemo(():
    | StyleProp<ViewStyle>
    | undefined => {
    if (!width) {
      return undefined;
    }
    return {marginHorizontal: scaledValue(width, 16)};
  }, [width]);
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Item<IValue>>) => {
      const checked = values.includes(info.item.value);
      return (
        <CheckGridListItem
          item={info.item}
          checked={checked}
          width={width}
          disabled={!checked && !!maxLength && values.length >= maxLength}
          onSelectValue={onSelectValue}
        />
      );
    },
    [width, values],
  );
  return (
    <FlatList
      data={data}
      contentContainerStyle={contentContainerStyle}
      onLayout={onLayout}
      numColumns={3}
      renderItem={renderItem}
    />
  );
}

export default React.memo(CheckGridList) as typeof CheckGridList;
