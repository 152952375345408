import React from 'react';
import CurrentUserStatusContext from '@/contexts/CurrentUserStatusContext';

import CurrentUserStatus from '@/entities/CurrentUserStatus';

import {useGetMutation} from './swr';

const useCurrentUserStatus = () => {
  const {currentUserStatus, setCurrentUserStatus} = React.useContext(
    CurrentUserStatusContext,
  );
  const {trigger} = useGetMutation<CurrentUserStatus>(
    '/api/creator/current_user_status',
  );
  const get = React.useCallback(() => {
    return trigger().then(data => {
      if (setCurrentUserStatus) {
        setCurrentUserStatus(data || null);
      }
      return data || null;
    });
  }, []);
  return {currentUserStatus, get};
};

export default useCurrentUserStatus;
