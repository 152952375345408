import React from 'react';
import {Pressable, StyleSheet, View, ViewStyle} from 'react-native';
import {
  Control,
  FieldValues,
  FieldPath,
  FieldErrors,
  useWatch,
} from 'react-hook-form';

import Label from './Label';
import ErrorMessage from './ErrorMessage';
import TextCounter from './TextCounter';
import EnteredLabel from './EnteredLabel';
import RequiredBadge from './RequiredBadge';
import OptionalBadge from './OptionalBadge';
import {QuestionCircleIcon} from '@/components/icons';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  label: string;
  labelOption?: string;
  control?: Control<TFieldValues>;
  name: TName;
  errors?: FieldErrors<TFieldValues>;
  hiddenEnteredLabel?: boolean;
  maxLength?: number;
  holdLabelInErrorMessage?: boolean;
  badgeType?: 'required' | 'optional';
  onPressQuestion?: () => void;
}

function ControlledLabel<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>): React.ReactElement<any, any> {
  const {
    label,
    labelOption,
    control,
    errors,
    name,
    hiddenEnteredLabel,
    maxLength,
    badgeType,
    onPressQuestion,
  } = props;
  const value = useWatch({control, name});
  const message = generateErrorMessage({
    ...props,
    value,
    message: errors && errors[name]?.message?.toString(),
  });
  return (
    <View style={styles.container}>
      <View style={styles.main}>
        <View style={styles.left}>
          <Content margin={0}>
            <Label>
              {label}
              {labelOption}
            </Label>
          </Content>
          {onPressQuestion && (
            <Pressable style={styles.question} onPress={onPressQuestion}>
              <QuestionCircleIcon size={14} />
            </Pressable>
          )}
          {badgeType === 'required' ? (
            <Content>
              <RequiredBadge />
            </Content>
          ) : badgeType === 'optional' ? (
            <Content>
              <OptionalBadge />
            </Content>
          ) : null}
          {!hiddenEnteredLabel && !!value && (
            <View style={styles.content}>
              <EnteredLabel />
            </View>
          )}
        </View>
        {maxLength && (
          <View style={styles.right}>
            <TextCounter length={value.length} maxLength={maxLength} />
          </View>
        )}
      </View>
      {message && (
        <View style={styles.error}>
          <ErrorMessage>{message}</ErrorMessage>
        </View>
      )}
    </View>
  );
}

export default React.memo(ControlledLabel) as typeof ControlledLabel;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  main: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  left: {
    flex: 1,
    flexDirection: 'row',
    overflow: 'hidden',
  } as ViewStyle,
  right: {
    width: 40,
    alignItems: 'flex-end',
  } as ViewStyle,
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 8,
  } as ViewStyle,
  question: {
    marginLeft: 5,
  } as ViewStyle,
  error: {
    marginTop: 8,
    marginBottom: 0,
  } as ViewStyle,
});

const generateErrorMessage = (props: {
  message: string | null | undefined;
  value: string | number | null | undefined;
  label: string;
  maxLength?: number;
  holdLabelInErrorMessage?: boolean;
}) => {
  const {message, value, label, maxLength, holdLabelInErrorMessage} = props;
  if (
    maxLength &&
    value &&
    typeof value === 'string' &&
    value.length > maxLength
  ) {
    return '文字数オーバーです';
  }
  if (!message) {
    return null;
  }
  if (holdLabelInErrorMessage) {
    return message;
  }
  return removeLabelFromErrorMessage(label, message);
};

const removeLabelFromErrorMessage = (label: string, message: string) => {
  return message.replace(new RegExp(`^(その)?${label}は`), '');
};

const Content: React.FC<React.PropsWithChildren & ViewStyle> = props => {
  const {children, ...style} = props;
  return <View style={[styles.content, style]} children={children} />;
};
