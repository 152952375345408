import React from 'react';
import {WebView, WebViewProps} from 'react-native-webview';
import {TAPNOVEL_API_ENDPOINT} from '@/config';

interface Props extends WebViewProps {
  source?: {uri: string};
  path: string;
}

const TapNovelWebView: React.FunctionComponent<Props> = props => {
  return (
    <WebView
      {...props}
      source={{uri: `${TAPNOVEL_API_ENDPOINT}${props.path}`}}
    />
  );
};

export default React.memo(TapNovelWebView);
