import React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

const Label: React.FC<React.PropsWithChildren> = props => {
  return <Text style={styles.container}>{props.children}</Text>;
};

export default React.memo(Label);

const styles = StyleSheet.create({
  container: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
});
