import React from 'react';
import {StackScreenProps} from '@react-navigation/stack';

import {Layout} from '@/components/layout';
import TapNovelWebView from '@/components/utils/TapNovelWebView';

import {RootStackParamList} from '@/navigation/RootStackParamList';

export interface Params {}

type Props = StackScreenProps<
  RootStackParamList,
  'PoliciesTerms' | 'SettingsPoliciesTerms'
>;

const Page = (props: Props) => {
  const {name} = props.route;
  const back = name !== 'PoliciesTerms';
  const close = name === 'PoliciesTerms';
  return (
    <Layout title={'利用規約'} back={back} close={close} scrollable={false}>
      <TapNovelWebView path={'/creator/policies/terms?without_layout=on'} />
    </Layout>
  );
};

export default React.memo(Page);
