import React from 'react';
import {
  GestureResponderEvent,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {PrimaryButton} from '@/components/ui/buttons';

interface Props extends React.PropsWithChildren {
  disabled?: boolean | undefined;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

const SubmitButtonWithEditable: React.FC<Props> = props => {
  const {disabled, children, onPress} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.editable}>あとで編集可能です</Text>
      <PrimaryButton
        style={styles.button}
        disabled={disabled}
        onPress={onPress}>
        {children || '決定'}
      </PrimaryButton>
    </View>
  );
};

export default React.memo(SubmitButtonWithEditable);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  editable: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  button: {
    marginTop: 8,
    marginBottom: 16,
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
  withReset: {
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
});
