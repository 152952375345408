import React from 'react';
import {StyleSheet, TouchableOpacity, ViewStyle} from 'react-native';

import {CloseIcon} from '@/components/icons';

interface Props {
  onPress?: () => void;
}

const TagRmoveButton: React.FC<Props> = props => {
  const {onPress} = props;
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <CloseIcon size={7} color={'white'} />
    </TouchableOpacity>
  );
};

export default React.memo(TagRmoveButton);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#cccccc',
    padding: 6,
    width: 14,
    height: 14,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
