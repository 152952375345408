import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import {PrimaryButton, CheckButton} from '@/components/ui/buttons';

import {AlertTriangleIcon} from '@/components/icons';

interface Props {
  onPress: () => void;
}

const Step3: React.FC<Props> = props => {
  const {onPress} = props;
  const [checked, setChecked] = React.useState(false);
  const toggoleChecked = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <View style={styles.container}>
      <View style={styles.alert}>
        <AlertTriangleIcon size={32} />
        <Text style={styles.alertText}>注意</Text>
      </View>
      <Text style={styles.message}>
        TapNovelCreatorに公開・販売できるのは、{'\n'}
        ご自身が描き、著作権を保有している{'\n'}
        イラストのみです。
      </Text>
      <Text style={styles.warning}>
        他サービスで購入したイラストや、{'\n'}
        フリー素材でダウンロードしたイラスト、{'\n'}
        AIで作成したイラストは{'\n'}
        公開・販売することはできません。
      </Text>
      <View style={styles.check}>
        <CheckButton checked={checked} onPress={toggoleChecked}>
          注意事項を理解した
        </CheckButton>
      </View>
      <PrimaryButton disabled={!checked} onPress={onPress}>
        TapNovelCreatorを始める
      </PrimaryButton>
    </View>
  );
};

export default React.memo(Step3);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    margin: 24,
  } as ViewStyle,
  alert: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 32,
  } as ViewStyle,
  alertText: {
    color: '#e7b600',
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 5,
  } as TextStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    marginBottom: 8,
    textAlign: 'center',
  } as TextStyle,
  warning: {
    color: '#f23406',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 32,
  } as TextStyle,
  check: {
    width: 160,
    marginBottom: 16,
  } as ViewStyle,
});
