import React from 'react';
import {Linking} from 'react-native';

import SocialShareButton from './SocialShareButton';

import {TwitterIcon} from '@/components/icons/social';

import {twitterShareUrl} from '@/helpers/SocialUrlHelper';

interface Props {
  url: string;
  text: string;
  hashtags: Array<string>;
  textWithServiceName?: boolean;
}

const TwitterShareButton: React.FC<Props> = props => {
  const {url, text, hashtags, textWithServiceName} = props;
  const onPress = React.useCallback(() => {
    Linking.openURL(twitterShareUrl(url, text, hashtags, textWithServiceName));
  }, [url, text, hashtags]);
  return (
    <SocialShareButton color={'#efefef'} onPress={onPress}>
      <TwitterIcon size={46} />
    </SocialShareButton>
  );
};

export default React.memo(TwitterShareButton);
