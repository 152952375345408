import React from 'react';
import {View, ViewStyle} from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';

import {APPLICATION_HEADER_HEIGHT} from '@/styles';

interface Props extends React.PropsWithChildren {
  signedIn?: boolean;
}

const Container: React.FC<Props> = props => {
  const styles = useStyles();
  return <View style={styles.container}>{props.children}</View>;
};

export default React.memo(Container);

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: 'row',
      width: '100%',
      height: APPLICATION_HEADER_HEIGHT,
      backgroundColor: '#383838',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      zIndex: 3,
      shadowColor: 'black',
      shadowOffset: {width: 0, height: 1},
    } as ViewStyle,
  },
  {
    [maxSize(DEVICE_SIZES.SM)]: {
      container: {
        display: 'none',
      } as ViewStyle,
    },
  },
);
