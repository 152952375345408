import React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import AnnouncementCategory from '@/entities/AnnouncementCategory';

interface Props {
  announcementCategory: AnnouncementCategory;
  checked: boolean;
  onSelectAnnouncementCategory: (
    announcementCategory: AnnouncementCategory,
  ) => void;
}

const AnnouncementCategoryListItem: React.FC<Props> = props => {
  const {announcementCategory, checked, onSelectAnnouncementCategory} = props;
  const onPress = React.useCallback(() => {
    onSelectAnnouncementCategory(announcementCategory);
  }, []);
  return (
    <Pressable onPress={onPress}>
      <View style={styles.container}>
        <View style={styles.inner}>
          <Text style={checked ? styles.titleTextChecked : styles.titleText}>
            {announcementCategory.name}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

export default React.memo(AnnouncementCategoryListItem);

const fontSize = 14;

const styles = StyleSheet.create({
  container: {
    height: 42,
    justifyContent: 'center',
    paddingHorizontal: 16,
  } as ViewStyle,
  inner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: Platform.select({
      android: 22,
    }),
  } as ViewStyle,
  titleText: {
    color: '#666',
    fontSize,
  } as TextStyle,
  titleTextChecked: {
    color: '#FF8F13',
    fontSize,
    fontWeight: 'bold',
  } as TextStyle,
});
