import React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import TransferListItem from './TransferListItem';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';

import Transfer from '@/entities/Transfer';

interface Props {
  transfers: Transfer[];
  token: string;
}

const TransferList: React.FC<Props> = props => {
  const {transfers, token} = props;
  const renderItem = React.useCallback((info: ListRenderItemInfo<Transfer>) => {
    return <TransferListItem transfer={info.item} token={token} />;
  }, []);
  return (
    <>
      <FlatList
        data={transfers}
        contentContainerStyle={style}
        renderItem={renderItem}
        ItemSeparatorComponent={ListItemNormalSeparator}
        ListFooterComponent={ListItemNormalSeparator}
      />
    </>
  );
};

export default React.memo(TransferList);

const style = {
  paddingBottom: 78,
};
