import React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
}

const BellOffIcon: React.FC<Props> = props => {
  const size = props.size || 17;
  const color = props.color || '#999999';
  return <Icon name={'bell-off'} size={size} color={color} />;
};

export default React.memo(BellOffIcon);
