import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {PrimaryButton} from '@/components/ui/buttons';

const SignUpButton = () => {
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo('/users/sign_up');
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>会員登録がまだお済みでない方</Text>
      <PrimaryButton onPress={onPress}>会員登録(無料)はこちら</PrimaryButton>
    </View>
  );
};

export default React.memo(SignUpButton);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  } as ViewStyle,
  title: {
    marginTop: 32,
    color: '#222',
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
  } as TextStyle,
  message: {
    color: '#222',
    fontSize: 13,
    lineHeight: 18,
    textAlign: 'center',
    marginVertical: 16,
  } as TextStyle,
});
