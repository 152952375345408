import {Platform} from 'react-native';
import {camelizeKeys, decamelizeKeys, Camelized} from 'humps';

import AccessToken from '@/stores/AccessToken';

import {TAPNOVEL_API_ENDPOINT} from '@/config';

const buildFetcher = (method: 'POST' | 'PATCH' | 'DELETE') => {
  return async (urlOrPath: string, {arg}: {arg: Record<string, any>}) => {
    const url = buildUrl(urlOrPath);
    const options = generateOptions(arg);
    const body = generateBody(arg, options);
    const headers = await generateHeaders(options);
    const res = await fetch(url, {method, body, headers});
    if (!res.ok) {
      const error = new Error(
        'An error occurred while fetching the data.',
      ) as ResponseError;
      error.info = camelizeKeys(await res.json());
      error.status = res.status;
      error.headers = res.headers;
      throw error;
    }
    return res;
  };
};

export const rawCreate = buildFetcher('POST');
export const rawUpdate = buildFetcher('PATCH');
export const rawDestroy = buildFetcher('DELETE');

export const getWithResponse = async <T = any>(
  urlOrPath: string,
): Promise<{data: T; response: Response}> => {
  const headers = await generateHeaders();
  const url = buildUrl(urlOrPath);
  const response = await fetch(url, {headers});
  if (!response.ok) {
    const error = new Error(
      'An error occurred while fetching the data.',
    ) as ResponseError;
    error.info = camelizeKeys(await response.json());
    error.status = response.status;
    error.headers = response.headers;
    throw error;
  }
  return {data: camelizeKeys(await response.json()) as T, response};
};

export const get = async <T = any>(urlOrPath: string): Promise<T> => {
  return (await getWithResponse(urlOrPath)).data;
};

export const create = async <T = any>(
  urlOrPath: string,
  options: {arg: Record<string, any>},
): Promise<T> => {
  return rawCreate(urlOrPath, options)
    .then(res => res.json())
    .then(row => camelizeKeys(row) as T);
};

export const update = async <T = any>(
  urlOrPath: string,
  options: {arg: Record<string, any>},
): Promise<T> => {
  return rawUpdate(urlOrPath, options)
    .then(res => res.json())
    .then(row => camelizeKeys(row) as T);
};

export const destroy = async <T = any>(
  urlOrPath: string,
  options: {arg: Record<string, any>},
): Promise<T> => {
  return rawDestroy(urlOrPath, options)
    .then(res => (res.status === 204 ? null : res.json()))
    .then(row => camelizeKeys(row) as T);
};

interface Options {
  multipart?: boolean;
  onlyAccessToken?: boolean;
}

async function generateHeaders(options?: Options): Promise<any> {
  const accessToken = await AccessToken.get();
  if (options && options.onlyAccessToken) {
    return {
      Authorization: accessToken,
    };
  }
  if (options && options.multipart) {
    return {
      Accept: 'application/json',
      Authorization: accessToken,
      ...Platform.select({
        web: {},
        default: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    };
  } else {
    return {
      Accept: 'application/json',
      Authorization: accessToken,
      'Content-Type': 'application/json',
    };
  }
}

function generateOptions(params?: {[key: string]: any} | null): Options {
  return {
    multipart: hasFileObject(params),
  };
}

function generateBody(
  params?: {[key: string]: any} | null,
  options?: Options,
): FormData | string {
  const postParams = params ? decamelizeKeys(params) : {};
  if (options && options.multipart) {
    const formData = new FormData();
    buildFormData(postParams, formData);
    return formData;
  } else {
    return JSON.stringify(postParams);
  }
}

function buildFormData(
  postParams: {[key: string]: any},
  formData: FormData,
  namespace?: string,
) {
  Object.keys(postParams).forEach(key => {
    const formKey = Array.isArray(postParams)
      ? `${namespace}[]`
      : namespace
      ? `${namespace}[${key}]`
      : key;
    const value = postParams[key];
    if (value && typeof value === 'object' && !isFileObject(value)) {
      buildFormData(value, formData, formKey);
    } else {
      if (value === undefined) {
        return;
      } else if (value === null) {
        formData.append(formKey, '');
      } else if (isFileObject(value)) {
        formData.append(formKey, value);
      } else {
        formData.append(formKey, value);
      }
    }
  });
}

function hasFileObject(params?: {[key: string]: any} | null): boolean {
  if (!params) {
    return false;
  }
  return Object.keys(params).some(key => {
    const value = params[key];
    if (value && typeof value === 'object' && !isFileObject(value)) {
      return hasFileObject(value);
    } else {
      return isFileObject(value);
    }
  });
}

function isFileObject(obj: any): boolean {
  if (toString.call(obj) === '[object File]') {
    return true;
  }
  if (!obj) {
    return false;
  }
  if (!(typeof obj.uri === 'string')) {
    return false;
  }
  if (!(typeof obj.type === 'string')) {
    return false;
  }
  if (!(typeof obj.name === 'string')) {
    return false;
  }
  return true;
}

const buildUrl = (urlOrPath: string) => {
  return urlOrPath.startsWith('/')
    ? `${TAPNOVEL_API_ENDPOINT}${urlOrPath}`
    : urlOrPath;
};
