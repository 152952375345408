import React from 'react';

import CollapsibleHelpList from './components/CollapsibleHelpList';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import Help from '@/entities/Help';
import HelpCategory from '@/entities/HelpCategory';

import {useGet, usePaginatedGet} from '@/hooks/swr';

export interface Params {
  id: number;
}

const Page = (props: ScreenProps<'Help'>) => {
  const {id} = props.route.params;
  const [refreshing, setRefreshing] = React.useState(false);
  const {data: helpCategory, error} = useGet<HelpCategory>(
    `/api/creator/help_categories/${id}`,
  );
  const {data, size, setSize, mutate} = usePaginatedGet<Help>(
    '/api/creator/helps',
    {creatorHelpCategoryId: id},
  );
  const onEndReached = React.useCallback(
    (info: {distanceFromEnd: number}) => setSize(size + 1),
    [size],
  );
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    mutate();
  }, []);
  const helps = React.useMemo(() => data?.flat(), [data]);
  return (
    <Layout
      title={helpCategory ? helpCategory.name : ''}
      back={true}
      scrollable={false}
      hideHeaderBottomBorder={true}
      error={error}>
      {helps && (
        <CollapsibleHelpList
          helps={helps}
          refreshing={refreshing}
          onEndReached={onEndReached}
          onRefresh={onRefresh}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);
