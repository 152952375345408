import React from 'react';
import {camelizeKeys} from 'humps';
import CurrentUserStatusContext from '@/contexts/CurrentUserStatusContext';

import AccessToken from '@/stores/AccessToken';

import CurrentUser from '@/entities/CurrentUser';

const useCurrentUserAndAccessToken = () => {
  const {setCurrentUser} = React.useContext(CurrentUserStatusContext);
  const update = React.useCallback((res: Response | undefined) => {
    if (!res || !res.ok) {
      return null;
    }
    const token = res.headers.get('Authorization');
    if (token) {
      AccessToken.set(token);
    }
    if (!setCurrentUser) {
      return null;
    }
    return res.json().then((row: Record<string, any>) => {
      const currentUser = camelizeKeys(row) as CurrentUser;
      setCurrentUser(currentUser);
      return currentUser;
    });
  }, []);
  const destroy = React.useCallback(() => {
    if (!setCurrentUser) {
      return null;
    }
    AccessToken.set('');
    setCurrentUser(null);
    return null;
  }, []);
  return {
    update,
    destroy,
  };
};

export default useCurrentUserAndAccessToken;
