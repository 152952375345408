import React from 'react';
import {KeyboardAvoidingView, Platform, View} from 'react-native';

import Container from './Container';
import Content from './Content';

import ApplicationHeader from './ApplicationHeader/index';

interface Props extends React.PropsWithChildren {
  signedIn?: boolean;
  noLayout?: boolean;
}

const ApplicationLayout: React.FC<Props> = props => {
  const {signedIn, noLayout} = props;
  return (
    <Container>
      {!noLayout && <ApplicationHeader signedIn={signedIn} />}
      <Content noLayout={noLayout}>
        <KeyboardAvoidingView style={style} behavior={behavior}>
          {props.children}
        </KeyboardAvoidingView>
      </Content>
    </Container>
  );
};

export default React.memo(ApplicationLayout);

const behavior = Platform.OS === 'ios' ? 'padding' : 'height';
const style = {flex: 1};
