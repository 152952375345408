import CurrentUser from '@/entities/CurrentUser';

import UserProperties from '@/stores/UserProperties';

import AnalyticsParticularEvent from '@/entities/AnalyticsParticularEvent';

import {create} from '@/services/fetchers';

type ActionName = 'show' | 'create' | 'update' | 'destroy';

export function sendDefaultCustomEvent(args: {
  resourceType: string;
  resourceId: number;
  resourceName?: string;
  actionName: ActionName;
  source?: string;
}) {
  if (!(window as any).gtag) {
    return;
  }
  (window as any).gtag('event', 'default_custom_event', {
    eventName: `${args.actionName}_${args.resourceType.replaceAll('/', '_')}`,
    resourceType: args.resourceType,
    resourceId: args.resourceId,
    resourceName: args.resourceName,
    actionName: args.actionName,
    source: args.source,
  });
}

export function setUser(currentUser: CurrentUser | null) {
  setUserId(currentUser?.id || null);
  if (currentUser) {
    UserProperties.set({
      user_id: currentUser.id,
    });
    setUserProperties({
      user_id: currentUser.id,
    });
  } else {
    UserProperties.remove();
    setUserProperties({
      user_id: null,
    });
  }
}

export function setUserId(userId: number | null) {
  if (!(window as any).gtag) {
    return;
  }
  if (equalsCurrentUserId(userId)) {
    return;
  }
  (window as any).gtag('set', 'user_id', userId);
}

export function setUserProperties(props: {
  user_id?: number | null;
  pen_name?: string | null;
  enabled?: boolean | null;
}) {
  if (!(window as any).gtag) {
    return;
  }
  (window as any).gtag('set', 'user_properties', props);
}

function equalsCurrentUserId(userId: number | null) {
  if (!(window as any).dataLayer) {
    return false;
  }
  const copyDataLayer = [...(window as any).dataLayer];
  const found = copyDataLayer.reverse().find(function (item: any) {
    return item[0] == 'set' && item[1] == 'user_id';
  });
  if (!found) {
    return false;
  }
  if (!found[2]) {
    return false;
  }
  return found[2] === userId;
}

export function sendDefaultCustomEventAndParticularEvent(args: {
  resourceType: string;
  resourceId: number;
  resourceName?: string;
  actionName: ActionName;
  source?: string;
}) {
  sendDefaultCustomEvent(args);
  create<AnalyticsParticularEvent>('/api/creator/analytics_particular_events', {
    arg: {
      analyticsParticularEventParams: {
        resourceType: args.resourceType,
        resourceId: args.resourceId,
        actionName: args.actionName,
      },
    },
  })
    .then(analyticsParticularEvent => {
      if (analyticsParticularEvent) {
        switch (analyticsParticularEvent.sameEventsCount) {
          case 1:
            sendDefaultCustomEvent({
              resourceType: `first_${args.resourceType}`,
              resourceId: analyticsParticularEvent.userId,
              actionName: args.actionName,
            });
            break;
          case 2:
            sendDefaultCustomEvent({
              resourceType: `second_${args.resourceType}`,
              resourceId: analyticsParticularEvent.userId,
              actionName: args.actionName,
            });
            break;
        }
      }
    })
    .catch(() => {});
}
