import React from 'react';
import {StyleSheet, TextStyle, View, ViewStyle} from 'react-native';
import {Control, FieldErrors} from 'react-hook-form';

import {
  Field,
  ControlledInput,
  ControlledDateSelect,
  ControlledLabel,
  ControlledTextarea,
  ControlledAvatarPicker,
} from '@/components/form';

export type Inputs = {
  userName: string;
  description: string;
  image: File | null;
  birthday: string | null;
  homePageUrl: string | null;
  twitterAccountUrl: string | null;
};

interface Props {
  control?: Control<Inputs>;
  errors?: FieldErrors<Inputs>;
}

const Form: React.FC<Props> = props => {
  const {control, errors} = props;
  return (
    <View style={styles.container}>
      <Field>
        <ControlledLabel
          label={'プロフィール画像'}
          control={control}
          name={'image'}
          errors={errors}
        />
        <ControlledAvatarPicker
          controllerProps={{
            control,
            name: 'image',
          }}
        />
      </Field>
      <Field>
        <ControlledLabel
          label={'クリエイターネーム'}
          control={control}
          name={'userName'}
          errors={errors}
          maxLength={USER_NAME_MAX_LENGTH}
          badgeType={'required'}
        />
        <ControlledInput
          placeholder={'クリエイターネームを入力'}
          required={true}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
              maxLength: USER_NAME_MAX_LENGTH,
            },
            name: 'userName',
          }}
        />
      </Field>
      <Field>
        <ControlledLabel
          label={'生年月日'}
          labelOption={'(非公開)'}
          control={control}
          name={'birthday'}
          errors={errors}
          badgeType={'optional'}
        />
        <ControlledDateSelect
          confirmText={'決定'}
          cancelText={'キャンセル'}
          controllerProps={{
            control,
            name: 'birthday',
          }}
          errors={errors}
        />
      </Field>
      <Field>
        <ControlledLabel
          label={'プロフィール'}
          control={control}
          name={'description'}
          errors={errors}
          maxLength={DESCRIPTION_MAX_LENGTH}
          badgeType={'optional'}
        />
        <ControlledTextarea
          placeholder={'プロフィールを入力'}
          controllerProps={{
            control,
            rules: {
              maxLength: DESCRIPTION_MAX_LENGTH,
            },
            name: 'description',
          }}
        />
      </Field>
      <Field>
        <ControlledLabel
          label={'ホームページ'}
          control={control}
          name={'homePageUrl'}
          errors={errors}
          badgeType={'optional'}
        />
        <ControlledInput
          placeholder={'ホームページを入力'}
          controllerProps={{
            control,
            name: 'homePageUrl',
          }}
        />
      </Field>
      <Field>
        <ControlledLabel
          label={'Twitter'}
          control={control}
          name={'twitterAccountUrl'}
          errors={errors}
          badgeType={'optional'}
        />
        <ControlledInput
          placeholder={'Twitterを入力'}
          controllerProps={{
            control,
            name: 'twitterAccountUrl',
          }}
          errors={errors}
        />
      </Field>
    </View>
  );
};

export default React.memo(Form) as typeof Form;

const USER_NAME_MAX_LENGTH = 20;
const DESCRIPTION_MAX_LENGTH = 150;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
    backgroundColor: 'white',
    paddingBottom: 67,
  } as ViewStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  submitButton: {
    marginVertical: 16,
  } as ViewStyle,
  newPassword: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
  newPasswordText: {
    borderBottomWidth: 1,
    color: '#222222',
    fontSize: 11,
  } as TextStyle,
});
