import React from 'react';
import {StyleSheet, Text, TextStyle, ViewStyle} from 'react-native';
import CurrentUserStatus from '@/entities/CurrentUserStatus';

import {PrimaryButton, TextButton} from '@/components/ui/buttons';

interface Props {
  currentUserStatus: CurrentUserStatus;
  onPressSubmit: () => void;
  onPressCancel: () => void;
}

const FormStep2: React.FC<Props> = props => {
  const {currentUserStatus, onPressSubmit, onPressCancel} = props;
  return (
    <>
      {currentUserStatus.enabledWriterUser ? (
        <>
          <Text style={styles.defaultMessage}>
            TapNovelCreator（素材サービス）と{'\n'}
            TapNovelMaker（執筆サービス）の{'\n'}
            両方を退会します。
          </Text>
        </>
      ) : (
        <Text style={styles.defaultMessage}>TapNovelCreatorを退会します。</Text>
      )}
      {currentUserStatus.enabledWriterUser ? (
        <>
          <Text style={styles.defaultMessage}>
            一度退会すると投稿した作品及び{'\n'}
            登録したイラストはすべて削除されます。
          </Text>
        </>
      ) : (
        <Text style={styles.defaultMessage}>
          一度退会すると登録したイラストは{'\n'}
          復元することはできません。
        </Text>
      )}
      <Text style={styles.warningMessage}>
        尚、購入されたイラストは購入者が{'\n'}
        引き続き利用することができます。
      </Text>
      <Text style={styles.defaultMessage}>本当によろしいですか？</Text>
      <PrimaryButton style={styles.button} onPress={onPressSubmit}>
        退会する
      </PrimaryButton>
      <TextButton
        textProps={{style: styles.textButton}}
        onPress={onPressCancel}>
        キャンセル
      </TextButton>
    </>
  );
};

export default React.memo(FormStep2);

const styles = StyleSheet.create({
  defaultMessage: {
    color: '#222',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 16,
  } as TextStyle,
  warningMessage: {
    color: '#f23406',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
  } as TextStyle,
  button: {
    marginTop: 16,
  } as ViewStyle,
  textButton: {
    color: '#999',
    marginTop: 32,
  } as TextStyle,
});
