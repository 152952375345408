import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PreviewButton from '@/components/ui/buttons/PreviewButton';

interface Props {
  url: string;
  onPress?: () => void;
}

const PreviewSection: React.FC<Props> = props => {
  const {url, onPress} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        公開前にプレビューで最終確認をお願いします
      </Text>
      <PreviewButton
        url={url}
        style={styles.button}
        textStyle={styles.buttonText}
        iconSize={26}
        onPress={onPress}
      />
    </View>
  );
};

export default React.memo(PreviewSection);

const styles = StyleSheet.create({
  container: {
    margin: 16,
    marginBottom: 32,
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#383838',
    fontSize: 11,
  } as TextStyle,
  button: {
    width: 200,
    height: 46,
    marginTop: 8,
  } as ViewStyle,
  buttonText: {
    fontSize: 14,
    paddingHorizontal: 6,
  } as TextStyle,
});
