import React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
}

const EllipsisHorizontalIcon: React.FC<Props> = props => {
  const size = props.size || 20;
  const color = props.color;
  return <Icon name={'ellipsis-horizontal'} size={size} color={color} />;
};

export default React.memo(EllipsisHorizontalIcon);
