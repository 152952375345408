import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import {PlusIcon, MinusIcon} from '../icons';

import {ItemValue, Item} from '@/components/value/types';

export interface Props<IValue extends ItemValue = ItemValue> {
  items: Array<Item<IValue>>;
  value: IValue;
  onChangeValue: (value: IValue) => void;
}

function PriceInput<IValue extends ItemValue = ItemValue>(
  props: Props<IValue>,
) {
  const {items, onChangeValue} = props;
  const [value, setValue] = React.useState(props.value);
  React.useEffect(() => {
    const foundIndex = items.findIndex(item => item.value === props.value);
    if (foundIndex <= -1) {
      setValue(items[0].value);
    } else {
      setValue(props.value);
    }
  }, [props.value]);
  const onPressDown = React.useCallback(() => {
    const foundIndex = items.findIndex(item => item.value === value);
    if (foundIndex <= 0) {
      setValue(items[0].value);
    } else {
      setValue(items[foundIndex - 1].value);
    }
  }, [value]);
  const onPressUp = React.useCallback(() => {
    const foundIndex = items.findIndex(item => item.value === value);
    if (foundIndex <= -1) {
      setValue(items[0].value);
    } else if (foundIndex >= items.length - 1) {
      setValue(items[items.length - 1].value);
    } else {
      setValue(items[foundIndex + 1].value);
    }
  }, [value]);
  React.useEffect(() => {
    onChangeValue(value);
  }, [value]);
  const label = React.useMemo(
    () => items.find(item => item.value === value)?.label,
    [value],
  );
  return (
    <View style={styles.container}>
      <View style={styles.price}>
        <Text style={styles.yen}>￥</Text>
        <Text style={styles.priceValue}>{label}</Text>
      </View>
      <View style={styles.buttons}>
        <TouchableOpacity style={styles.buttonDown} onPress={onPressDown}>
          <MinusIcon size={10} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttonUp} onPress={onPressUp}>
          <PlusIcon size={10} />
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default React.memo(PriceInput);

const button = {
  width: 37,
  height: 30,
  justifyContent: 'center',
  alignItems: 'center',
} as ViewStyle;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  } as ViewStyle,
  price: {
    flex: 1,
    marginRight: 16,
    flexDirection: 'row',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  } as ViewStyle,
  yen: {
    color: '#383838',
    fontSize: 14,
  } as TextStyle,
  priceValue: {
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  buttons: {
    flexDirection: 'row',
    borderColor: '#383838',
    borderWidth: 1,
    borderRadius: 3,
  } as ViewStyle,
  buttonDown: {
    ...button,
    borderRightColor: '#383838',
    borderRightWidth: 0.5,
  } as ViewStyle,
  buttonUp: {
    ...button,
    borderLefColor: '#383838',
    borderLeftWidth: 0.5,
  } as ViewStyle,
});
