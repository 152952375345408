import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import {QuestionCircleIcon} from '@/components/icons';

import AccountRevenueAggregation from '@/entities/summary/AccountRevenueAggregation';

import useTooltipModal from '@/hooks/useTooltipModal';

interface Props {
  accountRevenueAggregation: AccountRevenueAggregation;
}

const Total: React.FC<Props> = props => {
  const {accountRevenueAggregation} = props;
  const tooltipModal = useTooltipModal();
  const onPress = React.useCallback(() => {
    tooltipModal.show(tooltipModalProps);
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>全体</Text>
      <View style={styles.rows}>
        <View style={styles.row}>
          <Text style={styles.label}>販売数</Text>
          <Text style={styles.value}>
            {numeral(accountRevenueAggregation.salesQuantity).format('0,0')}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>売上</Text>
          <Text style={styles.value}>
            ￥{numeral(accountRevenueAggregation.salesTotal).format('0,0')}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.labelWrapper}>
            <Text style={styles.label}>獲得収益</Text>
            <TouchableOpacity style={styles.question} onPress={onPress}>
              <QuestionCircleIcon size={14} />
            </TouchableOpacity>
          </View>
          <Text style={styles.value}>
            ￥{numeral(accountRevenueAggregation.grossProfit).format('0,0')}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default React.memo(Total);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    paddingHorizontal: 12,
    paddingBottom: 16,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  rows: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  } as ViewStyle,
  row: {
    flex: 1,
    height: 54,
    backgroundColor: '#fff',
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4,
  } as ViewStyle,
  labelWrapper: {
    flexDirection: 'row',
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
    marginBottom: 8,
  } as TextStyle,
  value: {
    color: '#e7b600',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  question: {
    marginLeft: 4,
  } as ViewStyle,
});

const tooltipModalProps = {
  title: '獲得収益',
  description: '売上から当社手数料22％（消費税込み）を差し引いた額となります。',
};
