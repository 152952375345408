import React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import ActorFaceListItem from './ActorCharacterFaceListItem';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';
import {BottomButton} from '@/components/ui/buttons';
import {AlertTriangleIcon} from '@/components/icons';

import useTooltipModal from '@/hooks/useTooltipModal';

import Actor from '@/entities/Actor';
import ActorCharacterFace from '@/entities/ActorCharacterFace';

interface Props {
  actorCharacterId: number;
  actor: Actor;
  actorCharacterFaces: ActorCharacterFace[];
}

const ActorCharacterFaceList: React.FC<Props> = props => {
  const {actorCharacterId, actor, actorCharacterFaces} = props;
  const tooltipModal = useTooltipModal();
  const linkTo = useLinkTo();
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ActorCharacterFace>) => {
      return <ActorFaceListItem actorCharacterFace={info.item} />;
    },
    [],
  );
  const onPress = React.useCallback(() => {
    if (actor.status === 'on_sale') {
      tooltipModal.show({
        title: <AlertTriangleIcon size={32} />,
        description:
          '販売中のイラストに表情差分を追加する場合は、\n一度非公開にしてから再度申請ください',
      });
    } else {
      linkTo(`/actor_characters/${actorCharacterId}/actor_character_faces/new`);
    }
  }, [actor]);
  return (
    <>
      <FlatList
        data={actorCharacterFaces}
        contentContainerStyle={style}
        renderItem={renderItem}
        ItemSeparatorComponent={ListItemNormalSeparator}
        ListFooterComponent={ListItemNormalSeparator}
      />
      <BottomButton onPress={onPress}>表情差分を追加</BottomButton>
    </>
  );
};

export default React.memo(ActorCharacterFaceList);

const style = {
  paddingBottom: 78,
};
