import React from 'react';
import {ScrollView, StyleSheet, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import ControlledImagePickerField from './ControlledImagePickerField';
import ControlledFaceNameSelectField, {
  AVAILABLE_FACE_NAMES,
} from './ControlledFaceNameSelectField';
import ControlledFaceNameOtherInputField from './ControlledFaceNameOtherInputField';

import SubmitButtonWithEditable from '@/components/utils/SubmitButtonWithEditable';

export {AVAILABLE_FACE_NAMES};

export type Inputs = {
  image: File | null;
  faceName: string;
  faceNameOther?: string;
};

interface Props {
  onSale: boolean;
  defaultValues?: Inputs;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {onSale, defaultValues, errors, onSubmit} = props;
  const {
    control,
    formState: {isValid},
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: defaultValues || ({image: null, faceName: ''} as Inputs),
  });
  const image = watch('image');
  const faceName = watch('faceName');
  const displayStyle = React.useMemo(
    () => (image ? null : styles.hide),
    [!!image],
  );
  React.useEffect(() => {
    if (faceName !== 'その他') {
      setValue('faceNameOther', '');
    }
  }, [faceName]);
  const onPress = handleSubmit(onSubmit);
  const onPressReset = React.useCallback(() => {
    setValue('faceName', '');
    setValue('faceNameOther', undefined);
    setValue('image', null, {shouldValidate: true});
  }, []);
  return (
    <View style={styles.container}>
      <ScrollView>
        <ControlledImagePickerField
          control={control}
          errors={errors}
          name={'image'}
          value={image}
          disabled={onSale}
          disabledReset={!image}
          onPressReset={onPressReset}
        />
        <ControlledFaceNameSelectField
          style={displayStyle}
          control={control}
          errors={errors}
          name={'faceName'}
        />
        {faceName === 'その他' ? (
          <ControlledFaceNameOtherInputField
            style={displayStyle}
            control={control}
            errors={errors}
            name={'faceNameOther'}
          />
        ) : null}
      </ScrollView>
      <SubmitButtonWithEditable disabled={!isValid} onPress={onPress} />
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});
