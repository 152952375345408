import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import EarningsBalanceSection from './BalanceSection';

import Balance from '@/entities/Balance';

interface Props {
  balance: Balance;
}

const BalanceTransactionListHeader: React.FC<Props> = props => {
  const {balance} = props;
  return (
    <>
      <EarningsBalanceSection balance={balance} />
      <View style={styles.container}>
        <Text style={styles.title}>履歴</Text>
      </View>
    </>
  );
};

export default React.memo(BalanceTransactionListHeader);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    paddingBottom: 11,
    paddingHorizontal: 16,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
});
