import {Platform} from 'react-native';
import {TransitionPresets} from '@react-navigation/stack';

if (Platform.OS !== 'ios') {
  TransitionPresets.ModalTransition.cardStyleInterpolator =
    TransitionPresets.FadeFromBottomAndroid.cardStyleInterpolator;
  TransitionPresets.ModalTransition.gestureDirection =
    TransitionPresets.FadeFromBottomAndroid.gestureDirection;
  TransitionPresets.ModalTransition.headerStyleInterpolator =
    TransitionPresets.FadeFromBottomAndroid.headerStyleInterpolator;
  TransitionPresets.ModalTransition.transitionSpec =
    TransitionPresets.FadeFromBottomAndroid.transitionSpec;
}

export const defaultScreenOptions = {
  animationEnabled: true,
  headerShown: false,
  cardStyle: {backgroundColor: 'transparent'},
  ...Platform.select({
    web: TransitionPresets.FadeFromBottomAndroid,
    android: TransitionPresets.SlideFromRightIOS,
  }),
};

export const modalScreenOptions = {
  animationEnabled: true,
  headerShown: false,
  cardStyle: {backgroundColor: 'transparent'},
  ...Platform.select({
    ios: TransitionPresets.ModalSlideFromBottomIOS,
    default: TransitionPresets.FadeFromBottomAndroid,
  }),
};
