import {defaultScreenOptions, modalScreenOptions} from '../ScreenOptions';
import RootStack from '../RootStack';

import Home from '@/pages';
import PoliciesPrivacy from '@/pages/policies/privacy';
import PoliciesSettlementLaw from '@/pages/policies/settlement_law';
import PoliciesSpecifiedCommercialTransactionLaw from '@/pages/policies/specified_commercial_transaction_law';
import PoliciesTerms from '@/pages/policies/terms';
import Helps from '@/pages/helps';
import Help from '@/pages/helps/show';
import Monitor from '@/pages/monitor';
import MonitorApplicationNew from '@/pages/monitor/applications/new';
import MonitorApplicationComplete from '@/pages/monitor/applications/complete';

const PublicScreens = (
  <>
    <RootStack.Group screenOptions={modalScreenOptions}>
      <RootStack.Screen
        name={'Home'}
        component={Home}
        options={{title: 'ホーム'}}
      />
      <RootStack.Screen
        name={'PoliciesPrivacy'}
        component={PoliciesPrivacy}
        options={{title: 'プライバシーポリシー'}}
      />
      <RootStack.Screen
        name={'PoliciesSettlementLaw'}
        component={PoliciesSettlementLaw}
        options={{title: '資金決済法に基づく表示'}}
      />
      <RootStack.Screen
        name={'PoliciesSpecifiedCommercialTransactionLaw'}
        component={PoliciesSpecifiedCommercialTransactionLaw}
        options={{title: '特定商取引法に基づく表示'}}
      />
      <RootStack.Screen
        name={'PoliciesTerms'}
        component={PoliciesTerms}
        options={{title: '利用規約'}}
      />
    </RootStack.Group>
    <RootStack.Group screenOptions={defaultScreenOptions}>
      <RootStack.Screen
        name={'Helps'}
        component={Helps}
        options={{title: 'ヘルプ'}}
      />
      <RootStack.Screen
        name={'Help'}
        component={Help}
        options={{title: 'ヘルプ'}}
      />
      <RootStack.Screen
        name={'Monitor'}
        component={Monitor}
        options={{title: 'モニター100名限定募集'}}
      />
      <RootStack.Screen
        name={'MonitorApplicationNew'}
        component={MonitorApplicationNew}
        options={{title: 'モニター100名限定募集フォーム'}}
      />
      <RootStack.Screen
        name={'MonitorApplicationComplete'}
        component={MonitorApplicationComplete}
        options={{title: 'モニター100名限定募集フォーム'}}
      />
    </RootStack.Group>
  </>
);

export default PublicScreens;
