import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import Select, {Props as SelectProps} from './Select';

import {ItemValue} from '@/components/value/types';

export interface Props {
  value?: string | null;
  confirmText?: string;
  cancelText?: string;
  onChangeValue: (value: string | null) => void;
}

const DateSelect: React.FC<Props> = props => {
  const {value, confirmText, cancelText, onChangeValue} = props;
  const date = value ? new Date(value) : null;
  const [year, setYear] = React.useState<number>(date?.getFullYear() || 0);
  const [month, setMonth] = React.useState<number | null>(
    date ? date.getMonth() + 1 : 0,
  );
  const [day, setDay] = React.useState<number | null>(date?.getDate() || 0);
  React.useEffect(() => {
    const value =
      year && month && day
        ? `${year}-${month.toString().padStart(2, '0')}-${day
            .toString()
            .padStart(2, '0')}`
        : null;
    onChangeValue(value);
  }, [year, month, day]);
  return (
    <View style={{flexDirection: 'row'}}>
      <SelectWrapper
        items={yearItems}
        value={year}
        label={'年'}
        confirmText={confirmText}
        cancelText={cancelText}
        onValueChange={setYear}
      />
      <SelectWrapper
        items={monthItems}
        value={month}
        label={'月'}
        confirmText={confirmText}
        cancelText={cancelText}
        onValueChange={setMonth}
      />
      <SelectWrapper
        items={dayItems}
        value={day}
        label={'日'}
        confirmText={confirmText}
        cancelText={cancelText}
        onValueChange={setDay}
      />
    </View>
  );
};

export default React.memo(DateSelect);

const range = (start: number, end: number) =>
  [...Array(end - start + 1)].map((_, i) => start + i);

const yearItems = [
  {label: '--', value: 0},
  ...range(1920, new Date().getFullYear())
    .map(i => {
      return {label: `${i}`, value: i};
    })
    .reverse(),
];

const monthItems = [
  {label: '--', value: 0},
  ...range(1, 12).map(i => {
    return {label: `${i}`, value: i};
  }),
];

const dayItems = [
  {label: '--', value: 0},
  ...range(1, 31).map(i => {
    return {label: `${i}`, value: i};
  }),
];

const SelectWrapper: React.FC<
  Omit<SelectProps, 'onValueChange'> & {
    label: string;
    onValueChange: (itemValue: number, itemPosition: number) => void;
  }
> = React.memo(props => {
  const {label, onValueChange, ...selectProps} = props;
  const handleValueChange = React.useCallback(
    (itemValue: ItemValue, itemPosition: number) => {
      onValueChange(Number(itemValue), itemPosition);
    },
    [],
  );
  return (
    <View style={label === '年' ? styles.containerYear : styles.container}>
      <Select
        style={styles.selectStyle}
        pickerStyle={styles.selectPickerStyle}
        {...selectProps}
        onValueChange={handleValueChange}
      />
      <Text style={styles.textLabel}>{label}</Text>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 4,
  } as ViewStyle,
  containerYear: {
    flex: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 4,
  } as ViewStyle,
  selectStyle: {
    width: undefined,
    flex: 1,
  } as ViewStyle,
  selectPickerStyle: {
    fontSize: 14,
    textAlign: 'left',
  } as TextStyle,
  textLabel: {
    fontSize: 12,
    paddingLeft: 8,
    fontWeight: 'bold',
  } as TextStyle,
});
