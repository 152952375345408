import React from 'react';
import {
  StyleSheet,
  Text,
  TextProps,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native';

interface Props extends TouchableOpacityProps {
  textProps?: TextProps;
}

const TextButton: React.FC<Props> = props => {
  const {textProps, children, ...restProps} = props;
  const {style: textStyle, ...restTextProps} = textProps || {};
  return (
    <TouchableOpacity {...restProps}>
      <Text {...restTextProps} style={[styles.text, textStyle]}>
        {children}
      </Text>
    </TouchableOpacity>
  );
};

export default React.memo(TextButton);

const styles = StyleSheet.create({
  text: {
    color: '#383838',
    fontSize: 14,
  } as TextStyle,
});
