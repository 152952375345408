import React from 'react';

import Form, {Inputs} from './comonents/Form';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import QualifiedInvoiceIssuer from '@/entities/QualifiedInvoiceIssuer';

import {buildFieldErrors} from '@/helpers/FormHelper';
import {useGet, useUpdateMutation} from '@/hooks/swr';
import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

export interface Params {
  from?: 'transfer';
}

const Page = (props: ScreenProps<'SettingsQualifiedInvoiceIssuer'>) => {
  const {route} = props;
  const {from} = route.params || {from: undefined};
  const navigation = useDefaultNavigation();
  const {data, isLoading} = useGet<QualifiedInvoiceIssuer>(
    '/api/creator/qualified_invoice_issuer',
  );
  const {trigger, isMutating, error} =
    useUpdateMutation<QualifiedInvoiceIssuer>(
      '/api/creator/qualified_invoice_issuer',
    );
  const defaultValues = React.useMemo(
    () => convertFormRecordToDefaultValues(data),
    [data],
  );
  const isNew = !data;
  const onSubmit = React.useCallback(
    (data: Inputs) => {
      trigger(convertFormValuesToPostParams(data)).then(
        qualifiedInvoiceIssuer => {
          if (qualifiedInvoiceIssuer) {
            if (from === 'transfer') {
              navigation.replace('TransferRequestNew', {});
            } else {
              navigation.goBack();
            }
            sendDefaultCustomEvent({
              resourceType: 'bank_account',
              resourceId: qualifiedInvoiceIssuer.id,
              actionName: isNew ? 'create' : 'update',
            });
          }
        },
      );
    },
    [isNew],
  );
  return (
    <Layout title={'消費税事業者設定'} back={true} loading={isMutating}>
      {!isLoading && (
        <Form
          defaultValues={defaultValues}
          errors={convertResponseToErrors(error)}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {qualifiedInvoiceIssuer: formValues};
};

const convertFormRecordToDefaultValues = (
  record?: QualifiedInvoiceIssuer,
): Inputs | undefined => {
  if (!record) {
    return undefined;
  }
  return {
    taxable: record.taxable,
    acquiredRegistrationNumber: record.acquiredRegistrationNumber,
    registrationNumber: record.registrationNumber,
  };
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    taxable: 'taxable',
    acquiredRegistrationNumber: 'acquiredRegistrationNumber',
    registrationNumber: 'registrationNumber',
  });
};
