import React from 'react';
import {
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TextInputProps,
} from 'react-native';
import {
  Controller,
  UseControllerProps,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
  FieldErrors,
} from 'react-hook-form';

import Input from './Input';
import ErrorMessage from './ErrorMessage';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends TextInputProps {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  errors?: FieldErrors<TFieldValues>;
  required?: boolean;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  renderCustomErrorMessage?: (
    children: React.ReactNode | undefined,
  ) => React.ReactNode;
}

function ControlledInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {
    controllerProps,
    errors,
    hideErrorMessage,
    renderCustomErrorMessage,
    ...textInputProps
  } = props;
  const {name, rules, control} = controllerProps;
  const message = errors ? errors[name]?.message?.toString() : null;
  const render = React.useCallback(
    ({
      field: {onChange, onBlur, value},
    }: {
      field: ControllerRenderProps<TFieldValues, TName>;
    }) => (
      <>
        {message && !hideErrorMessage && (
          <ErrorMessage renderCustom={renderCustomErrorMessage}>
            {message}
          </ErrorMessage>
        )}
        <Input
          invalid={!!message}
          {...textInputProps}
          onBlur={React.useCallback(
            (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
              onBlur();
              props.onBlur && props.onBlur(e);
            },
            [],
          )}
          onChangeText={onChange}
          value={value}
        />
      </>
    ),
    [message, textInputProps.disabled],
  );
  return (
    <Controller control={control} rules={rules} render={render} name={name} />
  );
}

export default React.memo(ControlledInput) as typeof ControlledInput;
