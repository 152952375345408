import React from 'react';
import {TextInputProps} from 'react-native';
import {
  Controller,
  UseControllerProps,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
  FieldErrors,
} from 'react-hook-form';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  errors?: FieldErrors<TFieldValues>;
  required?: boolean;
  hideErrorMessage?: boolean;
}

function ControlledHiddenInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {controllerProps, errors} = props;
  const {name, rules, control} = controllerProps;
  const message = errors ? errors[name]?.message?.toString() : null;
  const render = React.useCallback(() => <></>, [message]);
  return (
    <Controller control={control} rules={rules} render={render} name={name} />
  );
}

export default React.memo(
  ControlledHiddenInput,
) as typeof ControlledHiddenInput;
