import * as React from 'react';
import {StyleProp, ScrollView, View, ViewStyle} from 'react-native';

import Step1 from './Step1';
import Step2 from './Step2';

import CurrentUserStatus from '@/entities/CurrentUserStatus';

type Step = 1 | 2;

interface Props {
  width: number;
  currentUserStatus: CurrentUserStatus;
  onSubmit: () => void;
  onPressCancel: () => void;
}

const Pagination: React.FC<Props> = props => {
  const {width, currentUserStatus, onSubmit, onPressCancel} = props;
  const scrollViewRef = React.useRef<ScrollView | null>(null);
  const [step, setStep] = React.useState<Step>(1);
  const goNext = React.useCallback((step: Step) => {
    setStep(step);
    scrollViewRef.current?.scrollTo({x: width * (step - 1)});
  }, []);
  const onPressNext = React.useCallback(() => {
    goNext(2);
  }, []);
  const style = React.useMemo(() => [{width}, itemStyle], [width]);
  return (
    <ScrollView
      ref={scrollViewRef}
      pagingEnabled={true}
      horizontal={true}
      scrollEnabled={false}>
      <View style={style}>
        <Step1
          currentUserStatus={currentUserStatus}
          onPressNext={onPressNext}
        />
      </View>
      <View style={style}>
        <Step2
          currentUserStatus={currentUserStatus}
          onPressSubmit={onSubmit}
          onPressCancel={onPressCancel}
        />
      </View>
    </ScrollView>
  );
};

export default React.memo(Pagination);

const itemStyle = {
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
} as ViewStyle;
