import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {TAPNOVEL_API_ENDPOINT} from '@/config';

import {Layout} from '@/components/layout';
import SocialShareButtons from '@/components/ui/SocialShareButtons';

import ScreenProps from '@/navigation/ScreenProps';

import {buildPathWithQuery} from '@/helpers/UrlHelper';

import Actor from '@/entities/Actor';
import {useGet} from '@/hooks/swr';

type Type = 'now_on_sale' | 'new_face' | 'performing';

export interface Params {
  actorId: number;
  type: Type;
}

const Page = (props: ScreenProps<'ActorOgpImage'>) => {
  const {route} = props;
  const {actorId, type, ...rest} = route.params;
  const {data: actor, error} = useGet<Actor>(`/api/creator/actors/${actorId}`);
  const source = React.useMemo(() => {
    return {
      uri: buildPathWithQuery(
        `${TAPNOVEL_API_ENDPOINT}/actors/${actorId}/ogp_images/${type}.png`,
        rest,
      ),
    };
  }, [actorId, type, rest]);
  const url = React.useMemo(() => {
    const storyId = (route.params as any)?.story_id;
    if (type === 'performing' && storyId) {
      return buildPathWithQuery(`${TAPNOVEL_API_ENDPOINT}/stories/${storyId}`, {
        ogp_type: 'actor_performing',
        ogp_actor_id: actorId,
      });
    }
    return buildPathWithQuery(`${TAPNOVEL_API_ENDPOINT}/actors/${actorId}`, {
      ogp_type: type,
      ...rest,
    });
  }, []);
  return (
    <Layout title={'通知'} close={true} scrollable={false}>
      <View style={styles.container}>
        <Text style={styles.title}>{TYPE_TO_TITLE[type]}</Text>
        <Image source={source} style={styles.image} />
        <Text>沢山の人に購入されるようシェアしましょう！</Text>
        <SocialShareButtons
          url={url}
          text={generateShareText(actor?.name, type)}
          hashtags={[actor?.name || '']}
          textWithServiceName={false}
        />
      </View>
    </Layout>
  );
};

export default React.memo(Page);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: 25,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
  image: {
    width: 343,
    height: 180,
    marginVertical: 24,
  } as ImageStyle,
  message: {
    color: '#222',
    fontSize: 14,
    textAlign: 'center',
  } as TextStyle,
});

const TYPE_TO_TITLE: {[key in Type]: string} = {
  now_on_sale: '公開申請が承認され\nイラストが販売開始されました。',
  new_face: '公開申請が承認され\nイラストの表情差分が販売開始されました。',
  performing: '購入されたイラストが使用されました。',
};

const generateShareText = (
  actorName: string | undefined,
  type: Type,
): string => {
  switch (type) {
    case 'now_on_sale':
      return `『${actorName}』を販売開始しました。`;
    case 'new_face':
      return `『${actorName}』の表情差分を追加しました。`;
    case 'performing':
      return `${actorName}`;
  }
};
