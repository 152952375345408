import * as React from 'react';
import {Animated, StyleSheet, TextStyle, View} from 'react-native';

import {BellIcon} from '@/components/icons';

interface Props {
  badgeCount?: number | null;
  disabled: boolean;
}

const BellIconWithBadgeCount: React.FC<Props> = props => {
  const {badgeCount, disabled} = props;
  const [scale] = React.useState(new Animated.Value(0));
  React.useEffect(() => {
    Animated.timing(scale, {
      toValue: badgeCount ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [!!badgeCount]);
  return (
    <View>
      <BellIcon
        size={20}
        color={disabled ? '#d5d5d5' : badgeCount ? '#FF8F13' : '#383838'}
      />
      {!badgeCount ? null : (
        <Animated.Text style={[styles.badge, {transform: [{scale}]}]}>
          {badgeCount}
        </Animated.Text>
      )}
    </View>
  );
};

export default React.memo(BellIconWithBadgeCount);

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    width: 14,
    height: 14,
    borderRadius: 7,
    top: -3,
    right: -6,
    backgroundColor: 'red',
    color: 'white',
    fontSize: 9,
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 14,
  } as TextStyle,
});
