interface Actor {
  faceCenterPosition: {top: number; left: number};
}
export function getPositionForCenteringFace(
  actor: Actor,
  offset: {top?: number; left?: number} = {},
) {
  const offsetTop = offset.top || 0;
  const offsetLeft = offset.left || 0;
  if (!actor.faceCenterPosition) {
    return {};
  }
  return {
    top: `${-(actor.faceCenterPosition.top - 0.15 + offsetTop) * 100}%`,
    left: `${-(actor.faceCenterPosition.left + offsetLeft) * 100}%`,
  };
}
