import React from 'react';

import {
  Pressable,
  Modal,
  ModalProps,
  StyleSheet,
  ViewStyle,
} from 'react-native';

interface Props extends ModalProps {}

const Backdrop: React.FC<Props> = props => {
  const {children, onRequestClose, ...modalProps} = props;
  return (
    <Modal
      animationType={'fade'}
      {...modalProps}
      transparent={true}
      onRequestClose={onRequestClose}>
      <Pressable style={styles.backdrop} onPress={onRequestClose}>
        {children}
      </Pressable>
    </Modal>
  );
};

export default React.memo(Backdrop);

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, .5)',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
