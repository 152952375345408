import React from 'react';

import SearchForm, {Inputs} from './components/SearchForm';
import Total from './components/Total';
import ResourceRevenueList from './components/ResourceRevenueList';

import {Layout} from '@/components/layout';

import AccountRevenueAggregation from '@/entities/summary/AccountRevenueAggregation';
import ResourceRevenueAggregation from '@/entities/summary/ResourceRevenueAggregation';

import {DatePeriod} from '@/components/value/types';

import {useGet} from '@/hooks/swr';
import {convertTermRangeToQueryParams} from '@/helpers/TimeHelper';

export interface Params {}

const Page = () => {
  const [period, setPeriod] = React.useState<DatePeriod>('all');
  const params = convertTermRangeToQueryParams(period);
  const {data: accountRevenueAggregation} = useGet<AccountRevenueAggregation>(
    '/api/creator/summary/account_revenue_aggregation',
    params,
    {keepPreviousData: true},
  );
  const {data} = useGet<ResourceRevenueAggregation[]>(
    '/api/creator/summary/resource_revenue_aggregations',
    params,
    {keepPreviousData: true},
  );
  const onSubmit = React.useCallback(
    (data: Inputs) => setPeriod(data.period),
    [],
  );
  return (
    <Layout title={'収益管理'} back={true} scrollable={false}>
      <SearchForm defaultValues={{period: period}} onSubmit={onSubmit} />
      {accountRevenueAggregation && (
        <Total accountRevenueAggregation={accountRevenueAggregation} />
      )}
      {data && <ResourceRevenueList data={data} />}
    </Layout>
  );
};

export default React.memo(Page);
