import React from 'react';
import {
  NavigationContainer,
  LinkingOptions,
  DocumentTitleOptions,
  NavigationState,
} from '@react-navigation/native';

import ref from './ref';

import LinkingConfig from './LinkingConfig';
import {RootStackParamList} from './RootStackParamList';
import Navigator from './Navigator';

import CurrentUser from '@/entities/CurrentUser';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [],
  config: {
    screens: LinkingConfig,
    initialRouteName: 'Home',
  },
};

const documentTitle: DocumentTitleOptions = {
  formatter: (options, route) =>
    `${options?.title ?? route?.name}${
      (route?.name || '').startsWith('Reservation') ||
      (route?.name || '').startsWith('Community')
        ? ''
        : ' - TapNovelCreator'
    }`,
};

interface Props {
  currentUser: CurrentUser | null;
  onStateChange?: (state: NavigationState | undefined) => void;
  onReady?: () => void;
}

const Index: React.FC<Props> = props => {
  const {currentUser, ...restProps} = props;
  return (
    <NavigationContainer
      ref={ref}
      linking={linking}
      documentTitle={documentTitle}
      {...restProps}>
      <Navigator currentUser={currentUser} />
    </NavigationContainer>
  );
};

export default React.memo(Index);
