import React from 'react';
import {Item} from '@/components/value/types';

import {useGet} from '@/hooks/swr';

import ActorCategory from '@/entities/ActorCategory';

import {Inputs} from '../components/Form';
import {ItemsList} from '../components/types';

export const ATTRIBUTE_ID = 1;

export const HUMAN_AGE_ID = 5;
export const HUMAN_GENDER_ID = 13;
export const HUMAN_BODY_SHAPE_ID = 16;
export const HUMAN_HEIGHT_ID = 20;
export const HUMAN_HAIR_COLOR_ID = 24;
export const HUMAN_FACE_TYPE_MALE_ID = 71;
export const HUMAN_FACE_TYPE_FEMALE_ID = 102;

export const ANIMAL_TYPE_ID = 40;

export const OTHER_TYPE_ID = 49;
export const OTHER_FORM_ID = 60;
export const OTHER_SIZE_ID = 64;

export const ATTRIBUTE_HUMAN_ID = 2;
export const HUMAN_GENDER_MALE_ID = 14;

export const useActorCategoryItems = (): {
  itemsList: ItemsList | null;
  convertFormInputs:
    | ((
        ids: number[],
      ) => Pick<
        Inputs,
        | 'attributeId'
        | 'humanGenderId'
        | 'humanAgeIds'
        | 'humanFacialFeatureIds'
        | 'humanBodyShapeId'
        | 'humanHeightId'
        | 'humanHairColorId'
        | 'animalTypeId'
        | 'otherTypeId'
        | 'otherFormId'
        | 'otherSizeId'
      >)
    | null;
} => {
  const {data: actorCategories} = useGet<ActorCategory[]>(
    '/api/actor_categories',
  );
  return React.useMemo(() => {
    if (!actorCategories) {
      return {
        itemsList: null,
        convertFormInputs: null,
      };
    }
    return {
      itemsList: {
        attributeItems: generateItems(ATTRIBUTE_ID, actorCategories),
        humanAgeItems: generateItems(HUMAN_AGE_ID, actorCategories),
        humanGenderItems: generateItems(HUMAN_GENDER_ID, actorCategories),
        humanBodyShapeItems: generateItems(
          HUMAN_BODY_SHAPE_ID,
          actorCategories,
        ),
        humanHeightItems: generateItems(HUMAN_HEIGHT_ID, actorCategories),
        humanFaceTypeMaleItems: generateItems(
          HUMAN_FACE_TYPE_MALE_ID,
          actorCategories,
        ),
        humanFaceTypeFemaleItems: generateItems(
          HUMAN_FACE_TYPE_FEMALE_ID,
          actorCategories,
        ),
        humanFaceTypeNoneItems: [] as Item<number>[],
        humanHairColorItems: generateItems(
          HUMAN_HAIR_COLOR_ID,
          actorCategories,
        ),
        animalTypeItems: generateItems(ANIMAL_TYPE_ID, actorCategories),
        otherTypeItems: generateItems(OTHER_TYPE_ID, actorCategories),
        otherFormItems: generateItems(OTHER_FORM_ID, actorCategories),
        otherSizeItems: generateItems(OTHER_SIZE_ID, actorCategories),
      },
      convertFormInputs: (ids: number[]) => {
        const attributeId =
          fetchValue(ids, ATTRIBUTE_ID, actorCategories) || ATTRIBUTE_HUMAN_ID;
        return {
          attributeId,
          humanGenderId:
            fetchValue(ids, HUMAN_GENDER_ID, actorCategories) ||
            (attributeId === ATTRIBUTE_HUMAN_ID ? HUMAN_GENDER_MALE_ID : null),
          humanAgeIds: fetchValues(ids, HUMAN_AGE_ID, actorCategories),
          humanFacialFeatureIds: [
            ...fetchValues(ids, HUMAN_FACE_TYPE_MALE_ID, actorCategories),
            ...fetchValues(ids, HUMAN_FACE_TYPE_FEMALE_ID, actorCategories),
          ],
          humanBodyShapeId: fetchValue(
            ids,
            HUMAN_BODY_SHAPE_ID,
            actorCategories,
          ),
          humanHeightId: fetchValue(ids, HUMAN_HEIGHT_ID, actorCategories),
          humanHairColorId: fetchValue(
            ids,
            HUMAN_HAIR_COLOR_ID,
            actorCategories,
          ),
          animalTypeId: fetchValue(ids, ANIMAL_TYPE_ID, actorCategories),
          otherTypeId: fetchValue(ids, OTHER_TYPE_ID, actorCategories),
          otherFormId: fetchValue(ids, OTHER_FORM_ID, actorCategories),
          otherSizeId: fetchValue(ids, OTHER_SIZE_ID, actorCategories),
        };
      },
    };
  }, [actorCategories]);
};

const generateItems = (
  parentId: number,
  actorCategories: ActorCategory[] | undefined,
): Item<number>[] => {
  if (!actorCategories) {
    return [];
  }
  return actorCategories
    .filter(actorCategory => actorCategory.parentId === parentId)
    .map(actorCategory => ({
      label: actorCategory.name,
      value: actorCategory.id,
    }));
};

const fetchValue = (
  ids: number[],
  parentId: number,
  actorCategories: ActorCategory[] | undefined,
) => {
  return fetchValues(ids, parentId, actorCategories)[0] || null;
};

const fetchValues = (
  ids: number[],
  parentId: number,
  actorCategories: ActorCategory[] | undefined,
): number[] => {
  if (!actorCategories) {
    return [];
  }
  return actorCategories
    .filter(
      actorCategory =>
        actorCategory.parentId === parentId && ids.includes(actorCategory.id),
    )
    .map(actorCategory => actorCategory.id);
};
