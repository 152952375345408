import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import {AlertTriangleIcon} from '@/components/icons';

const FormWarning: React.FC = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        <AlertTriangleIcon size={12} color={'#f23406'} />
        一度公開するとイラストの削除はできなくなります
      </Text>
      <Text style={styles.text}>
        読者及び購入者保護のため、一度公開したイラストは削除できなくなります。
      </Text>
      <Text style={styles.text}>
        非公開にすることはできますが、公開している期間に購入されたイラストは、
        非公開に変更した場合であっても購入者は引き続き利用することができます。
      </Text>
    </View>
  );
};

export default React.memo(FormWarning);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderRadius: 4,
    margin: 16,
    padding: 16,
  } as ViewStyle,
  title: {
    color: '#f23406',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 2,
    textAlign: 'center',
  } as TextStyle,
  text: {
    color: '#666',
    fontSize: 12,
    marginTop: 8,
  } as TextStyle,
});
