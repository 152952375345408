import React from 'react';

import CurrentUser from '@/entities/CurrentUser';
import CurrentUserStatus from '@/entities/CurrentUserStatus';

import {useGetMutation} from '@/hooks/swr';

type CurrentUserNullable = CurrentUser | null;
type CurrentUserStatusNullable = CurrentUserStatus | null;

const CurrentUserStatusContext = React.createContext<{
  currentUser: CurrentUserNullable;
  currentUserStatus: CurrentUserStatusNullable;
  setCurrentUser?: React.Dispatch<React.SetStateAction<CurrentUserNullable>>;
  setCurrentUserStatus?: React.Dispatch<
    React.SetStateAction<CurrentUserStatusNullable>
  >;
}>({
  currentUser: null,
  currentUserStatus: null,
});

interface Props extends React.PropsWithChildren {}

const CurrentUserStatusProvider: React.FC<Props> = React.memo(props => {
  const {children} = props;
  const [initialized, setInitialized] = React.useState(false);
  const [currentUser, setCurrentUser] =
    React.useState<CurrentUserNullable>(null);
  const [currentUserStatus, setCurrentUserStatus] =
    React.useState<CurrentUserStatusNullable>(null);
  const {trigger} = useGetMutation<CurrentUser>('/api/creator/current_user');
  React.useEffect(() => {
    trigger()
      .then(data => {
        setCurrentUser(data || null);
        setInitialized(true);
      })
      .catch(() => {
        setInitialized(true);
      });
  }, []);
  if (!initialized) {
    return null;
  }
  return (
    <CurrentUserStatusContext.Provider
      value={{
        currentUser,
        currentUserStatus,
        setCurrentUser,
        setCurrentUserStatus,
      }}>
      {children}
    </CurrentUserStatusContext.Provider>
  );
});

export {CurrentUserStatusContext, CurrentUserStatusProvider};

export default CurrentUserStatusContext;
