import React from 'react';

import SettingList from './components/SettingList';

import {Layout} from '@/components/layout';

export interface Params {}

const Page = () => {
  return (
    <Layout
      title={'設定'}
      back={true}
      containerStyle={containerStyle}
      scrollable={false}>
      <SettingList />
    </Layout>
  );
};

export default React.memo(Page);

const containerStyle = {
  backgroundColor: '#fafafa',
};
