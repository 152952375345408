import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import ResourceRevenueInfo from './ResourceRevenueInfo';

import {RightIcon} from '@/components/icons';

import ResourceRevenueAggregation from '@/entities/summary/ResourceRevenueAggregation';

import {getDoubleResizeImageUrl} from '@/helpers/ImageHelper';

interface Props {
  resourceRevenueAggregation: ResourceRevenueAggregation;
}

const ResourceRevenueListItem: React.FC<Props> = props => {
  const {resourceRevenueAggregation} = props;
  const {resource} = resourceRevenueAggregation;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(
    () =>
      linkTo(
        `/revenue/${resourceRevenueAggregation.type.toLowerCase()}s/${
          resource.id
        }`,
      ),
    [],
  );
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(resource, {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      }),
    }),
    [],
  );
  return (
    <TouchableOpacity
      style={styles.container}
      activeOpacity={0.8}
      onPress={onPress}>
      <Image source={source} style={styles.image} />
      <ResourceRevenueInfo
        resourceRevenueAggregation={resourceRevenueAggregation}
      />
      <RightIcon color={'#999999'} size={13} />
    </TouchableOpacity>
  );
};

export default React.memo(ResourceRevenueListItem);

const IMAGE_WIDTH = 70;
const IMAGE_HEIGHT = 100;

const styles = StyleSheet.create({
  container: {
    margin: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 16,
  } as ViewStyle,
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  } as ImageStyle,
});
