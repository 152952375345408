import React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo, useFocusEffect} from '@react-navigation/native';

import FacebookAccountItem from './components/FacebookAccountItem';
import LineAccountItem from './components/LineAccountItem';
import TwitterAccountItem from './components/TwitterAccountItem';

import {Layout} from '@/components/layout';

import OauthAccount from '@/entities/OauthAccount';

import {useGetMutation} from '@/hooks/swr';
import useCurrentUser from '@/hooks/useCurrentUser';

import {displayableEmail} from '@/helpers/CurrentUserHelper';
export interface Params {}

const Page = () => {
  const {currentUser, get} = useCurrentUser();
  const {data: oauthAccounts, trigger} = useGetMutation<OauthAccount[]>(
    '/api/creator/oauth_accounts',
  );
  useFocusEffect(
    React.useCallback(() => {
      get();
      trigger();
    }, []),
  );
  const linkTo = useLinkTo();
  const onPressEmail = React.useCallback(
    () => linkTo('/settings/account/email/edit'),
    [],
  );
  const onPressPassword = React.useCallback(
    () => linkTo('/settings/account/password/edit'),
    [],
  );
  const connectedCodes = oauthAccounts?.map(o => o.oauthProviderCode);
  return (
    <Layout title={'アカウント設定'} back={true}>
      <View style={styles.row}>
        <Text style={styles.label}>メールアドレス</Text>
        <Pressable onPress={onPressEmail}>
          <Text style={styles.value}>
            {currentUser ? displayableEmail(currentUser) : ''}
          </Text>
        </Pressable>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>パスワード</Text>
        <Pressable onPress={onPressPassword}>
          <Text style={styles.value}>***********</Text>
        </Pressable>
      </View>
      <View style={styles.rowSNS}>
        <Text style={styles.label}>SNS連携</Text>
        <View style={styles.valueSNS}>
          <FacebookAccountItem
            connected={!!connectedCodes?.includes('facebook')}
            onRequestUpdate={trigger}
          />
          <LineAccountItem
            connected={!!connectedCodes?.includes('line')}
            onRequestUpdate={trigger}
          />
        </View>
      </View>
    </Layout>
  );
};

export default React.memo(Page);

const row = {
  width: '100%',
  alignItems: 'center',
  flexDirection: 'row',
  borderBottomColor: '#efefef',
  borderBottomWidth: StyleSheet.hairlineWidth,
  paddingHorizontal: 16,
} as ViewStyle;

const styles = StyleSheet.create({
  row: {
    ...row,
    height: 46,
  } as ViewStyle,
  rowSNS: row as ViewStyle,
  label: {
    width: 75,
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  value: {
    color: '#383838',
    fontSize: 14,
    paddingHorizontal: 16,
  } as TextStyle,
  valueSNS: {
    flex: 1,
    paddingHorizontal: 16,
    marginVertical: 4,
  } as ViewStyle,
});
