import React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import dateformat from 'dateformat';

import Announcement from '@/entities/Announcement';

interface Props {
  announcement: Announcement;
}

const AnnouncementListItem: React.FC<Props> = props => {
  const {announcement} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(
    () => linkTo(`/announcements/${announcement.id}`),
    [],
  );
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <Text style={styles.categoryLabel}>
        {announcement.creatorAnnouncementCategory.name}
      </Text>
      <Text style={styles.title}>{announcement.title}</Text>
      <Text style={styles.publishedDate}>
        {dateformat(new Date(announcement.publishedAt), 'yyyy.mm.dd')}
      </Text>
    </Pressable>
  );
};

export default React.memo(AnnouncementListItem);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 10,
  } as ViewStyle,
  categoryLabel: {
    color: '#FF8F13',
    fontSize: 11,
  } as TextStyle,
  publishedDate: {
    color: '#999',
    fontSize: 11,
    marginTop: 5,
  } as TextStyle,
  title: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: Platform.select({
      android: 18,
      ios: 14,
    }),
    marginTop: 8,
  } as TextStyle,
});
