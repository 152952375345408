import React from 'react';

import {ModalProps} from 'react-native';

import ModalBackdrop from './Backdrop';
import Dialog, {Props as DialogProps} from './Dialog';

export interface Props extends ModalProps, DialogProps {}

const FullScreenModal: React.FC<Props> = props => {
  const {children, onRequestClose, ...modalProps} = props;
  const dialogAnimationProps = props.dialogAnimationProps || {opacity: true};

  return (
    <ModalBackdrop
      {...modalProps}
      animationType={'none'}
      transparent={true}
      onRequestClose={onRequestClose}>
      <Dialog
        dialogAnimationProps={dialogAnimationProps}
        onRequestClose={onRequestClose}>
        {children}
      </Dialog>
    </ModalBackdrop>
  );
};

export default React.memo(FullScreenModal);
