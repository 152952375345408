interface Entity {
  originalImageUrl: string;
}

const getResizedImageUrl = (
  entity: Entity,
  {width, height}: {width: number; height: number},
  options?: {nocanvas: boolean},
) => {
  return entity.originalImageUrl.replace(
    /\/original\//,
    `/${options?.nocanvas ? 'nc-' : ''}${width}x${height}/`,
  );
};

const getDoubleResizeImageUrl = (
  entity: Entity,
  {width, height}: {width: number; height: number},
  options?: {nocanvas: boolean},
) => {
  return getResizedImageUrl(
    entity,
    {width: width * 2, height: height * 2},
    options,
  );
};
export {getResizedImageUrl, getDoubleResizeImageUrl};
