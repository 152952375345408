import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {PrimaryButton} from '@/components/ui/buttons';

import SvgUriAdapter from '@/components/utils/SvgUriAdapter';

import illustKeyVisualUri from '@/assets/svgs/illust-key-visual.svg';

interface Props {}

const NoIllustrations: React.FC<Props> = () => {
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => linkTo('/actors/new'), []);
  return (
    <View style={styles.container}>
      <SvgUriAdapter uri={illustKeyVisualUri} width={183} height={162} />
      <Text style={styles.text}>まだ登録したイラストはありません</Text>
      <PrimaryButton style={styles.button} onPress={onPress}>
        新しいイラストを登録する
      </PrimaryButton>
    </View>
  );
};

export default React.memo(NoIllustrations);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fafafa',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 65,
  } as ViewStyle,
  text: {
    color: '#666',
    fontSize: 14,
    marginVertical: 24,
  } as TextStyle,
  button: {
    width: 240,
  } as ViewStyle,
});
