import React from 'react';

import Form, {Inputs} from './components/Form';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import {buildFieldErrors} from '@/helpers/FormHelper';
import {mutateActorCharacterFace} from '@/helpers/swr/MutateHelper';

import {useCreateMutation} from '@/hooks/swr';
import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import {get} from '@/services/fetchers';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

import ActorCharacterFace from '@/entities/ActorCharacterFace';

export interface Params {
  actorCharacterId: number;
}

const Page = (props: ScreenProps<'ActorCharacterFaceNew'>) => {
  const {route} = props;
  const {actorCharacterId} = route.params;
  const navigation = useDefaultNavigation();
  const [actorCharacterError, setActorCharacterError] =
    React.useState<any>(null);
  React.useEffect(() => {
    get(`/api/creator/actor_characters/${actorCharacterId}`).catch(
      setActorCharacterError,
    );
  }, []);
  const {trigger, error, isMutating} = useCreateMutation<ActorCharacterFace>(
    `/api/creator/actor_character_faces/`,
  );
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger({...convertFormValuesToPostParams(data), actorCharacterId}).then(
      actorCharacterFace => {
        if (actorCharacterFace) {
          navigation.navigate('Actor', {
            id: actorCharacterFace.actorId,
          });
          mutateActorCharacterFace({
            id: actorCharacterFace.id,
            actorId: actorCharacterFace.actorId,
          });
          sendDefaultCustomEvent({
            resourceType: 'actor_character_face',
            resourceId: actorCharacterFace.id,
            actionName: 'create',
          });
        }
      },
    );
  }, []);
  return (
    <Layout
      title={'表情差分の追加'}
      back={true}
      loading={isMutating}
      error={actorCharacterError}>
      <Form
        onSale={false}
        errors={convertResponseToErrors(error)}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {actorCharacterFace: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    image: [
      'image',
      'miniMagickImageValid',
      'miniMagickImageWidth',
      'miniMagickImageHeight',
      'miniMagickImageType',
      'miniMagickImageDataType',
    ],
    faceName: 'faceName',
  });
};
