import React from 'react';
import {StyleProp, StyleSheet, View, ViewProps, ViewStyle} from 'react-native';

interface Props extends ViewProps {
  style?: StyleProp<ViewStyle> | undefined;
}

const Field: React.FC<Props> = props => {
  const {style, ...restProps} = props;
  return <View style={[styles.container, style]} {...restProps} />;
};

export default React.memo(Field);

const styles = StyleSheet.create({
  container: {
    margin: 16,
  } as ViewStyle,
});
