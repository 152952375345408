import React from 'react';
import {
  UseFormWatch,
  UseFormSetValue,
  FieldPathValue,
  FieldValues,
  FieldPath,
} from 'react-hook-form';
import {FullScreenModal} from '@/components/modals';

import HumanFacialFeatureIds from './HumanFacialFeatureIds';
import Keywords from './Keywords';

import {ItemsList} from '../types';

export type ModalType = 'humanFacialFeatureIds' | 'keywords';

export interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends React.PropsWithChildren {
  modalType: ModalType | null;
  itemsList: ItemsList;
  name: TName | null;
  humanGenderId: number | null;
  watch: UseFormWatch<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  onRequestClose: () => void;
}

function Modals<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {
    modalType,
    itemsList,
    name,
    humanGenderId,
    watch,
    setValue,
    onRequestClose,
  } = props;
  const onSubmit = React.useCallback(
    (value: FieldPathValue<TFieldValues, TName>) => {
      name && setValue(name, value as any, {shouldValidate: true});
      onRequestClose();
    },
    [name],
  );
  if (!modalType || !name) {
    return null;
  }
  return (
    <FullScreenModal>
      {modalType === 'humanFacialFeatureIds' ? (
        <HumanFacialFeatureIds
          itemsList={itemsList}
          humanGenderId={humanGenderId}
          values={watch(name)}
          onSubmit={onSubmit}
          onRequestClose={onRequestClose}
        />
      ) : modalType === 'keywords' ? (
        <Keywords
          values={watch(name)}
          onSubmit={onSubmit}
          onRequestClose={onRequestClose}
        />
      ) : null}
    </FullScreenModal>
  );
}

export default React.memo(Modals) as typeof Modals;
