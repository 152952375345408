import React from 'react';
import {StyleSheet, TouchableOpacity, ViewStyle} from 'react-native';
import Collapsible from 'react-native-collapsible';

import AnnouncementCategoryList from './AnnouncementCategoryList';

import AnnouncementCategory from '@/entities/AnnouncementCategory';

interface Props {
  announcementCategories: AnnouncementCategory[];
  selectedAnnouncementCategory: AnnouncementCategory;
  onSelectAnnouncementCategory: (
    announcementCategory: AnnouncementCategory,
  ) => void;
  onCloseModal: () => void;
  children?: React.ReactNode;
}

const AnnouncementCategorySelectModal: React.FC<Props> = props => {
  const {
    announcementCategories,
    selectedAnnouncementCategory,
    onSelectAnnouncementCategory,
    onCloseModal,
  } = props;
  const [collapsed, setCollapsed] = React.useState(true);
  React.useEffect(() => {
    setCollapsed(false);
    return () => {
      setCollapsed(true);
    };
  }, []);
  return (
    <TouchableOpacity style={styles.container} onPress={onCloseModal}>
      <Collapsible collapsed={collapsed}>
        <AnnouncementCategoryList
          announcementCategories={announcementCategories}
          selectedAnnouncementCategory={selectedAnnouncementCategory}
          onSelectAnnouncementCategory={onSelectAnnouncementCategory}
        />
      </Collapsible>
    </TouchableOpacity>
  );
};

export default React.memo(AnnouncementCategorySelectModal);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0, 0, 0, .6)',
    ...StyleSheet.absoluteFillObject,
    top: 56,
  } as ViewStyle,
});
