import React from 'react';

import {defaultScreenOptions} from './ScreenOptions';
import RootStack from './RootStack';

import MemberOnlyScreens from './screens/MemberOnlyScreens';
import NotMemberScreens from './screens/NotMemberScreens';
import PublicScreens from './screens/PublicScreens';
import TemporaryMemberOnlyScreens from './screens/TemporaryMemberOnlyScreens';
import UnacceptedMemberScreens from './screens/UnacceptedMemberScreens';
import UnenabledMemberScreens from './screens/UnenabledMemberScreens';

import CurrentUser from '@/entities/CurrentUser';

interface Props {
  currentUser: CurrentUser | null;
}

const Navigator = (props: Props) => {
  const {currentUser} = props;
  return (
    <RootStack.Navigator
      screenOptions={defaultScreenOptions}
      initialRouteName={'Home'}>
      {PublicScreens}
      {!currentUser
        ? NotMemberScreens
        : !currentUser.confirmed
        ? TemporaryMemberOnlyScreens
        : !currentUser.acceptedTerms
        ? UnacceptedMemberScreens
        : !currentUser.enabled
        ? UnenabledMemberScreens
        : currentUser.enabled
        ? MemberOnlyScreens
        : NotMemberScreens}
    </RootStack.Navigator>
  );
};

export default React.memo(Navigator);
