import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import {CheckButton, PrimaryButton} from '@/components/ui/buttons';

import CurrentUserStatus from '@/entities/CurrentUserStatus';

interface Props {
  currentUserStatus: CurrentUserStatus;
  onPressNext: () => void;
}

const FormStep1: React.FC<Props> = props => {
  const {currentUserStatus, onPressNext} = props;
  const [checked, setChecked] = React.useState(false);
  const toggleChecked = React.useCallback(
    () => setChecked(!checked),
    [checked],
  );
  return (
    <>
      {currentUserStatus.enabledWriterUser ? (
        <>
          <Text style={styles.defaultMessage}>
            お客様は執筆サービスのTapNovelMakerも{'\n'}
            ご利用いただいています。
          </Text>
          <Text style={styles.defaultMessage}>
            一度退会すると投稿した作品及び{'\n'}
            登録したイラストはすべて削除されます。
          </Text>
        </>
      ) : (
        <Text style={styles.defaultMessage}>
          一度退会すると登録したイラストは{'\n'}
          すべて消去されます。
        </Text>
      )}
      <Text style={styles.warningMessage}>
        尚、購入されたイラストは購入者が{'\n'}
        引き続き利用することができます。
      </Text>
      {currentUserStatus.entryContestsCount > 0 && (
        <Text style={styles.warningMessage}>
          応募規約に記載の通りコンテストへ{'\n'}
          応募した作品は消去されません。
        </Text>
      )}
      <View style={styles.check}>
        <CheckButton checked={checked} onPress={toggleChecked}>
          上記に同意します
        </CheckButton>
      </View>
      <PrimaryButton disabled={!checked} onPress={onPressNext}>
        次へ
      </PrimaryButton>
    </>
  );
};

export default React.memo(FormStep1);

const styles = StyleSheet.create({
  defaultMessage: {
    color: '#222',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 16,
  } as TextStyle,
  warningMessage: {
    color: '#f23406',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
  } as TextStyle,
  check: {
    width: 160,
    marginVertical: 16,
  } as ViewStyle,
});
