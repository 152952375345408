import React from 'react';

import Icon from '../Icon';

interface Props {
  size?: number;
  color?: string;
}

const FacebookIcon: React.FC<Props> = props => {
  const size = props.size || 20;
  const color = props.color || '#1877F2';
  return <Icon name={'facebook'} size={size} color={color} />;
};

export default React.memo(FacebookIcon);
