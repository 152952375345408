import Config from './Config';

const TAPNOVEL_API_ENDPOINT = fetch('REACT_APP_TAPNOVEL_API_ENDPOINT');

const FACEBOOK_APP_ID = fetch('REACT_APP_FACEBOOK_APP_ID');

const TWITTER_COMSUMER_KEY = '';
const TWITTER_CONSUMER_SECRET = '';
const TWITTER_CREATOR_AUTH_REDIRECT_URI = fetch(
  'REACT_APP_TWITTER_CREATOR_AUTH_REDIRECT_URI',
);

const LINE_CREATOR_CHANNEL_ID = fetch('REACT_APP_LINE_CREATOR_CHANNEL_ID');
const LINE_CREATOR_AUTH_REDIRECT_URI = fetch(
  'REACT_APP_LINE_CREATOR_AUTH_REDIRECT_URI',
);

export {
  TAPNOVEL_API_ENDPOINT,
  FACEBOOK_APP_ID,
  TWITTER_COMSUMER_KEY,
  TWITTER_CONSUMER_SECRET,
  TWITTER_CREATOR_AUTH_REDIRECT_URI,
  LINE_CREATOR_CHANNEL_ID,
  LINE_CREATOR_AUTH_REDIRECT_URI,
};

function fetch(key: string) {
  const value = Config[key] as string;
  if (!value) {
    throw new Error(`Should set ${key}`);
  }
  return value;
}
