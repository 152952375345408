import React from 'react';
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

import Button, {Props} from './Button';

const BlackButton: React.FC<Props> = props => {
  const {style, textProps, ...restProps} = props;
  const {style: textStyle, ...restTextProps} = textProps || {};
  return (
    <Button
      style={[styles.container, style]}
      {...restProps}
      textProps={{
        ...restTextProps,
        style: [styles.text, textStyle],
      }}
    />
  );
};

export default React.memo(BlackButton);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#383838',
  } as ViewStyle,
  text: {
    color: '#fff',
  } as TextStyle,
});
