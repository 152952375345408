import {mutate} from 'swr';

export const mutateActor = (args: {id: number}) => {
  mutate(`/api/creator/actos/${args.id}`);
  mutate(`/api/creator/actor_character_faces?actor_id=${args.id}`);
};

export const mutateActorCharacterFace = (args: {
  id: number;
  actorId: number;
}) => {
  mutate(`/api/creator/actor_character_faces/${args.id}`);
  mutate(`/api/creator/actors/${args.actorId}`);
  mutate(`/api/creator/actor_character_faces?actor_id=${args.actorId}`);
};
