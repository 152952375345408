import MobileDetect from 'mobile-detect';

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const isAndroid = mobileDetect.os() === 'AndroidOS';
const isIOS = mobileDetect.os() === 'iOS';

const isMobile = !!mobileDetect.mobile();

const userAgent = window.navigator.userAgent.toLowerCase();

const isChrome = userAgent.indexOf('chrome') != -1;

const isSafari =
  !(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) &&
  !(userAgent.indexOf('edge') != -1) &&
  !(userAgent.indexOf('chrome') != -1) &&
  userAgent.indexOf('safari') != -1;

const isWindows = userAgent.indexOf('windows') != -1;

const getUserAgent = async () => {
  return new Promise((resolve, reject) => {
    resolve(navigator.userAgent);
  });
};

export {
  isAndroid,
  isIOS,
  isMobile,
  isChrome,
  isSafari,
  isWindows,
  getUserAgent,
};
