import React from 'react';
import {
  ColorValue,
  GestureResponderEvent,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
} from 'react-native';

interface Props extends React.PropsWithChildren {
  color?: ColorValue | undefined;
  size?: 'small' | 'medium' | 'large';
  bold?: boolean;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

const LinkButton: React.FC<Props> = props => {
  const {size, bold, children, onPress} = props;
  const color = props.color || '#222';
  const containerStyle =
    size === 'large'
      ? styles.containerLarge
      : size === 'small'
      ? styles.containerSmall
      : styles.containerMedium;

  const textStyle =
    size === 'large'
      ? styles.textLarge
      : size === 'small'
      ? styles.textSmall
      : styles.textMedium;
  return (
    <TouchableOpacity
      style={[containerStyle, {borderBottomColor: color}]}
      onPress={onPress}>
      <Text
        style={[
          textStyle,
          {
            color,
            fontWeight: bold ? 'bold' : 'normal',
          },
        ]}>
        {children}
      </Text>
    </TouchableOpacity>
  );
};

export default React.memo(LinkButton);

const styles = StyleSheet.create({
  containerSmall: {
    borderBottomWidth: 0.5,
  } as TextStyle,
  containerMedium: {
    borderBottomWidth: 1,
  },
  containerLarge: {
    borderBottomWidth: 1,
  },
  textSmall: {
    fontSize: 10,
  } as TextStyle,
  textMedium: {
    fontSize: 14,
  },
  textLarge: {
    fontSize: 16,
  },
});
