import {Platform} from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';

export const useStyle = CreateResponsiveStyle(
  {
    container: {
      backgroundColor: 'white',
      flex: Platform.select({web: undefined, default: 1}),
      width: Platform.select({web: '100dvw', default: undefined}),
      height: Platform.select({web: '100dvh', default: undefined}),
    },
  },
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: {
        flex: 1,
        width: 'unset',
        height: 'unset',
      },
    },
  },
);
