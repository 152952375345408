import React from 'react';
import {StyleSheet} from 'react-native';
import useApplicationLayoutStyle from '@/hooks/useApplicationLayoutStyle';

interface Props extends React.PropsWithChildren {
  noLayout?: boolean;
}

const Content: React.FC<Props> = props => {
  const {noLayout, children} = props;
  const styles = useApplicationLayoutStyle();
  const style = React.useMemo(
    () =>
      StyleSheet.flatten(
        noLayout ? styles.contentForNoLayout : styles.content,
      ) as React.CSSProperties,
    [noLayout, styles],
  );
  return (
    <div
      className={`view-div layout-content layout-content-${
        noLayout ? 'off' : 'on'
      }`}
      style={style}>
      {children}
    </div>
  );
};

export default React.memo(Content);
