import React from 'react';

import Detail from './components/Detail';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import Announcement from '@/entities/Announcement';

import {useGet} from '@/hooks/swr';

export interface Params {
  id: number;
}

const Page = (props: ScreenProps<'Announcement'>) => {
  const {id} = props.route.params;
  const {data: announcement, error} = useGet<Announcement>(
    `/api/creator/announcements/${id}`,
  );
  return (
    <Layout title={'お知らせ'} back={true} error={error}>
      {announcement && <Detail announcement={announcement} />}
    </Layout>
  );
};

export default React.memo(Page);
