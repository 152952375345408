import React from 'react';
import {StackScreenProps} from '@react-navigation/stack';

import {Layout} from '@/components/layout';
import TapNovelWebView from '@/components/utils/TapNovelWebView';

import {RootStackParamList} from '@/navigation/RootStackParamList';

export interface Params {}

type Props = StackScreenProps<
  RootStackParamList,
  'PoliciesSettlementLaw' | 'SettingsPoliciesSettlementLaw'
>;

const Page = (props: Props) => {
  const {name} = props.route;
  const back = name !== 'PoliciesSettlementLaw';
  const close = name === 'PoliciesSettlementLaw';
  return (
    <Layout
      title={'資金決済法に基づく表示'}
      back={back}
      close={close}
      scrollable={false}>
      <TapNovelWebView path={'/policies/settlement_law?without_layout=on'} />
    </Layout>
  );
};

export default React.memo(Page);
