import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import Tag from './Tag';

interface Props {
  values: string[];
  onRemove?: (value: string) => void;
}

const Tags: React.FC<Props> = props => {
  const {values, onRemove} = props;
  return (
    <View style={styles.container}>
      {values.map(value => (
        <Tag key={value} value={value} onRemove={onRemove} />
      ))}
    </View>
  );
};

export default React.memo(Tags);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
  } as ViewStyle,
});
