import React from 'react';

import HelpList from './HelpList';

import Help from '@/entities/Help';

interface Props {
  helps: Help[];
  refreshing?: boolean | null;
  scrollEnabled?: boolean | undefined;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
  onRefresh?: () => void;
}

const CollapsibleHelpList: React.FC<Props> = props => {
  const [helpIdToCollapsed, setHelpIdToCollapsed] = React.useState<{
    [key: string]: boolean;
  }>({});
  const onPressHelp = React.useCallback(
    (help: Help) => {
      const collapsed =
        helpIdToCollapsed[help.id] === undefined
          ? true
          : helpIdToCollapsed[help.id];
      setHelpIdToCollapsed({
        ...helpIdToCollapsed,
        [help.id]: !collapsed,
      });
    },
    [helpIdToCollapsed],
  );
  return (
    <HelpList
      {...props}
      helpIdToCollapsed={helpIdToCollapsed}
      onPressHelp={onPressHelp}
    />
  );
};

export default React.memo(CollapsibleHelpList);
