import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {LinkItem} from '@/components/value/types';

import {RightIcon} from '@/components/icons';

interface Props {
  item: LinkItem;
}

const DefaultLinkListItem: React.FC<Props> = props => {
  const {item} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(
    () => (item.action ? item.action() : linkTo(item.url)),
    [],
  );
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <>
        <Text style={styles.label}>{item.label}</Text>
        <RightIcon color={'#999999'} size={13} />
      </>
    </TouchableOpacity>
  );
};

export default React.memo(DefaultLinkListItem);

const styles = StyleSheet.create({
  container: {
    height: 44,
    width: '100%',
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    flex: 1,
  } as TextStyle,
});
