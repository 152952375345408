import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import BaseBadge from './BaseBadge';

interface Props extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
}

const LightBadge: React.FC<Props> = props => {
  const {style} = props;
  return <BaseBadge {...props} style={[style, color]} />;
};

export default React.memo(LightBadge);

const color = {
  backgroundColor: '#d5d5d5',
} as ViewStyle;
