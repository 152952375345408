import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';

import Alert from '@/components/alert/Alert';

import {RootStackParamList} from '@/navigation/RootStackParamList';
import {useRawCreateMutation} from '@/hooks/swr';
import useCurrentUserAndAccessToken from '@/hooks/useCurrentUserAndAccessToken';
import SocialLogin, {
  SuccessData,
  ErrorData,
} from '@/services/social_logins/SocialLogin';

const useSocialLogin = (
  socialLogin: SocialLogin,
  url: string,
  key: 'facebookAccount' | 'twitterAccount' | 'lineAccount',
) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const {trigger, ...response} = useRawCreateMutation(url);
  const {update} = useCurrentUserAndAccessToken();
  const onSuccess = React.useCallback((data: SuccessData) => {
    const {
      accessToken,
      accessTokenSecret,
      id: userId,
      email,
      screenName,
      screenName: penName,
    } = data;
    if (!accessToken) {
      Alert.alert('アクセストークンが取得できませんでした');
      return;
    }
    const account = {accessToken, accessTokenSecret, userId, screenName};
    trigger({[key]: account})
      .then(update)
      .then(() => navigation.popToTop())
      .catch(e => {
        switch (e.status) {
          case 401:
            navigation.navigate('UserSignUp', {
              email,
              options: {[key]: account},
              penName,
            });
            break;
          case 422:
            Alert.alert('トークンが不正です');
            break;
        }
      });
  }, []);
  const onError = React.useCallback((error: ErrorData) => {
    Alert.alert('エラーが発生しました。');
  }, []);
  const login = React.useCallback(
    () => socialLogin.login({onSuccess, onError}),
    [],
  );
  return {login, ...response};
};

export default useSocialLogin;
