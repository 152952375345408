import React from 'react';
import {
  StyleSheet,
  TextInput,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

export interface Props extends TextInputProps {
  isInvalid?: boolean;
}

const Textarea: React.FC<Props> = props => {
  const {isInvalid, ...restProps} = props;
  return (
    <View style={styles.container}>
      <TextInput
        {...restProps}
        style={isInvalid ? styles.inputDisabled : styles.input}
        placeholderTextColor={'#999'}
        multiline={true}
      />
    </View>
  );
};

export default React.memo(Textarea);

const container = {
  backgroundColor: 'white',
  borderWidth: 1,
  borderColor: '#efefef',
  marginVertical: 16,
} as ViewStyle;

const input = {
  backgroundColor: 'white',
  fontSize: 15,
  height: 174,
  margin: 8,
  textAlignVertical: 'top',
} as TextStyle;

const styles = StyleSheet.create({
  container,
  input,
  inputDisabled: {
    ...input,
    backgroundColor: 'rgba(242, 52, 6, 0.3)',
    borderBottomColor: 'rgba(242, 52, 6, 0.3)',
  } as TextStyle,
});
