import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import {ControlledInput, Label, Field} from '@/components/form';
import {PrimaryButton} from '@/components/ui/buttons';
import {Paragraph} from '@/components/content';

export type Inputs = {
  email: string;
};

interface Props {
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const FormNew: React.FC<Props> = props => {
  const {errors, onSubmit} = props;
  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const onPress = handleSubmit(onSubmit);
  return (
    <View style={styles.container}>
      <Paragraph paddingHorizontal={16}>
        パスワード再設定用URLを記載したメールを送信します。{'\n'}
        TapNovelCreatorにご登録のメールアドレスを入力してください。
      </Paragraph>
      <Field>
        <Label>メールアドレス</Label>
        <ControlledInput
          placeholder={'メールアドレスを入力'}
          textContentType={'emailAddress'}
          keyboardType={'email-address'}
          onSubmitEditing={onPress}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
              pattern: {value: /.+@.+\.[A-Za-z0-9]{1,}/, message: '不正です'},
            },
            name: 'email',
          }}
          errors={errors}
        />
      </Field>

      <View style={styles.submitButton}>
        <PrimaryButton disabled={!isValid} onPress={onPress}>
          送信する
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(FormNew);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 40,
  } as ViewStyle,
  submitButton: {
    alignItems: 'center',
    marginTop: 24,
  } as ViewStyle,
});
