import React from 'react';
import {StyleSheet, TextStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {TextButton} from '@/components/ui/buttons';

interface Props {
  url: string | null;
  disabled?: boolean;
}

const PublishButton: React.FC<Props> = props => {
  const {url, disabled} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => url && linkTo(url), [url]);
  return (
    <TextButton
      textProps={disabled ? textDisabledProps : textProps}
      disabled={disabled}
      onPress={onPress}>
      公開設定
    </TextButton>
  );
};

export default React.memo(PublishButton);

const text = {
  color: '#e7b600',
  fontWeight: 'bold',
  fontSize: 16,
} as TextStyle;

const styles = StyleSheet.create({
  text,
  textDisabled: {
    ...text,
    color: '#999999',
  } as TextStyle,
});

const textProps = {style: styles.text};

const textDisabledProps = {style: styles.textDisabled};
