import React from 'react';

import NoBalanceHistory from './components/NoBalanceTransaction';
import BalanceHistoryList from './components/BalanceTransactionList';
import BalanceTransactionListFooter from './components/BalanceTransactionListFooter';

import Balance from '@/entities/Balance';
import BalanceTransaction from '@/entities/BalanceTransaction';

import {Layout} from '@/components/layout';

import {useGet, usePaginatedGetWithResponse} from '@/hooks/swr';

export interface Params {}

const Page = () => {
  const {data: balance} = useGet<Balance>(`/api/creator/balance`, undefined, {
    revalidateOnFocus: true,
  });
  const {data, size, setSize} = usePaginatedGetWithResponse<BalanceTransaction>(
    `/api/creator/balance_transactions`,
    undefined,
    {revalidateAll: true},
  );
  const balanceTransactions = React.useMemo(
    () => data?.flatMap(row => row.data),
    [data],
  );
  const ListFooterComponent = React.useMemo(() => {
    const link = data ? data[data.length - 1].response.headers.get('Link') : '';
    return link?.includes('rel="next"') ? (
      <BalanceTransactionListFooter
        onPress={() => {
          setSize(size + 1);
        }}
      />
    ) : null;
  }, [size, data]);
  return (
    <Layout title={'残高履歴'} back={true} scrollable={false}>
      {balanceTransactions && balance ? (
        balanceTransactions.length === 0 ? (
          <NoBalanceHistory />
        ) : (
          <>
            <BalanceHistoryList
              balanceTransactions={balanceTransactions}
              balance={balance}
              ListFooterComponent={ListFooterComponent}
            />
          </>
        )
      ) : null}
    </Layout>
  );
};

export default React.memo(Page);
