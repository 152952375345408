import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

const ListItemNormalSeparator: React.FC = () => {
  return <View style={styles.separator} />;
};

export default React.memo(ListItemNormalSeparator);

const styles = StyleSheet.create({
  separator: {
    backgroundColor: '#E5E5E5',
    height: StyleSheet.hairlineWidth,
  } as ViewStyle,
});
