import React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo, useFocusEffect} from '@react-navigation/native';
import {useForm, FieldErrors} from 'react-hook-form';
import numeral from 'numeral';

import TransferRequest from '@/entities/TransferRequest';

import {useGet} from '@/hooks/swr';

import {PrimaryButton, CheckButton} from '@/components/ui/buttons';

export type Inputs = {
  amount: number;
};

interface Props {
  errors?: FieldErrors<Inputs>;
  defaultValues?: Inputs;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {errors, defaultValues, onSubmit} = props;
  const linkTo = useLinkTo();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {isValid},
  } = useForm({
    defaultValues: defaultValues || {
      amount: 2000,
    },
  });
  const amount = watch('amount');
  const {data: transferRequest, mutate} = useGet<TransferRequest>(
    '/api/creator/transfer_request',
    {amount},
    {keepPreviousData: true},
  );
  const mount = React.useRef(false);
  const [checked, setChecked] = React.useState(false);
  useFocusEffect(
    React.useCallback(() => {
      if (!mount.current) {
        return;
      }
      mutate();
    }, []),
  );
  React.useEffect(() => {
    mount.current = true;
  }, []);
  const toggleChecked = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  const onPressPolicyTerms = React.useCallback(
    () => linkTo('/policies/terms'),
    [],
  );
  const onPressSettingQualifiedInvoiceIssuer = React.useCallback(
    () => linkTo('/settings/qualified_invoice_issuer'),
    [],
  );
  const onPress = handleSubmit(onSubmit);
  if (!transferRequest) {
    return null;
  }
  return (
    <View style={styles.container}>
      <View style={styles.amountRow}>
        <Text style={styles.rowLabel}>申請額</Text>
        <View style={styles.amountRowValue}>
          <Text style={styles.amountRowValueUnit}>￥</Text>
          <TextInput
            style={styles.amountRowValueInput}
            value={`${amount}`}
            onChangeText={text => {
              setValue('amount', Number.parseInt(text) || 0);
            }}
          />
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowLabel}>収益残高</Text>
        <Text style={styles.rowValue}>
          <Text style={styles.rowValueUnit}>￥</Text>
          {numeral(transferRequest.remainingBalance).format('0,0')}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowLabel}>振込手数料</Text>
        <Text style={styles.rowValue}>
          <Text style={styles.rowValueUnit}>−￥</Text>
          {numeral(transferRequest.bankTransferFee).format('0,0')}
        </Text>
      </View>
      <Text style={[styles.note, {marginBottom: 16}]}>
        ※
        <TouchableOpacity onPress={onPressPolicyTerms}>
          <Text style={styles.noteLink}>規約</Text>
        </TouchableOpacity>
        で定めている通り、振込手数料はお客様負担となります。
      </Text>

      {transferRequest.discountTax && (
        <View style={styles.row}>
          <Text style={styles.rowLabel}>消費税分割引</Text>
          <Text style={styles.rowValue}>
            <Text style={styles.rowValueUnit}>−￥</Text>
            {numeral(transferRequest.discountTax).format('0,0')}
          </Text>
        </View>
      )}
      <Text style={styles.note}>
        ※
        <TouchableOpacity onPress={onPressPolicyTerms}>
          <Text style={styles.noteLink}>規約</Text>
        </TouchableOpacity>
        で定めている通り、消費税の登録番号を未取得の場合、{'\n'}
        {'　'}インボイス制度の経過措置に基づき消費税分を割引かせていただきます。
      </Text>
      <Text style={[styles.note, {marginBottom: 32}]}>
        ※登録番号を取得されている場合は
        <TouchableOpacity onPress={onPressSettingQualifiedInvoiceIssuer}>
          <Text style={styles.noteLink}>消費税事業者設定</Text>
        </TouchableOpacity>
        を修正ください。
      </Text>

      <Text style={styles.title}>受け取れる金額</Text>
      <View style={styles.box}>
        <Text style={styles.value}>
          <Text style={styles.valueUnit}>￥</Text>
          {numeral(transferRequest.amountWithoutFee).format('0,0')}
        </Text>
      </View>
      <View style={styles.submit}>
        <CheckButton
          style={styles.submitCheck}
          checked={checked}
          onPress={toggleChecked}>
          振込日は振込申請月の翌月10日前後となります
        </CheckButton>
        <PrimaryButton
          disabled={
            transferRequest.requested ||
            !(isValid && checked) ||
            !transferRequest.requestable
          }
          style={styles.submitButton}
          onPress={onPress}>
          {transferRequest.requested ? '申請中' : '振込申請する'}
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form) as typeof Form;

const styles = StyleSheet.create({
  container: {
    padding: 16,
  } as ViewStyle,
  amountRow: {
    marginBottom: 16,
  } as ViewStyle,
  amountRowValue: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
    marginTop: 8,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  amountRowValueUnit: {
    color: '#383838',
    fontSize: 14,
  } as TextStyle,
  amountRowValueInput: {
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
  } as TextStyle,
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
    alignItems: 'center',
  } as ViewStyle,
  rowLabel: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  rowValue: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  rowValueUnit: {
    fontSize: 14,
  } as TextStyle,
  note: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  noteLink: {
    color: '#e7b600',
    borderBottomColor: '#e7b600',
    borderBottomWidth: 0.6,
  } as TextStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  box: {
    height: 65,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    marginTop: 10,
    marginBottom: 32,
  } as ViewStyle,
  value: {
    color: '#383838',
    fontSize: 25,
    fontWeight: 'bold',
  } as TextStyle,
  valueUnit: {
    fontSize: 20,
  } as TextStyle,
  submit: {
    alignItems: 'center',
  } as ViewStyle,
  submitCheck: {
    width: 290,
  } as ViewStyle,
  submitButton: {
    marginTop: 16,
  } as ViewStyle,
});
