import React from 'react';

import Form, {Inputs} from './components/Form';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import {buildFieldErrors} from '@/helpers/FormHelper';
import {get} from '@/services/fetchers';

import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import {useUpdateMutation} from '@/hooks/swr';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';
import {mutateActor} from '@/helpers/swr/MutateHelper';

import Actor from '@/entities/Actor';

export interface Params {
  id: number;
}

const Page = (props: ScreenProps<'ActorFaceCenterPositionEdit'>) => {
  const {route} = props;
  const {id} = route.params;
  const navigation = useDefaultNavigation();
  const [actor, setActor] = React.useState<Actor | null>(null);
  const [actorError, setActorError] = React.useState<any>(null);
  React.useEffect(() => {
    get<Actor>(`/api/creator/actors/${id}`).then(setActor).catch(setActorError);
  }, []);
  const {trigger, isMutating, error} = useUpdateMutation<Actor>(
    `/api/creator/actors/${id}`,
  );
  const defaultValues = React.useMemo((): Inputs | undefined => {
    if (actor) {
      return {
        faceCenterPosition: actor.faceCenterPosition,
      };
    } else {
      return undefined;
    }
  }, [actor]);
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(actor => {
      if (actor) {
        navigation.goBack();
        sendDefaultCustomEvent({
          resourceType: 'actor_face_center_position',
          resourceId: actor.id,
          actionName: 'update',
        });
        mutateActor({id: actor.id});
      }
    });
  }, []);
  return (
    <Layout
      title={'画像位置の調整'}
      close={true}
      loading={isMutating}
      error={actorError}
      scrollable={false}>
      {defaultValues && actor && (
        <Form
          actor={actor}
          defaultValues={defaultValues}
          errors={convertResponseToErrors(error)}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {
    actor: formValues,
  };
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {});
};
