import React from 'react';
import {StyleProp, StyleSheet, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {OutlineButton} from '@/components/ui/buttons';

import {PencilIcon} from '@/components/icons';

interface Props {
  style?: StyleProp<ViewStyle> | undefined;
  url: string;
}

const EditButton: React.FC<Props> = props => {
  const {style, url} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => linkTo(url), []);
  return (
    <OutlineButton
      style={[styles.button, style]}
      textProps={textProps}
      icon={icon}
      onPress={onPress}>
      編集
    </OutlineButton>
  );
};

export default React.memo(EditButton);

const styles = StyleSheet.create({
  button: {
    width: 82,
    height: 28,
  } as ViewStyle,
});

const icon = <PencilIcon size={14} />;

const textProps = {style: {fontSize: 12}};
