import React from 'react';

import Form, {Inputs} from './components/Form';

import ActorPreview from '@/pages/actors/components/ActorPreview';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import {
  matchPreviousRouteName,
  findPreviousIndexForRouteName,
} from '@/helpers/NavigationHelper';
import {buildFieldErrors} from '@/helpers/FormHelper';
import {mutateActor} from '@/helpers/swr/MutateHelper';

import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import {useGet, useUpdateMutation} from '@/hooks/swr';
import {
  sendDefaultCustomEvent,
  sendDefaultCustomEventAndParticularEvent,
} from '@/helpers/AnalyticsHelper';

import Actor from '@/entities/Actor';
import ResourceProductReleaseRequest from '@/entities/ResourceProductReleaseRequest';

export interface Params {
  actorId: number;
  productId: number;
  allowedInversion: boolean;
}

const Page = (props: ScreenProps<'ActorProductReleaseRequest'>) => {
  const {route} = props;
  const {actorId, productId, allowedInversion} = route.params;
  const navigation = useDefaultNavigation();
  const {
    data: actorProductReleaseRequest,
    error: actorProductReleaseRequestError,
  } = useGet<ResourceProductReleaseRequest<Actor>>(
    `/api/creator/actor_product_release_requests/${actorId}`,
  );
  const {trigger, isMutating, error} = useUpdateMutation<
    ResourceProductReleaseRequest<Actor>
  >(`/api/creator/actor_product_release_requests/${actorId}`);
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(
      convertFormValuesToPostParams({
        ...data,
        productId,
        options: {allowedInversion},
      }),
    ).then(actorProductReleaseRequest => {
      if (actorProductReleaseRequest) {
        if (
          actorProductReleaseRequest.id &&
          actorProductReleaseRequest.state == 'pending'
        ) {
          const previousIndex = findPreviousIndexForRouteName(
            navigation,
            'Actor',
          );
          if (previousIndex === -1) {
            navigation.popToTop();
            navigation.navigate('ActorProductReleaseRequestComplete', {
              actorId,
            });
          } else {
            navigation.pop(previousIndex - 1);
            navigation.replace('ActorProductReleaseRequestComplete', {
              actorId,
            });
          }
          sendDefaultCustomEventAndParticularEvent({
            resourceType: `actor${
              actorProductReleaseRequest.product ? '_product' : ''
            }_release_req`,
            resourceId: actorProductReleaseRequest.resource.id,
            actionName: 'create',
          });
        } else if (actor?.actorCharacter) {
          navigation.navigate('Actor', {id: actor.id});
        }
        if (actorProductReleaseRequest.resource.actorCharacter) {
          mutateActor({
            id: actorProductReleaseRequest.resource.id,
          });
        }
      }
    });
  }, []);
  const onPressBack = React.useCallback(() => {
    if (matchPreviousRouteName(navigation, 'ActorProduct', 1)) {
      navigation.goBack();
    } else {
      navigation.navigate('ActorProduct', {actorId});
    }
  }, []);
  const onPressClose = React.useCallback(() => {
    if (findPreviousIndexForRouteName(navigation, 'Actor') === -1) {
      navigation.replace('Actor', {id: actorId});
    } else {
      navigation.navigate('Actor', {id: actorId});
    }
  }, []);
  const defaultValues = convertFormRecordToDefaultValues(
    actorProductReleaseRequest,
    !!productId,
  );
  const actor = actorProductReleaseRequest?.resource;
  return (
    <Layout
      title={'イラストの公開設定'}
      back={true}
      close={true}
      loading={isMutating}
      error={actorProductReleaseRequestError}
      onPressBack={onPressBack}
      onPressClose={onPressClose}>
      {actor?.actorCharacter && <ActorPreview actor={actor} />}
      {defaultValues && (
        <Form
          actorId={actorId}
          sellable={!!productId}
          defaultValues={defaultValues}
          errors={convertResponseToErrors(error)}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (
  formValues: Inputs & {
    productId: number;
    options: {allowedInversion: boolean};
  },
) => {
  return {actorProductReleaseRequest: formValues};
};

const convertFormRecordToDefaultValues = (
  record: ResourceProductReleaseRequest | undefined,
  sellable: boolean,
): Inputs | null => {
  if (!record) {
    return null;
  }
  return {
    published: record.publicationRangeType !== 'no_writer_users',
    publicationRangeValue: record.publicationRangeValue,
    publicationRangeType: record.publicationRangeType,
  };
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    publicationRangeValue: 'publicationRangeValue',
    publicationRangeType: 'publicationRangeType',
  });
};
