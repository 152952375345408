import {getStateFromPath, getActionFromState} from '@react-navigation/native';
import navigationRef from './ref';
import LinkingConfig from './LinkingConfig';

const applicationLinkTo = (path: string) => {
  const state = getStateFromPath(path, {screens: LinkingConfig});
  if (!state) {
    return;
  }
  const action = getActionFromState(state);
  if (!action) {
    return;
  }
  try {
    navigationRef?.current?.dispatch(action);
  } catch (error) {
    // noop
  }
};

export default applicationLinkTo;
