import React from 'react';
import {useForm} from 'react-hook-form';
import {useNavigation} from '@react-navigation/native';

import Form, {Inputs} from './components/Form';

import {Layout, RightButton} from '@/components/layout';

import useCurrentUserRegistration from '@/hooks/useCurrentUserRegistration';
import useApplicationModal from '@/hooks/useApplicationModal';
import {buildFieldErrors} from '@/helpers/FormHelper';

export interface Params {}

const Page = () => {
  const {
    update,
    updateResponse: {isMutating, error},
  } = useCurrentUserRegistration();
  const {show: showModal} = useApplicationModal();
  const navigation = useNavigation();
  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm<Inputs>();
  const goBack = React.useCallback(() => {
    navigation.goBack();
  }, []);
  const onPress = handleSubmit(data => {
    update(convertFormValuesToPostParams(data)).then(res => {
      if (!res) {
        return;
      }
      goBack();
      showModal({title: 'パスワードを変更しました'});
    });
  });
  const rightButton = React.useMemo(
    () => ({
      children: (
        <RightButton disabled={!isValid} onPress={onPress}>
          保存
        </RightButton>
      ),
    }),
    [isValid],
  );
  return (
    <Layout
      title={'パスワードの変更'}
      back={true}
      rightButton={rightButton}
      loading={isMutating}>
      <Form control={control} errors={convertResponseToErrors(error)} />
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {
    apiCreatorUser: formValues,
  };
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    currentPassword: 'currentPassword',
    password: 'password',
    passwordConfirmation: 'passwordConfirmation',
  });
};
