import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import FacebookShareButton from './buttons/share_buttons/FacebookShareButton';
import LineShareButton from './buttons/share_buttons/LineShareButton';
import TwitterShareButton from './buttons/share_buttons/TwitterShareButton';
import CopyShareButton from './buttons/share_buttons/CopyShareButton';

interface Props {
  url: string;
  text: string;
  hashtags: Array<string>;
  textWithServiceName?: boolean;
}
const SocialShareButtons: React.FC<Props> = props => {
  return (
    <View style={styles.container}>
      <View style={styles.buttons}>
        <FacebookShareButton {...props} />
        <LineShareButton {...props} />
        <TwitterShareButton {...props} />
        <CopyShareButton {...props} />
      </View>
    </View>
  );
};

export default React.memo(SocialShareButtons);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: 24,
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 278,
  } as ViewStyle,
});
