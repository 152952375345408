import * as React from 'react';
import {LayoutChangeEvent, View} from 'react-native';

import Switcher, {Props as SwitcherProps} from './Switcher';

import {ItemValue, Item} from '@/components/value/types';

interface Props<IValue extends ItemValue = ItemValue>
  extends Omit<SwitcherProps<IValue>, 'width' | 'onSelectItem'> {
  onValueChange: (itemValue: IValue, itemPosition: number) => void;
}

export type {Props};

function MultiSwitch<IValue extends ItemValue = ItemValue>(
  props: Props<IValue>,
) {
  const {onValueChange} = props;
  const [width, setWidth] = React.useState<number | null>(null);
  const onLayout = React.useCallback((event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);
  const onSelectItem = React.useCallback((item: Item<IValue>) => {
    const itemPosition = props.items.findIndex(i => i.value === item.value);
    onValueChange(item.value, itemPosition);
  }, []);
  return (
    <View onLayout={onLayout}>
      {width && (
        <Switcher {...props} width={width} onSelectItem={onSelectItem} />
      )}
    </View>
  );
}

export default React.memo(MultiSwitch) as typeof MultiSwitch;
