import React from 'react';

import Form, {Inputs} from './components/Form';

import ActorPreview from '@/pages/actors/components/ActorPreview';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import {
  matchPreviousRouteName,
  findPreviousIndexForRouteName,
} from '@/helpers/NavigationHelper';

import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import {useGet} from '@/hooks/swr';
import {get} from '@/services/fetchers';

import Actor from '@/entities/Actor';
import Product from '@/entities/Product';
import ResourceProductReleaseRequest from '@/entities/ResourceProductReleaseRequest';

export interface Params {
  actorId: number;
}

const Page = (props: ScreenProps<'ActorProduct'>) => {
  const {route} = props;
  const {actorId} = route.params;
  const navigation = useDefaultNavigation();
  const [actor, setActor] = React.useState<Actor | null>(null);
  const [actorError, setActorError] = React.useState<any>(null);
  React.useEffect(() => {
    get<Actor>(`/api/creator/actors/${actorId}`)
      .then(setActor)
      .catch(setActorError);
  }, []);
  const {data: products} = useGet<Product[]>(
    '/api/creator/my_stripe/products',
    {
      productCategoryId: 3,
    },
  );
  const {data: resourceProductReleaseRequest} = useGet<
    ResourceProductReleaseRequest<Actor>
  >(`/api/creator/actor_product_release_requests/${actorId}`);
  const onSubmit = React.useCallback(
    (data: Inputs) =>
      navigation.navigate('ActorProductReleaseRequest', {
        actorId,
        allowedInversion: data.allowedInversion,
        productId: data.productId,
      }),
    [],
  );
  const defaultValues = convertFormRecordToDefaultValues(
    resourceProductReleaseRequest,
    products,
  );
  const onPressBack = React.useCallback(() => {
    if (matchPreviousRouteName(navigation, 'ActorCategoriesEdit', 1)) {
      navigation.goBack();
    } else {
      navigation.replace('ActorCategoriesEdit', {id: actorId});
    }
  }, []);
  const onPressClose = React.useCallback(() => {
    if (findPreviousIndexForRouteName(navigation, 'Actor') === -1) {
      navigation.replace('Actor', {id: actorId});
    } else {
      navigation.navigate('Actor', {id: actorId});
    }
  }, []);
  return (
    <Layout
      title={'イラストの販売設定'}
      back={true}
      close={true}
      error={actorError}
      onPressBack={onPressBack}
      onPressClose={onPressClose}>
      {actor?.actorCharacter && <ActorPreview actor={actor} />}
      {products && defaultValues && actor?.actorCharacter && (
        <Form
          disableAllowedInversion={
            !!actor.options.allowedInversion && actor.enabled
          }
          products={products}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);

const convertFormRecordToDefaultValues = (
  record?: ResourceProductReleaseRequest,
  products?: Product[],
): Inputs | null => {
  if (!record || !products) {
    return null;
  }
  const defaultProduct =
    products.find(product => Math.round(product.price) === 500) || products[0];
  return {
    productId: record.product ? record.product.id : defaultProduct?.id,
    allowedInversion: !!record.options.allowedInversion,
  };
};
