import React from 'react';

import HelpCategoryList from './components/HelpCategoryList';

import {Layout} from '@/components/layout';

import HelpCategory from '@/entities/HelpCategory';

import {useGet} from '@/hooks/swr';

export interface Params {}

const Page = () => {
  const {data: helpCategories} = useGet<HelpCategory[]>(
    '/api/creator/help_categories',
  );
  return (
    <Layout title={'ヘルプ'} scrollable={false} back={true}>
      {helpCategories && <HelpCategoryList helpCategories={helpCategories} />}
    </Layout>
  );
};

export default React.memo(Page);
