import React from 'react';
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

import Button, {Props} from './Button';

const OutlineButton: React.FC<Props> = props => {
  const {style, textProps, ...restProps} = props;
  const {style: textStyle, ...restTextProps} = textProps || {};
  return (
    <Button
      style={[styles.container, style]}
      {...restProps}
      textProps={{
        ...restTextProps,
        style: [styles.text, textStyle],
      }}
      disabledStyle={styles.containerDisabled}
      disabledTextStyle={styles.textDisabled}
    />
  );
};

export default React.memo(OutlineButton);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#383838',
  } as ViewStyle,
  containerDisabled: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#383838',
    opacity: 0.5,
  } as ViewStyle,
  text: {
    color: '#383838',
  } as TextStyle,
  textDisabled: {
    color: '#383838',
  } as TextStyle,
});
