import React from 'react';

import {DefaultLinkList} from '@/components/ui/lists';

import HelpCategory from '@/entities/HelpCategory';

interface Props {
  helpCategories: HelpCategory[];
}

const HelpCategoryList: React.FC<Props> = props => {
  const {helpCategories} = props;
  const data = React.useMemo(
    () => helpCategories.map(o => ({label: o.name, url: `/helps/${o.id}`})),
    [helpCategories],
  );
  return <DefaultLinkList data={data} />;
};

export default React.memo(HelpCategoryList);
