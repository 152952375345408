import React from 'react';
import {useLinkTo} from '@react-navigation/native';
import {WebViewMessageEvent} from 'react-native-webview';

import {Layout} from '@/components/layout';
import TapNovelWebView from '@/components/utils/TapNovelWebView';
import MetaTags from '@/components/utils/MetaTags';

export interface Params {}

const Page = () => {
  const linkTo = useLinkTo();
  const onMessage = React.useCallback(
    (event: WebViewMessageEvent) => {
      if (typeof event.nativeEvent.data !== 'string') {
        return;
      }
      const [action, url] = event.nativeEvent.data.split(',');
      if (action == 'dispatchAction' && url) {
        linkTo(url);
      }
    },
    [linkTo],
  );
  return (
    <>
      <MetaTags
        title={'モニター100名限定募集 - TapNovelCreator'}
        description={
          '開発中のTapNovelCreatorをご利用いただき、使い勝手の向上や機能に対する改善、サービス全般に対してのご要望やご意見をいただけるモニターを募集いたします。'
        }
        keywords={
          'モニター,募集,TapNovelCreator,タップノベルクリエイター,小説素材サイト,ゲーム小説素材,Web小説素材,携帯小説素材,販売'
        }
      />
      <Layout hideNavigationBar={true} scrollable={false}>
        <TapNovelWebView path={'/creator/monitor'} onMessage={onMessage} />
      </Layout>
    </>
  );
};

export default React.memo(Page);
