import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import {RightIcon} from '@/components/icons';

import {ItemValue, Item} from '@/components/value/types';

export interface Props<IValue extends ItemValue = ItemValue> {
  item: Item<IValue>;
  onSelectValue: (value: IValue) => void;
}

function DefaultListItem<IValue extends ItemValue = ItemValue>(
  props: Props<IValue>,
) {
  const {item, onSelectValue} = props;
  const onPress = React.useCallback(() => onSelectValue(item.value), []);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <>
        <Text style={styles.label}>{item.label}</Text>
        <RightIcon color={'#999999'} size={13} />
      </>
    </TouchableOpacity>
  );
}

export default React.memo(DefaultListItem) as typeof DefaultListItem;

const styles = StyleSheet.create({
  container: {
    height: 44,
    width: '100%',
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    flex: 1,
  } as TextStyle,
});
