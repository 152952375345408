import * as React from 'react';
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import TabContent from './TabContent';

import ScrollableTabView, {
  DefaultTabBar,
  ScrollableTabBar,
} from '@/adapters/react-native-scrollable-tab-view';

interface Size {
  height: number;
  width: number;
}

interface Props {
  tabs: TabItem[];
  scrollableTabBar?: boolean;
  locked?: boolean;
  initialPage?: number;
  onChangeTab?: (changedTabInfo: ChangedTabInfo) => void;
  onTouchStart?: (event: GestureResponderEvent) => void;
  onTouchMove?: (event: GestureResponderEvent) => void;
  onTouchEnd?: (event: GestureResponderEvent) => void;
  onTransitionStartForWeb?: () => void;
  onTransitionEndForWeb?: () => void;
}

interface State {
  currentPage: number;
  containerSize: Size | null;
}

export interface ChangedTabInfo {
  from: number;
  i: number;
  ref: any;
}

export interface TabItem {
  label: string;
  key?: string;
  element: React.ReactNode;
}

export default class ScrollableTabViewAdapter extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      containerSize: null,
      currentPage: 0,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    if (this.props.tabs !== nextProps.tabs) {
      return true;
    }
    if (this.props.scrollableTabBar !== nextProps.scrollableTabBar) {
      return true;
    }
    if (this.props.locked !== nextProps.locked) {
      return true;
    }
    if (this.props.initialPage !== nextProps.initialPage) {
      return true;
    }
    if (this.state.containerSize !== nextState.containerSize) {
      return true;
    }
    if (this.state.currentPage !== nextState.currentPage) {
      return true;
    }
    return false;
  }

  public render(): React.ReactNode {
    const {
      tabs,
      locked,
      initialPage,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      onTransitionStartForWeb,
      onTransitionEndForWeb,
    } = this.props;
    return (
      <View style={styles.container}>
        <ScrollableTabView
          contentProps={
            {
              bounces: false,
              onTouchEnd,
              onTouchMove,
              onTouchStart,
            } as any
          }
          locked={locked}
          initialPage={initialPage}
          tabBarActiveTextColor={'#222'}
          tabBarInactiveTextColor={'#666'}
          prerenderingSiblingsNumber={3}
          renderTabBar={this.renderTabBar}
          onChangeTab={this.onChangeTab}
          {...{
            onTransitionStartForWeb,
            onTransitionEndForWeb,
          }}>
          {tabs.map(tab => {
            return (
              <TabContent
                key={tab.key || tab.label}
                tabLabel={tab.label}
                onLayout={this.handleLayout}>
                {tab.element}
              </TabContent>
            );
          })}
        </ScrollableTabView>
      </View>
    );
  }

  private renderTabBar = (): JSX.Element => {
    const {scrollableTabBar, locked} = this.props;
    const customTabBar = scrollableTabBar ? (
      <ScrollableTabBar
        style={styles.scrollableTabBar as any}
        tabStyle={styles.scrollableTabBarTab as any}
        textStyle={styles.scrollableTabBarText as any}
        tabsContainerStyle={styles.scrollableTabBarTabsContainer as any}
        underlineStyle={styles.scrollableTabBarUnderline as any}
      />
    ) : (
      <DefaultTabBar
        tabStyle={styles.defaultTabBarTab as any}
        textStyle={styles.defaultTabBarText as any}
        underlineStyle={styles.defaultTabBarUnderline as any}
        {...({locked} as any)}
      />
    );
    return customTabBar;
  };

  private onChangeTab = (changedTabInfo: ChangedTabInfo) => {
    this.setState({currentPage: changedTabInfo.i});
    if (this.props.onChangeTab) {
      this.props.onChangeTab(changedTabInfo);
    }
  };

  private handleLayout = (event: LayoutChangeEvent) => {
    this.setState({
      containerSize: {
        height: event.nativeEvent.layout.height,
        width: event.nativeEvent.layout.height,
      },
    });
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  defaultTabBar: {
    backgroundColor: '#FAFAFA',
    borderColor: '#D6D6D6',
  } as ViewStyle,
  defaultTabBarTab: {
    paddingBottom: 0,
  } as ViewStyle,
  defaultTabBarText: {
    color: '#222',
    fontSize: 14,
  } as TextStyle,
  defaultTabBarUnderline: {
    backgroundColor: '#ff8f13',
    bottom: -1,
    height: 3,
  } as ViewStyle,
  scrollableTabBar: {
    backgroundColor: '#FAFAFA',
    borderWidth: 0,
    height: 44,
  } as ViewStyle,
  scrollableTabBarTab: {
    paddingBottom: 6,
    paddingLeft: 30,
    paddingRight: 30,
  } as ViewStyle,
  scrollableTabBarTabsContainer: {
    borderBottomWidth: 1,
    borderColor: '#D6D6D6',
  } as ViewStyle,
  scrollableTabBarText: {
    color: '#222',
    fontSize: 13,
  } as TextStyle,
  scrollableTabBarUnderline: {
    backgroundColor: '#383838',
    bottom: -1,
    height: 3,
  } as ViewStyle,
});
