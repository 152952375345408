import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import BaseBadge from './BaseBadge';

interface Props extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
}

const PrimaryBadge: React.FC<Props> = props => {
  const {style} = props;
  return <BaseBadge {...props} style={[style, color]} />;
};

export default React.memo(PrimaryBadge);

const color = {
  backgroundColor: '#f5c71c',
} as ViewStyle;
