import React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import ResourceRevenueListItem from './ResourceRevenueListItem';
import ResourceRevenueHeader from './ResourceRevenueHeader';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';

import ResourceRevenueAggregation from '@/entities/summary/ResourceRevenueAggregation';

interface Props {
  data: ResourceRevenueAggregation[];
}

const ResourceRevenueList: React.FC<Props> = props => {
  const {data} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ResourceRevenueAggregation>) => (
      <ResourceRevenueListItem resourceRevenueAggregation={info.item} />
    ),
    [],
  );
  return (
    <>
      <ResourceRevenueHeader />
      <FlatList
        data={data}
        renderItem={renderItem}
        ItemSeparatorComponent={ListItemNormalSeparator}
      />
    </>
  );
};

export default React.memo(ResourceRevenueList);
