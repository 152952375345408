import React from 'react';
import {LayoutChangeEvent, StyleSheet, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import Autocomplete from './Autocomplete';

import {
  Field,
  ControlledInput,
  ControlledLabel,
  ControlledMultiSwitch,
} from '@/components/form';
import {PrimaryButton} from '@/components/ui/buttons';
import {Item} from '@/components/value/types';

import {AccountType} from '@/entities/BankAccount';
import Bank from '@/entities/Bank';
import BankBranch from '@/entities/BankBranch';

import {useGet} from '@/hooks/swr';

export type Inputs = {
  bankName: string;
  bankBranchName: string;
  accountType: AccountType;
  accountNumber: string;
  accountHolderName: string;
};

interface Props {
  errors?: FieldErrors<Inputs>;
  defaultValues?: Inputs;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {errors, defaultValues, onSubmit} = props;
  const mount = React.useRef(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {isValid},
  } = useForm({
    defaultValues: defaultValues || {
      bankName: '',
      bankBranchName: '',
      accountType: 'savings_account',
      accountNumber: undefined,
      accountHolderName: '',
    },
  });
  const onPress = handleSubmit(onSubmit);
  const bankName = watch('bankName');
  const bankBranchName = watch('bankBranchName');
  React.useEffect(() => {
    if (!mount.current) {
      return;
    }
    setValue('bankBranchName', '');
  }, [bankName]);
  React.useEffect(() => {
    mount.current = true;
  }, []);

  const [displayBanksAutocomplete, setDisplayBanksAutocomplete] =
    React.useState(false);
  const [banksAutocompleteStyle, setBanksAutocompleteStyle] =
    React.useState<ViewStyle | null>(null);
  const [displayBankBranchesAutocomplete, setDisplayBankBrancesAutocomplete] =
    React.useState(false);
  const [bankBranchesAutocompleteStyle, setBankBranchesAutocompleteStyle] =
    React.useState<ViewStyle | null>(null);
  const {data: banks} = useGet<Bank[]>(
    '/api/creator/banks',
    {term: bankName, limit: 1000},
    {keepPreviousData: true},
  );
  const bank = banks?.find(item => item.name === bankName);
  const {data: bankBranches} = useGet<BankBranch[]>(
    bank ? '/api/creator/bank_branches' : null,
    {bankId: bank?.id, term: bankBranchName, limit: 1000},
    {keepPreviousData: true},
  );
  const onLayoutBankName = React.useCallback((e: LayoutChangeEvent) => {
    const {x, y, width, height} = e.nativeEvent.layout;
    setBanksAutocompleteStyle({top: y + height - 1, left: x, width});
  }, []);
  const onFocusBankName = React.useCallback(() => {
    setDisplayBanksAutocomplete(true);
  }, []);
  const onBlurBankName = React.useCallback(() => {
    setTimeout(() => {
      setDisplayBanksAutocomplete(false);
    }, 120);
  }, []);
  const onLayoutBankBranchName = React.useCallback((e: LayoutChangeEvent) => {
    const {x, y, width, height} = e.nativeEvent.layout;
    setBankBranchesAutocompleteStyle({top: y + height - 1, left: x, width});
  }, []);
  const onFocusBankBranchName = React.useCallback(
    () => setDisplayBankBrancesAutocomplete(true),
    [],
  );
  const onBlurBankBranchName = React.useCallback(
    () => setTimeout(() => setDisplayBankBrancesAutocomplete(false), 120),
    [],
  );
  return (
    <View style={styles.container}>
      <Field onLayout={onLayoutBankName}>
        <ControlledLabel
          label={'金融機関'}
          control={control}
          name={'bankName'}
          errors={errors}
          hiddenEnteredLabel={true}
        />
        <ControlledInput
          placeholder={'金融機関を入力'}
          onFocus={onFocusBankName}
          onBlur={onBlurBankName}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'bankName',
          }}
        />
      </Field>
      <Field onLayout={onLayoutBankBranchName}>
        <ControlledLabel
          label={'支店名'}
          control={control}
          name={'bankBranchName'}
          errors={errors}
          hiddenEnteredLabel={true}
        />
        <ControlledInput
          placeholder={'支店名を入力'}
          disabled={!bank}
          onFocus={onFocusBankBranchName}
          onBlur={onBlurBankBranchName}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'bankBranchName',
          }}
        />
      </Field>
      <Field>
        <ControlledLabel
          label={'口座種別'}
          control={control}
          name={'accountType'}
          errors={errors}
          hiddenEnteredLabel={true}
        />
        <ControlledMultiSwitch
          items={ACCOUNT_TYPE_ITEMS}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '選択してください'},
            },
            name: 'accountType',
          }}
          errors={errors}
        />
      </Field>
      <Field>
        <ControlledLabel
          label={'口座番号'}
          control={control}
          name={'accountNumber'}
          errors={errors}
          hiddenEnteredLabel={true}
        />
        <ControlledInput
          placeholder={'口座番号を入力'}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
              pattern: {value: /^(\d){7}$/, message: '不正です'},
            },
            name: 'accountNumber',
          }}
        />
      </Field>
      <Field>
        <ControlledLabel
          label={'口座名義'}
          control={control}
          name={'accountHolderName'}
          errors={errors}
          hiddenEnteredLabel={true}
        />
        <ControlledInput
          placeholder={'口座名義を入力'}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'accountHolderName',
          }}
        />
      </Field>
      <View style={styles.submit}>
        <PrimaryButton disabled={!isValid} onPress={onPress}>
          登録
        </PrimaryButton>
      </View>
      {displayBanksAutocomplete && banks ? (
        <Autocomplete
          style={banksAutocompleteStyle}
          data={banks.map(bank => ({label: bank.name, value: bank.name}))}
          onValueChange={value => {
            setValue('bankName', value, {shouldValidate: true});
          }}
        />
      ) : null}
      {displayBankBranchesAutocomplete && bankBranches ? (
        <Autocomplete
          style={bankBranchesAutocompleteStyle}
          data={bankBranches.map(bankBranch => ({
            label: bankBranch.name,
            value: bankBranch.name,
          }))}
          onValueChange={value => {
            setValue('bankBranchName', value, {shouldValidate: true});
          }}
        />
      ) : null}
    </View>
  );
};

export default React.memo(Form) as typeof Form;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
    backgroundColor: 'white',
    paddingBottom: 67,
  } as ViewStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
});

const ACCOUNT_TYPE_ITEMS: Array<Item<AccountType>> = [
  {
    label: '普通預金',
    value: 'savings_account',
  },
  {
    label: '当座預金',
    value: 'checking_account',
  },
  {
    label: '貯蓄預金',
    value: 'certificate_of_deposit',
  },
];
