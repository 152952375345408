import React from 'react';
import {
  Controller,
  UseControllerProps,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
  FieldErrors,
} from 'react-hook-form';

import PriceInput, {Props as PriceInputProps} from './PriceInput';
import ErrorMessage from './ErrorMessage';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<PriceInputProps, 'value' | 'onChangeValue'> {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  errors?: FieldErrors<TFieldValues>;
}

function ControlledPriceInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {controllerProps, errors, ...selectProps} = props;
  const {name, rules, control} = controllerProps;
  const message = errors ? errors[name]?.message?.toString() : null;
  const render = React.useCallback(
    ({
      field: {onChange, value},
    }: {
      field: ControllerRenderProps<TFieldValues, TName>;
    }) => (
      <>
        {message && <ErrorMessage>{message}</ErrorMessage>}
        <PriceInput {...selectProps} value={value} onChangeValue={onChange} />
      </>
    ),
    [message, selectProps],
  );
  return (
    <Controller control={control} rules={rules} render={render} name={name} />
  );
}

export default React.memo(ControlledPriceInput) as typeof ControlledPriceInput;
