import React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

export interface Props extends TextInputProps {
  invalid?: boolean;
  required?: boolean;
  disabled?: boolean;
}

const Input: React.FC<Props> = props => {
  const {invalid, required, disabled, value, style, ...restProps} = props;
  return (
    <View style={styles.container}>
      {disabled ? (
        <Text style={styles.inputDisabled}>{props.placeholder}</Text>
      ) : (
        <TextInput
          {...restProps}
          value={value || ''}
          style={[
            invalid
              ? styles.inputInvalid
              : required && !props.value
              ? styles.inputRequired
              : styles.input,
            style,
          ]}
          placeholderTextColor={'#999'}
        />
      )}
    </View>
  );
};

export default React.memo(Input);

const container = {
  borderBottomColor: '#efefef',
  borderBottomWidth: 1,
  paddingVertical: 3,
} as ViewStyle;

const input = {
  height: 30,
  fontSize: 14,
} as TextStyle;

const styles = StyleSheet.create({
  container,
  input,
  inputInvalid: {
    ...input,
    backgroundColor: 'rgba(242, 52, 6, 0.3)',
    borderBottomColor: 'rgba(242, 52, 6, 0.3)',
  } as TextStyle,
  inputRequired: {
    ...input,
    backgroundColor: '#fff8dc',
  } as TextStyle,
  inputDisabled: {
    ...input,
    backgroundColor: '#eee',
    opacity: 0.4,
  } as TextStyle,
});
