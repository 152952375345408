export const baseWidth = 375;

export const scaledValue = (width: number | undefined, value: number) => {
  if (!width) {
    return value;
  }
  const scale = width / baseWidth;
  return Math.round(scale * value);
};

export const APPLICATION_HEADER_HEIGHT = 50;

export const APPLICATION_CONTENT_MAX_WIDTH = 433;
export const APPLICATION_CONTENT_MAX_HEIGHT = 822;
