import React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {LinkButton} from '@/components/ui/buttons';

export interface Params {}

const PolicyLinks = () => {
  const linkTo = useLinkTo();
  const onPressPrivacy = React.useCallback(() => {
    linkTo('/policies/privacy');
  }, []);
  const onPressTerms = React.useCallback(() => {
    linkTo('/policies/terms');
  }, []);
  return (
    <Text style={styles.text}>
      TapNovelCreatorの
      <LinkButton color={linkColor} size={'small'} onPress={onPressTerms}>
        利用規約
      </LinkButton>
      と
      <LinkButton color={linkColor} size={'small'} onPress={onPressPrivacy}>
        プライバシーポリシー
      </LinkButton>
      {'\n'}
      に同意の上ご登録ください
    </Text>
  );
};

export default React.memo(PolicyLinks);

const linkColor = '#e7b600';

const styles = StyleSheet.create({
  text: {
    color: '#222',
    fontSize: 10,
    textAlign: 'center',
  } as TextStyle,
});
