import React from 'react';

import {Layout} from '@/components/layout';

import ActorRevenueAggregationProfitGraph from './components/ActorRevenueAggregationProfitGraph';
import ActorRevenueAggregationInfo from './components/ActorRevenueAggregationInfo';

import SearchForm, {Inputs} from '../components/SearchForm';

import ScreenProps from '@/navigation/ScreenProps';

import ResourceRevenueAggregation from '@/entities/summary/ResourceRevenueAggregation';
import ResourceRevenueDailyAggregation from '@/entities/summary/ResourceRevenueDailyAggregation';
import Actor from '@/entities/Actor';

import {DatePeriod} from '@/components/value/types';

import {useGet} from '@/hooks/swr';
import {convertTermRangeToQueryParams} from '@/helpers/TimeHelper';

export interface Params {
  id: number;
}

const Page = (props: ScreenProps<'RevenueActor'>) => {
  const {route} = props;
  const {id} = route.params;
  const [period, setPeriod] = React.useState<DatePeriod>('all');
  const params = convertTermRangeToQueryParams(period);
  const {data: actorRevenueAggregation, error} = useGet<
    ResourceRevenueAggregation<Actor>
  >(
    `/api/creator/summary/resource_revenue_aggregations/${id}`,
    {type: 'Actor', ...params},
    {keepPreviousData: true},
  );
  const {data: resourceRevenueDailyAggregations} = useGet<
    ResourceRevenueDailyAggregation[]
  >(
    `/api/creator/summary/resource_revenue_daily_aggregations`,
    {id, type: 'Actor', ...params},
    {keepPreviousData: true},
  );
  const onSubmit = React.useCallback(
    (data: Inputs) => setPeriod(data.period),
    [],
  );
  return (
    <Layout
      title={`${
        actorRevenueAggregation?.resource.name || 'キャラクター'
      }の収益`}
      containerStyle={{backgroundColor: '#fafafa'}}
      back={true}
      error={error}>
      <SearchForm defaultValues={{period: period}} onSubmit={onSubmit} />
      {actorRevenueAggregation && resourceRevenueDailyAggregations ? (
        <>
          <ActorRevenueAggregationInfo
            actorRevenueAggregation={actorRevenueAggregation}
          />
          <ActorRevenueAggregationProfitGraph
            resourceRevenueDailyAggregations={resourceRevenueDailyAggregations}
          />
        </>
      ) : null}
    </Layout>
  );
};

export default React.memo(Page);
