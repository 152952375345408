import React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import dateformat from 'dateformat';

import Announcement from '@/entities/Announcement';

interface Props extends React.PropsWithChildren {
  announcement: Announcement;
}

const Detail: React.FC<Props> = props => {
  const {announcement} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.categoryLabel}>
        {announcement.creatorAnnouncementCategory.name}
      </Text>
      <Text style={styles.title}>{announcement.title}</Text>
      <Text style={styles.publishedDate}>
        {dateformat(announcement.publishedAt, 'yyyy.mm.dd')}
      </Text>
      <Text style={styles.content}>{announcement.content}</Text>
    </View>
  );
};

export default React.memo(Detail);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  } as ViewStyle,
  categoryLabel: {
    color: '#FF8F13',
    fontSize: 11,
  } as TextStyle,
  title: {
    color: '#222',
    fontSize: 17,
    fontWeight: 'bold',
    lineHeight: Platform.select({
      android: 20,
      ios: 16,
    }),
    marginTop: 8,
  } as TextStyle,
  publishedDate: {
    color: '#999',
    fontSize: 11,
    marginTop: 8,
  } as TextStyle,
  content: {
    color: '#222',
    fontSize: 15,
    lineHeight: Platform.select({
      android: 24,
      ios: 20,
    }),
    marginVertical: 20,
  } as TextStyle,
});
