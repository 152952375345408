import React from 'react';
import {
  AlertButton,
  AlertOptions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

let instance: AlertViewClass;

interface Props {}

interface State {
  visible: boolean;
  title: string;
  message?: string;
  buttons?: AlertButton[];
  options?: AlertOptions;
}

class AlertViewClass extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      title: '',
    };
  }

  public render() {
    const {visible, message, title, buttons} = this.state;
    if (!visible) {
      return null;
    }
    return (
      <View style={styles.backdrop}>
        <View style={styles.container}>
          <View style={styles.textContainer}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.message}>{message}</Text>
          </View>
          <View style={styles.buttonContainer}>
            {buttons?.map(({text, onPress}, i) => (
              <TouchableOpacity
                key={i}
                onPress={this.onButtonPress(onPress)}
                style={styles.button}>
                <Text style={styles.buttonLabel}>{text}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </View>
    );
  }

  private onButtonPress = (onPress?: () => void) => () => {
    this.setState({visible: false});
    if (onPress) {
      onPress();
    }
  };
}

const saveRef = (r: AlertViewClass) => {
  instance = r;
};

export default {
  AlertView: () => <AlertViewClass ref={saveRef} />,
  alert(
    title: string,
    message?: string,
    buttons: AlertButton[] = [{text: 'OK'}],
    options?: AlertOptions,
  ) {
    instance.setState({
      visible: true,
      title,
      message,
      buttons,
      options,
    });
  },
  alertNative(title: string) {
    alert(title);
  },
};

const styles = StyleSheet.create({
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0,0,0,0.6)',
    alignItems: 'center',
    justifyContent: 'space-around',
    zIndex: 1000,
  },
  container: {
    overflow: 'hidden',
    backgroundColor: '#f8f8f8',
    borderRadius: 10,
    width: Platform.select({web: 'min(100vw, 311px)', default: '82%'}),
  },
  textContainer: {
    padding: 20,
    justifyContent: 'space-around',
  },
  title: {
    color: '#2b3137',
    fontSize: 17,
    fontWeight: '600',
  },
  message: {
    color: '#2b3137',
    marginTop: 4,
    fontSize: 14,
    maxWidth: 300,
  },
  buttonContainer: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    paddingRight: 14,
    paddingBottom: 6,
  },
  button: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 80,
    height: 44,
  },
  buttonLabel: {
    color: '#4b8ffe',
    fontSize: 14,
    flex: 1,
  },
});
