import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {LinkButton} from '@/components/ui/buttons';

interface Props {}

const PolicyLinks: React.FC<Props> = props => {
  const linkTo = useLinkTo();
  const onPressPrivacy = React.useCallback(() => {
    linkTo('/policies/privacy');
  }, []);
  const onPressTerms = React.useCallback(() => {
    linkTo('/policies/terms');
  }, []);
  return (
    <View style={styles.container}>
      <LinkButton color={linkColor} size={'small'} onPress={onPressPrivacy}>
        プライバシーポリシー
      </LinkButton>
      <Text style={styles.text}>と</Text>
      <LinkButton color={linkColor} size={'small'} onPress={onPressTerms}>
        利用規約
      </LinkButton>
      <Text style={styles.text}>に同意の上ご登録ください</Text>
    </View>
  );
};

export default PolicyLinks;

const linkColor = '#e7b600';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 10,
  } as TextStyle,
});
