import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import {Inputs} from './Form';

import {Field, Label} from '@/components/form';
import {
  PrimaryButton,
  OutlineButton,
  CheckButton,
} from '@/components/ui/buttons';
import {AlertTriangleIcon} from '@/components/icons';

import ContactCategory from '@/entities/ContactCategory';

interface Props {
  data: Inputs;
  contactCategories?: ContactCategory[];
  onSubmit: (data: Inputs) => void;
  onBack: () => void;
}

const Confirm: React.FC<Props> = props => {
  const {data, contactCategories, onSubmit, onBack} = props;
  const [checked, setChecked] = React.useState(false);
  const contactCategory = contactCategories?.find(
    o => o.id === Number(data.creatorContactCategoryId),
  );
  const toggleChecked = React.useCallback(
    () => setChecked(!checked),
    [checked],
  );
  const onPress = React.useCallback(() => {
    onSubmit(data);
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>お問い合わせの内容確認</Text>
      <Field>
        <Label>お問い合わせカテゴリ</Label>
        <Text style={styles.value}>
          {contactCategory ? contactCategory.name : ''}
        </Text>
      </Field>
      <Field>
        <Label>返信用メールアドレス</Label>
        <Text style={styles.value}>{data.email}</Text>
      </Field>
      <Field>
        <Label>お問い合わせ内容</Label>
        <Text style={styles.value}>{data.content}</Text>
      </Field>
      <View style={styles.alertBox}>
        <Text style={styles.alertBoxTitle}>
          <AlertTriangleIcon color={alertColor} size={11} />
          ご注意
        </Text>
        <Text style={styles.alertBoxMessage}>
          お問い合わせ内容がマニュアルやヘルプ、利用規約に記載されている場合、お返事はいたしかねます。
        </Text>
        <View style={styles.check}>
          <CheckButton checked={checked} onPress={toggleChecked}>
            上記の通り理解いたしました
          </CheckButton>
        </View>
      </View>
      <View style={styles.buttons}>
        <OutlineButton style={styles.button} onPress={onBack}>
          戻る
        </OutlineButton>
        <PrimaryButton
          style={styles.button}
          disabled={!checked}
          onPress={onPress}>
          送信する
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Confirm);

const alertColor = '#f23406';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 32,
    marginBottom: 8,
    marginHorizontal: 16,
  } as TextStyle,
  value: {
    color: '#222',
    fontSize: 14,
    marginTop: 16,
  } as TextStyle,
  alertBox: {
    backgroundColor: '#fafafa',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 16,
    paddingVertical: 16,
    paddingHorizontal: 20,
  } as ViewStyle,
  alertBoxTitle: {
    color: alertColor,
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  alertBoxMessage: {
    color: alertColor,
    fontSize: 11,
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 16,
  } as TextStyle,
  check: {
    width: 222,
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    marginVertical: 16,
    marginHorizontal: 12,
  } as ViewStyle,
  button: {
    flex: 1,
    marginHorizontal: 4,
  } as ViewStyle,
});
