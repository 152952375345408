import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {Control, FieldErrors} from 'react-hook-form';

import {Field, ControlledInput, Label} from '@/components/form';

export type Inputs = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};

interface Props {
  control?: Control<Inputs>;
  errors?: FieldErrors<Inputs>;
}

const Form: React.FC<Props> = props => {
  const {control, errors} = props;
  return (
    <View style={styles.container}>
      <Field>
        <Label>現在のパスワード</Label>
        <ControlledInput
          placeholder={'現在のパスワードを入力'}
          secureTextEntry={true}
          textContentType={'password'}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'currentPassword',
          }}
          errors={errors}
        />
      </Field>
      <Field>
        <Label>新しいパスワード(8文字以上)</Label>
        <ControlledInput
          placeholder={'新しいパスワードを入力'}
          secureTextEntry={true}
          textContentType={'password'}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'password',
          }}
          errors={errors}
        />
      </Field>
      <Field>
        <Label>確認用パスワード</Label>
        <ControlledInput
          placeholder={'パスワードを再度入力'}
          secureTextEntry={true}
          textContentType={'password'}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'passwordConfirmation',
          }}
          errors={errors}
        />
      </Field>
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
  } as ViewStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  submitButton: {
    marginVertical: 16,
  } as ViewStyle,
});
