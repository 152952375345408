import React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import dateFormat from 'dateformat';
import {useActionSheet} from '@expo/react-native-action-sheet';

import {EllipsisHorizontalIcon} from '@/components/icons';
import ActorAvatorImage from '@/components/utils/ActorAvatorImage';
import Label from '@/components/content/Label';

import {mutateActorCharacterFace} from '@/helpers/swr/MutateHelper';

import {useDestroyMutation} from '@/hooks/swr';
import useResponseErrorModal from '@/hooks/useResponseErrorModal';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

import ActorCharacterFace from '@/entities/ActorCharacterFace';

interface Props {
  actorCharacterFace: ActorCharacterFace;
}

const ActorCharacterFaceListItem: React.FC<Props> = props => {
  const {actorCharacterFace} = props;
  const linkTo = useLinkTo();
  const {showActionSheetWithOptions} = useActionSheet();
  const {show} = useResponseErrorModal();
  const {trigger, error} = useDestroyMutation(
    `/api/creator/actor_character_faces/${actorCharacterFace.id}`,
  );
  React.useEffect(() => {
    if (error) {
      show({error});
    }
  }, [error]);
  const onPress = React.useCallback(() => {
    linkTo(`/actor_character_faces/${actorCharacterFace.id}/edit`);
  }, []);
  const onPressMenu = React.useCallback(() => {
    const options = ['削除', 'キャンセル'];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        tintColor: '#383838',
        destructiveColor: '#f23406',
        cancelButtonTintColor: '#999',
        textStyle: {fontSize: 14},
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case destructiveButtonIndex:
            trigger({}).then(() => {
              mutateActorCharacterFace({
                id: actorCharacterFace.id,
                actorId: actorCharacterFace.actorId,
              });
              sendDefaultCustomEvent({
                resourceType: 'actor_character_face',
                resourceId: actorCharacterFace.id,
                actionName: 'destroy',
              });
            });
            break;
          case cancelButtonIndex:
        }
      },
    );
  }, []);
  return (
    <TouchableOpacity
      style={styles.container}
      activeOpacity={0.8}
      onPress={onPress}>
      <>
        <ActorAvatorImage actor={actorCharacterFace} size={80} />
        <View style={styles.center}>
          <Text style={styles.name}>{actorCharacterFace.faceName}</Text>
          <View style={styles.dates}>
            <Text style={styles.updatedAt}>
              {dateFormat(actorCharacterFace.updatedAt, 'yyyy.mm.dd')} 更新
            </Text>
          </View>
          <View style={styles.label}>
            {actorCharacterFace.approved ? (
              <Label color={'#3baf49'} width={40}>
                承認済み
              </Label>
            ) : (
              <Label color={'#999999'} width={40}>
                未承認
              </Label>
            )}
          </View>
        </View>
        <View style={styles.right}>
          <Pressable style={styles.menu} onPress={onPressMenu}>
            <EllipsisHorizontalIcon />
          </Pressable>
        </View>
      </>
    </TouchableOpacity>
  );
};

export default React.memo(ActorCharacterFaceListItem);

const styles = StyleSheet.create({
  container: {
    margin: 10,
    flexDirection: 'row',
  } as ViewStyle,
  center: {
    marginLeft: 11,
    justifyContent: 'center',
  } as ViewStyle,
  right: {
    flex: 1,
    alignItems: 'flex-end',
  } as ViewStyle,
  name: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 6,
  } as TextStyle,
  dates: {
    marginTop: 2,
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  updatedAt: {
    color: '#666',
    fontSize: 10,
    marginRight: 10,
  } as TextStyle,
  menu: {
    padding: 10,
    paddingTop: 2,
    paddingRight: 6,
  } as ViewStyle,
  label: {
    marginTop: 8,
  } as ViewStyle,
});
