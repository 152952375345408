import React from 'react';
import {
  ColorValue,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props extends React.PropsWithChildren {
  color?: ColorValue | undefined;
  width?: number;
}

const Label: React.FC<Props> = props => {
  const {color: backgroundColor, width, children} = props;
  return (
    <View style={[styles.container, {backgroundColor, width}]}>
      <Text style={styles.text}>{children}</Text>
    </View>
  );
};

export default React.memo(Label);

const styles = StyleSheet.create({
  container: {
    width: 32,
    height: 14,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#fff',
    fontSize: 8,
    fontWeight: 'bold',
  } as TextStyle,
});
