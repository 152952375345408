import React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import ArrowDown from '@/components/utils/ArrowDown';

import AnnouncementCategory from '@/entities/AnnouncementCategory';

interface Props {
  selectedAnnouncementCategory: AnnouncementCategory;
  onPress: () => void;
}

const AnnouncementCategoryFilter: React.FC<Props> = props => {
  const {selectedAnnouncementCategory, onPress} = props;
  return (
    <TouchableOpacity
      style={styles.container}
      activeOpacity={0.6}
      onPress={onPress}>
      <View style={styles.inner}>
        <View style={styles.selectedCategory}>
          <Text style={styles.selectedCategoryText}>
            {selectedAnnouncementCategory.name}
          </Text>
          <ArrowDown />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(AnnouncementCategoryFilter);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    height: 56,
    width: '100%',
    padding: 16,
  } as ViewStyle,
  inner: {
    flex: 1,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  selectedCategory: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  } as ViewStyle,
  selectedCategoryText: {
    color: '#999999',
    fontSize: 15,
    lineHeight: Platform.select({web: 1, default: undefined}),
    marginRight: 5,
  } as TextStyle,
});
