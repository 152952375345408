import React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
}

const BaseBadge: React.FC<Props> = props => {
  const {style} = props;
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.text}>{props.children}</Text>
    </View>
  );
};

export default React.memo(BaseBadge);

const styles = StyleSheet.create({
  container: {
    height: 16,
    paddingVertical: 3,
    paddingHorizontal: 4,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#fff',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
});
