import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {getDoubleResizeImageUrl} from '@/helpers/ImageHelper';

import Actor from '@/entities/Actor';

interface Props {
  actor: Actor;
}

const ActorPreview: React.FC<Props> = props => {
  const {actor} = props;
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(actor, {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      }),
    }),
    [],
  );
  return (
    <View style={styles.container}>
      <Text style={styles.name}>{actor.name}</Text>
      <Text style={styles.text}>{actor.defaultCostumeName}</Text>
      <Text style={styles.text}>表情差分：{actor.faceCount}</Text>
      <View style={{alignItems: 'center'}}>
        <Image style={styles.image} source={source} />
      </View>
    </View>
  );
};

export default React.memo(ActorPreview);

const IMAGE_WIDTH = 170;
const IMAGE_HEIGHT = 240;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    marginHorizontal: 16,
    marginTop: 32,
    marginBottom: 12,
  } as ViewStyle,
  name: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  } as TextStyle,
  text: {
    color: '#383838',
    fontSize: 10,
    marginVertical: 2,
  } as TextStyle,
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    marginTop: 16,
  } as ImageStyle,
});
