import React from 'react';

import {DefaultLinkList} from '@/components/ui/lists';

import useCurrentUserSession from '@/hooks/useCurrentUserSession';

const SettingList: React.FC = () => {
  const {destroy} = useCurrentUserSession();
  const dataWithAction = React.useMemo(
    () => [...data, {label: 'ログアウト', url: '', action: destroy}],
    [],
  );
  return <DefaultLinkList data={dataWithAction} />;
};

export default React.memo(SettingList);

const data = [
  {
    label: '通知設定',
    url: '/settings/notifications',
  },
  {
    label: 'アカウント設定',
    url: '/settings/account',
  },
  {
    label: '消費税事業者設定',
    url: '/settings/qualified_invoice_issuer',
  },
  {
    label: '受取口座設定',
    url: '/settings/bank_account',
  },
  {
    label: '振込履歴',
    url: '/settings/transfer_history',
  },
  {
    label: '特定商取引法に基づく表示',
    url: '/settings/policies/specified_commercial_transaction_law',
  },
  {
    label: 'プライバシーポリシー',
    url: '/settings/policies/privacy',
  },
  {
    label: '利用規約',
    url: '/settings/policies/terms',
  },
  {
    label: 'お問い合わせ',
    url: '/contacts/new',
  },
  {
    label: '退会',
    url: '/user/cancel',
  },
];
