import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';

import {OutlineButton} from '@/components/ui/buttons';

interface Props {
  onPress?: (() => void) | undefined;
}

const BalanceTransactionListFooter: React.FC<Props> = props => {
  const {onPress} = props;
  return (
    <View style={styles.container}>
      <ListItemNormalSeparator />
      <OutlineButton style={styles.button} onPress={onPress}>
        もっと見る
      </OutlineButton>
    </View>
  );
};

export default React.memo(BalanceTransactionListFooter);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  button: {
    marginVertical: 24,
  } as ViewStyle,
});
