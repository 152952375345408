import React from 'react';
import useSWR, {SWRConfiguration} from 'swr';
import useSWRMutation, {SWRMutationConfiguration} from 'swr/mutation';
import useSWRInfinite, {SWRInfiniteConfiguration} from 'swr/infinite';
import {decamelizeKeys} from 'humps';

import {
  get,
  getWithResponse,
  create,
  update,
  destroy,
  rawCreate,
  rawUpdate,
  rawDestroy,
} from '@/services/fetchers';

export const useGet = <T>(
  path: string | null,
  params?: Record<string, any> | null,
  config?: SWRConfiguration<T, ResponseError>,
) => {
  return useSWR(generateGetUrl(path, params), get<T>, config);
};

export const useGetWithResponse = <T>(
  path: string,
  params?: Record<string, any> | null,
  config?: SWRConfiguration<{data: T; response: Response}, ResponseError>,
) => {
  return useSWR(generateGetUrl(path, params), getWithResponse<T>, config);
};

const defaultMutationConfig: SWRMutationConfiguration<any, ResponseError> = {
  throwOnError: false,
};

const generateMutationConfig = <Data, ExtraArg = any>(
  config?: SWRMutationConfiguration<Data, ResponseError, ExtraArg>,
): SWRMutationConfiguration<Data, ResponseError, ExtraArg> => {
  if (!config) {
    return defaultMutationConfig;
  } else {
    return {...defaultMutationConfig, ...config};
  }
};

export const useGetMutation = <T>(
  path: string,
  params?: Record<string, any>,
  config?: SWRMutationConfiguration<T, ResponseError, never>,
) => {
  return useSWRMutation(
    generateGetUrl(path, params),
    get<T>,
    generateMutationConfig<T, never>(config),
  );
};

export const useCreateMutation = <T>(
  path: string,
  config?: SWRMutationConfiguration<T, ResponseError>,
) => {
  return useSWRMutation(path, create<T>, generateMutationConfig(config));
};

export const useUpdateMutation = <T>(
  path: string | null,
  config?: SWRMutationConfiguration<T, ResponseError>,
) => {
  return useSWRMutation(path, update<T>, generateMutationConfig(config));
};

export const useDestroyMutation = <T>(
  path: string,
  config?: SWRMutationConfiguration<T, ResponseError>,
) => {
  return useSWRMutation(path, destroy<T>, generateMutationConfig(config));
};

export const useRawCreateMutation = (
  path: string,
  config?: SWRMutationConfiguration<Response, ResponseError>,
) => {
  return useSWRMutation(path, rawCreate, generateMutationConfig(config));
};

export const useRawUpdateMutation = (
  path: string,
  config?: SWRMutationConfiguration<Response, ResponseError>,
) => {
  return useSWRMutation(path, rawUpdate, generateMutationConfig(config));
};

export const useRawDestroyMutation = (
  path: string,
  config?: SWRMutationConfiguration<Response, ResponseError>,
) => {
  return useSWRMutation(path, rawDestroy, generateMutationConfig(config));
};

const defaultInfiniteConfig: SWRInfiniteConfiguration<any, ResponseError> = {
  revalidateFirstPage: false,
};

const generateInfiniteConfig = <Data, ExtraArg = any>(
  config?: SWRInfiniteConfiguration<Data, ResponseError>,
): SWRInfiniteConfiguration<Data, ResponseError> => {
  if (!config) {
    return defaultInfiniteConfig;
  } else {
    return {...defaultInfiniteConfig, ...config};
  }
};

export const usePaginatedGet = <T>(
  path: string,
  params?: Record<string, any>,
  config?: SWRInfiniteConfiguration,
) => {
  const getKey = React.useCallback(
    (index: number, previousPageData: Array<T> | null) => {
      return generateGetKey(path, params)(index, previousPageData);
    },
    [path, params],
  );
  return useSWRInfinite<Array<T>>(getKey, get, generateInfiniteConfig(config));
};

export const generateGetKey = <T>(
  path: string,
  params?: Record<string, any>,
) => {
  return (index: number, previousPageData: Array<T> | null) => {
    if (previousPageData && !previousPageData.length) {
      return null;
    }
    return generateGetUrl(path, {
      page: index + 1,
      ...(params || {}),
    });
  };
};

export const usePaginatedGetWithResponse = <T>(
  path: string | null,
  params?: Record<string, any>,
  config?: SWRInfiniteConfiguration,
) => {
  const getKey = React.useCallback(
    (
      index: number,
      previousPageData: {data: Array<T>; response: Response} | null,
    ) => {
      return generateGetKeyWithResponse(path, params)(index, previousPageData);
    },
    [path, params],
  );
  return useSWRInfinite<{data: Array<T>; response: Response}>(
    getKey,
    getWithResponse,
    generateInfiniteConfig(config),
  );
};

export const generateGetKeyWithResponse = <T>(
  path: string | null,
  params?: Record<string, any>,
) => {
  return (
    index: number,
    previousPageData: {data: Array<T>; response: Response} | null,
  ) => {
    if (
      previousPageData &&
      !previousPageData.response.headers.get('Link')?.includes(`rel="next"`)
    ) {
      return null;
    }
    return generateGetUrl(path, {
      page: index + 1,
      ...(params || {}),
    });
  };
};

function generateGetUrl(
  url: string | null,
  params?: {[key: string]: any} | null,
): string | null {
  if (!url) {
    return null;
  }
  const getParams = params ? decamelizeKeys(params) : {};
  const queryString = generateQueryString(getParams);
  if (queryString.length === 0) {
    return url;
  }
  return url + (url.indexOf('?') === -1 ? '?' : '&') + queryString;
}

function generateQueryString(params: {[key: string]: any}): string {
  return Object.keys(params)
    .filter(k => params[k] !== undefined)
    .map(k => {
      const v = params[k];
      if (Array.isArray(v)) {
        return Object.keys(v)
          .map(
            k2 =>
              encodeURIComponent(`${k}[]`) +
              '=' +
              (v !== null ? encodeURIComponent(v[Number(k2)]) : ''),
          )
          .join('&');
      } else {
        return (
          encodeURIComponent(k) +
          '=' +
          (v !== null ? encodeURIComponent(v) : '')
        );
      }
    })
    .join('&');
}
