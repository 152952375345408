import React from 'react';
import {ColorValue, StyleSheet, Text, TextStyle} from 'react-native';

interface Props extends React.PropsWithChildren {
  color?: ColorValue | undefined;
  fontSize?: number | undefined;
  margin?: number | string | undefined;
  marginBottom?: number | string | undefined;
  marginEnd?: number | string | undefined;
  marginHorizontal?: number | string | undefined;
  marginLeft?: number | string | undefined;
  marginRight?: number | string | undefined;
  marginStart?: number | string | undefined;
  marginTop?: number | string | undefined;
  marginVertical?: number | string | undefined;
  padding?: number | string | undefined;
  paddingBottom?: number | string | undefined;
  paddingEnd?: number | string | undefined;
  paddingHorizontal?: number | string | undefined;
  paddingLeft?: number | string | undefined;
  paddingRight?: number | string | undefined;
  paddingStart?: number | string | undefined;
  paddingTop?: number | string | undefined;
  paddingVertical?: number | string | undefined;
}

const Heading: React.FC<Props> = props => {
  const {children, ...style} = props;
  return <Text style={[styles.container, style]} children={children} />;
};

export default React.memo(Heading);

const color = '#383838';

const styles = StyleSheet.create({
  container: {
    color,
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
});
