import React from 'react';

import {
  Pressable,
  Modal,
  ModalProps,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

interface Props extends ModalProps {}

const ModalBackdrop: React.FC<Props> = props => {
  const {children, onRequestClose, ...modalProps} = props;
  return (
    <Modal
      animationType={'fade'}
      {...modalProps}
      transparent={true}
      onRequestClose={onRequestClose}>
      <View style={styles.backdrop}>{children}</View>
    </Modal>
  );
};

export default React.memo(ModalBackdrop);

const styles = StyleSheet.create({
  backdrop: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
