import React from 'react';
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

import Button, {Props} from './Button';

export type {Props};

const PrimaryButton: React.FC<Props> = props => {
  const {style, ...restProps} = props;
  return (
    <Button
      style={[styles.container, style]}
      {...restProps}
      textProps={{style: styles.text}}
    />
  );
};

export default React.memo(PrimaryButton);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f5c71c',
  } as ViewStyle,
  text: {
    color: '#fff',
  } as TextStyle,
});
