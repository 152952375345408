import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {PrimaryButton} from '@/components/ui/buttons';

const NoBalanceTransaction: React.FC = () => {
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => linkTo('/actor_characters/new'), []);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>獲得した収益はありません</Text>
      <Text style={styles.note}>
        登録したイラストを全国のタップライターに{'\n'}
        販売することで収益を得ることができます。
      </Text>
      <PrimaryButton style={styles.button} onPress={onPress}>
        イラストを投稿する
      </PrimaryButton>
    </View>
  );
};

export default React.memo(NoBalanceTransaction);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fafafa',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  title: {
    color: '#666',
    fontSize: 14,
  } as TextStyle,
  note: {
    color: '#383838',
    fontSize: 12,
    marginTop: 32,
    marginBottom: 24,
  } as TextStyle,
  button: {
    marginBottom: 60,
  } as ViewStyle,
});
