import React from 'react';
import {
  ActivityIndicator,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {SafeAreaInsetsContext} from 'react-native-safe-area-context';
import {ActionSheetProvider} from '@expo/react-native-action-sheet';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';

import Container from './Container';
import NavigationBar from './NavigationBar';
import {Props as NavigationBarButtonProps} from './NavigationBarButton';

import ErrorContent from '../content/ErrorContent';

import Navigation from '@/navigation/Navigation';
import {CloseIcon, LeftIcon} from '@/components/icons';

type ButtonProps = NavigationBarButtonProps | null;

interface Props extends React.PropsWithChildren {
  title?: string | null;
  element?: React.ReactElement<any> | null;
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
  innerRightButton?: ButtonProps;
  back?: boolean;
  nolayout?: boolean;
  close?: boolean;
  scrollable?: boolean;
  bounces?: boolean;
  containerStyle?: ViewStyle;
  hideHeaderBottomBorder?: boolean;
  scrollViewStyle?: ViewStyle;
  loading?: boolean;
  footer?: React.ReactNode;
  safeAreaTopStyle?: StyleProp<ViewStyle>;
  alwaysEnableBackButton?: boolean;
  hideNavigationBar?: boolean;
  error?: any;
  onPressBack?: () => void;
  onPressClose?: () => void;
  onScroll?: (event?: NativeSyntheticEvent<NativeScrollEvent>) => void;
  onScrollEndDrag?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

const Layout: React.FC<Props> = props => {
  const {
    title,
    element,
    scrollable,
    bounces,
    children,
    containerStyle,
    hideHeaderBottomBorder,
    scrollViewStyle,
    loading,
    footer,
    safeAreaTopStyle,
    nolayout,
    innerRightButton,
    hideNavigationBar,
    error,
    onScroll,
    onScrollEndDrag,
  } = props;
  const navigation = useNavigation<Navigation>();
  const leftButton = useLeftButton(navigation, props);
  const rightBarButton = useRightBarButton(navigation, props);
  if (nolayout) {
    return <>{children}</>;
  }
  return (
    <SafeAreaInsetsContext.Consumer>
      {insets => (
        <ActionSheetProvider>
          <Container style={containerStyle}>
            <View
              style={[
                {paddingTop: insets?.top},
                styles.safeAreaTop,
                safeAreaTopStyle,
              ]}
            />
            {!hideNavigationBar && (
              <NavigationBar
                title={title}
                element={element}
                leftButton={leftButton}
                rightButton={rightBarButton}
                innerRightButton={innerRightButton}
                hideBottomBorder={hideHeaderBottomBorder}
              />
            )}
            {error ? (
              <ErrorContent error={error} />
            ) : scrollable !== false ? (
              <ScrollView
                style={scrollViewStyle}
                bounces={bounces === undefined ? true : bounces}
                scrollEventThrottle={16}
                onScroll={onScroll}
                automaticallyAdjustContentInsets={false}
                onScrollEndDrag={onScrollEndDrag}>
                {children}
              </ScrollView>
            ) : (
              <View style={styles.inner}>{children}</View>
            )}
            <View
              style={{
                paddingBottom: insets?.bottom,
                backgroundColor: 'transparent',
              }}
            />
            {loading && (
              <View style={styles.loading}>
                <ActivityIndicator size={'large'} color={'#999999'} />
              </View>
            )}
            {footer}
          </Container>
        </ActionSheetProvider>
      )}
    </SafeAreaInsetsContext.Consumer>
  );
};

export default React.memo(Layout);

function useLeftButton(navigation: Navigation, props: Props) {
  const {leftButton, back, onPressBack} = props;
  const defaultLeftButton = React.useMemo(() => {
    return {
      handler: onPressBack || (() => navigation.goBack()),
      tintColor: 'white',
      title: <LeftIcon />,
    };
  }, [navigation, onPressBack]);
  if (leftButton) {
    return leftButton;
  }
  if (back && navigation) {
    return defaultLeftButton;
  }
  return undefined;
}

function useRightBarButton(
  navigation: Navigation,
  props: Props,
): ButtonProps | undefined {
  const {rightButton, close, onPressClose} = props;
  const defaultRightButton = React.useMemo(() => {
    return {
      handler:
        onPressClose ||
        (() => {
          (navigation.getParent() || navigation).goBack();
        }),
      tintColor: 'white',
      title: <CloseIcon />,
    };
  }, [navigation]);

  if (rightButton) {
    return rightButton;
  }
  if (close && navigation) {
    return defaultRightButton;
  }
  return undefined;
}

const styles = StyleSheet.create({
  inner: {
    flex: 1,
    backgroundColor: 'white',
  } as ViewStyle,
  loading: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .6)',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
  safeAreaTop: {
    backgroundColor: 'white',
  } as ViewStyle,
});
