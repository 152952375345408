import React from 'react';
import {ScrollView, StyleSheet, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import FormActorAvatorImage from './FormActorAvatorImage';
import ControlledPrimaryActorCharacterFaceIdSelect from './ControlledPrimaryActorCharacterFaceIdSelect';

import ControlledImagePickerField from '@/pages/actor_character_faces/components/ControlledImagePickerField';
import ControlledFaceNameSelectField from '@/pages/actor_character_faces/components/ControlledFaceNameSelectField';
import ControlledFaceNameOtherInputField from '@/pages/actor_character_faces/components/ControlledFaceNameOtherInputField';

import {Field, ControlledInput, ControlledLabel} from '@/components/form';
import SubmitButtonWithEditable from '@/components/utils/SubmitButtonWithEditable';
import useTooltipModal, {
  Props as TooltipModalProps,
} from '@/hooks/useTooltipModal';

import Actor from '@/entities/Actor';
import ActorCharacterFace from '@/entities/ActorCharacterFace';

import actorNameUri from '@/assets/images/tips/actor-name.png';
import actorCostumeNameUri from '@/assets/images/tips/actor-costume-name.png';
import primaryActorCharacterFaceIdUri from '@/assets/images/tips/primary-actor-character-face-id.png';

export type Inputs = {
  image?: File | null;
  name: string;
  costumeName: string;
  faceName?: string;
  faceNameOther?: string;
  primaryActorCharacterFaceActorCharacterFaceId?: number | null;
};

interface Props {
  isNew: boolean;
  actor?: Actor;
  actorCharacterFaces?: ActorCharacterFace[] | null;
  defaultValues?: Inputs;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {isNew, actor, actorCharacterFaces, defaultValues, errors, onSubmit} =
    props;
  const {
    control,
    formState: {isValid},
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues:
      defaultValues ||
      ({
        image: null,
        name: '',
        costumeName: '',
        faceName: '',
        faceNameOther: '',
      } as Inputs),
  });
  const tooltipModal = useTooltipModal();
  const image = watch('image');
  const faceName = watch('faceName');
  const displayStyle = React.useMemo(
    () => (!isNew || image ? null : styles.hide),
    [!!image],
  );
  React.useEffect(() => {
    if (faceName !== 'その他') {
      setValue('faceNameOther', '');
    }
  }, [faceName]);

  const onPress = handleSubmit(onSubmit);
  const onPressNameQuestion = React.useCallback(() => {
    tooltipModal.show(NAME_QUESTION);
  }, []);
  const onPressCostumeNameQuestion = React.useCallback(() => {
    tooltipModal.show(COSTUME_NAME_QUESTION);
  }, []);
  const onPressPrimaryActorCharacterFaceIdQuestion = React.useCallback(() => {
    tooltipModal.show(PRIMARY_ACTOR_CHARACTER_FACE_ID_QUESTION);
  }, []);
  const onPressReset = React.useCallback(() => reset(), []);
  return (
    <View style={styles.container}>
      <ScrollView>
        {isNew && image !== undefined && (
          <ControlledImagePickerField
            control={control}
            errors={errors}
            name={'image'}
            value={image}
            onPressReset={onPressReset}
          />
        )}
        {!isNew && actor && <FormActorAvatorImage actor={actor} />}
        <Field style={displayStyle}>
          <ControlledLabel
            label={'イラストタイトル'}
            labelOption={'（最大20文字）'}
            control={control}
            name={'name'}
            errors={errors}
            maxLength={NAME_MAX_LENGTH}
            badgeType={'required'}
            onPressQuestion={onPressNameQuestion}
          />
          <ControlledInput
            placeholder={'イラストタイトルを入力'}
            required={true}
            controllerProps={{
              control,
              rules: {
                required: {value: true, message: '入力してください'},
                maxLength: NAME_MAX_LENGTH,
              },
              name: 'name',
            }}
            errors={errors}
            hideErrorMessage={true}
          />
        </Field>
        <Field style={displayStyle}>
          <ControlledLabel
            label={'衣装名'}
            labelOption={'（最大20文字）'}
            control={control}
            name={'costumeName'}
            errors={errors}
            maxLength={NAME_MAX_LENGTH}
            badgeType={'required'}
            onPressQuestion={onPressCostumeNameQuestion}
          />
          <ControlledInput
            placeholder={'衣装名を入力'}
            required={true}
            controllerProps={{
              control,
              rules: {
                required: {value: true, message: '入力してください'},
                maxLength: NAME_MAX_LENGTH,
              },
              name: 'costumeName',
            }}
            errors={errors}
            hideErrorMessage={true}
          />
        </Field>
        {isNew ? (
          <>
            <ControlledFaceNameSelectField
              style={displayStyle}
              control={control}
              errors={errors}
              name={'faceName'}
            />
            {faceName === 'その他' ? (
              <ControlledFaceNameOtherInputField
                style={displayStyle}
                control={control}
                errors={errors}
                name={'faceNameOther'}
              />
            ) : null}
          </>
        ) : (
          <Field style={displayStyle}>
            <ControlledLabel
              label={'代表の表情'}
              control={control}
              name={'primaryActorCharacterFaceActorCharacterFaceId'}
              errors={errors}
              onPressQuestion={onPressPrimaryActorCharacterFaceIdQuestion}
            />
            <ControlledPrimaryActorCharacterFaceIdSelect
              control={control}
              name={'primaryActorCharacterFaceActorCharacterFaceId'}
              actorCharacterFaces={actorCharacterFaces || []}
              errors={errors}
            />
          </Field>
        )}
      </ScrollView>
      <SubmitButtonWithEditable disabled={!isValid} onPress={onPress}>
        {!isNew ? '決定' : !image ? '次へ' : '登録'}
      </SubmitButtonWithEditable>
    </View>
  );
};

export default React.memo(Form);

const NAME_MAX_LENGTH = 20;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});

const NAME_QUESTION: TooltipModalProps = {
  title: 'イラストタイトル',
  description:
    'イラストの詳細ページに表示されるタイトルになります。\nタップライターが理解しやすいシンプルなタイトルをつけましょう。\n同じタイトルをつけるとイラストがグループ化されます。',
  imageProps: {
    source: actorNameUri,
    style: {width: 279, height: 160},
  },
};

const COSTUME_NAME_QUESTION: TooltipModalProps = {
  title: '衣装名',
  description:
    'イラストの詳細ページに表示される衣装名になります。\nキーワード検索の対象にもなりますので、タップライターが検索しやすいシンプルな衣装名をつけましょう。',
  imageProps: {
    source: actorCostumeNameUri,
    style: {width: 279, height: 160},
  },
};

const PRIMARY_ACTOR_CHARACTER_FACE_ID_QUESTION: TooltipModalProps = {
  title: '代表の表情',
  description:
    'ショップページでタップライターに表示される表情差分になります。\nよりキャラクターの個性を表現する表情を選びましょう。',
  imageProps: {
    source: primaryActorCharacterFaceIdUri,
    style: {width: 279, height: 160},
  },
};
