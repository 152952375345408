import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import {Field, ControlledInput, Label} from '@/components/form';
import {PrimaryButton} from '@/components/ui/buttons';

import CurrentUser from '@/entities/CurrentUser';

import {
  displayableEmail,
  verifiedEmailAddress,
} from '@/helpers/CurrentUserHelper';
export type Inputs = {
  email: string;
};

interface Props {
  currentUser: CurrentUser;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {currentUser, errors, onSubmit} = props;
  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const onPress = handleSubmit(onSubmit);
  return (
    <View style={styles.container}>
      <Field>
        <Label>
          {' '}
          現在のメールアドレス(
          {verifiedEmailAddress(currentUser) ? (
            <Text style={styles.verifiedEmail}>認証済</Text>
          ) : (
            <Text style={styles.notVerifiedEmail}>未認証</Text>
          )}
          )
        </Label>
        <Text style={styles.value}>{displayableEmail(currentUser)}</Text>
      </Field>
      <Field>
        <Label>新しいメールアドレス</Label>
        <ControlledInput
          placeholder={'新しいメールアドレスを入力'}
          textContentType={'emailAddress'}
          keyboardType={'email-address'}
          onSubmitEditing={onPress}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
              pattern: {value: /.+@.+\.[A-Za-z0-9]{1,}/, message: '不正です'},
            },
            name: 'email',
          }}
          errors={errors}
        />
      </Field>
      <View style={styles.submit}>
        <PrimaryButton
          style={styles.submitButton}
          disabled={!isValid}
          onPress={onPress}>
          認証メールを送信
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
  } as ViewStyle,
  value: {
    color: '#383838',
    fontSize: 14,
    marginTop: 16,
  } as TextStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  submitButton: {
    marginVertical: 16,
  } as ViewStyle,
  verifiedEmail: {
    color: 'green',
  } as TextStyle,
  notVerifiedEmail: {
    color: 'red',
  } as TextStyle,
});
