import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

const CompleteMessage: React.FC = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>公開申請が完了いたしました</Text>
      <Text style={styles.note}>
        イラストは審査を経て公開されます。{'\n'}
        審査は最大で5営業日ほどかかります。
      </Text>
    </View>
  );
};

export default React.memo(CompleteMessage);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    width: '100%',
    paddingVertical: 32,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 4,
  } as TextStyle,
  note: {
    color: '#383838',
    fontSize: 11,
  } as TextStyle,
});
