import React from 'react';

import Form, {Inputs} from './components/Form';

import {Layout} from '@/components/layout';

import useDefaultNavigation from '@/hooks/useDefaultNavigation';

import TransferRequest from '@/entities/TransferRequest';

import {buildFieldErrors} from '@/helpers/FormHelper';
import {useUpdateMutation} from '@/hooks/swr';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

export interface Params {}

const Page = () => {
  const navigation = useDefaultNavigation();
  const {trigger, isMutating, error} = useUpdateMutation<TransferRequest>(
    '/api/creator/transfer_request',
  );
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(transferRequest => {
      if (transferRequest?.id) {
        navigation.replace('TransferRequestComplete', {});
        sendDefaultCustomEvent({
          resourceType: 'transfer_request',
          resourceId: transferRequest.id,
          actionName: 'create',
        });
      }
    });
  }, []);
  return (
    <Layout
      title={'振込申請'}
      back={true}
      containerStyle={{backgroundColor: '#fafafa'}}
      loading={isMutating}>
      <Form errors={convertResponseToErrors(error)} onSubmit={onSubmit} />
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (
  formValues: Inputs & {productId?: number},
) => {
  return {transferRequest: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    amount: 'amount',
  });
};
