import React from 'react';
import {
  Image,
  ImageProps,
  StyleSheet,
  Text,
  TextStyle,
  View,
} from 'react-native';

import useApplicationModal from './useApplicationModal';

import {AlertTriangleIcon} from '@/components/icons';

export interface Props extends React.PropsWithChildren {
  error: ResponseError<any>;
  onPressOK?: () => void;
}

const useResponseErrorModal = () => {
  const applicationModal = useApplicationModal();
  const show = React.useCallback((props: Props) => {
    const {error, onPressOK} = props;
    applicationModal.show({
      title: <AlertTriangleIcon color={'#e7b600'} size={32} />,
      children: (
        <View>
          {Object.values(error.info).map((value, i) => {
            return <Text key={i}>{`${value}`}</Text>;
          })}
        </View>
      ),
      animationType: 'none',
      dialogAnimationProps: {
        scale: true,
        opacity: true,
      },
      onPressOK,
    });
  }, []);
  return {show};
};

export default useResponseErrorModal;

const content = {
  color: '#222',
  fontSize: 12,
} as TextStyle;

const styles = StyleSheet.create({
  content,
  contentWithImage: {
    ...content,
    marginBottom: 16,
  } as TextStyle,
});
