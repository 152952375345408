import React from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {CheckCircleOIcon} from '@/components/icons';

import {ItemValue, Item} from '@/components/value/types';

import {scaledValue} from '@/styles';

interface Props<IValue extends ItemValue = ItemValue> {
  item: Item<IValue>;
  checked: boolean;
  disabled?: boolean;
  width?: number;
  onSelectValue: (item: IValue) => void;
}

function CheckGridListItem<IValue extends ItemValue = ItemValue>(
  props: Props<IValue>,
) {
  const {item, checked, disabled, width, onSelectValue} = props;
  const onPress = React.useCallback(() => {
    onSelectValue(item.value);
  }, [onSelectValue]);
  const containerStyle = React.useMemo((): StyleProp<ViewStyle> | undefined => {
    return [
      checked
        ? styles.containerChecked
        : disabled
        ? styles.containerDisabled
        : styles.container,
      {
        width: scaledValue(width, 109),
        marginVertical: scaledValue(width, 4),
        marginHorizontal: scaledValue(width, 2),
      },
    ];
  }, [width, checked, disabled]);
  return (
    <Pressable disabled={disabled} style={containerStyle} onPress={onPress}>
      <View style={styles.icon}>
        <CheckCircleOIcon size={13} color={checked ? '#f5c71c' : '#efefef'} />
      </View>
      <Text
        style={
          checked
            ? styles.labelChecked
            : disabled
            ? styles.labelDisabled
            : styles.label
        }>
        {item.label}
      </Text>
    </Pressable>
  );
}

export default React.memo(CheckGridListItem) as typeof CheckGridListItem;

const container = {
  height: 30,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  borderWidth: 1,
  borderRadius: 4,
  borderColor: '#efefef',
} as ViewStyle;

const label = {
  color: '#666',
  fontSize: 12,
  fontWeight: 'bold',
} as TextStyle;

const styles = StyleSheet.create({
  container,
  containerChecked: {
    ...container,
    borderColor: '#f5c71c',
  } as ViewStyle,
  containerDisabled: {
    ...container,
    borderColor: '#efefef',
  } as ViewStyle,
  icon: {
    position: 'absolute',
    left: 8,
  } as ViewStyle,
  label,
  labelChecked: {
    ...label,
    color: '#e7b600',
  } as TextStyle,
  labelDisabled: {
    ...label,
    color: '#999999',
  } as TextStyle,
});
