import React from 'react';
import {
  Controller,
  UseControllerProps,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
  FieldErrors,
} from 'react-hook-form';

import Select, {Props as SelectProps} from './Select';
import ErrorMessage from './ErrorMessage';

import {ItemValue} from '@/components/value/types';

interface Props<
  IValue extends ItemValue = ItemValue,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<SelectProps<IValue>, 'value' | 'onValueChange'> {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  errors?: FieldErrors<TFieldValues>;
}

function ControlledSelect<
  IValue extends ItemValue = ItemValue,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<IValue, TFieldValues, TName>) {
  const {controllerProps, errors, ...selectProps} = props;
  const {name, rules, control} = controllerProps;
  const message = errors ? errors[name]?.message?.toString() : null;
  const render = React.useCallback(
    ({
      field: {onChange, value},
    }: {
      field: ControllerRenderProps<TFieldValues, TName>;
    }) => (
      <>
        {message && <ErrorMessage>{message}</ErrorMessage>}
        <Select {...selectProps} value={value} onValueChange={onChange} />
      </>
    ),
    [message, selectProps],
  );
  return (
    <Controller control={control} rules={rules} render={render} name={name} />
  );
}

export default React.memo(ControlledSelect) as typeof ControlledSelect;
