import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

const ArrowDown: React.FC = () => {
  return <View style={styles.container} />;
};

export default React.memo(ArrowDown);

const styles = StyleSheet.create({
  container: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
  } as ViewStyle,
});
