import React from 'react';

import NotificationList from './components/NotificationList';

import {Layout} from '@/components/layout';

import {useGetMutation} from '@/hooks/swr';

import UserNotificationSetting from '@/entities/UserNotificationSetting';

export interface Params {}

const Page = () => {
  const {data, trigger} = useGetMutation<{
    creatorUserNotificationSettings: UserNotificationSetting[];
  }>('/api/creator/user_notification_settings_batch');
  React.useLayoutEffect(() => {
    trigger();
  }, []);
  return (
    <Layout title={'通知設定'} back={true} scrollable={false}>
      {data?.creatorUserNotificationSettings && (
        <NotificationList
          userNotificationSettings={data.creatorUserNotificationSettings}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);
