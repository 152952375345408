import React from 'react';
import {useNavigation} from '@react-navigation/native';
import Form from './components/Form';

import {Layout} from '@/components/layout';

import useCurrentUserRegistration from '@/hooks/useCurrentUserRegistration';
import useCurrentUserStatus from '@/hooks/useCurrentUserStatus';
import {setUser} from '@/helpers/AnalyticsHelper';

export interface Params {}

const Page = () => {
  const navigation = useNavigation();
  const {
    destroy,
    destroyResponse: {isMutating},
  } = useCurrentUserRegistration();
  const {currentUserStatus, get} = useCurrentUserStatus();
  React.useEffect(() => {
    get();
  }, []);
  const onSubmit = () => {
    destroy().then(() => {
      setUser(null);
    });
  };
  return (
    <Layout title={'退会'} back={true} loading={isMutating}>
      {currentUserStatus && (
        <Form
          currentUserStatus={currentUserStatus}
          onSubmit={onSubmit}
          onPressCancel={navigation.goBack}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);
