import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

const AngleDownIcon: React.FC = () => <View style={styles.icon} />;

export default React.memo(AngleDownIcon);

const styles = StyleSheet.create({
  icon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
  } as ViewStyle,
});
