type UserPropertiesValue = {
  user_id: number;
};

export default class UserProperties {
  public static get(): Promise<UserPropertiesValue | null> {
    return new Promise((resolve, reject) => {
      const val = localStorage.getItem(UserProperties.KEY);
      if (val) {
        resolve(JSON.parse(val) as UserPropertiesValue);
      } else {
        resolve(null);
      }
    });
  }

  public static set(value: UserPropertiesValue): Promise<void> {
    return new Promise(resolve => {
      localStorage.setItem(UserProperties.KEY, JSON.stringify(value));
      resolve();
    });
  }

  public static remove(): Promise<void> {
    return new Promise(resolve => {
      localStorage.removeItem(UserProperties.KEY);
      resolve();
    });
  }

  private static KEY = 'user_properties';
}
