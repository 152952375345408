import React from 'react';
import {
  Controller,
  UseControllerProps,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
  FieldErrors,
} from 'react-hook-form';

import AvatarPicker from './image_picker/AvatarPicker';
import ErrorMessage from './ErrorMessage';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  errors?: FieldErrors<TFieldValues>;
}

function ControlledAvatarPicker<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {controllerProps, errors} = props;
  const {name, rules, control} = controllerProps;
  const message = errors ? errors[name]?.message?.toString() : null;
  const render = React.useCallback(
    ({
      field: {onChange, value},
    }: {
      field: ControllerRenderProps<TFieldValues, TName>;
    }) => (
      <>
        {message && <ErrorMessage>{message}</ErrorMessage>}
        <AvatarPicker source={value} onChangeImage={onChange} />
      </>
    ),
    [message],
  );
  return (
    <Controller control={control} rules={rules} render={render} name={name} />
  );
}

export default React.memo(
  ControlledAvatarPicker,
) as typeof ControlledAvatarPicker;
