import {Platform} from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';

import {
  APPLICATION_HEADER_HEIGHT,
  APPLICATION_CONTENT_MAX_WIDTH,
  APPLICATION_CONTENT_MAX_HEIGHT,
} from '@/styles';

const useApplicationLayoutStyle = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#555',
      width: Platform.select({web: undefined, default: '100%'}),
      height: Platform.select({web: undefined, default: '100%'}),
    },
    content: {
      backgroundColor: 'white',
      width: Platform.select({web: '100dvw', default: '100%'}),
      height: Platform.select({web: '100dvh', default: '100%'}),
    },
    contentForNoLayout: {
      width: Platform.select({web: '100dvw', default: '100%'}),
      height: Platform.select({web: '100dvh', default: '100%'}),
    },
  },
  {
    [minSize(DEVICE_SIZES.MD)]: {
      content: {
        backgroundColor: 'white',
        top: APPLICATION_HEADER_HEIGHT / 2,
        width: Platform.select<number | string>({
          web: `min(${APPLICATION_CONTENT_MAX_WIDTH}px, 100dvw)`,
          default: APPLICATION_CONTENT_MAX_WIDTH,
        }),
        height: Platform.select<number | string>({
          web: `min(${APPLICATION_CONTENT_MAX_HEIGHT}px, 100dvh - ${APPLICATION_HEADER_HEIGHT}px)`,
          default: APPLICATION_CONTENT_MAX_HEIGHT,
        }),
      },
    },
  },
);

export default useApplicationLayoutStyle;
