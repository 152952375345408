import React from 'react';

import CompleteMessage from './components/CompleteMessage';
import CloseButton from './components/CloseButton';
import ActorPreview from '@/pages/actors/components/ActorPreview';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import {useGet} from '@/hooks/swr';

import Actor from '@/entities/Actor';
import ResourceProductReleaseRequest from '@/entities/ResourceProductReleaseRequest';

export interface Params {
  actorId: number;
}

const Page = (props: ScreenProps<'ActorProductReleaseRequestComplete'>) => {
  const {route} = props;
  const {actorId} = route.params;
  const navigation = useDefaultNavigation();
  const onPress = React.useCallback(() => {
    navigation.pop();
  }, []);
  const {data: actorProductReleaseRequest, error} = useGet<
    ResourceProductReleaseRequest<Actor>
  >(`/api/creator/actor_product_release_requests/${actorId}`);
  const actor = actorProductReleaseRequest?.resource;
  return (
    <Layout
      title={'公開申請完了'}
      scrollable={false}
      close={true}
      error={error}>
      <CompleteMessage />
      {actor?.actorCharacter && <ActorPreview actor={actor} />}

      <CloseButton onPress={onPress} />
    </Layout>
  );
};

export default React.memo(Page);
