import React from 'react';
import {NavigationState, PartialState} from '@react-navigation/native';
import {SWRConfig, useSWRConfig} from 'swr';

import Navigation from '@/navigation';
import navigationRef from '@/navigation/ref';
import ApplicationLayout from '@/components/application_layout/ApplicationLayout';
import useCurrentUser from '@/hooks/useCurrentUser';

import {CurrentUserStatusProvider} from '@/contexts/CurrentUserStatusContext';
import {ApplicationModalProvider} from '@/contexts/ApplicationModalContext';

const NavigationWithLayout = () => {
  const {currentUser} = useCurrentUser();
  const [activeRouteName, setActiveRouteName] = React.useState<string>('Home');
  const onStateChange = React.useCallback(
    (state: NavigationState | undefined) => {
      if (!state) {
        return;
      }
      setActiveRouteName(getActiveRouteName(state));
    },
    [],
  );
  const onReady = React.useCallback(() => {
    if (!navigationRef.current) {
      return;
    }
    const route = navigationRef.current.getCurrentRoute();
    const routeName = route?.name;
    if (routeName) {
      setActiveRouteName(routeName);
    }
  }, [navigationRef.current]);
  const signedIn = !!currentUser;
  const config = useSWRConfig();
  return (
    <SWRConfig
      value={{
        onErrorRetry: (err, key, _config, revalidate, opts) => {
          if (err.status === 401 || err.status === 404) {
            return;
          }
          return config.onErrorRetry(err, key, config, revalidate, opts);
        },
      }}>
      <ApplicationLayout
        signedIn={signedIn}
        noLayout={
          (!signedIn && activeRouteName === 'Home') ||
          [
            'Monitor',
            'MonitorApplicationNew',
            'MonitorApplicationComplete',
          ].includes(activeRouteName)
        }>
        <Navigation
          currentUser={currentUser}
          onStateChange={onStateChange}
          onReady={onReady}
        />
      </ApplicationLayout>
    </SWRConfig>
  );
};

const swrConfigValue = {
  revalidateOnFocus: false,
  loadingTimeout: 10 * 1000,
  errorRetryCount: 3,
};

const App = () => {
  return (
    <SWRConfig value={swrConfigValue}>
      <CurrentUserStatusProvider>
        <ApplicationModalProvider>
          <NavigationWithLayout />
        </ApplicationModalProvider>
      </CurrentUserStatusProvider>
    </SWRConfig>
  );
};

export default React.memo(App);

const getActiveRouteName = (
  state: NavigationState | PartialState<NavigationState>,
): string => {
  const route = state.routes[state.index || 0];
  if (route.state) {
    return getActiveRouteName(route.state);
  }
  return route.name;
};
