import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';

import AnnouncementCategoryFilter from './components/AnnouncementCategoryFilter';
import AnnouncementList from './components/AnnouncementList';
import AnnouncementCategorySelectModal from './components/AnnouncementCategorySelectModal';

import {Layout} from '@/components/layout';

import {RootStackParamList} from '@/navigation/RootStackParamList';
import ScreenProps from '@/navigation/ScreenProps';

import Announcement from '@/entities/Announcement';
import AnnouncementCategory from '@/entities/AnnouncementCategory';

import {useGet, usePaginatedGet} from '@/hooks/swr';

export interface Params {
  creator_announcement_category_id?: number;
}

const Page = (props: ScreenProps<'Announcements'>) => {
  const {creator_announcement_category_id} = props.route.params || {};
  const {data, size, setSize, mutate} = usePaginatedGet<Announcement>(
    '/api/creator/announcements',
    {creator_announcement_category_id},
  );
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [activatedFilter, setActivatedFilter] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [selectedAnnouncementCategory, setSelectedAnnouncementCategory] =
    React.useState<AnnouncementCategory>(nullAnnouncementCategory);
  const {data: announcementCategories} = useGet<AnnouncementCategory[]>(
    '/api/creator/announcement_categories',
  );
  React.useEffect(() => {
    const id = Number(creator_announcement_category_id);
    if (
      announcementCategories &&
      selectedAnnouncementCategory.id !== Number(id)
    ) {
      const found = announcementCategories.find(o => o.id === Number(id));
      if (found) {
        setSelectedAnnouncementCategory(found);
      }
    }
  }, [announcementCategories]);
  const onSelectAnnouncementCategory = React.useCallback(
    (announcementCategory: AnnouncementCategory) => {
      navigation.setParams({
        creator_announcement_category_id:
          announcementCategory.id === 0 ? undefined : announcementCategory.id,
      });
      setSelectedAnnouncementCategory(announcementCategory);
      setActivatedFilter(false);
    },
    [],
  );
  const toggleOpenModal = React.useCallback(() => {
    setActivatedFilter(!activatedFilter);
  }, [activatedFilter]);
  const onCloseModal = React.useCallback(() => {
    setActivatedFilter(false);
  }, []);
  const onEndReached = React.useCallback(
    (info: {distanceFromEnd: number}) => setSize(size + 1),
    [size],
  );
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    mutate();
  }, []);
  const announcements = React.useMemo(() => data?.flat(), [data]);
  return (
    <Layout title={'お知らせ'} back={true} scrollable={false}>
      {selectedAnnouncementCategory && (
        <AnnouncementCategoryFilter
          selectedAnnouncementCategory={selectedAnnouncementCategory}
          onPress={toggleOpenModal}
        />
      )}
      {announcements && (
        <AnnouncementList
          announcements={announcements}
          refreshing={refreshing}
          onEndReached={onEndReached}
          onRefresh={onRefresh}
        />
      )}
      {activatedFilter &&
        announcementCategories &&
        selectedAnnouncementCategory && (
          <AnnouncementCategorySelectModal
            announcementCategories={[nullAnnouncementCategory].concat(
              announcementCategories,
            )}
            selectedAnnouncementCategory={selectedAnnouncementCategory}
            onSelectAnnouncementCategory={onSelectAnnouncementCategory}
            onCloseModal={onCloseModal}
          />
        )}
    </Layout>
  );
};

export default React.memo(Page);

const nullAnnouncementCategory = {id: 0, name: 'すべて'};
