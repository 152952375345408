import React from 'react';

import Form, {Inputs, AVAILABLE_FACE_NAMES} from './components/Form';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import {buildFieldErrors} from '@/helpers/FormHelper';
import {mutateActorCharacterFace} from '@/helpers/swr/MutateHelper';

import {get} from '@/services/fetchers';

import {useUpdateMutation} from '@/hooks/swr';
import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import useResponseErrorModal from '@/hooks/useResponseErrorModal';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

import ActorCharacterFace from '@/entities/ActorCharacterFace';
import Actor from '@/entities/Actor';

export interface Params {
  id: number;
}

const Page = (props: ScreenProps<'ActorCharacterFaceEdit'>) => {
  const {route} = props;
  const {id} = route.params;
  const navigation = useDefaultNavigation();
  const {show} = useResponseErrorModal();
  const [actorCharacterFace, setActorCharacterFace] =
    React.useState<ActorCharacterFace | null>(null);
  const [actorCharacterFaceError, setActorCharacterFaceError] =
    React.useState<any>(null);
  const [actor, setActor] = React.useState<Actor | null>(null);
  React.useEffect(() => {
    get<ActorCharacterFace>(`/api/creator/actor_character_faces/${id}`)
      .then(setActorCharacterFace)
      .catch(setActorCharacterFaceError);
  }, []);
  React.useEffect(() => {
    if (actorCharacterFace) {
      get<Actor>(`/api/creator/actors/${actorCharacterFace.actorId}`).then(
        setActor,
      );
    }
  }, [actorCharacterFace]);

  const {trigger, error, isMutating} = useUpdateMutation<ActorCharacterFace>(
    `/api/creator/actor_character_faces/${id}`,
  );
  React.useEffect(() => {
    if (error && Object.hasOwn(error?.info, 'resourceProduct')) {
      show({error});
    }
  }, [error]);
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(actorCharacterFace => {
      if (actorCharacterFace) {
        navigation.navigate('Actor', {
          id: actorCharacterFace.actorId,
        });
        mutateActorCharacterFace({
          id: actorCharacterFace.id,
          actorId: actorCharacterFace.actorId,
        });
        sendDefaultCustomEvent({
          resourceType: 'actor_character_face',
          resourceId: actorCharacterFace.id,
          actionName: 'update',
        });
      }
    });
  }, []);
  const defaultValues = convertFormRecordToDefaultValues(actorCharacterFace);
  return (
    <Layout
      title={'表情情報'}
      back={true}
      loading={isMutating}
      error={actorCharacterFaceError}>
      {actor && defaultValues && (
        <Form
          onSale={!!actor.price && !!actorCharacterFace?.approved}
          defaultValues={defaultValues}
          errors={convertResponseToErrors(error)}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);

const convertFormRecordToDefaultValues = (
  record?: ActorCharacterFace | null,
): Inputs | null => {
  if (!record) {
    return null;
  }
  return {
    image: record.hasImage
      ? ({uri: record.originalImageUrl} as unknown as File)
      : null,
    faceName: AVAILABLE_FACE_NAMES.includes(record.faceName)
      ? record.faceName
      : 'その他',
    faceNameOther: AVAILABLE_FACE_NAMES.includes(record.faceName)
      ? ''
      : record.faceName,
  };
};

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {actorCharacterFace: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    image: [
      'image',
      'miniMagickImageValid',
      'miniMagickImageWidth',
      'miniMagickImageHeight',
      'miniMagickImageType',
      'miniMagickImageDataType',
      'resourceProduct',
    ],
    faceName: 'faceName',
    faceNameOther: 'faceNameOther',
  });
};
