import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {Control, FieldValues, FieldPath, FieldErrors} from 'react-hook-form';

import {ControlledSelect} from '@/components/form';

import ActorCharacterFace from '@/entities/ActorCharacterFace';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  style?: StyleProp<ViewStyle> | undefined;
  control?: Control<TFieldValues>;
  name: TName;
  actorCharacterFaces: ActorCharacterFace[];
  errors?: FieldErrors<TFieldValues>;
}

function ControlledPrimaryActorCharacterFaceIdSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {control, name, actorCharacterFaces} = props;
  const items = actorCharacterFaces.map(actorCharacterFace => {
    return {
      label: actorCharacterFace.faceName,
      value: actorCharacterFace.id,
    };
  });
  return (
    <ControlledSelect
      includeBlank={'表情名を選択'}
      cancelText={'キャンセル'}
      confirmText={'決定'}
      items={items}
      required={true}
      controllerProps={{
        control,
        rules: {},
        name,
      }}
    />
  );
}

export default React.memo(
  ControlledPrimaryActorCharacterFaceIdSelect,
) as typeof ControlledPrimaryActorCharacterFaceIdSelect;
