import React from 'react';
import {PrimaryBadge} from '@/components/ui/badges';

const RequiredBadge: React.FC = () => (
  <PrimaryBadge style={style}>必須</PrimaryBadge>
);

export default React.memo(RequiredBadge);

const style = {
  marginLeft: 5,
};
