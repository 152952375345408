import React from 'react';
import {Linking} from 'react-native';

import SocialShareButton from './SocialShareButton';

import {FacebookIcon} from '@/components/icons/social';

import {facebookShareUrl} from '@/helpers/SocialUrlHelper';

interface Props {
  url: string;
}

const FacebookShareButton: React.FC<Props> = props => {
  const {url} = props;
  const onPress = React.useCallback(() => {
    Linking.openURL(facebookShareUrl(url));
  }, [url]);
  return (
    <SocialShareButton color={'#efefef'} onPress={onPress}>
      <FacebookIcon size={46} />
    </SocialShareButton>
  );
};

export default React.memo(FacebookShareButton);
