import React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import NotificationListItem from './NotificationListItem';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';

import UserNotificationSetting from '@/entities/UserNotificationSetting';

interface Props {
  userNotificationSettings: UserNotificationSetting[];
}

const NotificationList: React.FC<Props> = props => {
  const {userNotificationSettings} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<UserNotificationSetting>) => (
      <NotificationListItem userNotificationSetting={info.item} />
    ),
    [],
  );
  const keyExtractor = React.useCallback(
    (item: UserNotificationSetting) => `${item.creatorNotificationSetting.id}`,
    [],
  );
  return (
    <FlatList
      data={userNotificationSettings}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(NotificationList);
