import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';
import {useLinkTo} from '@react-navigation/native';

import PolicyLinks from './PolicyLinks';
import {Field, ControlledInput, Label} from '@/components/form';
import {PrimaryButton} from '@/components/ui/buttons';

export type Inputs = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

interface Props {
  errors?: FieldErrors<Inputs>;
  defaultValues?: Inputs;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {errors, defaultValues, onSubmit} = props;
  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    defaultValues: defaultValues || {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  });
  const renderCustomErrorMessage = useRenderCustomErrorMessage();
  const onPress = handleSubmit(onSubmit);
  return (
    <View style={styles.container}>
      <Field>
        <Label>メールアドレス</Label>
        <ControlledInput
          placeholder={'メールアドレスを入力'}
          textContentType={'emailAddress'}
          keyboardType={'email-address'}
          onSubmitEditing={onPress}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
              pattern: {value: /.+@.+\.[A-Za-z0-9]{1,}/, message: '不正です'},
            },
            name: 'email',
          }}
          errors={errors}
          renderCustomErrorMessage={renderCustomErrorMessage}
        />
      </Field>
      <Field>
        <Label>パスワード(8文字以上)</Label>
        <ControlledInput
          placeholder={'パスワードを入力'}
          secureTextEntry={true}
          textContentType={'password'}
          onSubmitEditing={onPress}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'password',
          }}
          errors={errors}
        />
      </Field>
      <Field>
        <Label>確認用パスワード</Label>
        <ControlledInput
          placeholder={'パスワードを再度入力'}
          secureTextEntry={true}
          textContentType={'password'}
          onSubmitEditing={onPress}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'passwordConfirmation',
          }}
          errors={errors}
        />
      </Field>
      <View style={styles.submit}>
        <PolicyLinks />
        <PrimaryButton
          style={styles.submitButton}
          disabled={!isValid}
          onPress={onPress}>
          会員登録する
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
  } as ViewStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  submitButton: {
    marginVertical: 16,
  } as ViewStyle,
});

const useRenderCustomErrorMessage = () => {
  const linkTo = useLinkTo();
  return React.useCallback(
    (children: React.ReactNode | undefined): React.ReactNode => {
      if (
        children != '既に会員となっております。' &&
        children !=
          '既に「TapNovelMaker（投稿サービス）」の会員となっております。' &&
        children != '既に「TapNovel（読書サービス）」の会員となっております。'
      ) {
        return children;
      }
      return (
        <>
          {children}
          {'\n'}
          同一のアカウントでTapNovelを利用するため、
          <TouchableOpacity onPress={() => linkTo('/users/sign_in')}>
            <Text
              style={{
                fontWeight: 'bold',
                textDecorationLine: 'underline',
              }}>
              ログイン
            </Text>
          </TouchableOpacity>
          ください。
        </>
      );
    },
    [],
  );
};
