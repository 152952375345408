import React from 'react';

import Alert from '@/components/alert/Alert';

import {
  useGetMutation,
  useRawCreateMutation,
  useRawDestroyMutation,
} from '@/hooks/swr';
import SocialLogin, {
  SuccessData,
  ErrorData,
} from '@/services/social_logins/SocialLogin';

const useSocialConnect = (
  socialLogin: SocialLogin,
  url: string,
  key: 'facebookAccount' | 'twitterAccount' | 'lineAccount',
  callback?: () => void,
) => {
  const {trigger: createTrigger, ...connectResponse} =
    useRawCreateMutation(url);
  const {trigger: destroyTrigger, ...disconnectResponse} =
    useRawDestroyMutation(url);
  const onSuccess = React.useCallback((data: SuccessData) => {
    if (!data.accessToken) {
      return;
    }
    createTrigger({
      [key]: {
        accessToken: data.accessToken,
        accessTokenSecret: data.accessTokenSecret,
        screenName: data.screenName,
        userId: data.id,
      },
    })
      .then(() => callback && callback())
      .catch(res => {
        Alert.alert(generateMessage(res.info));
      });
  }, []);
  const onError = React.useCallback((error: ErrorData) => {
    Alert.alert('連携できませんでした');
  }, []);
  const connect = React.useCallback(
    () => socialLogin.login({onSuccess, onError}),
    [],
  );
  const disconnect = React.useCallback(() => {
    Alert.alert('確認', 'Twitterとの連携を解除しますか？', [
      {text: 'キャンセル', onPress: () => {}, style: 'cancel'},
      {
        text: '解除する',
        onPress: () => destroyTrigger({}).then(() => callback && callback()),
      },
    ]);
  }, []);
  return {connect, disconnect, connectResponse, disconnectResponse};
};

export default useSocialConnect;

const generateMessage = (res: any): string => {
  if (!res) {
    return '';
  }
  if (Array.isArray(res)) {
    return res.map(r => generateMessage(r)).join('/');
  } else if (typeof res === 'object') {
    return Object.keys(res)
      .map(k => {
        return generateMessage(res[k]);
      })
      .join('/');
  } else {
    return `${res}`;
  }
};
