import React from 'react';
import CurrentUserStatusContext from '@/contexts/CurrentUserStatusContext';

import CurrentUser from '@/entities/CurrentUser';

import {useGetMutation} from './swr';

import {setUser} from '@/helpers/AnalyticsHelper';

const useCurrentUser = () => {
  const {currentUser, setCurrentUser} = React.useContext(
    CurrentUserStatusContext,
  );
  const {trigger} = useGetMutation<CurrentUser>('/api/creator/current_user');
  const get = React.useCallback(() => {
    return trigger().then(data => {
      if (data) {
        setUser(data);
      }
      setCurrentUser && setCurrentUser(data || null);
      return data || null;
    });
  }, []);
  return {currentUser, get};
};

export default useCurrentUser;
