import React from 'react';
import {Linking} from 'react-native';

import SocialShareButton from './SocialShareButton';

import {LineIcon} from '@/components/icons/social';

import {lineShareUrl} from '@/helpers/SocialUrlHelper';

interface Props {
  url: string;
  text: string;
  textWithServiceName?: boolean;
}

const LineShareButton: React.FC<Props> = props => {
  const {url, text, textWithServiceName} = props;
  const onPress = React.useCallback(() => {
    Linking.openURL(lineShareUrl(url, text, textWithServiceName));
  }, [url, text]);
  return (
    <SocialShareButton color={'#efefef'} onPress={onPress}>
      <LineIcon size={46} />
    </SocialShareButton>
  );
};

export default React.memo(LineShareButton);
