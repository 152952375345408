import {FieldValues, FieldPath, FieldErrors} from 'react-hook-form';

const generateErrorMessage = (
  obj: any,
  key: string | string[],
): string | undefined => {
  if (Array.isArray(key)) {
    return key
      .flatMap(k => generateErrorMessage(obj, k))
      .filter(m => !!m)
      .join('\n');
  }
  const messages = obj[key];
  if (!messages) {
    return undefined;
  }
  if (Array.isArray(messages)) {
    return messages.filter(m => !!m).join('\n');
  }
  return messages;
};

const buildFieldErrors = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  error: ResponseError | undefined,
  map: Record<TName, string | string[]>,
): FieldErrors<TFieldValues> | undefined => {
  if (!error) {
    return undefined;
  }
  return (Object.keys(map) as TName[]).reduce(
    (previousValue: any, key: TName) => {
      const value = map[key];
      previousValue[key] = {
        message: generateErrorMessage(error.info, value),
        type: 'required',
      };
      return previousValue;
    },
    {},
  );
};

export {generateErrorMessage, buildFieldErrors};
