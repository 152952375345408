import CurrentUser from '@/entities/CurrentUser';

export const verifiedEmailAddress = (currentUser: CurrentUser): boolean => {
  if (!currentUser.email) {
    return false;
  }
  if (currentUser.unconfirmedEmail && currentUser.unconfirmedEmail.length > 0) {
    return false;
  } else {
    return true;
  }
};

export const displayableEmail = (currentUser: CurrentUser): string => {
  const str = currentUser.unconfirmedEmail || currentUser.email;
  const ary = str.split('@');
  const localPart = ary[0];
  const displayCharNum =
    localPart.length >= 4 ? 3 : localPart.length > 1 ? localPart.length - 1 : 1;
  const displayLocalPart = `${localPart.slice(0, displayCharNum)}${'*'.repeat(
    localPart.length - displayCharNum,
  )}`;
  const domain = ary[1];
  return `${displayLocalPart}@${domain}`;
};
