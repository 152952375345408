import React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextProps,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native';

export interface Props extends TouchableOpacityProps {
  textProps?: TextProps;
  icon?: React.ReactElement<any, any>;
  disabledStyle?: StyleProp<ViewStyle>;
  disabledTextStyle?: StyleProp<ViewStyle>;
}

const Button: React.FC<Props> = props => {
  const {
    style,
    disabled,
    textProps,
    icon,
    disabledStyle,
    disabledTextStyle,
    children,
    ...restProps
  } = props;
  const {style: textStyle, ...restTextProps} = textProps || {};
  return (
    <TouchableOpacity
      style={[
        styles.container,
        style,
        disabled ? styles.containerDisabled : null,
        disabled ? disabledStyle : null,
      ]}
      activeOpacity={0.8}
      disabled={disabled}
      {...restProps}>
      {icon}
      <Text
        style={[
          styles.text,
          textStyle,
          disabled ? styles.textDisabled : null,
          disabled ? disabledTextStyle : null,
        ]}
        {...restTextProps}>
        {children}
      </Text>
    </TouchableOpacity>
  );
};

export default React.memo(Button);

const styles = StyleSheet.create({
  container: {
    width: 311,
    height: 46,
    borderRadius: 24,
    backgroundColor: '#f5c71c',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  containerDisabled: {
    backgroundColor: '#d5d5d5',
    borderColor: '#d5d5d5',
  } as ViewStyle,
  text: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    paddingHorizontal: 4,
  } as TextStyle,
  textDisabled: {
    color: '#999999',
  } as TextStyle,
});
