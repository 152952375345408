import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import {ControlledInput, Label, Field} from '@/components/form';
import {PrimaryButton} from '@/components/ui/buttons';
import {Paragraph} from '@/components/content';

export type Inputs = {
  email: string;
};

interface Props {
  email: string;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {email, errors, onSubmit} = props;
  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    defaultValues: {
      email,
    },
  });
  const onPress = handleSubmit(onSubmit);
  return (
    <View style={styles.container}>
      <Paragraph paddingHorizontal={16}>
        アカウント認証を行うメールアドレスをご入力ください。
      </Paragraph>
      <Field>
        <Label>メールアドレス</Label>
        <ControlledInput
          placeholder={'メールアドレスを入力'}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
              pattern: {value: /.+@.+\.[A-Za-z0-9]{1,}/, message: '不正です'},
            },
            name: 'email',
          }}
          errors={errors}
        />
      </Field>
      <View style={{alignItems: 'center', marginTop: 32}}>
        <PrimaryButton disabled={!isValid} onPress={onPress}>
          認証メールを再送信する
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 40,
  } as ViewStyle,
  fieldWithErrors: {
    backgroundColor: 'rgba(242, 52, 6, 0.3)',
    color: '#999999',
  } as ViewStyle,
});
