import React from 'react';
import {Image, StyleSheet, View, ViewStyle} from 'react-native';

import {getDoubleResizeImageUrl} from '@/helpers/ImageHelper';
import {getPositionForCenteringFace} from '@/helpers/ActorImageHelper';

import Actor from '@/entities/Actor';
import ActorCharacterFace from '@/entities/ActorCharacterFace';

interface Props {
  actor: Actor | ActorCharacterFace;
  size: number;
}

const ActorAvatorImage: React.FC<Props> = props => {
  const {actor, size} = props;
  const imageSize = size * 2;
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(actor, {
        width: imageSize,
        height: imageSize,
      }),
    }),
    [actor.originalImageUrl],
  );
  const style = React.useMemo(
    () => [
      styles.container,
      {width: size, height: size, borderRadius: size / 2},
    ],
    [size],
  );
  const imageStyle = React.useMemo(
    () => [
      getPositionForCenteringFace(actor, {
        top: 0,
        left: -0.5,
      }),
      {width: imageSize, height: imageSize},
    ],
    [actor.faceCenterPosition, size],
  );
  return (
    <View style={style}>
      <Image source={source} style={imageStyle} />
    </View>
  );
};

export default React.memo(ActorAvatorImage);

const styles = StyleSheet.create({
  container: {
    borderColor: '#efefef',
    borderWidth: 1,
    overflow: 'hidden',
    alignItems: 'center',
  } as ViewStyle,
});
