import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {useUpdateMutation} from '@/hooks/swr';
import {formatElapsedTimeOrFormattedTime} from '@/helpers/TimeHelper';

import UserNotification from '@/entities/UserNotification';

interface Props {
  userNotification: UserNotification;
}

const NotificationListItem: React.FunctionComponent<Props> = props => {
  const [userNotification, setUserNotification] = React.useState(
    props.userNotification,
  );
  React.useEffect(() => {
    setUserNotification(props.userNotification);
  }, [props.userNotification]);
  const linkTo = useLinkTo();
  const {trigger} = useUpdateMutation<UserNotification>(
    `/api/creator/user_notifications/${userNotification.id}`,
  );
  const confirmed = !!userNotification.confirmedAt;
  const onPress = React.useCallback(() => {
    if (!userNotification.confirmedAt) {
      trigger({creatorUserNotification: {confirmed: true}}).then(
        userNotification => {
          if (userNotification) {
            setUserNotification(userNotification);
          }
        },
      );
    }
    linkTo(userNotification.fullpath);
  }, [userNotification]);
  return (
    <Pressable
      style={[styles.container, confirmed ? styles.containerConfirmed : null]}
      onPress={onPress}>
      <View>
        <Text style={[styles.text, confirmed ? styles.textConfirmed : null]}>
          {userNotification.content}
        </Text>
        <Text style={styles.time}>
          {formatElapsedTimeOrFormattedTime(
            new Date(
              userNotification.optionsUpdatedAt || userNotification.createdAt,
            ),
          )}
        </Text>
      </View>
    </Pressable>
  );
};

export default React.memo(NotificationListItem);

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: 'white',
  } as ViewStyle,
  containerConfirmed: {
    backgroundColor: '#f4f4f4',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
  textConfirmed: {
    color: '#666',
  } as TextStyle,
  time: {
    color: '#999',
    fontSize: 10,
    marginTop: 8,
  } as TextStyle,
});
