import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import numeral from 'numeral';

import ActorInfo from './ActorInfo';
import EditButton from './EditButton';

import PreviewButton from '@/components/ui/buttons/PreviewButton';

import Actor from '@/entities/Actor';

interface Props {
  actor: Actor;
}

const ActorSection: React.FC<Props> = props => {
  const {actor} = props;
  return (
    <View style={styles.container}>
      <ActorInfo actor={actor} />
      <View style={styles.right}>
        <View style={styles.price}>
          {actor.price && (
            <Text style={{color: '#222', fontSize: 12, fontWeight: 'bold'}}>
              ￥{numeral(actor.price).format('0,0')}
            </Text>
          )}
        </View>
        <View style={styles.buttons}>
          <PreviewButton
            style={styles.previewButton}
            url={`/actors/${actor.id}/preview`}
          />
          <EditButton
            style={styles.editButton}
            url={`/actors/${actor.id}/edit`}
          />
        </View>
      </View>
    </View>
  );
};

export default React.memo(ActorSection);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    padding: 16,
    flexDirection: 'row',
  } as ViewStyle,
  right: {
    flex: 1,
    alignItems: 'flex-end',
  } as ViewStyle,
  price: {
    flex: 1,
  } as ViewStyle,
  priceValue: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  buttons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
  } as ViewStyle,
  previewButton: {} as ViewStyle,
  editButton: {
    marginLeft: 10,
  } as ViewStyle,
});
