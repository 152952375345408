import React from 'react';
import {LightBadge} from '@/components/ui/badges';

const OptionalBadge: React.FC = () => (
  <LightBadge style={style}>任意</LightBadge>
);

export default React.memo(OptionalBadge);

const style = {
  marginLeft: 5,
};
