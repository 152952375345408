import RootStack from '../RootStack';

import TemporaryUserRegistrationCompletion from '@/pages/temporary_user/registration_completion';
import UserSignIn from '@/pages/users/sign_in';
import UserSignUp from '@/pages/users/sign_up';
import UserPasswordNew from '@/pages/users/password/new';
import UserPasswordEdit from '@/pages/users/password/edit';
import UserConfirmationNew from '@/pages/users/confirmation/new';

const TemporaryMemberOnlyScreens = (
  <>
    <RootStack.Screen
      name={'TemporaryUserRegistrationCompletion'}
      component={TemporaryUserRegistrationCompletion}
      options={{title: '仮会員登録完了'}}
    />
    <RootStack.Screen
      name={'UserSignIn'}
      component={UserSignIn}
      options={{title: 'ログイン'}}
    />
    <RootStack.Screen
      name={'UserSignUp'}
      component={UserSignUp}
      options={{title: '会員登録'}}
    />
    <RootStack.Screen
      name={'UserPasswordNew'}
      component={UserPasswordNew}
      options={{title: 'パスワード再設定'}}
    />
    <RootStack.Screen
      name={'UserPasswordEdit'}
      component={UserPasswordEdit}
      options={{title: 'パスワード再設定'}}
    />
    <RootStack.Screen
      name={'UserConfirmationNew'}
      component={UserConfirmationNew}
      options={{title: 'アカウント認証メール再送'}}
    />
  </>
);

export default TemporaryMemberOnlyScreens;
