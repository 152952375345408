import useSocialConnect from './useSocialConnect';

import LineLogin from '@/services/social_logins/LineLogin';

const useLineConnect = (callback?: () => void) => {
  return useSocialConnect(
    new LineLogin(),
    '/api/creator/social_accounts/line_accounts/registration',
    'lineAccount',
    callback,
  );
};

export default useLineConnect;
