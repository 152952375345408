import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import dateFormat from 'dateformat';
import numeral from 'numeral';

import Label from '@/components/content/Label';

import Actor, {Status} from '@/entities/Actor';

interface Props {
  actor: Actor;
  visibleStatusUpdateDate?: boolean;
  displayPrice?: boolean;
}

const ActorInfo: React.FC<Props> = props => {
  const {actor, visibleStatusUpdateDate, displayPrice} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.name}>{actor.name}</Text>
      <Text style={styles.text}>{actor.defaultCostumeName}</Text>
      <Text style={styles.text}>表情差分：{actor.faceCount}</Text>
      <Text style={styles.text}>
        反転機能：
        {actor.enabled
          ? `使用${actor.options.allowedInversion ? '' : '不'}可`
          : '---'}
      </Text>
      {displayPrice && actor.price && (
        <Text style={styles.price}>￥{numeral(actor.price).format('0,0')}</Text>
      )}
      <View style={styles.dates}>
        <Text style={styles.updatedAt}>
          {dateFormat(actor.updatedAt, 'yyyy.mm.dd')} 更新
        </Text>
        {statusToLabel[actor.status]}
        {visibleStatusUpdateDate && actor.status !== 'draft' ? (
          <Text style={styles.publishedAt}>
            {dateFormat(actor.statusUpdatedAt, 'yyyy.mm.dd')}{' '}
            {statusToDateText[actor.status]}
          </Text>
        ) : null}
      </View>
    </View>
  );
};

export default React.memo(ActorInfo);

const statusToLabel: {[key in Status]: React.ReactElement<any, any>} = {
  draft: <Label color={'#383838'}>非公開</Label>,
  published: <Label color={'#f5c71c'}>公開</Label>,
  reviewing: <Label color={'#f23406'}>審査中</Label>,
  on_sale: <Label color={'#2e83f2'}>販売中</Label>,
};

const statusToDateText: {[key in Status]: string} = {
  draft: '',
  published: '公開',
  reviewing: '申請',
  on_sale: '販売',
};

const date = {
  color: '#666',
  fontSize: 10,
} as TextStyle;

const styles = StyleSheet.create({
  container: {
    marginLeft: 11,
    justifyContent: 'center',
  } as ViewStyle,
  name: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 6,
  } as TextStyle,
  text: {
    color: '#383838',
    fontSize: 10,
    marginVertical: 2,
  } as TextStyle,
  price: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 5,
  } as TextStyle,
  dates: {
    marginTop: 6,
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  updatedAt: {
    ...date,
    marginRight: 10,
  } as TextStyle,
  publishedAt: {
    ...date,
    marginLeft: 5,
  } as TextStyle,
});
