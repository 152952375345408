import React from 'react';

import CreatorSection from './components/CreatorSection';
import MenuList from './components/MenuList';

import {Layout} from '@/components/layout';

import Balance from '@/entities/Balance';

import useCurrentUser from '@/hooks/useCurrentUser';
import {useGet} from '@/hooks/swr';

export interface Params {}

const Page = () => {
  const {data: balance} = useGet<Balance>(`/api/creator/balance`);
  const {currentUser} = useCurrentUser();
  return (
    <Layout title={'マイメニュー'} close={true}>
      {currentUser && balance ? (
        <>
          <CreatorSection currentUser={currentUser} balance={balance} />
          <MenuList />
        </>
      ) : null}
    </Layout>
  );
};

export default React.memo(Page);
