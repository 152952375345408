import React from 'react';

import Form, {Inputs} from './components/Form';

import {Layout} from '@/components/layout';

import {buildFieldErrors} from '@/helpers/FormHelper';

import {useCreateMutation} from '@/hooks/swr';
import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import {mutateActor} from '@/helpers/swr/MutateHelper';
import {sendDefaultCustomEventAndParticularEvent} from '@/helpers/AnalyticsHelper';

import ActorCharacter from '@/entities/ActorCharacter';

export interface Params {}

const Page = () => {
  const defaultNavigation = useDefaultNavigation();
  const {trigger, isMutating, error} = useCreateMutation<ActorCharacter>(
    '/api/creator/actor_characters',
  );
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(actorCharacter => {
      if (actorCharacter) {
        defaultNavigation.replace('Actor', {id: actorCharacter.actorId});
        mutateActor({id: actorCharacter.actorId});
        sendDefaultCustomEventAndParticularEvent({
          resourceType: 'actor',
          resourceId: actorCharacter.actorId,
          actionName: 'create',
        });
      }
    });
  }, []);
  return (
    <Layout title={'イラスト登録'} back={true} loading={isMutating}>
      <Form
        isNew={true}
        errors={convertResponseToErrors(error)}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {actorCharacter: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    image: [
      'image',
      'miniMagickImageValid',
      'miniMagickImageWidth',
      'miniMagickImageHeight',
      'miniMagickImageType',
      'miniMagickImageDataType',
    ],
    name: 'name',
    costumeName: ['costumeName', 'thisName'],
    faceName: 'faceName',
    faceNameOther: 'faceNameOther',
  });
};
