import React from 'react';

import Icon from '../Icon';

interface Props {
  size?: number;
  color?: string;
}

const LineIcon: React.FC<Props> = props => {
  const size = props.size || 20;
  const color = props.color || '#06C755';
  return <Icon name={'line'} size={size} color={color} />;
};

export default React.memo(LineIcon);
