import React from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  StyleSheet,
  ViewStyle,
  Platform,
} from 'react-native';

import AnnouncementCategoryListItem from './AnnouncementCategoryListItem';

import {ListItemRightSeparator} from '@/components/ui/lists/separators';

import AnnouncementCategory from '@/entities/AnnouncementCategory';

interface Props {
  announcementCategories: AnnouncementCategory[];
  selectedAnnouncementCategory: AnnouncementCategory;
  onSelectAnnouncementCategory: (
    announcementCategory: AnnouncementCategory,
  ) => void;
}

const AnnouncementCategoryList: React.FC<Props> = props => {
  const {
    announcementCategories,
    selectedAnnouncementCategory,
    onSelectAnnouncementCategory,
  } = props;
  const keyExtractor = React.useCallback(
    (announcementCategory: AnnouncementCategory, index: number): string =>
      `${announcementCategory.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (
      info: ListRenderItemInfo<AnnouncementCategory>,
    ): React.ReactElement<any> | null => {
      const {item: announcementCategory} = info;
      return (
        <AnnouncementCategoryListItem
          announcementCategory={announcementCategory}
          checked={selectedAnnouncementCategory.id === announcementCategory.id}
          onSelectAnnouncementCategory={onSelectAnnouncementCategory}
        />
      );
    },
    [],
  );
  return (
    <FlatList
      scrollEnabled={Platform.select({web: true, default: false})}
      contentContainerStyle={styles.container}
      data={announcementCategories}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemRightSeparator}
    />
  );
};

export default React.memo(AnnouncementCategoryList);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    elevation: 1, // Android
    shadowColor: 'black', // iOS
    shadowOffset: {width: 0, height: 3}, // iOS
    shadowOpacity: 0.2, // iOS
    shadowRadius: 2, // iOS
  } as ViewStyle,
});
