import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import {ControlledDatePeriodSelect} from '@/components/form';
import {DatePeriod} from '@/components/value/types';

export type Inputs = {
  period: DatePeriod;
};

interface Props {
  defaultValues?: Inputs;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {defaultValues, errors, onSubmit} = props;
  const {control, watch, handleSubmit} = useForm({
    defaultValues: defaultValues || ({period: 'all'} as Inputs),
  });
  const onPress = handleSubmit(onSubmit);
  React.useEffect(() => {
    onPress();
  }, [watch()]);

  return (
    <View style={styles.container}>
      <ControlledDatePeriodSelect
        pickerStyle={{backgroundColor: '#fafafa'}}
        controllerProps={{
          control,
          name: 'period',
        }}
        errors={errors}
      />
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: '#fafafa',
  } as ViewStyle,
});
