import React from 'react';
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

interface Props {
  icon: React.ReactElement<any, any>;
  label: string;
  connected: boolean;
  onPress?: null | ((event: GestureResponderEvent) => void) | undefined;
}

const OauthAccountItem: React.FC<Props> = props => {
  const {icon, label, connected, onPress} = props;
  return (
    <Pressable style={styles.container} onPress={onPress}>
      {icon}
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.value}>{connected ? '連携済' : ''}</Text>
    </Pressable>
  );
};

export default React.memo(OauthAccountItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 15,
    alignItems: 'center',
    width: '100%',
  } as ViewStyle,
  label: {
    flex: 1,
    color: '#383838',
    fontSize: 14,
    paddingHorizontal: 16,
  } as TextStyle,
  value: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
});
