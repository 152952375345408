import React from 'react';

import NotificationList from './components/NotificationList';

import {Layout} from '@/components/layout';

import {useGetMutation} from '@/hooks/swr';

import UserNotification from '@/entities/UserNotification';

export interface Params {}

const Page = () => {
  const {data: userNotifications, trigger} = useGetMutation<UserNotification[]>(
    '/api/creator/user_notifications',
  );
  React.useLayoutEffect(() => {
    trigger();
  }, []);
  return (
    <Layout title={'通知'} close={true} scrollable={false}>
      {userNotifications && (
        <NotificationList userNotifications={userNotifications} />
      )}
    </Layout>
  );
};

export default React.memo(Page);
