import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import ActorAvatorImage from '@/components/utils/ActorAvatorImage';
import {OutlineButton} from '@/components/ui/buttons';

import Actor from '@/entities/Actor';

interface Props {
  actor: Actor;
}

const FormActorAvatorImage: React.FC<Props> = props => {
  const {actor} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(`/actors/${actor.id}/face_center_position/edit`);
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <OutlineButton
          style={styles.button}
          textProps={textProps}
          onPress={onPress}>
          画像位置の調整
        </OutlineButton>
      </View>
      <ActorAvatorImage actor={actor} size={112} />
    </View>
  );
};

export default React.memo(FormActorAvatorImage);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
  header: {
    width: '100%',
    alignItems: 'flex-end',
    padding: 16,
    paddingBottom: 8,
  } as ViewStyle,
  button: {
    width: 112,
    height: 28,
  } as ViewStyle,
});

const textProps = {style: {fontSize: 12}};
