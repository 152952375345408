import React from 'react';

import Form, {Inputs} from './comonents/Form';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import BankAccount from '@/entities/BankAccount';

import {buildFieldErrors} from '@/helpers/FormHelper';
import {useGet, useUpdateMutation} from '@/hooks/swr';
import {get} from '@/services/fetchers';
import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

export interface Params {
  from?: 'transfer';
}

const Page = (props: ScreenProps<'SettingsBankAccount'>) => {
  const {route} = props;
  const {from} = route.params || {};
  const navigation = useDefaultNavigation();
  const {data, isLoading} = useGet<BankAccount | null>(
    '/api/creator/bank_account',
  );
  const {trigger, isMutating, error} = useUpdateMutation<BankAccount>(
    '/api/creator/bank_account',
  );
  const defaultValues = React.useMemo(
    () => convertFormRecordToDefaultValues(data),
    [data],
  );
  const isNew = !data;
  const onSubmit = React.useCallback(
    (data: Inputs) => {
      trigger(convertFormValuesToPostParams(data)).then(bankAccount => {
        if (bankAccount) {
          if (from === 'transfer') {
            get('/api/creator/qualified_invoice_issuer').then(
              qualifiedInvoiceIssuer => {
                if (qualifiedInvoiceIssuer) {
                  navigation.replace('TransferRequestNew', {});
                } else {
                  navigation.replace('SettingsQualifiedInvoiceIssuer', {from});
                }
              },
            );
          } else {
            navigation.goBack();
          }
          sendDefaultCustomEvent({
            resourceType: 'bank_account',
            resourceId: bankAccount.id,
            actionName: isNew ? 'create' : 'update',
          });
        }
      });
    },
    [isNew],
  );
  return (
    <Layout title={'受取口座設定'} back={true} loading={isMutating}>
      {!isLoading && (
        <Form
          defaultValues={defaultValues}
          errors={convertResponseToErrors(error)}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {bankAccount: formValues};
};

const convertFormRecordToDefaultValues = (
  record?: BankAccount | null,
): Inputs | undefined => {
  if (!record) {
    return undefined;
  }
  return {
    bankName: record.bankName,
    bankBranchName: record.bankBranchName,
    accountType: record.accountType,
    accountNumber: record.accountNumber,
    accountHolderName: record.accountHolderName,
  };
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    bankName: ['bankName', 'bank'],
    bankBranchName: ['bankBranchName', 'bankBranch'],
    accountType: 'accountType',
    accountNumber: 'accountNumber',
    accountHolderName: 'accountHolderName',
  });
};
