import React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import ActorSection from './components/ActorSection';
import PublishButton from './components/PublishButton';
import ActorCharacterFaceList from '@/pages/actor_character_faces/components/ActorCharacterFaceList';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import Actor from '@/entities/Actor';
import ActorCharacterFace from '@/entities/ActorCharacterFace';

import {useGet} from '@/hooks/swr';

export interface Params {
  id: number;
}

const Page = (props: ScreenProps<'Actor'>) => {
  const {route} = props;
  const {id} = route.params;
  const mounted = React.useRef(false);
  const {
    data: actor,
    error,
    mutate: mutateActor,
  } = useGet<Actor>(`/api/creator/actors/${id}`, {}, {keepPreviousData: true});
  const {data: actorCharacterFaces, mutate: mutateActorCharacterFace} = useGet<
    ActorCharacterFace[]
  >(
    `/api/creator/actor_character_faces?actor_id=${id}`,
    {},
    {keepPreviousData: true},
  );
  useFocusEffect(
    React.useCallback(() => {
      if (!mounted.current) {
        return;
      }
      mutateActor();
      mutateActorCharacterFace();
    }, []),
  );
  React.useEffect(() => {
    mounted.current = true;
  }, []);
  const rightButton = React.useMemo(
    () => ({
      children: (
        <PublishButton
          url={`/actors/${id}/categories/edit`}
          disabled={!actor || actor?.actorCharacter?.faceCount === 0}
        />
      ),
    }),
    [actor],
  );
  return (
    <Layout
      title={'表情差分一覧'}
      back={true}
      rightButton={rightButton}
      scrollable={false}
      error={error}>
      {actor && actor.actorCharacter && actorCharacterFaces ? (
        <>
          <ActorSection actor={actor} />
          <ActorCharacterFaceList
            actor={actor}
            actorCharacterId={actor.actorCharacter.id}
            actorCharacterFaces={actorCharacterFaces}
          />
        </>
      ) : null}
    </Layout>
  );
};

export default React.memo(Page);
