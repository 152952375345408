import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo, useFocusEffect} from '@react-navigation/native';
import numeral from 'numeral';

import BalanceSection from '../balance/components/BalanceSection';

import {Layout} from '@/components/layout';
import {PrimaryButton} from '@/components/ui/buttons';

import Balance from '@/entities/Balance';
import TransferRequest from '@/entities/TransferRequest';

import {useGet} from '@/hooks/swr';
import {get} from '@/services/fetchers';

export interface Params {}

const Page = () => {
  const linkTo = useLinkTo();
  const {data: balance} = useGet<Balance>(
    `/api/creator/balance`,
    {},
    {revalidateOnMount: true},
  );
  const [transferRequest, setTransferRequest] =
    React.useState<TransferRequest | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      get<TransferRequest>('/api/creator/transfer_request').then(
        setTransferRequest,
      );
    }, []),
  );
  const onPress = React.useCallback(() => {
    get('/api/creator/bank_account').then(bankAccount => {
      if (bankAccount) {
        get('/api/creator/qualified_invoice_issuer').then(
          qualifiedInvoiceIssuer => {
            if (qualifiedInvoiceIssuer) {
              linkTo('/transfer/requests/new');
            } else {
              linkTo('/settings/qualified_invoice_issuer');
            }
          },
        );
      } else {
        linkTo('/settings/bank_account?from=transfer');
      }
    });
  }, []);
  return (
    <Layout title={'振込申請'} back={true} containerStyle={styles.container}>
      {balance && transferRequest ? (
        <>
          <BalanceSection balance={balance} />
          <View style={styles.button}>
            <PrimaryButton
              disabled={!transferRequest.available}
              onPress={onPress}>
              {transferRequest.requested ? '申請中' : '振込申請する'}
            </PrimaryButton>
            <Text style={styles.note}>
              ※振込申請は￥
              {numeral(transferRequest.amountMin).format('0,0')}
              から行うことができます。
            </Text>
          </View>
        </>
      ) : null}
    </Layout>
  );
};

export default React.memo(Page);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
  } as ViewStyle,
  button: {
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  note: {
    color: '#383838',
    fontSize: 11,
    marginTop: 16,
  } as TextStyle,
});
