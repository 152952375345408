import React from 'react';

import Form, {Inputs} from './components/Form';
import SignInButton from './components/SignInButton';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import useCurrentUserRegistration from '@/hooks/useCurrentUserRegistration';
import {buildFieldErrors} from '@/helpers/FormHelper';

export interface Params {
  penName?: string;
  email?: string;
  options?: {
    facebookAccount?: {
      accessToken: string;
      userId: string;
      screenName: string;
    };
    lineAccount?: {
      accessToken: string;
      userId: string;
      screenName: string;
    };
    twitterAccount?: {
      accessToken: string;
      accessTokenSecret: string;
      userId: string;
      screenName: string;
    };
  };
}

const Page = (props: ScreenProps<'UserSignUp'>) => {
  const {route} = props;
  const {
    create,
    createResponse: {isMutating, error},
  } = useCurrentUserRegistration();
  const onSubmit = React.useCallback((data: Inputs) => {
    create(convertFormValuesToPostParams(data, route.params));
  }, []);
  return (
    <Layout
      title={'会員登録'}
      close={true}
      hideHeaderBottomBorder={true}
      loading={isMutating}>
      <Form errors={convertResponseToErrors(error)} onSubmit={onSubmit} />
      <SignInButton />
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs, params: Params) => {
  const {options} = params || {};
  const {facebookAccount, twitterAccount, lineAccount} = options || {};
  return {
    apiCreatorUser: {
      ...formValues,
      facebookAccount,
      twitterAccount,
      lineAccount,
    },
  };
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    email: 'email',
    password: 'password',
    passwordConfirmation: 'passwordConfirmation',
  });
};
