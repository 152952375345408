import React from 'react';
import {FieldPathValue, FieldValues, FieldPath} from 'react-hook-form';

import FormWithTags from './FormWithTags';

import {Layout, RightButton} from '@/components/layout';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  values?: FieldPathValue<TFieldValues, TName>;
  onSubmit: (value: FieldPathValue<TFieldValues, TName>) => void;
  onRequestClose: () => void;
}

function Keywords<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {onSubmit, onRequestClose} = props;
  const [values, setValues] = React.useState<string[]>(
    (props.values as string[]) || [],
  );
  const onPress = React.useCallback(() => {
    onSubmit(values as FieldPathValue<TFieldValues, TName>);
  }, [values]);
  const rightButton = React.useMemo(
    () => ({
      children: <RightButton onPress={onPress}>決定</RightButton>,
    }),
    [onPress],
  );
  const onCreate = React.useCallback(
    (value: string) => {
      setValues([...new Set([...values, value])]);
    },
    [values],
  );
  const onRemove = React.useCallback(
    (value: string) => {
      setValues(values.filter(v => v !== value));
    },
    [values],
  );
  return (
    <Layout
      title={'キーワード'}
      scrollable={false}
      back={true}
      onPressBack={onRequestClose}
      rightButton={rightButton}>
      <FormWithTags values={values} onCreate={onCreate} onRemove={onRemove} />
    </Layout>
  );
}

export default React.memo(Keywords) as typeof Keywords;
