import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

interface Props extends React.PropsWithChildren {
  color: string;
  onPress?: () => void;
}

const SocialShareButton: React.FC<Props> = props => {
  const {color, children, onPress} = props;
  const content = <View style={styles.container}>{children}</View>;
  return onPress ? (
    <TouchableOpacity onPress={onPress}>{content}</TouchableOpacity>
  ) : (
    content
  );
};

export default React.memo(SocialShareButton);

export const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: 48,
    justifyContent: 'center',
    width: 48,
  },
});
