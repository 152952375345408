import React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
}

const CheckCircleOIcon: React.FC<Props> = props => {
  const size = props.size || 20;
  const color = props.color || '#D5D5D5';
  return <Icon name={'check-circle-o'} size={size} color={color} />;
};

export default React.memo(CheckCircleOIcon);
