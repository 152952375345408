export default class DefaultCustomEventHistory {
  public static has(value: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const hash = JSON.parse(
        localStorage.getItem(DefaultCustomEventHistory.KEY) || '{}',
      );
      resolve(!!hash[value]);
    });
  }

  public static set(value: string): Promise<void> {
    return new Promise(resolve => {
      const hash = JSON.parse(
        localStorage.getItem(DefaultCustomEventHistory.KEY) || '{}',
      );
      hash[value] = true;
      localStorage.setItem(DefaultCustomEventHistory.KEY, JSON.stringify(hash));
      resolve();
    });
  }

  private static KEY = 'event_history';
}
