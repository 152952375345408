import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import saleUri from '@/assets/images/tutorial/sale.png';

import {PrimaryButton, CheckButton} from '@/components/ui/buttons';

interface Props {
  onPress: () => void;
}

const Step2: React.FC<Props> = props => {
  const {onPress} = props;
  const [checked, setChecked] = React.useState(false);
  const toggoleChecked = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        登録したイラストは、TapNovelMakerを利用する全国のタップライターに販売することができます。
      </Text>
      <Image source={saleUri} style={styles.image} />
      <Text style={styles.note}>
        TapNovelCreatorでは、TapNovelMakerでの{'\n'}
        イラスト使用権の販売となるため、{'\n'}
        著作権の譲渡は発生いたしません。
      </Text>
      <View style={styles.check}>
        <CheckButton checked={checked} onPress={toggoleChecked}>
          理解した
        </CheckButton>
      </View>
      <PrimaryButton disabled={!checked} onPress={onPress}>
        次へ
      </PrimaryButton>
    </View>
  );
};

export default React.memo(Step2);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    margin: 24,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    marginBottom: 24,
  } as TextStyle,
  note: {
    color: '#383838',
    fontSize: 12,
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 32,
  } as TextStyle,
  image: {
    width: 215,
    height: 260,
  } as ImageStyle,
  check: {
    width: 160,
    marginBottom: 16,
  } as ViewStyle,
});
