import {modalScreenOptions} from '../ScreenOptions';
import RootStack from '../RootStack';

import UserEdit from '@/pages/user/edit';
import UserCancel from '@/pages/user/cancel';
import ActorCategoriesEdit from '@/pages/actor_categories/edit';
import ActorNew from '@/pages/actors/new';
import Actor from '@/pages/actors/show';
import ActorFaceCenterPositionEdit from '@/pages/actors/face_center_position/edit';
import ActorEdit from '@/pages/actors/edit';
import ActorPreview from '@/pages/actors/preview/show';
import ActorOgpImage from '@/pages/actors/ogp_images/show';
import ActorCharacterFaceNew from '@/pages/actor_character_faces/new';
import ActorCharacterFaceEdit from '@/pages/actor_character_faces/edit';
import ActorProduct from '@/pages/actor_products/show';
import ActorProductReleaseRequest from '@/pages/actor_product_release_request/show';
import ActorProductReleaseRequestComplete from '@/pages/actor_product_release_request/complete';
import Menu from '@/pages/menu';
import Notifications from '@/pages/notifications';
import Revenue from '@/pages/revenue';
import RevenueActor from '@/pages/revenue/actors/show';
import Transfer from '@/pages/transfer';
import TransferRequestNew from '@/pages/transfer/requests/new';
import TransferRequestComplete from '@/pages/transfer/requests/complete';
import Balance from '@/pages/balance';
import Settings from '@/pages/settings';
import SettingsNotifications from '@/pages/settings/notifications';
import SettingsAccount from '@/pages/settings/account';
import SettingsAccountEmailEdit from '@/pages/settings/account/email/edit';
import SettingsAccountPasswordEdit from '@/pages/settings/account/password/edit';
import SettingsQualifiedInvoiceIssuer from '@/pages/settings/qualified_invoice_issuer';
import SettingsBankAccount from '@/pages/settings/bank_account';
import SettingsTransferHistory from '@/pages/settings/transfer_history';
import SettingsPoliciesPrivacy from '@/pages/policies/privacy';
import SettingsPoliciesSettlementLaw from '@/pages/policies/settlement_law';
import SettingsPoliciesSpecifiedCommercialTransactionLaw from '@/pages/policies/specified_commercial_transaction_law';
import SettingsPoliciesTerms from '@/pages/policies/terms';
import ContactNew from '@/pages/contacts/new';

import Announcements from '@/pages/announcements';
import Announcement from '@/pages/announcements/show';

const MemberOnlyScreens = (
  <>
    <RootStack.Screen
      name={'UserCancel'}
      component={UserCancel}
      options={{title: '退会'}}
    />
    <RootStack.Screen
      name={'ActorNew'}
      component={ActorNew}
      options={{title: 'イラスト登録'}}
    />
    <RootStack.Screen
      name={'Actor'}
      component={Actor}
      options={{title: 'イラスト'}}
    />
    <RootStack.Screen
      name={'ActorEdit'}
      component={ActorEdit}
      options={{title: 'イラスト情報'}}
    />
    <RootStack.Screen
      name={'ActorCharacterFaceNew'}
      component={ActorCharacterFaceNew}
      options={{title: '表情差分の追加'}}
    />
    <RootStack.Screen
      name={'ActorCharacterFaceEdit'}
      component={ActorCharacterFaceEdit}
      options={{title: '表情情報'}}
    />
    <RootStack.Group screenOptions={modalScreenOptions}>
      <RootStack.Screen
        name={'ActorFaceCenterPositionEdit'}
        component={ActorFaceCenterPositionEdit}
        options={{title: '画像位置の調整'}}
      />
      <RootStack.Screen
        name={'ActorCategoriesEdit'}
        component={ActorCategoriesEdit}
        options={{title: 'イラスト情報'}}
      />
      <RootStack.Screen
        name={'ActorPreview'}
        component={ActorPreview}
        options={{title: 'プレビュー'}}
      />
      <RootStack.Screen
        name={'ActorOgpImage'}
        component={ActorOgpImage}
        options={{title: '通知'}}
      />
      <RootStack.Screen
        name={'ActorProduct'}
        component={ActorProduct}
        options={{title: 'イラストの販売設定'}}
      />
      <RootStack.Screen
        name={'ActorProductReleaseRequest'}
        component={ActorProductReleaseRequest}
        options={{title: 'イラストの公開設定'}}
      />
      <RootStack.Screen
        name={'ActorProductReleaseRequestComplete'}
        component={ActorProductReleaseRequestComplete}
        options={{title: '公開申請完了'}}
      />
      <RootStack.Screen
        name={'UserEdit'}
        component={UserEdit}
        options={{title: 'プロフィール'}}
      />
      <RootStack.Screen
        name={'Menu'}
        component={Menu}
        options={{title: 'マイメニュー'}}
      />
      <RootStack.Screen
        name={'Notifications'}
        component={Notifications}
        options={{title: '通知'}}
      />
      <RootStack.Screen
        name={'TransferRequestComplete'}
        component={TransferRequestComplete}
        options={{title: '振込申請'}}
      />
    </RootStack.Group>
    <RootStack.Screen
      name={'Revenue'}
      component={Revenue}
      options={{title: '収益管理'}}
    />
    <RootStack.Screen
      name={'RevenueActor'}
      component={RevenueActor}
      options={{title: '収益管理'}}
    />
    <RootStack.Screen
      name={'Transfer'}
      component={Transfer}
      options={{title: '振込申請'}}
    />
    <RootStack.Screen
      name={'TransferRequestNew'}
      component={TransferRequestNew}
      options={{title: '振込申請'}}
    />
    <RootStack.Screen
      name={'Balance'}
      component={Balance}
      options={{title: '残高履歴'}}
    />
    <RootStack.Screen
      name={'Settings'}
      component={Settings}
      options={{title: '設定'}}
    />
    <RootStack.Screen
      name={'SettingsNotifications'}
      component={SettingsNotifications}
      options={{title: '通知設定'}}
    />
    <RootStack.Screen
      name={'SettingsAccount'}
      component={SettingsAccount}
      options={{title: 'アカウント設定'}}
    />
    <RootStack.Screen
      name={'SettingsAccountEmailEdit'}
      component={SettingsAccountEmailEdit}
      options={{title: 'メールアドレスの変更'}}
    />
    <RootStack.Screen
      name={'SettingsAccountPasswordEdit'}
      component={SettingsAccountPasswordEdit}
      options={{title: 'パスワードの変更'}}
    />
    <RootStack.Screen
      name={'SettingsQualifiedInvoiceIssuer'}
      component={SettingsQualifiedInvoiceIssuer}
      options={{title: '消費税事業者設定'}}
    />
    <RootStack.Screen
      name={'SettingsBankAccount'}
      component={SettingsBankAccount}
      options={{title: '受取口座設定'}}
    />
    <RootStack.Screen
      name={'SettingsTransferHistory'}
      component={SettingsTransferHistory}
      options={{title: '振込履歴'}}
    />
    <RootStack.Screen
      name={'SettingsPoliciesPrivacy'}
      component={SettingsPoliciesPrivacy}
      options={{title: 'プライバシーポリシー'}}
    />
    <RootStack.Screen
      name={'SettingsPoliciesSettlementLaw'}
      component={SettingsPoliciesSettlementLaw}
      options={{title: '資金決済法に基づく表示'}}
    />
    <RootStack.Screen
      name={'SettingsPoliciesSpecifiedCommercialTransactionLaw'}
      component={SettingsPoliciesSpecifiedCommercialTransactionLaw}
      options={{title: '特定商取引法に基づく表示'}}
    />
    <RootStack.Screen
      name={'SettingsPoliciesTerms'}
      component={SettingsPoliciesTerms}
      options={{title: '利用規約'}}
    />
    <RootStack.Screen
      name={'ContactNew'}
      component={ContactNew}
      options={{title: 'お問い合わせ'}}
    />
    <RootStack.Screen
      name={'Announcements'}
      component={Announcements}
      options={{title: 'お知らせ'}}
    />
    <RootStack.Screen
      name={'Announcement'}
      component={Announcement}
      options={{title: 'お知らせ'}}
    />
  </>
);

export default MemberOnlyScreens;
