import React from 'react';
import {StyleProp, TextStyle} from 'react-native';

import {createIconSetFromIcoMoon} from 'react-native-vector-icons';

import customFontIcon from '@/assets/fonts/selection.json';

const IcoMoon = createIconSetFromIcoMoon(customFontIcon);

interface Props {
  name: string;
  style?: StyleProp<TextStyle> | any;
  size?: number;
  color?: string;
}

const Icon: React.FC<Props> = props => {
  const {name, style} = props;
  const size = props.size || 15;
  const color = props.color || '#383838';
  return <IcoMoon name={name} style={style} size={size} color={color} />;
};

export default React.memo(Icon);
