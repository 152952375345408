import React from 'react';
import {
  ImageStyle,
  ImageURISource,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import ImagePickerAdapter from './ImagePickerAdapter';

import SvgUriAdapter from '@/components/utils/SvgUriAdapter';

import iconNoimageCreatorUri from '@/assets/svgs/icon-noimage-creator.svg';

interface Props {
  source: ImageURISource | ImageURISource[] | null;
  imageIconStyle?: StyleProp<ViewStyle>;
  onChangeImage?: (file: File) => void;
  renderNoImage?: () => React.ReactNode;
}

const AvatorPicker: React.FC<Props> = props => {
  const {source, imageIconStyle, onChangeImage, renderNoImage} = props;
  return (
    <ImagePickerAdapter
      imageSource={source}
      imageStyle={styles.image}
      imageContainerStyle={styles.imageContainer}
      imageIconStyle={imageIconStyle}
      onChangeImage={onChangeImage}
      noImage={
        <View style={styles.noImage}>
          {renderNoImage ? (
            renderNoImage()
          ) : (
            <View style={styles.noImageAvator}>
              <SvgUriAdapter
                uri={iconNoimageCreatorUri}
                width={100}
                height={100}
              />
            </View>
          )}
        </View>
      }
      visibleImageIcon={true}
    />
  );
};

export default React.memo(AvatorPicker);

const styles = StyleSheet.create({
  image: {
    backgroundColor: '#efefef',
    borderColor: '#efefef',
    borderWidth: 1,
    borderRadius: 50,
    height: 100,
    width: 100,
  } as ImageStyle,
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  noImage: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  noImageAvator: {
    overflow: 'hidden',
    width: 100,
    height: 100,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
