import React from 'react';
import {
  Image,
  ImageProps,
  StyleSheet,
  Text,
  TextStyle,
  View,
} from 'react-native';

import useApplicationModal from './useApplicationModal';

export interface Props extends React.PropsWithChildren {
  title: React.ReactNode;
  description: string;
  imageProps?: ImageProps;
  onPressOK?: () => void;
}

const useTooltipModal = () => {
  const applicationModal = useApplicationModal();
  const show = React.useCallback((props: Props) => {
    const {title, description, imageProps, onPressOK} = props;
    applicationModal.show({
      title,
      children: (
        <View>
          <Text style={imageProps ? styles.contentWithImage : styles.content}>
            {description}
          </Text>
          {imageProps && <Image {...imageProps} />}
        </View>
      ),
      animationType: 'none',
      dialogAnimationProps: {
        scale: true,
        opacity: true,
      },
      onPressOK,
    });
  }, []);
  return {show};
};

export default useTooltipModal;

const content = {
  color: '#222',
  fontSize: 12,
} as TextStyle;

const styles = StyleSheet.create({
  content,
  contentWithImage: {
    ...content,
    marginBottom: 16,
  } as TextStyle,
});
