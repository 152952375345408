import React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

import {CheckCircleOIcon} from '@/components/icons';

const EnteredLabel: React.FC = () => {
  return (
    <>
      <CheckCircleOIcon size={14} color={'#3baf49'} />
      <Text style={styles.text}>入力済み</Text>
    </>
  );
};

export default React.memo(EnteredLabel);

const styles = StyleSheet.create({
  text: {
    color: '#3baf49',
    fontSize: 10,
  } as TextStyle,
});
