import React from 'react';

import ApplicationModalContext from '@/contexts/ApplicationModalContext';
import {BasicModalProps} from '@/components/modals';

export type {BasicModalProps};

const useApplicationModal = () => {
  const applicationModalContext = React.useContext(ApplicationModalContext);
  const show = React.useCallback((modalProps: BasicModalProps) => {
    if (applicationModalContext.showModal) {
      applicationModalContext.showModal(modalProps);
    }
  }, []);
  return {show};
};

export default useApplicationModal;
