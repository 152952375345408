import React from 'react';

import CompleteMessage from './components/CompleteMessage';
import CloseButton from './components/CloseButton';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import TransferRequest from '@/entities/TransferRequest';

import useDefaultNavigation from '@/hooks/useDefaultNavigation';

import {useGet} from '@/hooks/swr';

export interface Params {}

const Page = (props: ScreenProps<'TransferRequestComplete'>) => {
  const navigation = useDefaultNavigation();
  const {data: transferRequest} = useGet<TransferRequest>(
    '/api/creator/transfer_request',
  );

  const onPress = React.useCallback(() => {
    navigation.pop();
  }, []);
  return (
    <Layout
      title={'振込申請'}
      scrollable={false}
      close={true}
      containerStyle={{backgroundColor: '#fafafa'}}>
      {transferRequest && <CompleteMessage transferRequest={transferRequest} />}
      <CloseButton onPress={onPress} />
    </Layout>
  );
};

export default React.memo(Page);
