import * as React from 'react';
import {
  Animated,
  PanResponder,
  PanResponderGestureState,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import Button from './Button';

import useForceUpdate from '@/hooks/useForceUpdate';

import {ItemValue, Item} from '@/components/value/types';

export interface Props<IValue extends ItemValue = ItemValue> {
  style?: StyleProp<ViewStyle>;
  width: number;
  items: Array<Item<IValue>>;
  disabled?: boolean;
  value?: IValue;
  onSelectItem: (item: Item<IValue>) => void;
}

function Switcher<IValue extends ItemValue = ItemValue>(props: Props<IValue>) {
  const {style, width, items, onSelectItem} = props;
  const forceUpdate = useForceUpdate();
  const translateXRef = React.useRef(new Animated.Value(0));
  const disabledRef = React.useRef(props.disabled);
  const isParentScrollDisabledRef = React.useRef(false);
  const [value, setValue] = React.useState(props.value);

  const selectedPosition = Math.max(
    items.findIndex(row => row.value === value),
    0,
  );
  const duration = 100;

  const currentItem =
    selectedPosition === undefined ? null : items[selectedPosition];
  const itemsLength = items.length;
  const switcherWidthRef = React.useRef(width / itemsLength - SWITCHER_PADDING);
  const posValueRef = React.useRef(switcherWidthRef.current * selectedPosition);
  const thresholdDistanceRef = React.useRef(width - switcherWidthRef.current);

  React.useEffect(() => setValue(props.value), [props.value]);
  React.useEffect(() => {
    disabledRef.current = props.disabled;
    forceUpdate();
  }, [props.disabled]);
  React.useEffect(() => {
    switcherWidthRef.current = width / itemsLength - SWITCHER_PADDING;
    posValueRef.current = switcherWidthRef.current * selectedPosition;
    thresholdDistanceRef.current = width - switcherWidthRef.current;
    forceUpdate();
    Animated.timing(translateXRef.current, {
      toValue: posValueRef.current,
      duration,
      useNativeDriver: true,
    }).start();
  }, [props.width]);
  const handleSelectItem = React.useCallback(
    (item: Item<IValue>, triggerSelectItem = true) => {
      const selectedPosition =
        items.findIndex(row => row.value === item.value) || 0;
      posValueRef.current = switcherWidthRef.current * selectedPosition;
      Animated.timing(translateXRef.current, {
        toValue: posValueRef.current,
        duration,
        useNativeDriver: true,
      }).start();
      setTimeout(() => setValue(item.value), 100);
      if (triggerSelectItem) {
        onSelectItem(item);
      }
    },
    [],
  );
  const moveInitialState = React.useCallback(() => {
    if (selectedPosition === undefined) {
      return;
    }
    handleSelectItem(items[selectedPosition], false);
  }, []);
  React.useEffect(() => {
    moveInitialState();
  }, []);
  const handlePanResponderRelease = React.useCallback(
    (gestureState: PanResponderGestureState) => {
      const finalValue = gestureState.dx + posValueRef.current;
      isParentScrollDisabledRef.current = false;
      const item = items.find((item, i) => {
        return (
          switcherWidthRef.current * (i - 0.5) <= finalValue &&
          finalValue < switcherWidthRef.current * (i + 0.5)
        );
      });
      if (item) {
        handleSelectItem(item);
      }
    },
    [],
  );
  const panResponder = React.useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponderCapture: () => true,

      onPanResponderGrant: () => {
        if (!isParentScrollDisabledRef.current) {
          isParentScrollDisabledRef.current = true;
        }
      },

      onPanResponderMove: (e, gestureState) => {
        if (disabledRef.current) {
          return;
        }
        const finalValue = gestureState.dx + posValueRef.current;
        if (finalValue >= 0 && finalValue <= thresholdDistanceRef.current)
          translateXRef.current.setValue(posValueRef.current + gestureState.dx);
      },

      onPanResponderTerminationRequest: (e, gestureState) => {
        if (disabledRef.current) {
          return true;
        }
        if (isParentScrollDisabledRef.current) {
          isParentScrollDisabledRef.current = false;
          handlePanResponderRelease(gestureState);
        }
        return true;
      },

      onPanResponderRelease: (e, gestureState) => {
        if (disabledRef.current) {
          return;
        }
        handlePanResponderRelease(gestureState);
      },

      onPanResponderTerminate: () => {},
      onShouldBlockNativeResponder: () => true,
    }),
  ).current;
  const currentItemStyle = React.useMemo(
    () => ({
      width: switcherWidthRef.current,
      transform: [{translateX: translateXRef.current}],
    }),
    [switcherWidthRef.current],
  );
  const disabled = disabledRef.current;
  return (
    <View style={[styles.container, style]}>
      {items.map((item, i) => (
        <Button
          key={i}
          item={item}
          disabled={disabled}
          last={items.length === i + 1}
          onSelectItem={handleSelectItem}
        />
      ))}
      {currentItem && (
        <Animated.View
          {...panResponder.panHandlers}
          style={[
            styles.switcher,
            currentItemStyle,
            disabled ? {borderColor: '#999999'} : null,
          ]}>
          <Button item={currentItem} checked={true} disabled={disabled} />
        </Animated.View>
      )}
    </View>
  );
}

export default React.memo(Switcher) as typeof Switcher;

const CONTAINER_MARGIN_VERTICAL = 7;

const SWITCHER_PADDING = 2;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderRadius: 4,
    height: 38,
    width: '100%',
    flexDirection: 'row',
    marginVertical: CONTAINER_MARGIN_VERTICAL,
    padding: 4,
  } as ViewStyle,
  switcher: {
    flexDirection: 'row',
    position: 'absolute',
    top: 4,
    left: SWITCHER_PADDING,
    bottom: 4,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    borderColor: '#f5c71c',
  },
});
