import React from 'react';
import {useNavigation} from '@react-navigation/native';

import Form, {Inputs} from './components/Form';

import {Layout} from '@/components/layout';

import useCurrentUser from '@/hooks/useCurrentUser';
import useCurrentUserRegistration from '@/hooks/useCurrentUserRegistration';
import useApplicationModal from '@/hooks/useApplicationModal';
import {buildFieldErrors} from '@/helpers/FormHelper';

export interface Params {}

const Page = () => {
  const navigation = useNavigation();
  const {currentUser, get} = useCurrentUser();
  React.useEffect(() => {
    get();
  }, []);
  const {show: showModal} = useApplicationModal();
  const {
    update,
    updateResponse: {isMutating, error},
  } = useCurrentUserRegistration();
  const onSubmit = React.useCallback((data: Inputs) => {
    update(convertFormValuesToPostParams(data)).then(res => {
      if (!res) {
        return;
      }
      navigation.goBack();
      showModal({
        title: '認証メールを送信しました',
        children:
          'メールに記載のＵＲＬをクリックして、変更を完了してください。',
      });
    });
  }, []);
  return (
    <Layout title={'メールアドレスの変更'} back={true} loading={isMutating}>
      {currentUser && (
        <Form
          currentUser={currentUser}
          errors={convertResponseToErrors(error)}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {
    apiCreatorUser: formValues,
  };
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    email: 'email',
  });
};
