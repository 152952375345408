import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PolicyLinks from './components/PolicyLinks';

import {Layout} from '@/components/layout';
import {PrimaryButton} from '@/components/ui/buttons';
import SvgUriAdapter from '@/components/utils/SvgUriAdapter';

import CurrentUser from '@/entities/CurrentUser';

import {useCreateMutation} from '@/hooks/swr';
import useCurrentUser from '@/hooks/useCurrentUser';

import logoUri from '@/assets/svgs/logo.svg';

export interface Params {}

const Page = () => {
  const {trigger, isMutating} = useCreateMutation<CurrentUser>(
    '/api/creator/temporary_users/terms_acceptance',
  );
  const {get} = useCurrentUser();
  const onPress = React.useCallback(() => {
    trigger({}).then(res => {
      if (res) {
        get();
      }
    });
  }, []);
  return (
    <Layout title={'サービス利用開始'} loading={isMutating}>
      <View style={styles.container}>
        <SvgUriAdapter uri={logoUri} width={300} />
        <Text style={styles.note}>
          TapNovelMaker（投稿サービス）のアカウントで{'\n'}
          TapNovelCreator（素材サービス）を利用できます。
        </Text>
        <PolicyLinks />
        <PrimaryButton style={styles.button} onPress={onPress}>
          利用開始する
        </PrimaryButton>
      </View>
    </Layout>
  );
};

export default React.memo(Page);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 40,
    marginHorizontal: 16,
  } as ViewStyle,
  note: {
    color: '#222',
    fontSize: 12,
    marginVertical: 26,
  } as TextStyle,
  button: {
    marginVertical: 16,
  } as ViewStyle,
});
