import * as React from 'react';
import {useSWRConfig} from 'swr';

import useCurrentUser from './useCurrentUser';
import useCurrentUserAndAccessToken from './useCurrentUserAndAccessToken';
import {useRawCreateMutation} from './swr';
import {sendDefaultCustomEvent, setUser} from '@/helpers/AnalyticsHelper';

interface CreateParams {
  apiCreatorUser: {
    email: string;
    password: string;
  };
}

const useCurrentUserSession = () => {
  const {currentUser} = useCurrentUser();
  const {mutate} = useSWRConfig();
  const {update: updateToken, destroy: destroyToken} =
    useCurrentUserAndAccessToken();
  const {trigger, ...createResponse} = useRawCreateMutation(
    '/api/creator/users/sign_in',
  );
  const create = React.useCallback(
    (params: CreateParams) =>
      trigger(params)
        .then(updateToken)
        .then(currentUser => {
          if (currentUser) {
            setUser(currentUser);
            sendDefaultCustomEvent({
              resourceType: 'creator/user_session',
              resourceId: currentUser.id,
              resourceName: currentUser.userName,
              actionName: 'create',
            });
          }
          return currentUser;
        }),
    [],
  );
  const destroy = React.useCallback(() => {
    if (currentUser) {
      sendDefaultCustomEvent({
        resourceType: 'creator/user_session',
        resourceId: currentUser.id,
        resourceName: currentUser.userName,
        actionName: 'destroy',
      });
      setUser(null);
    }
    destroyToken();
    mutate(() => true, undefined, {revalidate: false});
  }, [currentUser]);
  return {currentUser, create, createResponse, destroy};
};

export default useCurrentUserSession;
