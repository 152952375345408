import React from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {getDoubleResizeImageUrl} from '@/helpers/ImageHelper';
import {getPositionForCenteringFace} from '@/helpers/ActorImageHelper';

import ActorCharacterFace from '@/entities/ActorCharacterFace';

import {scaledValue} from '@/styles';

interface Props {
  actorCharacterFace: ActorCharacterFace;
  width?: number;
  active?: boolean;
  onSelectActorCharacterFace: (actorCharacterFace: ActorCharacterFace) => void;
}

const ActorCharacterFaceGridListItem: React.FC<Props> = props => {
  const {actorCharacterFace, width, active, onSelectActorCharacterFace} = props;
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(actorCharacterFace, {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      }),
    }),
    [],
  );
  const containerStyle = React.useMemo((): StyleProp<ViewStyle> | undefined => {
    return [
      styles.container,
      {
        marginVertical: scaledValue(width, 4),
        marginHorizontal: scaledValue(width, 2),
      },
    ];
  }, [width]);
  const imageWrapperStyle = React.useMemo(():
    | StyleProp<ViewStyle>
    | undefined => {
    return [
      styles.imageWrapper,
      {
        width: scaledValue(width, 112),
        height: scaledValue(width, 112),
        borderColor: active ? '#f5c71c' : 'white',
      },
    ];
  }, [width, active]);
  const onPress = React.useCallback(() => {
    onSelectActorCharacterFace(actorCharacterFace);
  }, []);
  const imageStyle = React.useMemo(() => {
    return [
      {
        width: scaledValue(width, 280),
        height: scaledValue(width, 280),
      },
      getPositionForCenteringFace(actorCharacterFace, {
        left: 0.25,
        top: 0.05,
      }),
    ];
  }, [width]);
  return (
    <Pressable style={containerStyle} onPress={onPress}>
      <View
        style={{
          width: scaledValue(width, 112),
          height: scaledValue(width, 109),
        }}>
        <View style={imageWrapperStyle}>
          <Image source={source} style={imageStyle} resizeMode={'contain'} />
        </View>
      </View>
      <Text style={styles.label}>{actorCharacterFace.faceName}</Text>
    </Pressable>
  );
};

export default React.memo(ActorCharacterFaceGridListItem);

const IMAGE_WIDTH = 450;
const IMAGE_HEIGHT = Math.round((IMAGE_WIDTH * 2894) / 2039);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  } as ViewStyle,
  imageWrapper: {
    borderWidth: 2,
    borderRadius: 4,
    overflow: 'hidden',
    position: 'absolute',
  } as ViewStyle,
  label: {
    color: '#666',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 4,
  } as TextStyle,
});
