import React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

interface Props extends React.PropsWithChildren {
  renderCustom?: (children: React.ReactNode | undefined) => React.ReactNode;
}

const ErrorMessage: React.FC<Props> = props => {
  const {renderCustom, children} = props;
  return (
    <Text style={styles.container}>
      {renderCustom ? renderCustom(children) : children}
    </Text>
  );
};

export default React.memo(ErrorMessage);

const styles = StyleSheet.create({
  container: {
    color: '#f23406',
    fontSize: 10,
  } as TextStyle,
});
