import React from 'react';
import {StyleSheet, TextStyle, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors, DefaultValues} from 'react-hook-form';

import {
  Field,
  ControlledInput,
  ControlledSelect,
  ControlledTextarea,
  Label,
} from '@/components/form';
import {PrimaryButton} from '@/components/ui/buttons';
import {Heading, Paragraph} from '@/components/content';

import ContactCategory from '@/entities/ContactCategory';

export type Inputs = {
  creatorContactCategoryId: string;
  email: string;
  content: string;
};

interface Props {
  contactCategories?: ContactCategory[];
  defaultValues?: DefaultValues<Inputs>;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
  scrollToEnd: (animated: boolean) => void;
}

const Form: React.FC<Props> = props => {
  const {defaultValues, contactCategories, errors, onSubmit, scrollToEnd} =
    props;
  const {
    control,
    formState: {isValid},
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      creatorContactCategoryId: '',
      email: '',
      content: '',
      ...defaultValues,
    },
  });
  const items = React.useMemo(
    () => contactCategories?.map(o => ({label: o.name, value: o.id})) || [],
    [contactCategories],
  );
  const onPress = handleSubmit(onSubmit);
  const creatorContactCategoryId = watch('creatorContactCategoryId');
  const displayStyle = React.useMemo(
    () => (creatorContactCategoryId ? null : styles.hide),
    [!!creatorContactCategoryId],
  );
  React.useEffect(() => {
    if (creatorContactCategoryId) {
      scrollToEnd(true);
    }
  }, [!!creatorContactCategoryId]);
  return (
    <View style={styles.container}>
      <Heading marginTop={16} marginHorizontal={16}>
        お問い合わせフォーム
      </Heading>
      <Paragraph color={'#222'} margin={16}>
        お問い合わせは、土日祝日を除く平日10時〜17時に順次返答させていただいておりますが、ご返答までにお時間を頂戴する可能性がございますのであらかじめご了承ください。
        {'\n'}
        また、「info@tapnovel.com」からのメールを受信できる設定になっているか必ずご確認ください。
      </Paragraph>
      <Field>
        <Label>返信用メールアドレス</Label>
        <ControlledInput
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
              pattern: {value: /.+@.+\.[A-Za-z0-9]{1,}/, message: '不正です'},
            },
            name: 'email',
          }}
          errors={errors}
        />
      </Field>
      <Field>
        <Label>お問い合わせカテゴリ</Label>
        <ControlledSelect
          items={items}
          includeBlank={'選択してください'}
          cancelText={'キャンセル'}
          confirmText={'決定'}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '選択してください'},
            },
            name: 'creatorContactCategoryId',
          }}
          errors={errors}
        />
      </Field>
      <Field style={displayStyle}>
        <Label>お問い合わせ内容</Label>
        <ControlledTextarea
          placeholder={'お問い合わせ内容を入力'}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'content',
          }}
          errors={errors}
        />
      </Field>
      <View style={[styles.submit, displayStyle]}>
        <PrimaryButton disabled={!isValid} onPress={onPress}>
          内容を確認
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form) as typeof Form;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 24,
    backgroundColor: 'white',
    paddingBottom: 67,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 16,
    marginHorizontal: 16,
  } as TextStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});
