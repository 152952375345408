import {PathConfigMap} from '@react-navigation/core';

import {RootStackParamList} from './RootStackParamList';

const LinkingConfig: PathConfigMap<RootStackParamList> = {
  Home: '',
  TemporaryUserRegistrationCompletion: 'temporary_user/registration_completion',
  TemporaryUserTermsAcceptance: 'temporary_user/terms_acceptance',
  UserEdit: 'user/edit',
  UserCancel: 'user/cancel',
  Menu: 'menu',
  Notifications: 'notifications',
  Revenue: 'revenue',
  RevenueActor: {
    path: 'revenue/actors/:id',
    parse: {
      id: Number,
    },
  },
  Transfer: 'transfer',
  TransferRequestNew: 'transfer/requests/new',
  TransferRequestComplete: 'transfer/requests/complete',
  Balance: 'balance',
  Settings: 'settings',
  SettingsNotifications: 'settings/notifications',
  SettingsAccount: 'settings/account',
  SettingsAccountEmailEdit: 'settings/account/email/edit',
  SettingsAccountPasswordEdit: 'settings/account/password/edit',
  SettingsQualifiedInvoiceIssuer: 'settings/qualified_invoice_issuer',
  SettingsBankAccount: 'settings/bank_account',
  SettingsPoliciesPrivacy: 'settings/policies/privacy',
  SettingsPoliciesSettlementLaw: 'settings/policies/settlement_law',
  SettingsPoliciesSpecifiedCommercialTransactionLaw:
    'settings/policies/specified_commercial_transaction_law',
  SettingsPoliciesTerms: 'settings/policies/terms',
  SettingsTransferHistory: 'settings/transfer_history',
  ContactNew: 'contacts/new',
  UserSignIn: 'users/sign_in',
  UserSignUp: 'users/sign_up',
  UserPasswordNew: 'users/password/new',
  UserPasswordEdit: 'users/password/edit',
  UserConfirmationNew: 'users/confirmation/new',
  PoliciesPrivacy: 'policies/privacy',
  PoliciesSettlementLaw: 'policies/settlement_law',
  PoliciesSpecifiedCommercialTransactionLaw:
    'policies/specified_commercial_transaction_law',
  PoliciesTerms: 'policies/terms',
  Helps: 'helps',
  Help: {
    path: 'helps/:id',
    parse: {
      id: Number,
    },
  },
  Announcements: 'announcements',
  Announcement: {
    path: 'announcements/:id',
    parse: {
      id: Number,
    },
  },
  ActorCategoriesEdit: {
    path: 'actors/:id/categories/edit',
    parse: {
      id: Number,
    },
  },
  ActorNew: 'actors/new',
  Actor: {
    path: 'actors/:id',
    parse: {
      id: Number,
    },
  },
  ActorEdit: {
    path: 'actors/:id/edit',
    parse: {
      id: Number,
    },
  },
  ActorFaceCenterPositionEdit: {
    path: 'actors/:id/face_center_position/edit',
    parse: {
      id: Number,
    },
  },
  ActorPreview: {
    path: 'actors/:actorId/preview',
    parse: {
      actorId: Number,
    },
  },
  ActorOgpImage: {
    path: 'actors/:actorId/ogp_images/:type',
    parse: {
      actorId: Number,
      type: String,
    },
  },
  ActorCharacterFaceNew: {
    path: 'actor_characters/:actorCharacterId/actor_character_faces/new',
    parse: {
      actorCharacterId: Number,
    },
  },
  ActorCharacterFaceEdit: {
    path: 'actor_character_faces/:id/edit',
    parse: {
      id: Number,
    },
  },
  ActorProduct: {
    path: 'actors/:actorId/product',
    parse: {
      actorId: Number,
    },
  },
  ActorProductReleaseRequest: {
    path: 'actors/:actorId/product_release_request',
    parse: {
      actorId: Number,
    },
  },
  ActorProductReleaseRequestComplete: {
    path: 'actors/:actorId/product_release_request/complete',
    parse: {
      actorId: Number,
    },
  },
  Monitor: 'monitor',
  MonitorApplicationNew: 'monitor/applications/new',
  MonitorApplicationComplete: 'monitor/applications/complete',
};

export default LinkingConfig;
