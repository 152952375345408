import useSocialLogin from './useSocialLogin';

import FacebookLogin from '@/services/social_logins/FacebookLogin';

const useFacebookLogin = () => {
  return useSocialLogin(
    new FacebookLogin(),
    '/api/creator/social_accounts/facebook_accounts/session',
    'facebookAccount',
  );
};

export default useFacebookLogin;
