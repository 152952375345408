import React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

import {ItemValue, Item} from '@/components/value/types';

interface Props<IValue extends ItemValue = ItemValue> {
  item: Item<IValue>;
  checked?: boolean;
  last?: boolean;
  disabled?: boolean;
  onSelectItem?: (item: Item<IValue>) => void;
}

function Button<IValue extends ItemValue = ItemValue>(props: Props<IValue>) {
  const {item, checked, last, disabled, onSelectItem} = props;
  const onPress = React.useCallback(() => {
    onSelectItem && onSelectItem(item);
  }, []);
  return (
    <Pressable
      style={[
        styles.container,
        checked ? null : {borderRightColor: last ? 'transparent' : '#efefef'},
      ]}
      disabled={disabled}
      key={`${item.value}`}
      onPress={onPress}>
      <Text
        style={
          disabled
            ? styles.labelDisabled
            : checked
            ? styles.labelChecked
            : styles.label
        }>
        {item.label}
        {optionLabel ? (
          <Text
            style={
              disabled
                ? styles.optionLabelDisabled
                : checked
                ? styles.optionLabelChecked
                : styles.optionLabel
            }>
            {item.optionLabel}
          </Text>
        ) : null}
      </Text>
    </Pressable>
  );
}

export default React.memo(Button) as typeof Button;

const label = {
  fontSize: 13,
  fontWeight: 'bold',
  lineHeight: 13,
  color: '#999999',
} as TextStyle;

const optionLabel = {
  marginTop: 2,
  fontSize: 9,
  lineHeight: 9,
  color: '#999999',
} as TextStyle;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: 'transparent',
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      } as any,
      default: {},
    }),
  } as ViewStyle,
  label,
  labelChecked: {
    ...label,
    color: '#f5c71c',
  } as TextStyle,
  labelDisabled: {
    ...label,
    color: '#999999',
  } as TextStyle,
  optionLabel,
  optionLabelChecked: {
    ...optionLabel,
    color: '#f5c71c',
  } as TextStyle,
  optionLabelDisabled: {
    ...optionLabel,
    color: '#999999',
  } as TextStyle,
});
