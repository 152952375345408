import React from 'react';
import {ScrollView} from 'react-native';
import {useNavigation} from '@react-navigation/native';

import Form, {Inputs} from './components/Form';
import Confirm from './components/Confirm';
import HelpsSection from './components/HelpsSection';

import {Layout} from '@/components/layout';

import {useGet, useCreateMutation} from '@/hooks/swr';
import useCurrentUser from '@/hooks/useCurrentUser';
import useApplicationModal from '@/hooks/useApplicationModal';
import {buildFieldErrors} from '@/helpers/FormHelper';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

import Contact from '@/entities/Contact';
import ContactCategory from '@/entities/ContactCategory';
import Help from '@/entities/Help';

export interface Params {}

const Page = () => {
  const navigation = useNavigation();
  const {show: showModal} = useApplicationModal();
  const scrollViewRef = React.useRef<ScrollView>(null);
  const {currentUser} = useCurrentUser();
  const [defaultValues, setDefaultValues] = React.useState({
    email: currentUser?.email || '',
  });
  const [data, setData] = React.useState<Inputs | null>(null);
  const {trigger, error, isMutating} = useCreateMutation<Contact>(
    '/api/creator/contacts',
  );
  const {data: contactCategories} = useGet<ContactCategory[]>(
    '/api/creator/contact_categories',
  );
  const {data: helps} = useGet<Help[]>('/api/creator/helps', {
    primary: true,
  });
  const onConfirm = React.useCallback((data: Inputs) => {
    setData(data);
  }, []);
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(contact => {
      if (!contact) {
        return;
      }
      navigation.goBack();
      showModal({title: '問い合わせが完了しました'});
      sendDefaultCustomEvent({
        resourceType: 'contact',
        resourceId: contact.id,
        actionName: 'create',
      });
    });
  }, []);
  const scrollToEnd = React.useCallback((animated: boolean) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({animated});
    }
  }, []);
  const onBack = React.useCallback(() => {
    if (data) {
      setDefaultValues(data);
    }
    setData(null);
    setTimeout(() => scrollToEnd(false), 10);
  }, [data]);
  return (
    <Layout
      title={'お問い合わせ'}
      back={true}
      scrollable={false}
      loading={isMutating}>
      <ScrollView ref={scrollViewRef}>
        {!helps ? null : data ? (
          <Confirm
            data={data}
            contactCategories={contactCategories}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        ) : (
          <>
            <HelpsSection helps={helps} />
            <Form
              contactCategories={contactCategories}
              defaultValues={defaultValues}
              errors={convertResponseToErrors(error)}
              onSubmit={onConfirm}
              scrollToEnd={scrollToEnd}
            />
          </>
        )}
      </ScrollView>
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {creatorContact: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    email: 'email',
    creatorContactCategoryId: 'creatorContactCategoryId',
    content: 'content',
  });
};
