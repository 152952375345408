import AddCharacterOIcon from '../AddCharacterOIcon';

interface Props {
  size?: number;
  color?: string;
}

const UploadIcon: React.FC<Props> = props => {
  return <AddCharacterOIcon {...props} />;
};

export default UploadIcon;
