import React from 'react';
import {View} from 'react-native';

import useApplicationLayoutStyle from '@/hooks/useApplicationLayoutStyle';

interface Props extends React.PropsWithChildren {}

const Container: React.FC<Props> = props => {
  const {children} = props;
  const styles = useApplicationLayoutStyle();
  return <View style={styles.container}>{children}</View>;
};

export default React.memo(Container);
