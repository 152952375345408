import React from 'react';

import {PrimaryButton} from '@/components/ui/buttons';
import {Props} from '@/components/ui/buttons/Button';

const RightButton: React.FC<Props> = props => {
  return <PrimaryButton {...props} style={style} />;
};

export default React.memo(RightButton);

const style = {
  width: 64,
  height: 32,
};
