import SocialLogin, {ErrorData, Params, SuccessData} from './SocialLogin';

import {create} from '@/services/fetchers';
import {
  LINE_CREATOR_AUTH_REDIRECT_URI,
  LINE_CREATOR_CHANNEL_ID,
} from '@/config';

export type {SuccessData, ErrorData};

export default class LineLogin implements SocialLogin {
  public login = (params: Params) => {
    const {onSuccess} = params;
    const listener = async (event: MessageEvent) => {
      if (event.data && event.data.type !== 'line_oauth') {
        return;
      }
      window.removeEventListener('message', listener);
      const accessToken = await create(
        '/api/creator/social_accounts/line_accounts/access_token',
        {
          arg: {
            code: event.data.params['code'],
            redirectUri: LINE_CREATOR_AUTH_REDIRECT_URI,
          },
        },
      );
      onSuccess({
        accessToken: accessToken.accessToken,
        email: '',
        id: accessToken.decodedIdToken[0].sub,
        provider: 'line',
        screenName: accessToken.decodedIdToken[0].name,
      });
    };
    window.addEventListener('message', listener, false);

    window.open(
      `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CREATOR_CHANNEL_ID}&redirect_uri=${LINE_CREATOR_AUTH_REDIRECT_URI}&state=state&scope=openid%20profile`,
      undefined,
      `width=500,height=600,toolbar=no,menubar=no,left=${
        window.innerWidth / 2 - 250
      },top=${window.innerHeight / 2 - 300}`,
    );
  };
}
