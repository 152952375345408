import React from 'react';
import {FieldPathValue, FieldValues, FieldPath} from 'react-hook-form';

import {Layout, RightButton} from '@/components/layout';
import {CheckGridList} from '@/components/ui/lists';
import {ItemValue} from '@/components/value/types';

import {ItemsList} from '../types';

import {HUMAN_GENDER_MALE_ID, HUMAN_GENDER_FEMALE_ID} from '../data';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  itemsList: ItemsList;
  humanGenderId: number | null;
  values?: FieldPathValue<TFieldValues, TName>;
  onSubmit: (value: FieldPathValue<TFieldValues, TName>) => void;
  onRequestClose: () => void;
}

function HumanFacialFeatureIds<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {itemsList, humanGenderId, onSubmit, onRequestClose} = props;
  const [values, setValues] = React.useState<ItemValue[]>(
    (props.values as ItemValue[]) || [],
  );
  const onSelectValue = React.useCallback(
    (value: ItemValue) => {
      const index = values.indexOf(value);
      if (index >= 0) {
        setValues(values.filter(v => v !== value));
      } else {
        setValues([...values, value]);
      }
    },
    [values],
  );
  const onPress = React.useCallback(() => {
    onSubmit(values as FieldPathValue<TFieldValues, TName>);
  }, [values]);
  const rightButton = React.useMemo(
    () => ({
      children: <RightButton onPress={onPress}>決定</RightButton>,
    }),
    [onPress],
  );
  return (
    <Layout
      title={'顔立ち(3つまで)'}
      scrollViewStyle={scrollViewStyle}
      back={true}
      onPressBack={onRequestClose}
      rightButton={rightButton}>
      <CheckGridList
        data={
          humanGenderId === HUMAN_GENDER_MALE_ID
            ? itemsList.humanFaceTypeMaleItems
            : humanGenderId === HUMAN_GENDER_FEMALE_ID
            ? itemsList.humanFaceTypeFemaleItems
            : itemsList.humanFaceTypeNoneItems
        }
        values={values}
        maxLength={3}
        onSelectValue={onSelectValue}
      />
    </Layout>
  );
}

export default React.memo(
  HumanFacialFeatureIds,
) as typeof HumanFacialFeatureIds;

const scrollViewStyle = {
  marginVertical: 12,
};
