import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

const ResourceRevenueHeader: React.FC = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>イラスト別</Text>
    </View>
  );
};

export default React.memo(ResourceRevenueHeader);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    paddingHorizontal: 16,
    paddingBottom: 10,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
});
