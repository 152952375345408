import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

export interface Props extends React.PropsWithChildren {
  tintColor?: string;
  title?: React.ReactElement<any>;
  disabled?: boolean;
  handler?: () => void;
  renderButtonWrapper?: (
    button: React.ReactElement<any>,
  ) => React.ReactElement<any>;
}

const NavigationBarButton: React.FC<Props> = props => {
  const {tintColor, title, disabled, children, handler, renderButtonWrapper} =
    props;
  const content = children ? (
    children
  ) : (
    <Text style={[styles.text, {color: tintColor}]}>{title}</Text>
  );
  return disabled || !handler ? (
    <View style={styles.container}>{content}</View>
  ) : renderButtonWrapper ? (
    renderButtonWrapper(
      <TouchableOpacity style={styles.container} onPress={handler}>
        {content}
      </TouchableOpacity>,
    )
  ) : (
    <TouchableOpacity style={styles.container} onPress={handler}>
      {content}
    </TouchableOpacity>
  );
};

export default React.memo(NavigationBarButton);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#fff',
    height: 42,
    margin: 1,
    borderRadius: 4,
  } as ViewStyle,
  text: {
    fontSize: 17,
    letterSpacing: 0.5,
  } as ViewStyle,
});
