import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

const ListItemRightSeparator: React.FC = () => {
  return <View style={styles.separator} />;
};

export default React.memo(ListItemRightSeparator);

const styles = StyleSheet.create({
  separator: {
    backgroundColor: '#E5E5E5',
    height: StyleSheet.hairlineWidth,
    marginLeft: 10,
  } as ViewStyle,
});
