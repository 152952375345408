export const twitterShareUrl = (
  url: string,
  text: string,
  hashtags: Array<string>,
  textWithServiceName?: boolean,
) => {
  return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    url,
  )}&text=${encodeURIComponent(
    normalizeShareText(text, textWithServiceName),
  )}&hashtags=${encodeURI(normalizeHashtags(hashtags))}`;
};

export const facebookShareUrl = (url: string) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url,
  )}`;
};

export const lineShareUrl = (
  url: string,
  text: string,
  textWithServiceName?: boolean,
) => {
  return `http://line.me/R/msg/text/?${encodeURIComponent(
    `${normalizeShareText(text, textWithServiceName)}\r\n${url}`,
  )}`;
};

const normalizeHashtags = (hashtags: Array<string>) => {
  return [
    'TapNovel',
    'ゲーム小説',
    ...hashtags.map(tag =>
      tag.replace(/[\s。、\-.,/#!$%^&*;:{}=\-_`~()]/g, ''),
    ),
  ].join(',');
};

const normalizeShareText = (text: string, withServiceName = true) => {
  return `${text}${withServiceName ? ' | TapNovel' : ''}`;
};
