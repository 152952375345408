import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import NotificationListItem from './NotificationListItem';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';

import UserNotification from '@/entities/UserNotification';

interface Props {
  userNotifications: UserNotification[] | null;
}

const CommentList: React.FunctionComponent<Props> = props => {
  const {userNotifications} = props;
  const keyExtractor = React.useCallback(
    (item: UserNotification, index: number): string => `${item.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (
      info: ListRenderItemInfo<UserNotification>,
    ): React.ReactElement<any> | null => {
      return <NotificationListItem userNotification={info.item} />;
    },
    [userNotifications],
  );
  return (
    <FlatList
      data={userNotifications}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
      onEndReachedThreshold={0.1}
    />
  );
};

export default React.memo(CommentList);
