import useSocialLogin from './useSocialLogin';

import LineLogin from '@/services/social_logins/LineLogin';

const useLineLogin = () => {
  return useSocialLogin(
    new LineLogin(),
    '/api/creator/social_accounts/line_accounts/session',
    'lineAccount',
  );
};

export default useLineLogin;
