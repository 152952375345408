import React from 'react';
import {useLinkTo} from '@react-navigation/native';

import Form, {Inputs} from './components/Form';

import {Layout} from '@/components/layout';

import {useRawCreateMutation} from '@/hooks/swr';
import useCurrentUser from '@/hooks/useCurrentUser';
import {buildFieldErrors} from '@/helpers/FormHelper';

export interface Params {}

const Page = () => {
  const linkTo = useLinkTo();
  const {trigger, isMutating, error} = useRawCreateMutation(
    '/api/creator/users/confirmation',
  );
  const {currentUser} = useCurrentUser();
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(res => {
      if (res?.ok) {
        linkTo('/temporary_user/registration_completion');
      }
    });
  }, []);
  return (
    <Layout title={'アカウント認証メール再送'} back={true} loading={isMutating}>
      <Form
        email={currentUser?.email || ''}
        errors={convertResponseToErrors(error)}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {apiCreatorUser: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    email: 'error',
  });
};
