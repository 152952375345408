import dateformat from 'dateformat';

import {DatePeriod} from '@/components/value/types';

export function getDiffTime(currentTime: Date, expiredAt: Date) {
  let diffDates = expiredAt.getTime() - currentTime.getTime();
  if (diffDates < 0) {
    diffDates *= -1;
  }
  const days = Math.floor(diffDates / (1000 * 60 * 60 * 24));
  diffDates = diffDates % (1000 * 60 * 60 * 24);
  const hours = Math.floor(diffDates / (1000 * 60 * 60));
  diffDates = diffDates % (1000 * 60 * 60);
  const mins = Math.floor(diffDates / (1000 * 60));
  diffDates = diffDates % (1000 * 60);
  const secs = Math.floor(diffDates / 1000);
  return {
    days,
    hours,
    mins,
    secs,
  };
}

export function formatRestTime(currentTime: Date, expiredAt: Date) {
  const {days, hours, mins, secs} = getDiffTime(currentTime, expiredAt);
  if (days > 0) {
    return `あと${days * 24 + hours}時間`;
  }
  if (hours > 0) {
    return `あと${hours}時間`;
  }
  if (mins > 0) {
    return `あと${mins}分`;
  }
  if (secs > 0) {
    return `あと${secs}秒`;
  }
  return '';
}

export function formatElapsedTime(datetime: Date): string {
  const from = new Date(datetime);
  const diff = new Date().getTime() - from.getTime();
  const elapsed = new Date(diff);

  if (diff <= 0) {
    return 'たった今';
  } else if (elapsed.getUTCFullYear() - 1970 > 0) {
    return elapsed.getUTCFullYear() - 1970 + '年前';
  } else if (elapsed.getUTCMonth()) {
    return elapsed.getUTCMonth() + 'ヶ月前';
  } else if (elapsed.getUTCDate() - 1) {
    return elapsed.getUTCDate() - 1 + '日前';
  } else if (elapsed.getUTCHours()) {
    return elapsed.getUTCHours() + '時間前';
  } else if (elapsed.getUTCMinutes()) {
    return elapsed.getUTCMinutes() + '分前';
  } else {
    return 'たった今';
  }
}

export function formatElapsedTimeOrFormattedTime(datetime: Date): string {
  const from = new Date(datetime);
  const diff = new Date().getTime() - from.getTime();
  const elapsed = new Date(diff);
  const format = 'yyyy.mm.dd TTh:MM';
  if (elapsed.getUTCFullYear() - 1970 > 0) {
    return dateformat(from, format);
  } else if (elapsed.getUTCMonth()) {
    return dateformat(from, format);
  } else if (elapsed.getUTCDate() - 1) {
    if (elapsed.getUTCDate() - 1 > 1) {
      return dateformat(from, format);
    } else {
      return `昨日 ${dateformat(from, 'TTh:MM')}`;
    }
  } else if (elapsed.getUTCHours()) {
    return elapsed.getUTCHours() + '時間前';
  } else if (elapsed.getUTCMinutes()) {
    return elapsed.getUTCMinutes() + '分前';
  } else {
    return 'たった今';
  }
}

export function convertTermRangeToQueryParams(datePeriod: DatePeriod): {
  from?: Date;
  to?: Date;
} {
  const now = new Date();
  switch (datePeriod) {
    case 'all':
      return {};
    case 'today': {
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return {from: today};
    }
    case 'yesterday': {
      const yesterday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1,
      );
      return {from: yesterday, to: yesterday};
    }
    case 'last_week': {
      const oneWeekAgo = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7,
      );
      const from = new Date(
        oneWeekAgo.getFullYear(),
        oneWeekAgo.getMonth(),
        oneWeekAgo.getDate() - oneWeekAgo.getDay(),
      );
      const to = new Date(
        from.getFullYear(),
        from.getMonth(),
        from.getDate() + 6,
      );
      return {from, to};
    }
    case 'last_month': {
      const from = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const to = new Date(now.getFullYear(), now.getMonth(), -1);
      return {from, to};
    }
    case 'last_7days': {
      const to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      const from = new Date(to.getFullYear(), to.getMonth(), to.getDate() - 6);
      return {from, to};
    }
    case 'last_30days': {
      const to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      const from = new Date(to.getFullYear(), to.getMonth(), to.getDate() - 29);
      return {from, to};
    }
  }
}
