import React from 'react';
import {StyleSheet, Text, TextStyle, TouchableOpacity} from 'react-native';

interface Props {
  onPress: () => void;
}
const CloseButton: React.FC<Props> = props => {
  const {onPress} = props;
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text style={styles.text}>プレビューを閉じる</Text>
    </TouchableOpacity>
  );
};

export default React.memo(CloseButton);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 46,
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
});
