import React from 'react';

import SocialLoginButton from './SocialLoginButton';

import {FacebookIcon} from '@/components/icons/social';

import useFacebookLogin from '@/hooks/social_logins/useFacebookLogin';

const FacebookLoginButton: React.FC = () => {
  const {login} = useFacebookLogin();
  return (
    <SocialLoginButton color={'#efefef'} onPress={login}>
      <FacebookIcon size={28} />
    </SocialLoginButton>
  );
};

export default React.memo(FacebookLoginButton);
