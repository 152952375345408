import React from 'react';
import {NativeSyntheticEvent, View} from 'react-native';

import ModalDialogAnimation, {
  DialogAnimationProps,
} from '../ModalDialogAnimation';

import useApplicationLayoutStyle from '@/hooks/useApplicationLayoutStyle';

export interface Props extends React.PropsWithChildren {
  dialogAnimationProps?: DialogAnimationProps;
  onRequestClose?: ((event: NativeSyntheticEvent<any>) => void) | undefined;
}

const Dialog: React.FC<Props> = props => {
  const {dialogAnimationProps, children} = props;
  const styles = useApplicationLayoutStyle();
  return (
    <ModalDialogAnimation {...dialogAnimationProps}>
      <View style={styles.container}>
        <View style={styles.content}>{children}</View>
      </View>
    </ModalDialogAnimation>
  );
};

export default React.memo(Dialog);
