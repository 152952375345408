import urlparser from 'url';
import {
  LINE_CREATOR_AUTH_REDIRECT_URI,
  TWITTER_CREATOR_AUTH_REDIRECT_URI,
} from '@/config';
const lineOauth = (parsedQuery: urlparser.UrlWithStringQuery, params: any) => {
  if (
    `${parsedQuery.protocol}//${parsedQuery.host}${parsedQuery.pathname}` ===
    LINE_CREATOR_AUTH_REDIRECT_URI
  ) {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'line_oauth',
          params,
        },
        '*',
      );
    }
    window.close();
  }
};

const twitterOauth = (
  parsedQuery: urlparser.UrlWithStringQuery,
  params: any,
) => {
  if (
    `${parsedQuery.protocol}//${parsedQuery.host}${parsedQuery.pathname}` ===
    TWITTER_CREATOR_AUTH_REDIRECT_URI
  ) {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'twitter_oauth',
          params,
        },
        '*',
      );
    }
    window.close();
  }
};

const oauthCallback = (
  parsedQuery: urlparser.UrlWithStringQuery,
  params: any,
) => {
  lineOauth(parsedQuery, params);
  twitterOauth(parsedQuery, params);
};

export default oauthCallback;
