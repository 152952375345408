import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

const NotFound: React.FC = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>お探しのページは見つかりません</Text>
    </View>
  );
};

export default React.memo(NotFound);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {} as TextStyle,
});
