import React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

import Actor from '@/entities/Actor';

interface Props {
  actor: Actor;
}

const ActorInfo: React.FC<Props> = props => {
  const {actor} = props;
  if (!actor.actorCharacter) {
    return null;
  }
  return (
    <>
      <Text style={styles.text}>{actor.actorCharacter.costumeName}</Text>
      <Text style={styles.text}>
        表情差分：{actor.actorCharacter.faceCount}
      </Text>
    </>
  );
};

export default React.memo(ActorInfo);

const styles = StyleSheet.create({
  text: {
    color: '#383838',
    fontSize: 10,
    marginVertical: 2,
  } as TextStyle,
});
