import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import SocialLoginButton from './SocialLoginButton';

import {LineIcon} from '@/components/icons/social';

import useLineLogin from '@/hooks/social_logins/useLineLogin';

const LineLoginButton: React.FC = () => {
  const {login} = useLineLogin();
  return (
    <SocialLoginButton color={'#efefef'} onPress={login}>
      <View style={styles.icon}>
        <LineIcon size={30} />
      </View>
    </SocialLoginButton>
  );
};

export default React.memo(LineLoginButton);

const styles = StyleSheet.create({
  icon: {
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: 15,
    height: 30,
    justifyContent: 'center',
    width: 30,
  } as ViewStyle,
});
