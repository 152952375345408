import React from 'react';
import {Platform, StyleSheet, TextStyle, View, ViewStyle} from 'react-native';
import {Picker} from '@react-native-picker/picker';

import {Props} from './types';
import AngleDownIcon from './AngleDownIcon';

import {ItemValue} from '@/components/value/types';

export type {Props};

function Select<IValue extends ItemValue = ItemValue>(props: Props<IValue>) {
  const {
    value,
    style,
    pickerStyle,
    items,
    rightIcon,
    includeBlank,
    required,
    onValueChange,
  } = props;
  const [selectedValue, setSelectedValue] = React.useState<IValue>(value);
  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);
  const handleValueChange = React.useCallback(
    (itemValue: IValue, selectedPosition: number) => {
      const selectedValue = itemValue;
      setSelectedValue(itemValue);
      onValueChange(selectedValue, selectedPosition);
    },
    [onValueChange],
  );
  return (
    <View style={[styles.container, style]}>
      <Picker
        style={[
          styles.picker,
          selectedValue
            ? null
            : required
            ? styles.pickerRequired
            : styles.pickerNoValue,
          pickerStyle,
        ]}
        selectedValue={selectedValue || ('' as IValue)}
        onValueChange={handleValueChange}>
        {includeBlank && (
          <Picker.Item
            label={typeof includeBlank === 'string' ? includeBlank : ''}
            color={'#999'}
            value={''}
          />
        )}
        {items.map(item => (
          <Picker.Item
            key={item.label}
            label={item.label}
            color={item.value === null ? '#9EA0A4' : undefined}
            value={item.value}
          />
        ))}
      </Picker>
      <View style={styles.rightIcon}>{rightIcon || <AngleDownIcon />}</View>
    </View>
  );
}

export default React.memo(Select) as typeof Select;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#efefef',
    width: '100%',
    height: 44,
  } as ViewStyle,
  picker: Platform.select({
    web: {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      background: 'none',
      backgroundColor: 'white',
      border: 'none',
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontSize: 15,
      outline: 'none',
      paddingVertical: 8,
      width: '100%',
      color: '#383838',
    },
    default: {
      backgroundColor: 'white',
      fontSize: 15,
      height: 44,
      flex: 1,
    },
  }) as TextStyle,
  pickerRequired: {
    color: '#999',
    backgroundColor: '#fff8dc',
  } as TextStyle,
  pickerNoValue: {
    color: '#999',
  } as TextStyle,
  rightIcon: {
    position: 'absolute',
    right: 8,
  } as ViewStyle,
});
