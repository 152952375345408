import React from 'react';
import {TextStyle, StyleProp, StyleSheet, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {BlackButton} from '@/components/ui/buttons';

import {PlayIcon} from '@/components/icons';

interface Props {
  style?: StyleProp<ViewStyle> | undefined;
  textStyle?: StyleProp<TextStyle> | undefined;
  url: string;
  iconSize?: number;
  onPress?: () => void;
}

const PreviewButton: React.FC<Props> = props => {
  const {style, textStyle, url, iconSize, onPress: callback} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(url);
    callback && callback();
  }, []);
  const icon = React.useMemo(
    () => <PlayIcon size={iconSize || 16} />,
    [iconSize],
  );
  const textProps = React.useMemo(
    () => ({style: textStyle || {fontSize: 12}}),
    [textStyle],
  );
  return (
    <BlackButton
      style={[styles.button, style]}
      textProps={textProps}
      icon={icon}
      onPress={onPress}>
      プレビュー
    </BlackButton>
  );
};

export default React.memo(PreviewButton);

const styles = StyleSheet.create({
  button: {
    width: 100,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
