import React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useForm, FieldErrors, FieldPath} from 'react-hook-form';

import {ItemsList} from './types';
import {
  ATTRIBUTE_HUMAN_ID,
  ATTRIBUTE_ANIMAL_ID,
  ATTRIBUTE_OTHER_ID,
  HUMAN_GENDER_MALE_ID,
  HUMAN_GENDER_FEMALE_ID,
} from './data';
import Modals, {ModalType} from './Modals';

import {
  Field,
  ControlledLabel,
  ControlledMultiSwitch,
  ControlledSelect,
  ControlledTextLink,
  ControlledHiddenInput,
  Select,
} from '@/components/form';
import {PrimaryButton} from '@/components/ui/buttons';
import useTooltipModal, {
  Props as TooltipModalProps,
} from '@/hooks/useTooltipModal';

export type Inputs = {
  attributeId: number;
  humanGenderId: number | null;
  humanAgeIds: number[] | null;
  humanFacialFeatureIds: number[] | null;
  humanBodyShapeId: number | null;
  humanHeightId: number | null;
  humanHairColorId: number | null;
  animalTypeId: number | null;
  otherTypeId: number | null;
  otherFormId: number | null;
  otherSizeId: number | null;
  keywords: string[];
};

interface Props {
  isNew: boolean;
  itemsList: ItemsList;
  defaultValues?: Inputs;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {isNew, itemsList, defaultValues, errors, onSubmit} = props;
  const mounted = React.useRef(false);
  const {
    control,
    formState: {isValid},
    watch,
    setValue,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues:
      defaultValues ||
      ({
        attributeId: ATTRIBUTE_HUMAN_ID,
        humanGenderId: HUMAN_GENDER_MALE_ID,
        humanAgeIds: null,
        humanFacialFeatureIds: null,
        humanBodyShapeId: null,
        humanHeightId: null,
        humanHairColorId: null,
        animalTypeId: null,
        otherTypeId: null,
        otherFormId: null,
        otherSizeId: null,
        keywords: [],
      } as Inputs),
  });
  const [modalType, setModalType] = React.useState<ModalType | null>(null);
  const [humanAgeSelectCount, setHumanAgeSelectCount] = React.useState(
    Math.max(defaultValues?.humanAgeIds?.length || 1, 1),
  );
  const [defaultHumanAgeIds, setDefaultHumanAgeIds] = React.useState(
    (defaultValues?.humanAgeIds || []).reduce(
      (previousValue, currentValue, index) => {
        previousValue[index] = currentValue;
        return previousValue;
      },
      {} as {[key: string]: number},
    ),
  );
  const [indexToHumanAgeId, setIndexToHumanAgeId] = React.useState<{
    [key: string]: number;
  }>(defaultHumanAgeIds);
  const tooltipModal = useTooltipModal();
  const requestOpenModalCallback = React.useCallback(
    (modalType: ModalType) =>
      React.useCallback(() => setModalType(modalType), []),
    [],
  );
  const onRequestOpenHumanFacialFeatureIdsModal = requestOpenModalCallback(
    'humanFacialFeatureIds',
  );
  const onRequestOpenKeywordIdsModal = requestOpenModalCallback('keywords');
  const onPressKeywordQuestion = React.useCallback(
    () => tooltipModal.show(KEYWORD_QUESTION),
    [],
  );
  const onPressAgeAdd = React.useCallback(() => {
    setHumanAgeSelectCount(humanAgeSelectCount + 1);
  }, [humanAgeSelectCount]);
  const onRequestClose = React.useCallback(() => setModalType(null), []);
  const onPress = handleSubmit(onSubmit);
  const attributeId = watch('attributeId');
  const humanGenderId = watch('humanGenderId');
  React.useEffect(() => {
    if (!mounted.current) {
      return;
    }
    reset({
      attributeId,
      humanGenderId:
        attributeId === ATTRIBUTE_HUMAN_ID ? HUMAN_GENDER_MALE_ID : null,
      keywords: watch('keywords'),
    });
    setHumanAgeSelectCount(attributeId === ATTRIBUTE_HUMAN_ID ? 1 : 0);
    setIndexToHumanAgeId({});
    setDefaultHumanAgeIds({});
  }, [attributeId]);
  React.useEffect(() => {
    if (!mounted.current) {
      return;
    }
    setValue('humanFacialFeatureIds', [], {shouldValidate: true});
  }, [humanGenderId]);
  React.useEffect(() => {
    mounted.current = true;
  }, []);
  React.useEffect(() => {
    setValue(
      'humanAgeIds',
      Object.values(indexToHumanAgeId)
        .map(v => Number(v))
        .filter(v => v > 0)
        .filter((elem, index, self) => self.indexOf(elem) === index),
      {shouldValidate: true},
    );
  }, [indexToHumanAgeId]);
  return (
    <View style={styles.container}>
      <ScrollView>
        <CommonField>
          <ControlledLabel
            label={'属性'}
            control={control}
            name={'attributeId'}
            errors={errors}
            hiddenEnteredLabel={true}
          />
          <ControlledMultiSwitch
            items={itemsList.attributeItems}
            controllerProps={{
              control,
              rules: {
                required: {value: true, message: '選択してください'},
              },
              name: 'attributeId',
            }}
            errors={errors}
          />
        </CommonField>
        {attributeId === ATTRIBUTE_HUMAN_ID ? (
          <>
            <CommonField>
              <ControlledLabel
                label={'性別'}
                control={control}
                name={'humanGenderId'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledMultiSwitch
                items={itemsList.humanGenderItems}
                controllerProps={{
                  control,
                  rules: {
                    required: {value: true, message: '選択してください'},
                  },
                  name: 'humanGenderId',
                }}
                errors={errors}
              />
            </CommonField>
            <CommonField>
              <ControlledLabel
                label={'年齢'}
                control={control}
                name={'humanAgeIds'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledHiddenInput
                controllerProps={{
                  control,
                  name: 'humanAgeIds',
                  rules: {required: {value: true, message: '入力してください'}},
                }}
              />
              {[...Array(humanAgeSelectCount)].map((e, i) => {
                return (
                  <Select
                    key={i}
                    value={defaultHumanAgeIds ? defaultHumanAgeIds[i] : null}
                    items={itemsList.humanAgeItems}
                    includeBlank={'選択する'}
                    onValueChange={value => {
                      setIndexToHumanAgeId({
                        ...indexToHumanAgeId,
                        [i]: value,
                      });
                    }}
                  />
                );
              })}
            </CommonField>
            {itemsList.humanAgeItems.length > humanAgeSelectCount && (
              <TouchableOpacity onPress={onPressAgeAdd}>
                <Text style={styles.additional}>追加する</Text>
              </TouchableOpacity>
            )}
            <CommonField>
              <ControlledLabel
                label={'顔立ち'}
                labelOption={'(3つまで)'}
                control={control}
                name={'humanFacialFeatureIds'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledTextLink
                data={
                  humanGenderId === HUMAN_GENDER_MALE_ID
                    ? itemsList.humanFaceTypeMaleItems
                    : humanGenderId === HUMAN_GENDER_FEMALE_ID
                    ? itemsList.humanFaceTypeFemaleItems
                    : itemsList.humanFaceTypeNoneItems
                }
                onPress={onRequestOpenHumanFacialFeatureIdsModal}
                controllerProps={{
                  control,
                  name: 'humanFacialFeatureIds',
                  rules: {required: {value: true, message: '入力してください'}},
                }}
              />
            </CommonField>
            <CommonField>
              <ControlledLabel
                label={'体型'}
                control={control}
                name={'humanBodyShapeId'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledSelect
                items={itemsList.humanBodyShapeItems}
                includeBlank={'選択する'}
                controllerProps={{
                  control,
                  name: 'humanBodyShapeId',
                  rules: {required: {value: true, message: '入力してください'}},
                }}
              />
            </CommonField>
            <CommonField>
              <ControlledLabel
                label={'身長'}
                control={control}
                name={'humanHeightId'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledSelect
                items={itemsList.humanHeightItems}
                includeBlank={'選択する'}
                controllerProps={{
                  control,
                  name: 'humanHeightId',
                  rules: {required: {value: true, message: '入力してください'}},
                }}
              />
            </CommonField>
            <CommonField>
              <ControlledLabel
                label={'髪色'}
                control={control}
                name={'humanHairColorId'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledSelect
                items={itemsList.humanHairColorItems}
                includeBlank={'選択する'}
                controllerProps={{
                  control,
                  name: 'humanHairColorId',
                  rules: {required: {value: true, message: '入力してください'}},
                }}
              />
            </CommonField>
          </>
        ) : attributeId === ATTRIBUTE_ANIMAL_ID ? (
          <>
            <CommonField>
              <ControlledLabel
                label={'種別'}
                control={control}
                name={'animalTypeId'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledSelect
                items={itemsList.animalTypeItems}
                includeBlank={'選択する'}
                controllerProps={{
                  control,
                  name: 'animalTypeId',
                  rules: {required: {value: true, message: '入力してください'}},
                }}
              />
            </CommonField>
          </>
        ) : attributeId === ATTRIBUTE_OTHER_ID ? (
          <>
            <CommonField>
              <ControlledLabel
                label={'種別'}
                control={control}
                name={'otherTypeId'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledSelect
                items={itemsList.otherTypeItems}
                includeBlank={'選択する'}
                controllerProps={{
                  control,
                  name: 'otherTypeId',
                  rules: {required: {value: true, message: '入力してください'}},
                }}
              />
            </CommonField>
            <CommonField>
              <ControlledLabel
                label={'形態'}
                control={control}
                name={'otherFormId'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledSelect
                items={itemsList.otherFormItems}
                includeBlank={'選択する'}
                controllerProps={{
                  control,
                  name: 'otherFormId',
                  rules: {required: {value: true, message: '入力してください'}},
                }}
              />
            </CommonField>
            <CommonField>
              <ControlledLabel
                label={'サイズ'}
                control={control}
                name={'otherSizeId'}
                errors={errors}
                hiddenEnteredLabel={true}
              />
              <ControlledSelect
                items={itemsList.otherSizeItems}
                includeBlank={'選択する'}
                controllerProps={{
                  control,
                  name: 'otherSizeId',
                  rules: {required: {value: true, message: '入力してください'}},
                }}
              />
            </CommonField>
          </>
        ) : null}
        <CommonField>
          <ControlledLabel
            label={'キーワード'}
            control={control}
            name={'keywords'}
            errors={errors}
            hiddenEnteredLabel={true}
            badgeType={'optional'}
            onPressQuestion={onPressKeywordQuestion}
          />
          <ControlledTextLink
            onPress={onRequestOpenKeywordIdsModal}
            controllerProps={{
              control,
              name: 'keywords',
            }}
          />
        </CommonField>
      </ScrollView>
      <View style={styles.submit}>
        <PrimaryButton disabled={!isValid} onPress={onPress}>
          {!isNew ? '決定' : '登録'}
        </PrimaryButton>
      </View>
      <Modals
        modalType={modalType}
        itemsList={itemsList}
        humanGenderId={humanGenderId}
        name={convertModalTypeToName(modalType)}
        watch={watch}
        setValue={setValue}
        onRequestClose={onRequestClose}
      />
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
  } as ViewStyle,
  additional: {
    color: '#e7b600',
    fontSize: 12,
    fontWeight: 'bold',
    marginHorizontal: 16,
    marginBottom: 6,
  } as TextStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});

const KEYWORD_QUESTION: TooltipModalProps = {
  title: 'キーワード',
  description:
    'タップライターがイラストを探しやすくなります。\nイラストの特徴となるキーワードを登録しましょう。',
};

const convertModalTypeToName = (
  modalType: ModalType | null,
): FieldPath<Inputs> | null => {
  switch (modalType) {
    case 'humanFacialFeatureIds':
      return 'humanFacialFeatureIds';
    case 'keywords':
      return 'keywords';
    default:
      return null;
  }
};

const CommonField = ({children}: React.PropsWithChildren) => (
  <Field style={{marginVertical: 6}}>{children}</Field>
);
