import React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';
import numeral from 'numeral';

import {
  Field,
  ControlledLabel,
  ControlledMultiSwitch,
  ControlledPriceInput,
} from '@/components/form';
import {PrimaryButton} from '@/components/ui/buttons';
import {AlertTriangleIcon} from '@/components/icons';

import useTooltipModal, {
  Props as TooltipModalProps,
} from '@/hooks/useTooltipModal';

import Product from '@/entities/Product';

export type Inputs = {
  productId: number;
  allowedInversion: boolean;
};

interface Props {
  products: Product[];
  disableAllowedInversion: boolean;
  defaultValues?: Inputs;
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {products, disableAllowedInversion, defaultValues, errors, onSubmit} =
    props;
  const defaultProduct =
    products.find(product => Math.round(product.price) === 500) || products[0];
  const {
    control,
    formState: {isValid},
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues:
      defaultValues ||
      ({
        productId: defaultProduct?.id,
        allowedInversion: false,
      } as Inputs),
  });
  const mount = React.useRef(false);
  const tooltipModal = useTooltipModal();
  const allowedInversion = watch('allowedInversion');
  const onPress = handleSubmit(onSubmit);
  const onPressAllowedInversionQuestion = React.useCallback(() => {
    tooltipModal.show(ALLOWED_INVERSION_QUESTION);
  }, []);
  const onPressPriceQuestion = React.useCallback(() => {
    tooltipModal.show(PRICE_QUESTION);
  }, []);
  const showAlert = React.useCallback(() => {
    tooltipModal.show({
      title: <AlertTriangleIcon size={32} />,
      description:
        '一度反転機能の使用を許可したイラストは、後から変更することはできなくなります。',
    });
  }, []);
  React.useEffect(() => {
    if (mount.current && allowedInversion) {
      showAlert();
    }
  }, [allowedInversion]);
  React.useEffect(() => {
    mount.current = true;
  }, []);
  const items = React.useMemo(
    () =>
      products.map(product => {
        return {
          label: numeral(product.price).format('0'),
          value: product.id,
        };
      }),
    [products],
  );
  return (
    <View style={styles.container}>
      <ScrollView>
        <Field>
          <ControlledLabel
            label={'販売額'}
            control={control}
            name={'productId'}
            errors={errors}
            hiddenEnteredLabel={true}
            onPressQuestion={onPressPriceQuestion}
          />
          <ControlledPriceInput
            items={items}
            controllerProps={{
              control,
              name: 'productId',
            }}
            errors={errors}
          />
          <Text style={styles.note}>
            ※ご自身のイラストはTapNovelMakerで無料で利用できます。
          </Text>
        </Field>
        <Field style={{marginTop: 0}}>
          <ControlledLabel
            label={'反転機能の使用許可'}
            control={control}
            name={'allowedInversion'}
            errors={errors}
            hiddenEnteredLabel={true}
            onPressQuestion={onPressAllowedInversionQuestion}
          />
          <ControlledMultiSwitch
            items={ALLOWED_INVERSION_ITEMS}
            disabled={disableAllowedInversion}
            controllerProps={{
              control,
              name: 'allowedInversion',
            }}
            errors={errors}
          />
        </Field>
        <View style={styles.boxNote}>
          <Text style={styles.boxNoteText}>
            一度購入されたイラストは、販売額を変更した場合で{'\n'}
            あっても購入者は引き続き利用することができます。
          </Text>
        </View>
      </ScrollView>
      <View style={styles.submit}>
        <PrimaryButton disabled={!isValid} onPress={onPress}>
          次へ
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form);

const ALLOWED_INVERSION_ITEMS = [
  {label: '使用不可', value: false},
  {label: '使用可', value: true},
];

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
  } as ViewStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  note: {
    color: '#383838',
    fontSize: 10,
    marginTop: 8,
  } as TextStyle,
  boxNote: {
    height: 68,
    backgroundColor: '#fafafa',
    borderRadius: 4,
    marginHorizontal: 16,
    marginBottom: 16,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  boxNoteText: {
    color: '#666',
    fontSize: 12,
  } as ViewStyle,
});

const ALLOWED_INVERSION_QUESTION: TooltipModalProps = {
  title: '反転機能の使用許可',
  description:
    'TapNovelMakerでは、立ち絵を使用する際に向きを反転する機能がございます。\nタップライターが本イラストに反転機能を使用することを認めるかどうかを設定できます。\n意味合いが大きく変わる場合を除いて、反転機能の使用を許可した方が購入につながりやすいです。\n尚、反転機能の使用可と使用不可で同じイラストを登録することはできません。',
};

const PRICE_QUESTION: TooltipModalProps = {
  title: '販売額',
  description:
    '100円単位で300円～900円まで設定できます。\n尚、TapNovelMakerでのイラスト使用権の販売と\nなるため、著作権の譲渡は発生いたしません。',
};
