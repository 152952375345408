import React from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {useActionSheet} from '@expo/react-native-action-sheet';

import ActorInfo from './ActorInfo';

import {EllipsisHorizontalIcon} from '@/components/icons';

import {getDoubleResizeImageUrl} from '@/helpers/ImageHelper';

import {useDestroyMutation} from '@/hooks/swr';
import useResponseErrorModal from '@/hooks/useResponseErrorModal';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

import Actor from '@/entities/Actor';

interface Props {
  actor: Actor;
  onNotifyActorDelete: (actor: Actor) => void;
}

const ActorListItem: React.FC<Props> = props => {
  const {actor, onNotifyActorDelete} = props;
  const linkTo = useLinkTo();
  const {showActionSheetWithOptions} = useActionSheet();
  const {show} = useResponseErrorModal();
  const onPress = React.useCallback(() => linkTo(`/actors/${actor.id}`), []);
  const {trigger, error} = useDestroyMutation(
    `/api/creator/actors/${actor.id}`,
  );
  React.useEffect(() => {
    if (error) {
      show({error});
    }
  }, [error]);
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(actor, {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      }),
    }),
    [actor],
  );
  const onPressMenu = React.useCallback(() => {
    const options = ['削除', 'キャンセル'];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        tintColor: '#383838',
        destructiveColor: '#f23406',
        cancelButtonTintColor: '#999',
        textStyle: {fontSize: 14},
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case destructiveButtonIndex:
            trigger({}).then(() => {
              onNotifyActorDelete(actor);
              sendDefaultCustomEvent({
                resourceType: 'actor',
                resourceId: actor.id,
                actionName: 'destroy',
              });
            });
            break;
          case cancelButtonIndex:
        }
      },
    );
  }, []);
  return (
    <TouchableOpacity
      style={styles.container}
      activeOpacity={0.8}
      onPress={onPress}>
      <Image source={source} style={styles.image} />
      <ActorInfo
        actor={actor}
        visibleStatusUpdateDate={true}
        displayPrice={true}
      />
      <View style={styles.right}>
        <Pressable style={styles.menu} onPress={onPressMenu}>
          <EllipsisHorizontalIcon />
        </Pressable>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(ActorListItem);

const IMAGE_WIDTH = 70;
const IMAGE_HEIGHT = 100;

const styles = StyleSheet.create({
  container: {
    margin: 10,
    flexDirection: 'row',
  } as ViewStyle,
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  } as ImageStyle,
  right: {
    flex: 1,
    alignItems: 'flex-end',
  } as ViewStyle,
  menu: {
    padding: 10,
    paddingTop: 2,
    paddingRight: 6,
  } as ViewStyle,
});
