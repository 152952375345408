import React from 'react';

import OauthAccountItem from './OauthAccountItem';

import {FacebookIcon} from '@/components/icons/social';

import useFacebookConnect from '@/hooks/social_connects/useFacebookConnect';

interface Props {
  connected: boolean;
  onRequestUpdate: () => void;
}

const FacebookAccountItem: React.FC<Props> = props => {
  const {connected, onRequestUpdate} = props;
  const {connect, disconnect} = useFacebookConnect(onRequestUpdate);
  return (
    <OauthAccountItem
      icon={<FacebookIcon />}
      label={'Facebook'}
      connected={connected}
      onPress={connected ? disconnect : connect}
    />
  );
};

export default React.memo(FacebookAccountItem);
