import React from 'react';
import {useNavigation} from '@react-navigation/native';

import ActorCharacterFacePreview from './components/ActorCharacterFacePreview';
import ActorCharacterFaceGridList from './components/ActorCharacterFaceGridList';
import CloseButton from './components/CloseButton';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import Actor from '@/entities/Actor';
import ActorCharacterFace from '@/entities/ActorCharacterFace';

import useCurrentUser from '@/hooks/useCurrentUser';
import {useGet} from '@/hooks/swr';

export interface Params {
  actorId: number;
}

const Page = (props: ScreenProps<'ActorPreview'>) => {
  const {route} = props;
  const {actorId} = route.params;
  const navigation = useNavigation();
  const {currentUser} = useCurrentUser();
  const {data: actor, error} = useGet<Actor>(`/api/creator/actors/${actorId}`);
  const {data: actorCharacterFaces} = useGet<ActorCharacterFace[]>(
    `/api/creator/actor_character_faces?actor_id=${actorId}`,
  );
  const [selectedActorCharacterFace, setSelectedActorCharacterFace] =
    React.useState<ActorCharacterFace | null>(
      actorCharacterFaces ? actorCharacterFaces[0] || null : null,
    );
  React.useEffect(() => {
    setSelectedActorCharacterFace(
      actorCharacterFaces ? actorCharacterFaces[0] || null : null,
    );
  }, [actorCharacterFaces]);
  const onSelectActorCharacterFace = React.useCallback(
    (actorCharacterFace: ActorCharacterFace) => {
      setSelectedActorCharacterFace(actorCharacterFace);
    },
    [],
  );
  return (
    <Layout title={'プレビュー'} close={true} scrollable={false} error={error}>
      {actor &&
        actorCharacterFaces &&
        selectedActorCharacterFace &&
        currentUser && (
          <>
            <ActorCharacterFacePreview
              currentUser={currentUser}
              actor={actor}
              selectedActorCharacterFace={selectedActorCharacterFace}
            />
            <ActorCharacterFaceGridList
              actorCharacterFaces={actorCharacterFaces}
              selectedActorCharacterFace={selectedActorCharacterFace}
              onSelectActorCharacterFace={onSelectActorCharacterFace}
            />
            <CloseButton onPress={navigation.goBack} />
          </>
        )}
    </Layout>
  );
};

export default React.memo(Page);
