import useSocialConnect from './useSocialConnect';

import FacebookLogin from '@/services/social_logins/FacebookLogin';

const useFacebookConnect = (callback?: () => void) => {
  return useSocialConnect(
    new FacebookLogin(),
    '/api/creator/social_accounts/facebook_accounts/registration',
    'facebookAccount',
    callback,
  );
};

export default useFacebookConnect;
