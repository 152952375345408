import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

export interface Props {
  icon: React.ReactElement<any, any> | null;
  label: string;
  url: string;
}

const MenuListItem: React.FC<Props> = props => {
  const {icon, label, url} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => linkTo(url), []);
  if (!icon) {
    return <View style={styles.container} />;
  }
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <>
        {icon}
        <Text style={styles.label}>{label}</Text>
      </>
    </TouchableOpacity>
  );
};

export default React.memo(MenuListItem);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 9,
    fontWeight: 'bold',
    marginTop: 10,
  } as TextStyle,
});
