import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import numeral from 'numeral';

import Balance from '@/entities/Balance';

interface Props {
  balance: Balance;
}

const BalanceSection: React.FC<Props> = props => {
  const {balance} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>収益残高</Text>
      <View style={styles.box}>
        <Text style={styles.value}>
          <Text style={styles.valueUnit}>￥</Text>
          {numeral(balance.amount).format('0,0')}
        </Text>
      </View>
    </View>
  );
};

export default React.memo(BalanceSection);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 117,
    backgroundColor: '#fafafa',
    padding: 16,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  box: {
    flex: 1,
    height: 65,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    borderRadius: 3,
  } as ViewStyle,
  value: {
    color: '#383838',
    fontSize: 25,
    fontWeight: 'bold',
  } as TextStyle,
  valueUnit: {
    fontSize: 20,
  } as TextStyle,
});
