import React from 'react';

import {ModalProps} from 'react-native';

import Backdrop from './Backdrop';
import Dialog, {Props as DialogProps} from './Dialog';

export interface Props extends ModalProps, DialogProps {}

const BasicModal: React.FC<Props> = props => {
  const {
    title,
    dialogAnimationProps,
    children,
    onRequestClose,
    onPressOK,
    ...modalProps
  } = props;
  return (
    <Backdrop {...modalProps} onRequestClose={onRequestClose}>
      <Dialog
        title={title}
        dialogAnimationProps={dialogAnimationProps}
        onRequestClose={onRequestClose}
        onPressOK={onPressOK}>
        {children}
      </Dialog>
    </Backdrop>
  );
};

export default React.memo(BasicModal);
