import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

const Attention: React.FC = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        TapNovel（読書サービス）とは別サービスの、{'\n'}
        TapNovelCreator（素材サービス）のプロフィールとなります。
      </Text>
    </View>
  );
};

export default React.memo(Attention);

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: '#f5c71c',
    marginHorizontal: 16,
    marginTop: 32,
  } as ViewStyle,
  text: {
    color: '#e7b600',
    fontSize: 10,
    marginTop: 10,
    marginBottom: 16,
    marginHorizontal: 12,
    textAlign: 'center',
  } as TextStyle,
});
