import React from 'react';
import {
  Controller,
  UseControllerProps,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
  FieldErrors,
} from 'react-hook-form';

import TextLink, {Props as TextLinkProps} from './TextLink';
import ErrorMessage from './ErrorMessage';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends TextLinkProps {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  errors?: FieldErrors<TFieldValues>;
}

function ControlledTextLink<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const {controllerProps, errors, ...textLinkProps} = props;
  const {name, rules, control} = controllerProps;
  const message = errors ? errors[name]?.message?.toString() : null;
  const render = React.useCallback(
    ({field: {value}}: {field: ControllerRenderProps<TFieldValues, TName>}) => (
      <>
        {message && <ErrorMessage>{message}</ErrorMessage>}
        <TextLink {...textLinkProps} value={value} />
      </>
    ),
    [message, props.data],
  );
  return (
    <Controller control={control} rules={rules} render={render} name={name} />
  );
}

export default React.memo(ControlledTextLink) as typeof ControlledTextLink;
