import React from 'react';

import NotFound from './NotFound';

interface Props {
  error: any;
}
const ErrorContent: React.FC<Props> = props => {
  const {error} = props;
  if (!error) {
    return null;
  }
  switch (error.status) {
    case 404:
      return <NotFound />;
  }
  return null;
};

export default React.memo(ErrorContent);
