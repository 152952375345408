import * as React from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import Pagination from './Pagination';

import {AlertTriangleIcon} from '@/components/icons';

import CurrentUserStatus from '@/entities/CurrentUserStatus';

interface Props {
  currentUserStatus: CurrentUserStatus;
  onSubmit: () => void;
  onPressCancel: () => void;
}

const Form: React.FC<Props> = props => {
  const [width, setWidth] = React.useState<number | null>(null);
  const onLayout = React.useCallback((e: LayoutChangeEvent) => {
    setWidth(e.nativeEvent.layout.width);
  }, []);
  return (
    <View style={styles.container} onLayout={onLayout}>
      <View style={styles.alert}>
        <AlertTriangleIcon color={'#e7b600'} size={32} />
        <Text style={styles.alertText}>注意</Text>
      </View>
      {width && <Pagination width={width} {...props} />}
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  alert: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 32,
  } as ViewStyle,
  alertText: {
    color: '#e7b600',
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 5,
  } as TextStyle,
});
