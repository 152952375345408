import React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import Form, {Inputs} from './components/Form';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import {buildFieldErrors} from '@/helpers/FormHelper';
import {mutateActor} from '@/helpers/swr/MutateHelper';
import {get} from '@/services/fetchers';

import {useUpdateMutation} from '@/hooks/swr';
import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import useResponseErrorModal from '@/hooks/useResponseErrorModal';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

import Actor from '@/entities/Actor';
import ActorCharacter from '@/entities/ActorCharacter';
import ActorCharacterFace from '@/entities/ActorCharacterFace';

export interface Params {
  id: number;
}

const Page = (props: ScreenProps<'ActorEdit'>) => {
  const {route} = props;
  const {id} = route.params;
  const navigation = useDefaultNavigation();
  const {show} = useResponseErrorModal();
  const [actor, setActor] = React.useState<Actor | null>(null);
  const [actorCharacterFaces, setActorCharacterFaces] = React.useState<
    ActorCharacterFace[] | null
  >(null);
  const [actorError, setActorError] = React.useState<any>(null);
  useFocusEffect(
    React.useCallback(() => {
      get<Actor>(`/api/creator/actors/${id}`)
        .then(actor => {
          setActor(actor);
          get<ActorCharacterFace[]>(
            `/api/creator/actor_character_faces?actor_id=${id}`,
          ).then(actorCharacterFaces => {
            setActorCharacterFaces(actorCharacterFaces);
          });
        })
        .catch(setActorError);
    }, []),
  );
  const {trigger, error, isMutating} = useUpdateMutation<ActorCharacter>(
    actor?.actorCharacter?.id
      ? `/api/creator/actor_characters/${actor.actorCharacter.id}`
      : null,
  );
  React.useEffect(() => {
    if (error && Object.hasOwn(error?.info, 'thisName')) {
      show({error});
    }
  }, [error]);
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(actorCharacter => {
      if (actorCharacter) {
        navigation.navigate('Actor', {id});
        mutateActor({id});
        sendDefaultCustomEvent({
          resourceType: 'actor',
          resourceId: actorCharacter.actorId,
          actionName: 'update',
        });
      }
    });
  }, []);
  const defaultValues = convertFormRecordToDefaultValues(actor);
  return (
    <Layout
      title={'イラスト情報'}
      back={true}
      scrollable={false}
      loading={isMutating}
      error={actorError}>
      {defaultValues && actor && (
        <Form
          isNew={false}
          actor={actor}
          actorCharacterFaces={actorCharacterFaces}
          defaultValues={defaultValues}
          errors={convertResponseToErrors(error)}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);

const convertFormRecordToDefaultValues = (
  record: Actor | null,
): Inputs | null => {
  if (!record) {
    return null;
  }
  return {
    name: record.name,
    costumeName: record.defaultCostumeName,
    image: record.hasImage
      ? ({uri: record.originalImageUrl} as unknown as File)
      : null,
    primaryActorCharacterFaceActorCharacterFaceId:
      record.actorCharacter?.primaryActorCharacterFaceActorCharacterFaceId,
  };
};

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {actorCharacter: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    name: 'name',
    costumeName: ['costumeName', 'thisName'],
  });
};
