import {modalScreenOptions} from '../ScreenOptions';
import RootStack from '../RootStack';

import UserSignIn from '@/pages/users/sign_in';
import UserSignUp from '@/pages/users/sign_up';
import UserPasswordNew from '@/pages/users/password/new';
import UserPasswordEdit from '@/pages/users/password/edit';

const MemberOnlyScreens = (
  <RootStack.Group screenOptions={modalScreenOptions}>
    <RootStack.Screen
      name={'UserSignIn'}
      component={UserSignIn}
      options={{title: 'ログイン'}}
    />
    <RootStack.Screen
      name={'UserSignUp'}
      component={UserSignUp}
      options={{title: '会員登録'}}
    />
    <RootStack.Screen
      name={'UserPasswordNew'}
      component={UserPasswordNew}
      options={{title: 'パスワード再設定'}}
    />
    <RootStack.Screen
      name={'UserPasswordEdit'}
      component={UserPasswordEdit}
      options={{title: 'パスワード再設定'}}
    />
  </RootStack.Group>
);

export default MemberOnlyScreens;
