import React from 'react';
import {
  Image,
  ImageStyle,
  LayoutChangeEvent,
  PixelRatio,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {getDoubleResizeImageUrl} from '@/helpers/ImageHelper';

import CurrentUser from '@/entities/CurrentUser';
import Actor from '@/entities/Actor';
import ActorCharacterFace from '@/entities/ActorCharacterFace';

import backgroundUri from '@/assets/images/preview/background.png';
import characterLeftUri from '@/assets/images/preview/character-left.png';
import characterRightUri from '@/assets/images/preview/character-right.png';

interface Props {
  currentUser: CurrentUser;
  actor: Actor;
  selectedActorCharacterFace: ActorCharacterFace;
}

const ActorCharacterFacePreview: React.FC<Props> = props => {
  const {currentUser, actor, selectedActorCharacterFace} = props;
  const [stageSize, setStageSize] = React.useState<
    {width: number; height: number} | undefined
  >(undefined);
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(
        selectedActorCharacterFace,
        {
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        },
        {nocanvas: true},
      ),
    }),
    [selectedActorCharacterFace],
  );
  const onLayout = React.useCallback((e: LayoutChangeEvent) => {
    const {width} = e.nativeEvent.layout;
    setStageSize({
      width,
      height: Math.round((width / 375) * 304),
    });
  }, []);
  const height = stageSize
    ? PixelRatio.roundToNearestPixel(stageSize.height * (10.0 / 12.0) * 1.1)
    : undefined;
  const width = height
    ? PixelRatio.roundToNearestPixel(height * (2039 / 2894))
    : undefined;
  return (
    <View style={styles.container} onLayout={onLayout}>
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Text style={styles.name}>{actor.name}</Text>
          <Text style={styles.costumeName}>{actor.defaultCostumeName}</Text>
        </View>
        <View style={styles.headerRight}>
          <Text style={styles.name}>イラスト：{currentUser.userName}</Text>
        </View>
      </View>
      <View style={[styles.body, stageSize]}>
        <Image source={backgroundUri} style={stageSize} />
        <Image
          source={characterLeftUri}
          style={[
            styles.image,
            {height, width, left: width ? -width / 6 - 4 : undefined},
          ]}
        />
        <Image
          source={characterRightUri}
          style={[
            styles.image,
            {height, width, right: width ? -width / 6 - 4 : undefined},
          ]}
        />
        <Image
          source={source}
          style={[
            styles.image,
            {height, width, left: width ? width / 2 - 10 : undefined},
          ]}
        />
      </View>
    </View>
  );
};

export default React.memo(ActorCharacterFacePreview);

const IMAGE_WIDTH = 395;
const IMAGE_HEIGHT = 560;

const styles = StyleSheet.create({
  container: {} as ViewStyle,
  header: {
    width: '100%',
    height: 65,
    backgroundColor: '#efefef',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingHorizontal: 16,
  } as ViewStyle,
  headerLeft: {} as ViewStyle,
  headerRight: {} as ViewStyle,
  name: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  costumeName: {
    color: '#383838',
    fontSize: 10,
    marginTop: 4,
  } as TextStyle,
  body: {
    width: '100%',
    height: 257,
    backgroundColor: '#fafafa',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    overflow: 'hidden',
  } as ViewStyle,
  image: {
    position: 'absolute',
    bottom: -23,
  } as ImageStyle,
});
