import React from 'react';
import {ListRenderItemInfo, FlatList} from 'react-native';

import DefaultLinkListItem from './DefaultLinkListItem';

import {LinkItem} from '@/components/value/types';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';

interface Props {
  data: Array<LinkItem>;
}

const DefaultLinkList: React.FC<Props> = props => {
  const {data} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<LinkItem>) => (
      <DefaultLinkListItem item={info.item} />
    ),
    [],
  );
  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(DefaultLinkList);
