import React from 'react';
import {useForm} from 'react-hook-form';
import {useNavigation} from '@react-navigation/native';

import Attention from './components/Attention';
import Form, {Inputs} from './components/Form';

import {Layout, RightButton, CancelButton} from '@/components/layout';

import CurrentUser from '@/entities/CurrentUser';

import useCurrentUserRegistration from '@/hooks/useCurrentUserRegistration';
import {buildFieldErrors} from '@/helpers/FormHelper';

export interface Params {}

const Page = () => {
  const {
    currentUser,
    update,
    updateResponse: {isMutating, error},
  } = useCurrentUserRegistration();
  const navigation = useNavigation();
  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm<Inputs>({
    defaultValues: convertEntityToFormValues(currentUser),
  });
  const goBack = React.useCallback(() => {
    navigation.goBack();
  }, []);
  const onPress = handleSubmit(data => {
    update(convertFormValuesToPostParams(data)).then(res => {
      if (res) {
        goBack();
      }
    });
  });
  const leftButton = React.useMemo(() => {
    if (!currentUser?.enabled) {
      return null;
    }
    return {
      children: <CancelButton onPress={goBack} />,
    };
  }, []);
  const rightButton = React.useMemo(
    () => ({
      children: (
        <RightButton disabled={!isValid} onPress={onPress}>
          保存
        </RightButton>
      ),
    }),
    [isValid],
  );
  return (
    <Layout
      title={'プロフィール'}
      leftButton={leftButton}
      rightButton={rightButton}
      loading={isMutating}>
      <Attention />
      <Form control={control} errors={convertResponseToErrors(error)} />
    </Layout>
  );
};

export default React.memo(Page);

const convertEntityToFormValues = (currentUser: CurrentUser | null) => {
  return {
    userName: currentUser?.userName,
    description: currentUser?.userProfile?.description || '',
    image: currentUser?.userProfile?.hasImage
      ? ({uri: currentUser?.userProfile.originalImageUrl} as unknown as File)
      : null,
    birthday: currentUser?.userProfile?.birthday,
    homePageUrl: currentUser?.userProfile?.homePageUrl || '',
    twitterAccountUrl: currentUser?.userProfile?.twitterAccountUrl || '',
  };
};

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {
    apiCreatorUser: {
      userName: formValues.userName,
      userProfileAttributes: {
        birthday: formValues.birthday,
        description: formValues.description,
        image: formValues.image,
        homePageUrl: formValues.homePageUrl,
        twitterAccountUrl: formValues.twitterAccountUrl,
      },
    },
  };
};

const convertResponseToErrors = (res: any) => {
  return buildFieldErrors(res, {
    userName: ['creatorUser.userName', 'creatorUser.theUserName'],
    description: 'creatorUser.creatorUserProfile.description',
    image: 'creatorUser.creatorUserProfile.image',
    homePageUrl: 'creatorUser.creatorUserProfile.homePageUrl',
    twitterAccountUrl: 'creatorUser.creatorUserProfile.twitterAccountUrl',
  });
};
