import React from 'react';

import Form, {Inputs} from './components/Form';

import {useActorCategoryItems} from './hooks/useActorCategoryItems';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import {findPreviousIndexForRouteName} from '@/helpers/NavigationHelper';
import {buildFieldErrors} from '@/helpers/FormHelper';
import {get} from '@/services/fetchers';

import useDefaultNavigation from '@/hooks/useDefaultNavigation';
import {useUpdateMutation} from '@/hooks/swr';
import {sendDefaultCustomEvent} from '@/helpers/AnalyticsHelper';

import Actor from '@/entities/Actor';

export interface Params {
  id: number;
}

const Page = (props: ScreenProps<'ActorCategoriesEdit'>) => {
  const {route} = props;
  const {id} = route.params;
  const navigation = useDefaultNavigation();
  const [actor, setActor] = React.useState<Actor | null>(null);
  const [actorError, setActorError] = React.useState<any>(null);
  React.useEffect(() => {
    get<Actor>(`/api/creator/actors/${id}`).then(setActor).catch(setActorError);
  }, []);
  const {trigger, isMutating, error} = useUpdateMutation<Actor>(
    `/api/creator/actors/${id}`,
  );
  const {itemsList, convertFormInputs} = useActorCategoryItems();
  const defaultValues = React.useMemo((): Inputs | undefined => {
    if (convertFormInputs && actor) {
      return {
        keywords: actor.tagList,
        ...convertFormInputs(actor.actorCategoryIds),
      };
    } else {
      return undefined;
    }
  }, [convertFormInputs, actor]);
  const onSubmit = React.useCallback((data: Inputs) => {
    trigger(convertFormValuesToPostParams(data)).then(actor => {
      if (actor) {
        navigation.navigate('ActorProduct', {actorId: id});
        sendDefaultCustomEvent({
          resourceType: 'actor_categories',
          resourceId: actor.id,
          actionName: 'update',
        });
      }
    });
  }, []);
  const onPressClose = React.useCallback(() => {
    if (findPreviousIndexForRouteName(navigation, 'Actor') === -1) {
      navigation.replace('Actor', {id});
    } else {
      navigation.navigate('Actor', {id});
    }
  }, []);
  return (
    <Layout
      title={'イラスト情報'}
      close={true}
      loading={isMutating}
      error={actorError}
      onPressClose={onPressClose}>
      {defaultValues && itemsList && (
        <Form
          isNew={false}
          itemsList={itemsList}
          defaultValues={defaultValues}
          errors={convertResponseToErrors(error)}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Page);

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {
    actor: {
      actorCategoryIds: [
        formValues.attributeId,
        formValues.humanGenderId,
        ...(formValues.humanAgeIds || []),
        ...(formValues.humanFacialFeatureIds || []),
        formValues.humanBodyShapeId,
        formValues.humanHeightId,
        formValues.humanHairColorId,
        formValues.animalTypeId,
        formValues.otherTypeId,
        formValues.otherFormId,
        formValues.otherSizeId,
      ].filter(value => value),
      tagList: formValues.keywords,
    },
  };
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    image: [
      'image',
      'miniMagickImageValid',
      'miniMagickImageWidth',
      'miniMagickImageHeight',
      'miniMagickImageType',
      'miniMagickImageDataType',
    ],
    name: 'name',
    costumeName: 'costumeName',
    faceName: 'faceName',
  });
};
