import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {OutlineButton} from '@/components/ui/buttons';

const SignInButton = () => {
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo('/users/sign_in');
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.message}>会員登録が済んでいる方</Text>
      <OutlineButton style={styles.signInButton} onPress={onPress}>
        ログイン
      </OutlineButton>
    </View>
  );
};

export default React.memo(SignInButton);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 10,
    marginBottom: 16,
  } as TextStyle,
  signInButton: {
    width: 180,
    height: 34,
  } as ViewStyle,
});
