import React from 'react';

import Form, {Inputs} from './components/Form';
import SignUpButton from './components/SignUpButton';

import {Layout} from '@/components/layout';

import useCurrentUserSession from '@/hooks/useCurrentUserSession';
import {buildFieldErrors} from '@/helpers/FormHelper';

export interface Params {}

const Page = () => {
  const {
    create,
    createResponse: {isMutating, error},
  } = useCurrentUserSession();
  const onSubmit = React.useCallback((data: Inputs) => {
    create(convertFormValuesToPostParams(data));
  }, []);
  return (
    <Layout
      title={'ログイン'}
      close={true}
      hideHeaderBottomBorder={true}
      scrollViewStyle={scrollViewStyle}
      loading={isMutating}>
      <Form errors={convertResponseToErrors(error)} onSubmit={onSubmit} />
      <SignUpButton />
    </Layout>
  );
};

export default React.memo(Page);

const scrollViewStyle = {
  backgroundColor: '#fafafa',
};

const convertFormValuesToPostParams = (formValues: Inputs) => {
  return {apiCreatorUser: formValues};
};

const convertResponseToErrors = (res?: ResponseError) => {
  return buildFieldErrors(res, {
    email: 'error',
    password: 'error',
  });
};
