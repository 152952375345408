import * as React from 'react';
import {ScrollView, View} from 'react-native';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

import {Layout} from '@/components/layout';

import {useRawCreateMutation} from '@/hooks/swr';
import useCurrentUserStatus from '@/hooks/useCurrentUserStatus';

type Step = 1 | 2 | 3;

interface Props {
  width: number;
}
const Pagination: React.FC<Props> = props => {
  const {width} = props;
  const {trigger} = useRawCreateMutation(
    '/api/creator/user_service_agreements',
  );
  const {get} = useCurrentUserStatus();
  const scrollViewRef = React.useRef<ScrollView | null>(null);
  const [step, setStep] = React.useState<Step>(1);
  const goNext = React.useCallback((step: Step) => {
    setStep(step);
    scrollViewRef.current?.scrollTo({x: width * (step - 1)});
  }, []);
  const onPressStep1 = React.useCallback(() => {
    goNext(2);
  }, []);
  const onPressStep2 = React.useCallback(() => {
    goNext(3);
  }, []);
  const onPressStep3 = React.useCallback(() => {
    trigger({
      creatorUserServiceAgreement: {
        creatorServiceAgreementCode: 'tapnovel_creator',
      },
    }).then(res => {
      if (!res?.ok) {
        return;
      }
      get();
    });
  }, []);
  const goPrev = React.useCallback(
    (step: Step) => {
      setStep(step);
      scrollViewRef.current?.scrollTo({x: width * (step - 1)});
    },
    [step],
  );
  const onPressBack1 = React.useCallback(() => {}, []);
  const onPressBack2 = React.useCallback(() => {
    goPrev(1);
  }, []);
  const onPressBack3 = React.useCallback(() => {
    goPrev(2);
  }, []);
  const style = React.useMemo(() => {
    return {width, flex: 1};
  }, [width]);
  return (
    <Layout
      title={stepToTitle[step]}
      back={step !== 1}
      onPressBack={
        step === 3 ? onPressBack3 : step === 2 ? onPressBack2 : onPressBack1
      }>
      <ScrollView
        ref={scrollViewRef}
        pagingEnabled={true}
        horizontal={true}
        scrollEnabled={false}>
        <ScrollView style={style}>
          <Step1 onPress={onPressStep1} />
        </ScrollView>
        <ScrollView style={style}>
          <Step2 onPress={onPressStep2} />
        </ScrollView>
        <ScrollView style={style}>
          <Step3 onPress={onPressStep3} />
        </ScrollView>
      </ScrollView>
    </Layout>
  );
};

export default React.memo(Pagination);

const stepToTitle = {
  1: 'イラストの公開',
  2: 'イラストの販売',
  3: '注意事項',
};
