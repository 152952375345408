import React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import Collapsible from 'react-native-collapsible';

import Help from '@/entities/Help';

interface Props {
  help: Help;
  collapsed: boolean;
  onPressHelp: (help: Help) => void;
}

const HelpListItem: React.FC<Props> = props => {
  const {help, collapsed, onPressHelp} = props;
  const onPress = React.useCallback(() => onPressHelp(help), [onPressHelp]);
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.row}>
        <View style={styles.rowInner}>
          <Text style={styles.title}>{help.title}</Text>
          <View style={collapsed ? styles.arrowCollapsed : styles.arrow} />
        </View>
      </View>
      <Collapsible collapsed={collapsed}>
        <Text style={styles.content}>{help.content}</Text>
      </Collapsible>
    </Pressable>
  );
};

export default React.memo(HelpListItem);

const COLLAPSED_HEIGHT = 50;

const arrow = {
  width: 9,
  height: 9,
  borderColor: '#999999',
  borderRightWidth: 1.5,
  borderBottomWidth: 1.5,
  transform: [{rotate: `225deg`}],
} as ViewStyle;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  row: {
    backgroundColor: 'white',
    height: COLLAPSED_HEIGHT,
    justifyContent: 'center',
  } as ViewStyle,
  rowInner: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
  } as ViewStyle,
  content: {
    color: '#222222',
    fontSize: 15,
    lineHeight: 20,
    padding: 16,
  } as TextStyle,
  title: {
    flex: 1,
    marginRight: 5,
    color: '#222222',
    fontSize: 13,
    fontWeight: 'bold',
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as TextStyle,
  arrow,
  arrowCollapsed: {
    ...arrow,
    transform: [{rotate: `45deg`}],
  } as ViewStyle,
});
