import React from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  ImageURISource,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {unstable_createElement} from 'react-native-web';

import Alert from '@/components/alert/Alert';
import AddImageIcon from '@/components/icons/AddImageIcon';

import {isAndroid} from '@/helpers/UserAgentHelper';

interface Props {
  imageSource: ImageSourcePropType | null;
  imageStyle: StyleProp<ImageStyle>;
  imageContainerStyle: StyleProp<ViewStyle>;
  noImage?: React.ReactNode;
  visibleImageIcon?: boolean;
  imageIconStyle?: StyleProp<ViewStyle>;
  imageIconSize?: number;
  disabled?: boolean;
  onChangeImage?: (file: File) => void;
  onFailImage?: (message: string) => void;
}

export interface ImagePickerAdapterInstance {
  openFileBrowser: () => void;
}

const ImagePickerAdapter = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<ImagePickerAdapterInstance>) => {
    const {
      imageStyle,
      imageContainerStyle,
      noImage,
      visibleImageIcon,
      imageIconStyle,
      imageIconSize,
      disabled,
      onChangeImage,
      onFailImage,
    } = props;
    const inputRef = React.useRef<HTMLInputElement>();
    const [imageSource, setImageSource] =
      React.useState<ImageSourcePropType | null>(props.imageSource);
    React.useEffect(() => {
      setImageSource(props.imageSource);
      if (props.imageSource === null && inputRef.current) {
        inputRef.current.value = '';
      }
    }, [props.imageSource]);
    const handleComplete = React.useCallback((data: CompleteData) => {
      const uri = data.uri;
      const file = data.file;
      (file as any).uri = uri;
      if (onChangeImage) {
        onChangeImage(file);
      }
      setImageSource({uri});
    }, []);
    const handleFail = React.useCallback((data: FailData) => {
      if (onFailImage) {
        onFailImage(`${data.error}`);
      } else {
        Alert.alert(`${data.error}`);
      }
    }, []);
    React.useImperativeHandle(ref, () => ({
      openFileBrowser: () => {
        inputRef.current?.click();
      },
    }));
    return (
      <View style={styles.container}>
        {unstable_createElement('label', {
          onChange: handleUpload(handleComplete, handleFail),
          style: disabled ? styles.labelDisabled : styles.label,
          children: (
            <>
              {unstable_createElement('input', {
                ref: inputRef,
                style: styles.input,
                type: 'file',
                disabled,
                accept: isAndroid
                  ? 'image/png,image/jpg,image/jpeg,video/*'
                  : 'image/png,image/jpg,image/jpeg',
              })}
              <View style={[imageStyle, imageContainerStyle]}>
                {imageSource === null ? (
                  noImage
                ) : (
                  <Image style={imageStyle} source={imageSource} />
                )}
                {visibleImageIcon ? (
                  <View style={[styles.imageIcon, imageIconStyle]}>
                    <AddImageIcon size={imageIconSize || 20} />
                  </View>
                ) : null}
              </View>
            </>
          ),
        })}
      </View>
    );
  },
);

export default React.memo(ImagePickerAdapter);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  label: {
    display: 'flex',
    cursor: 'pointer',
  } as any,
  labelDisabled: {
    display: 'flex',
  } as any,
  input: {
    border: 0,
    width: 0,
    height: 0,
    visibility: 'hidden' as any,
    display: 'none',
  } as any,
  imageIcon: {
    position: 'absolute',
    backgroundColor: '#efefef',
    right: -5,
    bottom: -5,
    width: 36,
    height: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});

interface CompleteData {
  cancelled: boolean;
  uri: string;
  width: number;
  height: number;
  file: File;
}

interface FailData {
  cancelled: boolean;
  error: any;
}

const handleUpload = (
  onComplete: (data: CompleteData) => void,
  onFail: (data: FailData) => void,
) => {
  return (event: React.SyntheticEvent<HTMLLabelElement>) => {
    if (!event.target) {
      return;
    }
    const target = event.target as HTMLInputElement;
    if (!target.files) {
      return;
    }
    const file = target.files[0];
    if (!file) {
      return;
    }

    if (!/^image\/(png|jpg|jpeg)/.test(file.type)) {
      const data = {
        cancelled: false,
        error: '画像はPNGかJPEGでアップロードしてください',
      };
      onFail(data);
      return;
    }
    if (file.size > Math.pow(1024, 2) * 10) {
      const data = {
        cancelled: false,
        error: '画像は10MB以下でアップロードしてください',
      };
      onFail(data);
      return;
    }

    const reader = new FileReader();

    reader.onload = (event: any) => {
      const image = new window.Image();

      if (!event.target || !event.target.result) {
        return;
      }
      if (typeof event.target.result !== 'string') {
        return;
      }
      image.src = event.target.result;
      image.onload = (event: Event) => {
        if (!event.target) {
          return;
        }
        const target = event.target as any;
        const {width, height} = target;
        const uri = target.src;
        const data = {cancelled: false, uri, width, height, file};
        onComplete(data);
      };
    };

    reader.onerror = err => {
      const data = {
        cancelled: false,
        error: convertCodeToMessage(reader.error?.code),
      };
      onFail(data);
    };

    reader.onabort = err => {
      const data = {
        cancelled: false,
        error: convertCodeToMessage(reader.error?.code),
      };
      onFail(data);
    };

    reader.readAsDataURL(file);
  };
};

const convertCodeToMessage = (code?: number | null) => {
  switch (code) {
    case DOMException.NOT_FOUND_ERR:
      return 'ファイルが見つかりません';
    case DOMException.SECURITY_ERR:
      return 'セキュリティ・エラーです';
    case DOMException.ABORT_ERR:
      return '読み込みが中止されました';
    default:
      return `エラーが発生しました(エラーコード: ${code})`;
  }
};
