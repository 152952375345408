import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {useForm, FieldErrors} from 'react-hook-form';

import {
  Field,
  ControlledInput,
  ControlledLabel,
  ControlledMultiSwitch,
} from '@/components/form';

import {PrimaryButton} from '@/components/ui/buttons';

import {Item} from '@/components/value/types';

export type Inputs = {
  taxable: boolean;
  acquiredRegistrationNumber: boolean;
  registrationNumber: string;
};

interface Props {
  errors?: FieldErrors<Inputs>;
  defaultValues?: Inputs;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {errors, defaultValues, onSubmit} = props;
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {isValid},
  } = useForm({
    defaultValues: defaultValues || {
      taxable: false,
      acquiredRegistrationNumber: false,
      registrationNumber: undefined,
    },
  });
  const onPress = handleSubmit(onSubmit);
  const taxable = watch('taxable');
  const acquiredRegistrationNumber = watch('acquiredRegistrationNumber');
  React.useEffect(() => {
    if (!taxable) {
      setValue('acquiredRegistrationNumber', false);
      setValue('registrationNumber', '', {shouldValidate: true});
    }
  }, [taxable]);
  React.useEffect(() => {
    if (!acquiredRegistrationNumber) {
      setValue('registrationNumber', '', {shouldValidate: true});
    }
  }, [acquiredRegistrationNumber]);
  return (
    <View style={styles.container}>
      <Field>
        <ControlledLabel
          label={'消費税の課税について'}
          control={control}
          name={'taxable'}
          errors={errors}
          badgeType={'required'}
          hiddenEnteredLabel={true}
        />
        <ControlledMultiSwitch
          items={TAXED_ITEMS}
          controllerProps={{
            control,
            name: 'taxable',
          }}
          errors={errors}
        />
      </Field>
      {taxable && (
        <Field>
          <ControlledLabel
            label={'登録番号の取得有無'}
            control={control}
            name={'acquiredRegistrationNumber'}
            errors={errors}
            badgeType={'required'}
            hiddenEnteredLabel={true}
          />
          <ControlledMultiSwitch
            items={HAS_REGISTRATION_NUMBER_ITEMS}
            controllerProps={{
              control,
              name: 'acquiredRegistrationNumber',
            }}
            errors={errors}
          />
        </Field>
      )}
      {acquiredRegistrationNumber && (
        <Field>
          <ControlledLabel
            label={'登録番号'}
            control={control}
            name={'registrationNumber'}
            errors={errors}
            badgeType={'required'}
            hiddenEnteredLabel={true}
          />
          <ControlledInput
            placeholder={'登録番号を入力'}
            controllerProps={{
              control,
              rules: {
                required: {value: true, message: '入力してください'},
              },
              name: 'registrationNumber',
            }}
          />
        </Field>
      )}
      <View style={styles.submit}>
        <PrimaryButton disabled={!isValid} onPress={onPress}>
          登録
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form) as typeof Form;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
    backgroundColor: 'white',
    paddingBottom: 67,
  } as ViewStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
});

const TAXED_ITEMS: Array<Item<boolean>> = [
  {
    label: '免税事業者',
    value: false,
  },
  {
    label: '課税事業者',
    value: true,
  },
];

const HAS_REGISTRATION_NUMBER_ITEMS: Array<Item<boolean>> = [
  {
    label: '未取得',
    value: false,
  },
  {
    label: '取得済み',
    value: true,
  },
];
