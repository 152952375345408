import React from 'react';

import TransferList from './components/TransferList';

import {Layout} from '@/components/layout';

import ScreenProps from '@/navigation/ScreenProps';

import {useGet, useCreateMutation} from '@/hooks/swr';

import Transfer from '@/entities/Transfer';

export interface Params {}

const Page = (props: ScreenProps<'SettingsTransferHistory'>) => {
  const [token, setToken] = React.useState<string | null>(null);
  const {
    data: transfers,
    error,
    mutate,
  } = useGet<Transfer[]>(
    `/api/creator/transfers`,
    {},
    {keepPreviousData: true},
  );
  const {trigger} = useCreateMutation<{token: string}>(
    '/api/creator/session_token',
  );

  React.useEffect(() => {
    mutate();
    trigger({}).then(res => {
      setToken(res?.token || null);
    });
  }, []);
  return (
    <Layout title={'振込履歴'} back={true}>
      {transfers && token ? (
        <TransferList transfers={transfers} token={token} />
      ) : null}
    </Layout>
  );
};

export default React.memo(Page);
