import React from 'react';
import {
  GestureResponderEvent,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

interface Props extends React.PropsWithChildren {
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

const Link: React.FC<Props> = props => {
  const {onPress} = props;
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text style={styles.text}>{props.children}</Text>
    </TouchableOpacity>
  );
};

export default React.memo(Link);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: 'white',
    fontSize: 15,
    padding: 20,
    paddingRight: 10,
  } as TextStyle,
});
