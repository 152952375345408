import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

interface Props {
  maxLength: number;
  length: number;
}

const TextCounter: React.FC<Props> = props => {
  const {maxLength, length} = props;
  return (
    <Text style={styles.container}>
      {length}/{maxLength}
    </Text>
  );
};
export default TextCounter;

const styles = StyleSheet.create({
  container: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
});
