import * as React from 'react';
import {LayoutChangeEvent, StyleSheet, View, ViewStyle} from 'react-native';

import Pagination from './Pagination';

const ServiceExplanation: React.FC = () => {
  const [width, setWidth] = React.useState<number | null>(null);
  const onLayout = React.useCallback((e: LayoutChangeEvent) => {
    setWidth(e.nativeEvent.layout.width);
  }, []);
  return (
    <View style={styles.container} onLayout={onLayout}>
      {width && <Pagination width={width} />}
    </View>
  );
};

export default React.memo(ServiceExplanation);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
});
