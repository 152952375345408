import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import TagRemoveButton from './TagRemoveButton';

interface Props {
  value: string;
  onRemove?: (value: string) => void;
}

const Tag: React.FC<Props> = props => {
  const {value, onRemove} = props;
  const onPress = React.useCallback(() => {
    if (!onRemove) {
      return;
    }
    onRemove(value);
  }, [onRemove]);
  return (
    <View style={styles.container}>
      <Text style={styles.value}>{value}</Text>
      {onRemove && (
        <View style={styles.removeButton}>
          <TagRemoveButton onPress={onPress} />
        </View>
      )}
    </View>
  );
};

export default React.memo(Tag);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: '#e5e5e5',
    borderRadius: 100,
    borderWidth: 1,
    height: 28,
    margin: 5,
  } as ViewStyle,
  value: {
    color: '#222',
    fontSize: 12,
    marginLeft: 20,
    marginRight: 30,
  } as TextStyle,
  removeButton: {
    position: 'absolute',
    right: 10,
  } as ViewStyle,
});
