import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import SocialShareButton from './SocialShareButton';

interface Props {
  url: string;
}

const CopyShareButton: React.FC<Props> = props => {
  const {url} = props;
  const onPress = React.useCallback(() => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(url);
    } else {
      const el = document.createElement('textarea');
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  }, [url]);
  return (
    <SocialShareButton color={'#efefef'} onPress={onPress}>
      <View style={styles.container}>
        <Text style={styles.text}>
          URLを{'\n'}
          コピー
        </Text>
      </View>
    </SocialShareButton>
  );
};

export default React.memo(CopyShareButton);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#e5e5e5',
    width: 48,
    height: 48,
    borderRadius: 24,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 10,
  } as TextStyle,
});
