import React from 'react';
import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

import {CheckCircleOIcon} from '@/components/icons';

interface Props extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
  checked: boolean;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

const CheckButton: React.FC<Props> = props => {
  const {style, checked, children, onPress} = props;
  return (
    <TouchableOpacity
      style={[checked ? styles.containerChecked : styles.container, style]}
      onPress={onPress}>
      <CheckCircleOIcon color={checked ? '#e7b600' : '#d5d5d5'} size={13} />
      <Text style={checked ? styles.textChecked : styles.text}>{children}</Text>
    </TouchableOpacity>
  );
};

export default React.memo(CheckButton);

const container = {
  flexDirection: 'row',
  backgroundColor: '#fff',
  borderWidth: 1,
  borderColor: '#d5d5d5',
  borderRadius: 4,
  paddingVertical: 9,
  alignItems: 'center',
  justifyContent: 'center',
} as ViewStyle;

const text = {
  color: '#666',
  fontSize: 12,
  marginLeft: 5,
  lineHeight: 12,
} as TextStyle;

const styles = StyleSheet.create({
  container,
  containerChecked: {
    ...container,
    borderColor: '#f5c71c',
  } as ViewStyle,
  text,
  textChecked: {
    ...text,
    color: '#e7b600',
  } as TextStyle,
});
