import {StackNavigationProp} from '@react-navigation/stack';

import {RootStackParamList} from '@/navigation/RootStackParamList';

export const matchPreviousRouteName = (
  navigation: StackNavigationProp<RootStackParamList>,
  routeName: keyof RootStackParamList,
  previousIndex = 0,
) => {
  const {routes} = navigation.getState();
  return routes[routes.length - 1 - previousIndex].name === routeName;
};

export const findPreviousIndexForRouteName = (
  navigation: StackNavigationProp<RootStackParamList>,
  routeName: keyof RootStackParamList,
) => {
  const {routes} = navigation.getState();
  const foundIndex = routes.findIndex(route => route.name === routeName);
  if (foundIndex === -1) {
    return -1;
  } else {
    return routes.length - 1 - foundIndex;
  }
};
