import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {useForm, FieldErrors} from 'react-hook-form';

import {Field, ControlledInput, Label} from '@/components/form';
import {OutlineButton} from '@/components/ui/buttons';
import SocialLoginButtons from '@/components/ui/SocialLoginButtons';

export type Inputs = {
  email: string;
  password: string;
};

interface Props {
  errors?: FieldErrors<Inputs>;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {errors, onSubmit} = props;
  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const onPress = handleSubmit(onSubmit);
  const linkTo = useLinkTo();
  const onPressNewPassword = React.useCallback(
    () => linkTo('/users/password/new'),
    [],
  );
  return (
    <View style={styles.container}>
      <Field>
        <Label>メールアドレス</Label>
        <ControlledInput
          placeholder={'メールアドレスを入力'}
          textContentType={'emailAddress'}
          keyboardType={'email-address'}
          onSubmitEditing={onPress}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
              pattern: {value: /.+@.+\.[A-Za-z0-9]{1,}/, message: '不正です'},
            },
            name: 'email',
          }}
          errors={errors}
        />
      </Field>
      <Field>
        <Label>パスワード</Label>
        <ControlledInput
          placeholder={'パスワードを入力'}
          secureTextEntry={true}
          textContentType={'password'}
          onSubmitEditing={onPress}
          controllerProps={{
            control,
            rules: {
              required: {value: true, message: '入力してください'},
            },
            name: 'password',
          }}
          errors={errors}
        />
      </Field>
      <View style={styles.submit}>
        <OutlineButton
          style={styles.submitButton}
          disabled={!isValid}
          onPress={onPress}>
          ログイン
        </OutlineButton>
      </View>
      <TouchableOpacity style={styles.newPassword} onPress={onPressNewPassword}>
        <Text style={styles.newPasswordText}>パスワードを忘れた方はこちら</Text>
      </TouchableOpacity>
      <Text style={{color: '#222222', fontSize: 11, textAlign: 'center'}}>
        お持ちのSNSアカウントではじめる
      </Text>
      <SocialLoginButtons />
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
    backgroundColor: 'white',
    paddingBottom: 67,
  } as ViewStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  submitButton: {
    marginVertical: 16,
  } as ViewStyle,
  newPassword: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
  newPasswordText: {
    borderBottomWidth: 1,
    color: '#222222',
    fontSize: 11,
  } as TextStyle,
});
