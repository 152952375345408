import RootStack from '../RootStack';

import TemporaryUserTermsAcceptance from '@/pages/temporary_user/terms_acceptance';

const UnacceptedMemberScreens = (
  <>
    <RootStack.Screen
      name={'TemporaryUserTermsAcceptance'}
      component={TemporaryUserTermsAcceptance}
      options={{title: 'サービス利用開始', animationEnabled: false}}
    />
  </>
);

export default UnacceptedMemberScreens;
