import React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import ActorListItem from './ActorListItem';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';
import NoEntities from '@/components/content/NoEntities';

import Actor from '@/entities/Actor';

interface Props {
  actors: Actor[];
  labelPrefix?: string;
  onNotifyActorDelete: (actor: Actor) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null | undefined;
}

const ActorList: React.FC<Props> = props => {
  const {actors, labelPrefix, onNotifyActorDelete, onEndReached} = props;
  const renderItem = React.useCallback((info: ListRenderItemInfo<Actor>) => {
    return (
      <ActorListItem
        actor={info.item}
        onNotifyActorDelete={onNotifyActorDelete}
      />
    );
  }, []);
  if (actors.length === 0) {
    return <NoEntities>{labelPrefix}イラストはありません</NoEntities>;
  }
  return (
    <FlatList
      data={actors}
      contentContainerStyle={style}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
      onEndReached={onEndReached}
    />
  );
};

export default React.memo(ActorList);

const style = {
  paddingBottom: 78,
};
