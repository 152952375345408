import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import NavigationBarButton, {
  Props as NavigationBarButtonProps,
} from './NavigationBarButton';

type ButtonProps = NavigationBarButtonProps | null;

interface Props {
  title?: string | null;
  element?: React.ReactElement<any> | null;
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
  innerRightButton?: ButtonProps;
  hideBottomBorder?: boolean;
}

function generateButton(props: ButtonProps): React.ReactNode {
  if (!props) {
    return null;
  }
  return (
    <NavigationBarButton
      title={props.title}
      tintColor={props.tintColor}
      disabled={props.disabled}
      handler={props.handler}
      children={props.children}
      renderButtonWrapper={props.renderButtonWrapper}
    />
  );
}

const NavigationBar: React.FC<Props> = props => {
  const {
    title,
    element,
    leftButton,
    rightButton,
    innerRightButton,
    hideBottomBorder,
  } = props;
  return (
    <View
      style={
        hideBottomBorder ? styles.containerWithoutBorder : styles.container
      }>
      <View style={styles.inner}>
        <View style={styles.titleWrapper}>
          {element ? (
            element
          ) : (
            <Text style={styles.title} ellipsizeMode={'tail'} numberOfLines={1}>
              {title}
            </Text>
          )}
        </View>
        <View style={styles.button}>
          {leftButton ? generateButton(leftButton) : null}
        </View>

        <View style={styles.button}>
          {innerRightButton ? generateButton(innerRightButton) : null}
          {rightButton ? generateButton(rightButton) : null}
        </View>
      </View>
    </View>
  );
};

export default React.memo(NavigationBar);

const NAV_BAR_HEIGHT = 44;

const container = {
  backgroundColor: 'white',
  borderBottomColor: '#efefef',
} as ViewStyle;

const styles = StyleSheet.create({
  container: {
    ...container,
    borderBottomWidth: 1,
  } as ViewStyle,
  containerWithoutBorder: {
    ...container,
    borderBottomWidth: 0,
  } as ViewStyle,
  inner: {
    alignItems: 'stretch',
    flexDirection: 'row',
    height: NAV_BAR_HEIGHT,
    justifyContent: 'space-between',
  } as ViewStyle,
  titleWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 44,
    right: 44,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 17,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  } as TextStyle,
  button: {
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
  } as ViewStyle,
});
