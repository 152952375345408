import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

interface Props extends React.PropsWithChildren {
  color: string;
  onPress?: () => void;
}

const SocialLoginButton: React.FC<Props> = props => {
  const {color, children, onPress} = props;
  const content = (
    <View style={[styles.container, {backgroundColor: color}]}>{children}</View>
  );
  return onPress ? (
    <TouchableOpacity onPress={onPress}>{content}</TouchableOpacity>
  ) : (
    content
  );
};

export default React.memo(SocialLoginButton);

export const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderRadius: 23,
    backgroundColor: '#efefef',
    height: 46,
    justifyContent: 'center',
    width: 46,
  },
});
