import React from 'react';
import {StyleProp, StyleSheet, TextProps, ViewStyle} from 'react-native';

import {OutlineButton} from '@/components/ui/buttons';

interface Props {
  style?: StyleProp<ViewStyle> | undefined;
  disabled?: boolean;
  onPress: () => void;
}

const ResetButton: React.FC<Props> = props => {
  const {style, disabled, onPress} = props;

  return (
    <OutlineButton
      style={[styles.button, style]}
      textProps={textProps}
      disabled={disabled}
      onPress={onPress}>
      リセット
    </OutlineButton>
  );
};

export default React.memo(ResetButton);

const styles = StyleSheet.create({
  button: {
    width: 84,
    height: 32,
  } as ViewStyle,
});

const textProps: TextProps = {
  style: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
  },
};
