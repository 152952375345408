import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import numeral from 'numeral';

import CreatorAvator from '@/components/utils/CreatorAvator';
import TextButton from '@/components/ui/buttons/TextButton';

import CurrentUser from '@/entities/CurrentUser';
import Balance from '@/entities/Balance';

interface Props {
  currentUser: CurrentUser;
  balance: Balance;
}

const CreatorSection: React.FC<Props> = props => {
  const {currentUser, balance} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo('/user/edit');
  }, []);
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onPress}>
        <CreatorAvator {...props} />
      </TouchableOpacity>
      <View style={styles.center}>
        <Text style={styles.userName}>{currentUser.userName}</Text>
        <Text style={styles.revenue}>
          収益：￥{numeral(balance.amount).format('0,0')}
        </Text>
      </View>
      <View style={styles.right}>
        <TextButton textProps={{style: styles.link}} onPress={onPress}>
          プロフィール修正
        </TextButton>
      </View>
    </View>
  );
};

export default React.memo(CreatorSection);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 16,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  center: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 12,
  } as ViewStyle,
  right: {
    justifyContent: 'flex-end',
  } as ViewStyle,
  userName: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 4,
  } as TextStyle,
  revenue: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  link: {
    color: '#999',
    fontSize: 11,
  } as TextStyle,
});
