import React from 'react';
import {FlatList, ListRenderItemInfo, Platform} from 'react-native';

import HelpListItem from './HelpListItem';

import {ListItemNormalSeparator} from '@/components/ui/lists/separators';

import Help from '@/entities/Help';

interface Props {
  helps: Help[];
  helpIdToCollapsed: {[key: string]: boolean};
  refreshing?: boolean | null;
  scrollEnabled?: boolean | undefined;
  onPressHelp: (help: Help) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
  onRefresh?: () => void;
}

const HelpList: React.FC<Props> = props => {
  const {
    helps,
    helpIdToCollapsed,
    refreshing,
    scrollEnabled,
    onPressHelp,
    onEndReached,
    onRefresh,
  } = props;
  const keyExtractor = React.useCallback(
    (item: Help, index: number): string => `${item.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Help>): React.ReactElement<any> | null => (
      <HelpListItem
        help={info.item}
        collapsed={
          helpIdToCollapsed[info.item.id] === undefined
            ? true
            : helpIdToCollapsed[info.item.id]
        }
        onPressHelp={onPressHelp}
      />
    ),
    [helpIdToCollapsed, onPressHelp],
  );
  return (
    <FlatList
      data={helps}
      refreshing={refreshing}
      scrollEnabled={scrollEnabled}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
      windowSize={5}
      onEndReachedThreshold={0.1}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onRefresh={onRefresh}
    />
  );
};

export default React.memo(HelpList);
