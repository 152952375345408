import React from 'react';
import {Text, View} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {BlackButton, LinkButton} from '@/components/ui/buttons';
import {Heading, Paragraph} from '@/components/content';

import CollapsibleHelpList from '@/pages/helps/components/CollapsibleHelpList';

import Help from '@/entities/Help';

interface Props {
  helps: Help[];
}

const HelpsSection: React.FC<Props> = props => {
  const {helps} = props;
  const linkTo = useLinkTo();
  const onPressHelps = React.useCallback(() => linkTo('/helps'), []);
  return (
    <View>
      <Paragraph color={'#222'} marginTop={24} marginHorizontal={16}>
        こちらはTapNovelCreator（素材サービス）のお問い合わせページとなります。
        {'\n'}
        TapNovel（読書サービス）へのお問い合わせは、TapNovelの画面よりお願いいたします。
      </Paragraph>
      <Paragraph color={'#222'} margin={16}>
        よくいただくご質問を
        <LinkButton bold={true} onPress={onPressHelps}>
          ヘルプ
        </LinkButton>
        にてまとめてございます。{'\n'}
        同じ質問がないか必ずご確認の上、お問い合わせいただきますようお願いいたします。
      </Paragraph>
      <Heading marginHorizontal={16} marginVertical={16}>
        よくあるご質問
      </Heading>
      <CollapsibleHelpList helps={helps} />
      <View style={{alignItems: 'center', marginTop: 24}}>
        <BlackButton onPress={onPressHelps}>ヘルプを見る</BlackButton>
      </View>
    </View>
  );
};

export default React.memo(HelpsSection);
