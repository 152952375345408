import React from 'react';
import {
  ImageStyle,
  ImageURISource,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ImagePickerAdapter, {
  ImagePickerAdapterInstance,
} from './ImagePickerAdapter';

import AddImageIcon from '@/components/icons/AddImageIcon';

import SvgUriAdapter from '@/components/utils/SvgUriAdapter';

import characterSizeUri from '@/assets/svgs/character-size.svg';

interface Props {
  source: ImageURISource | ImageURISource[] | null;
  imageIconStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  onChangeImage?: (file: File) => void;
}

const ActorImagePicker: React.FC<Props> = props => {
  const {source, imageIconStyle, disabled, onChangeImage} = props;
  const ref = React.useRef<ImagePickerAdapterInstance | null>(null);
  const onPress = React.useCallback(() => {
    ref.current?.openFileBrowser();
  }, []);
  return (
    <View style={styles.container}>
      <ImagePickerAdapter
        ref={ref}
        imageSource={source}
        imageStyle={styles.image}
        imageContainerStyle={styles.imageContainer}
        imageIconStyle={imageIconStyle}
        disabled={disabled}
        onChangeImage={onChangeImage}
        noImage={noImage}
      />
      <Pressable
        style={styles.characterSize}
        disabled={disabled}
        onPress={onPress}>
        <Text style={styles.characterSizeTitle}>
          標準的なキャラクターサイズ
        </Text>
        <View style={styles.characterSizeImage}>
          <SvgUriAdapter
            uri={characterSizeUri}
            width={CHARACTER_SIZE_WIDTH}
            height={CHARACTER_SIZE_HEIGHT}
          />
        </View>
      </Pressable>
    </View>
  );
};

export default React.memo(ActorImagePicker);

const PICKER_WIDTH = 293;
const PICKER_HEIGHT = 415;

const CHARACTER_SIZE_WIDTH = 140;
const CHARACTER_SIZE_HEIGHT = 356;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#efefef',
    width: PICKER_WIDTH,
    height: PICKER_HEIGHT,
    alignSelf: 'center',
  },
  image: {
    width: PICKER_WIDTH,
    height: PICKER_HEIGHT,
  } as ImageStyle,
  imageContainer: {} as ViewStyle,
  noImage: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  noImageTitle: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 16,
    marginBottom: 8,
  } as TextStyle,
  noImageText: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  characterSize: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
    ...StyleSheet.absoluteFillObject,
  } as ViewStyle,
  characterSizeTitle: {
    color: '#bbb',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  characterSizeImage: {
    width: CHARACTER_SIZE_WIDTH,
    height: CHARACTER_SIZE_HEIGHT,
  } as ViewStyle,
});

const noImage = (
  <View style={styles.noImage}>
    <AddImageIcon size={35} color={'#383838'} />
    <Text style={styles.noImageTitle}>イラストをアップロード</Text>
    <Text style={styles.noImageText}>
      ※大きさ：2039×2894 px{'\n'}
      ※容{'　'}量：5MBまで{'\n'}
      ※形{'　'}式：PNGのみ{'\n'}
      ※背{'　'}景：透明
    </Text>
  </View>
);
