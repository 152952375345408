import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import PrimaryButton, {Props} from './PrimaryButton';

const BottomButton: React.FC<Props> = props => {
  return (
    <View style={styles.container}>
      <PrimaryButton {...props} />
    </View>
  );
};

export default React.memo(BottomButton);

export const BOX_HEIGHT = 78;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: BOX_HEIGHT,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
