import ReactDOM from 'react-dom/client';
import {Linking} from 'react-native';
import {getStateFromPath, getActionFromState} from '@react-navigation/native';
import urlparser from 'url';
import queryString from 'query-string';

import App from './App';
import Alert from '@/components/alert/Alert';
import LinkingConfig from '@/navigation/LinkingConfig';
import navigationRef from '@/navigation/ref';
import oauthCallback from './helpers/OauthCallbackHelper';
import AccessToken from './stores/AccessToken';

Linking.getInitialURL().then((url: string | null) => {
  return url && handleOpenURL(url);
});

const handleOpenURL = (url: string | null) => {
  if (!url) {
    return;
  }
  const parsedQuery = urlparser.parse(url);
  if (!parsedQuery.pathname) {
    return;
  }
  const path = parsedQuery.pathname.substr(1);
  if (path === '') {
    return;
  }
  const params = queryString.parse(parsedQuery.search || '');
  oauthCallback(parsedQuery, params);
  const state = getStateFromPath(path, {
    screens: LinkingConfig,
  });
  if (!state) {
    return;
  }

  const action = getActionFromState(state);

  if (!action) {
    return;
  }

  try {
    navigationRef?.current?.dispatch(action);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

window.addEventListener('message', event => {
  if (
    typeof event.data === 'string' &&
    event.data.startsWith('dispatchAction')
  ) {
    //
  } else if (
    typeof event.data === 'string' &&
    event.data.startsWith('sendAccessToken')
  ) {
    const accessToken = event.data.split(',')[1];
    AccessToken.set(accessToken);
  }
});

const elem = document.getElementById('root');
if (elem) {
  const root = ReactDOM.createRoot(elem);
  root.render(
    <>
      <App />
      <Alert.AlertView />
    </>,
  );
}
