import React from 'react';
import {Animated, Easing, Platform, ViewStyle} from 'react-native';

export type DialogAnimationProps = {
  opacity?: boolean;
  scale?: boolean;
};

interface Props extends React.PropsWithChildren, DialogAnimationProps {}

const ModalDialogAnimation: React.FC<Props> = props => {
  const style = useAnimatedStyle(props);
  return <Animated.View {...props} style={style} />;
};

export default React.memo(ModalDialogAnimation);

const useAnimatedStyle = (options: {opacity?: boolean; scale?: boolean}) => {
  const opacityValue = React.useRef(new Animated.Value(0));
  const scaleValue = React.useRef(new Animated.Value(0));
  React.useEffect(() => {
    const animationConfig = defaultAnimationConfig;
    Animated.sequence([
      Animated.parallel([
        Animated.timing(opacityValue.current, animationConfig),
        Animated.timing(scaleValue.current, {
          ...animationConfig,
          toValue: 1.2,
        }),
      ]),
      Animated.timing(scaleValue.current, animationConfig),
    ]).start();
  }, []);
  const opacity = opacityValue.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0.5, 1],
  });
  const scale = scaleValue.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0.9, 1],
  });
  const style = {
    width: Platform.select({default: '100%', web: undefined}),
    height: Platform.select({default: '100%', web: undefined}),
    alignItems: 'center',
    justifyContent: 'center',
  } as Animated.WithAnimatedObject<ViewStyle>;
  if (options.opacity) {
    style.opacity = opacity;
  }
  if (options.scale) {
    style.transform = [{scale}];
  }
  return React.useMemo(() => style, []);
};

const defaultAnimationConfig = {
  duration: 120,
  easing: Easing.linear,
  toValue: 1,
  useNativeDriver: true,
};
