import React from 'react';
import {
  Dimensions,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ModalDialogAnimation, {
  DialogAnimationProps,
} from '../ModalDialogAnimation';

import {PrimaryButton} from '@/components/ui/buttons';

export interface Props extends React.PropsWithChildren {
  title?: React.ReactNode;
  dialogAnimationProps?: DialogAnimationProps;
  onRequestClose?: ((event: NativeSyntheticEvent<any>) => void) | undefined;
  onPressOK?: () => void;
}

const Dialog: React.FC<Props> = props => {
  const {title, dialogAnimationProps, children, onRequestClose, onPressOK} =
    props;
  const onPress = React.useCallback((event: NativeSyntheticEvent<any>) => {
    onPressOK && onPressOK();
    onRequestClose && onRequestClose(event);
  }, []);
  return (
    <ModalDialogAnimation {...dialogAnimationProps}>
      <View style={styles.container}>
        <Text style={styles.title}>{title}</Text>
        {typeof children === 'string' ? (
          <Text style={styles.content}>{children}</Text>
        ) : (
          children
        )}
        <PrimaryButton style={styles.button} onPress={onPress}>
          OK
        </PrimaryButton>
      </View>
    </ModalDialogAnimation>
  );
};

export default React.memo(Dialog);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 16,
    paddingTop: 24,
    width: Platform.select<number | string>({
      web: 'min(100vw, 311px)',
      default: Dimensions.get('window').width * 0.82,
    }),
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  title: {
    color: '#e7b600',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  } as TextStyle,
  content: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
  button: {
    width: 160,
    marginTop: 24,
  } as ViewStyle,
});
